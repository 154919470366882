import React from 'react';

import { useTitle } from '@src/hooks/useTitle';
import { Box } from '@src/stories/Components/Layout/Box';
import { Text } from '@src/stories/Components/UI/Text';
import { Button } from '@src/stories/Components/UX/Button';

import { type MapStateComponentProps } from '../SharedTypes';

import styles from './style.scss';

export const Header: React.FC<MapStateComponentProps> = () => {
  const title = { key: 'screen.title.task_create' }
  useTitle(title);

  return (
    <Box className={styles.header}>
      <Text
        tagName="h1"
        variant="larger"
        bold
        i18n={title}
      />
      <Text
        tagName="p"
        i18n={{ key: 'map.overview.add_task.instructions' }}
      />
    </Box>
  );
};

export const Footer: React.FC<MapStateComponentProps> = ({
  stateRef,
  onSubmit,
  onCancel,
}) => {
  const [ready, setReady] = React.useState(false);

  React.useEffect(() => {
    const onUpdate = ({ points }) => {
      setReady(points.length > 0);
    };

    stateRef.current?.addListener(onUpdate);

    return () => {
      stateRef.current?.removeListener(onUpdate);
    };
  }, [stateRef.current]);

  return (
    <div className={styles.footer}>
      <div className={styles.footerButtons}>
        <Button
          flex
          intent="destructive"
          outline
          type="reset"
          onClick={onCancel}
          label={{ key: 'map.overview.add_task.footer.cancel.label' }}
        />
        <Button
          flex
          type="submit"
          disabled={!ready}
          onClick={onSubmit}
          label={{ key: 'map.overview.add_task.footer.submit.label' }}
        />
      </div>
    </div>
  );
};
