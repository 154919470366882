import React from 'react';

import { MobbleAppDisplayItem } from '@src/screens/Settings/hooks/useGetMobbleAppAction';
import { Box } from '@src/stories/Components/Layout/Box';
import { Spacer } from '@src/stories/Components/Layout/Spacer';
import { HStack } from '@src/stories/Components/Layout/Stack';
import { Text } from '@src/stories/Components/UI/Text';
import { Button } from '@src/stories/Components/UX/Button';

import styles from './mobbleAppCard.scss';

export const MobbleAppCard = ({
  app,
  onClick,
}: {
  app: MobbleAppDisplayItem;
  onClick: () => void;
}) => {
  return (
    <Box className={styles.appTile}>
      <Box
        style={{
          padding: 20,
        }}
      >
        <img src={app.icon} className={styles.appIcon} />
      </Box>
      <Box spacing={{ left: 3 }}>
        <Text variant="card-title" i18n={app.title} />
      </Box>
      <Spacer y={2} />
      <Box spacing={{ left: 3, right: 3 }}>
        <Text variant="body" i18n={app.description} />
      </Box>
      <Spacer y={2} />
      <HStack flex className={styles.buttonsContainer}>
        <Button
          outline
          intent="secondary"
          label={{ key: 'mobble-apps.app.generic.learnMore' }}
          onClick={() => window.open(app.learnMore, '_blank')}
        />

        <Button
          intent={app.enabled ? 'secondary' : 'primary'}
          label={app.enabled ? app.disableText : app.enableText}
          onClick={onClick}
        />
      </HStack>
    </Box>
  );
};
