import React from 'react';

import { UserRole } from '@mobble/models/src/model/User';

import {
  FormBuilder,
  FormBuilderProps,
} from '@src/stories/Views/Form/FormBuilder';

export interface SettingsPropertyInviteUserFormProps {
  loading?: boolean;
  onSubmit: (formValues: SettingsPropertyInviteUserFormValues) => void;
  onCancel: () => void;
  roleOptions: { value: UserRole; label: string }[];
  propertyOptions: { value: string; label: string }[];
  initialValues: SettingsPropertyInviteUserFormValues;
}

export interface SettingsPropertyInviteUserFormValues {
  email: string;
  role: UserRole;
  propertyIds: string[];
}

export const SettingsPropertyInviteUserForm: React.FC<
  SettingsPropertyInviteUserFormProps
> = ({
  loading,
  onSubmit,
  onCancel,
  roleOptions,
  propertyOptions,
  initialValues,
}) => {
  const isPropertyOptionsNotRequired = propertyOptions.length <= 1;
  const form: FormBuilderProps<SettingsPropertyInviteUserFormValues> = {
    flex: true,
    i18nRootKey: 'settings.property.users.invite_user.form',
    fields: [
      {
        name: 'email',
        type: 'email',
        required: true,
      },
      {
        name: 'role',
        type: 'select',
        options: roleOptions,
        required: true,
        initialValue: initialValues.role,
      },
      {
        name: 'propertyIds',
        type: 'select-multiple',
        options: propertyOptions,
        required: isPropertyOptionsNotRequired,
        initialValue: initialValues.propertyIds,
        disabled: () => isPropertyOptionsNotRequired,
      },
    ],
    loading,
    onSubmit,
    onCancel,
  };

  return <FormBuilder {...form} />;
};
