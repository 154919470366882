import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import mobbleService from '@mobble/service';
import { useRootDispatch } from '@mobble/store/src/hooks/root';
import { setDisplayName,setUser } from '@mobble/store/src/reducers/auth';

import * as ROUTE_NAME from '@src/screens/config/routeNames';

export const useGetInvitationEmailFromToken = (token: string) => {
  const [email, setEmail] = useState<string | null>(null);

  useEffect(() => {
    if (!token) {
      window.open(ROUTE_NAME.AUTH_SIGN_UP, '_self');
      return;
    }

    mobbleService.api.me
      .getEmailByToken(token)
      .then((email) => {
        if (email) setEmail(email);
      })
      .catch(() => {
        window.open(ROUTE_NAME.AUTH_SIGN_UP, '_self');
      });
  }, [token]);

  return email;
};

export const useAcceptInvite = () => {
  const dispatch = useRootDispatch();

  const onAcceptInvite = async ({
    email,
    password,
    firstName,
    lastName,
  }: {
    email: string;
    password: string;
    firstName: string;
    lastName: string;
  }): Promise<void> => {
    const name = `${firstName} ${lastName}`;
    dispatch(setDisplayName(name));

    const { user } = await mobbleService.auth.createUserWithEmailAndPassword(
      email,
      password
    );

    const updatedUser = await mobbleService.api.me.update({
      id: user.id,
      email: user.email,
      name,
    });

    dispatch(
      setUser({
        ...user,
        ...updatedUser,
      })
    );
  };

  return onAcceptInvite;
};

export const useGetInviteTokenFromQueryParam = () => {
  const [queryParams] = useSearchParams();
  const token = queryParams.get('token');

  return token;
};
