import React from 'react';

import {
  I18nItem,
  makeQuantityFormatMessageItem,
  useI18n,
} from '@mobble/i18n/src';
import { InventoryCategory } from '@mobble/models/src';
import { inventoryCategoryToColor } from '@mobble/models/src/model/Inventory';
import { InventoryItemBatch } from '@mobble/models/src/model/InventoryItemBatch';
import { formatDate } from '@mobble/shared/src/core/Date';

import { Clickable } from '@src/stories/Components/UX/Clickable';

import { Box } from '../../Components/Layout/Box';
import { Card } from '../../Components/Layout/Card';
import { Spacer } from '../../Components/Layout/Spacer';
import { HStack } from '../../Components/Layout/Stack';
import { Text } from '../../Components/UI/Text';

interface InventoryItemBatchCardProps {
  inventoryItemBatch: InventoryItemBatch;
  href?: string;
  onClick?: (inventoryItem: InventoryItemBatch) => void;
}

interface InfoRow {
  label: I18nItem;
  value?: string | I18nItem;
}

export const InventoryItemBatchCard: React.FC<InventoryItemBatchCardProps> = ({
  inventoryItemBatch,
  href,
  onClick,
}) => {
  const makeKey = (label: string): I18nItem => ({
    key: `inventories.list.inventory_item_batch.card.${label}.label`,
  });
  const { formatMessage } = useI18n();

  const details: InfoRow[] = (() => {
    switch (inventoryItemBatch.category) {
      case InventoryCategory.Feed:
        return [
          {
            label: makeKey('supplierName'),
            value: inventoryItemBatch.supplierName,
          },
          {
            label: makeKey('quantity'),
            value:
              inventoryItemBatch.quantity &&
              formatMessage(
                ...makeQuantityFormatMessageItem(inventoryItemBatch.quantity)
              ),
          },
          ...(inventoryItemBatch.dateReceived
            ? [
                {
                  label: makeKey('dateReceived'),
                  value: formatDate(inventoryItemBatch.dateReceived),
                },
              ]
            : []),
        ];
      case InventoryCategory.Chemicals:
        return [
          {
            label: makeKey('name'),
            value: inventoryItemBatch.name,
          },
          {
            label: makeKey('quantity'),
            value: formatMessage(
              ...makeQuantityFormatMessageItem(inventoryItemBatch.quantity)
            ),
          },
          ...(inventoryItemBatch.dateReceived
            ? [
                {
                  label: makeKey('dateReceived'),
                  value: formatDate(inventoryItemBatch.dateReceived),
                },
              ]
            : []),
        ];
    }
  })();

  const card = (
    <Card color={inventoryCategoryToColor(inventoryItemBatch.category)}>
      <Spacer y={1} />
      <Box>
        {details.map(({ label, value }, index) => (
          <Box key={index} spacing={{ bottom: 1 }}>
            <HStack>
              <Text variant="small" i18n={label} />
              <Spacer flex />

              {typeof value === 'string' ? (
                <Text variant="small">{value}</Text>
              ) : (
                <Text variant="small" i18n={value} />
              )}
            </HStack>
          </Box>
        ))}
      </Box>
    </Card>
  );

  return href || typeof onClick === 'function' ? (
    <Clickable fullWidth href={href ? href : () => onClick(inventoryItemBatch)}>
      {card}
    </Clickable>
  ) : (
    card
  );
};
