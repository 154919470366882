import React from 'react';

import { type ConfiguredPropertyType } from '@mobble/models/src/model/Property';

import { FormBuilder, type FormBuilderProps } from '../Form/FormBuilder';

export interface SettingsPropertyTypesEditFormProps {
  showColor?: boolean;
  hasTagColors: boolean;
  loading?: boolean;
  onSubmit: (formValues: SettingsPropertyTypesEditFormValues) => void;
  onCancel: () => void;
  type: ConfiguredPropertyType;
}

export interface SettingsPropertyTypesEditFormValues {
  color: string;
}

export const SettingsPropertyTypesEditForm: React.FC<
  SettingsPropertyTypesEditFormProps
> = ({ type, showColor, loading, onSubmit, onCancel, hasTagColors }) => {
  const form: FormBuilderProps<SettingsPropertyTypesEditFormValues> = {
    flex: true,
    i18nRootKey: 'settings.property.types.edit.form',
    fields: [
      {
        name: 'label',
        type: 'display',
        initialValue: type.label,
      },
      {
        name: 'color',
        type: hasTagColors ? 'color-tags' : 'color',
        initialValue: type.color,
        show: () => showColor ?? false,
      },
    ],
    footer: showColor,
    loading,
    onSubmit,
    onCancel,
  };

  return <FormBuilder {...form} />;
};
