import React from 'react';

import { useI18n } from '@mobble/i18n';
import { type Mob } from '@mobble/models/src/model/Mob';
import {
  sortAnyContainingPaddocks,
  sortOptionsNameDistanceFromMe,
} from '@mobble/models/src/model/Paddock';
import { type ConfiguredPropertyTypeGroupCustom } from '@mobble/models/src/model/Property';
import {
  stockingRateToDSE,
  StockingUnit,
} from '@mobble/models/src/model/Settings';
import { useEntitiesRefresher } from '@mobble/shared/src/hooks/useEntitiesRefresher';
import {
  useExtStatus,
  useMobs,
  usePaddockGeometries,
  usePaddocks,
  useProperties,
  useSettings,
} from '@mobble/store/src/hooks';

import { useFormError } from '@src/hooks/useFormError';
import { useMyLocation } from '@src/hooks/useMyLocation';
import { useNavigateBack } from '@src/hooks/useNavigateBack';
import { usePaddockCardFactory } from '@src/hooks/usePaddockCardFactory';
import { EntitySliceFactoryPrelude } from '@src/stories/Views/Misc/EntitySliceFactoryPrelude';
import { ScreenHeader } from '@src/stories/Views/Misc/ScreenHeader';
import {
  MobCreateWizard,
  type MobCreateWizardProps,
} from '@src/stories/Views/Mob/MobCreateWizard';

import * as ROUTE_NAME from '../config/routeNames';
import { type ScreenRendererProps } from '../config/types';

export const MobCreateHeader: React.FC<ScreenRendererProps> = () => {
  const { formatMessage } = useI18n();
  const goBack = useNavigateBack();

  return (
    <ScreenHeader
      modal
      title={formatMessage({
        defaultMessage: 'Add a new mob',
        description: 'screen.title.mob_create',
      })}
      breadcrumbs={[
        {
          title: formatMessage({
            defaultMessage: 'Mobs',
            description: 'screen.title.mobs',
          }),
          href: ROUTE_NAME.MOBS_LIST,
        },
      ]}
      onClose={goBack}
    />
  );
};

export const MobCreate: React.FC<ScreenRendererProps> = (props) => {
  const { formatMessage } = useI18n();
  const { settings, update } = useSettings();
  const stockingUnit = settings.stockingUnit as StockingUnit;
  const mobBreed = settings.mobBreed;
  const mobType = settings.mobType;
  const properties = useProperties();
  const propertyId = properties.selected?.id;
  const paddocks = usePaddocks(propertyId);
  const paddockGeometries = usePaddockGeometries(propertyId);
  const { location } = useMyLocation();
  const goBack = useNavigateBack();
  const mobs = useMobs(propertyId);
  const makePaddockCard = usePaddockCardFactory({
    propertyId: propertyId,
    location,
  });
  const extStatus = useExtStatus('mobs', 'create', propertyId);
  const formLoading = extStatus?.loading;
  const formError = useFormError({
    entityName: formatMessage({
      defaultMessage: 'Mob',
      description: 'screen.title.mob_detail',
    }),
    active: Boolean(extStatus?.error),
  });

  useEntitiesRefresher([paddocks, mobs], propertyId);

  const Prelude = EntitySliceFactoryPrelude({
    preludes: [properties.prelude, paddocks.prelude],
  });

  if (Prelude) {
    return Prelude;
  }

  const paddockSortMeta = {
    origin: location || undefined,
    paddockGeometries: paddockGeometries.entities,
    mobs: mobs.allEntitiesAvailable,
  };

  const sortPaddockOptions = sortAnyContainingPaddocks((o) => o.entity)(
    paddockSortMeta
  );

  const paddockOptions = paddocks.entities.map((p) => ({
    label: p.name,
    value: p.id,
    entity: p,
    component: makePaddockCard(p),
  }));

  const handleSubmit: MobCreateWizardProps['onSubmit'] = (formValues) => {
    if (!properties.selected) {
      return Promise.reject();
    }

    const newMob: Omit<Mob, 'id'> = {
      type: formValues.mob_form.type,
      DSE: stockingRateToDSE(stockingUnit)(formValues.mob_form.stocking_rate),
      size: Number(formValues.mob_form.size),
      breed: formValues.mob_form.breed,
      gender: formValues.mob_form.gender,
      classes: formValues.mob_form.classes ?? [],
      ages: formValues.mob_form.ages.map((age: string) => Number(age)),
      paddockId: formValues.mob_form.paddock,
      safeDate: undefined,
      propertyId: properties.selected.id,
      purchaseInformation:
        formValues.type_of_addition.addition_type === 'purchase'
          ? {
              purchaseDate: formValues.purchase_form.date,
              pricePerHeadCents:
                Number(formValues.purchase_form.price_per_head) * 100,
              notes: formValues.purchase_form.notes,
              seller: formValues.purchase_form.vendor,
            }
          : undefined,
      naturalIncreaseInformation:
        formValues.type_of_addition.addition_type === 'natural_increase'
          ? {
              naturalIncreaseDate: formValues.natural_increase_form.date,
              notes: formValues.natural_increase_form.notes,
            }
          : undefined,
    };

    return mobs
      .create(newMob)
      .then((mob) => {
        // TODO: replace with Toast/Alert
        console.log(`Mob '${mob.type}' successfully created`);
        console.log(mob);

        // Save mob breed and type to settings
        update({
          mobBreed: mob.breed,
          mobType: mob.type,
        });

        goBack();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleCreateCustomField = (
    group: ConfiguredPropertyTypeGroupCustom,
    parentId: string,
    label: string
  ) => {
    if (!properties.selected) {
      return Promise.reject();
    }
    return properties.addPropertyType({
      group,
      parentId,
      label,
    });
  };

  const initialValues = {
    mob_form: {
      paddock: props.route.params.paddockId,
      breed: mobBreed,
      type: mobType,
    },
  };

  return (
    <MobCreateWizard
      paddockOptions={paddockOptions}
      paddockSortOptions={sortOptionsNameDistanceFromMe}
      sortPaddockOptions={sortPaddockOptions}
      initialValues={initialValues}
      error={formError}
      loading={formLoading}
      onSubmit={handleSubmit}
      onCreateCustomField={handleCreateCustomField}
      paddocks={paddocks.entities}
      propertyTypes={properties.selected.types}
      stockingUnit={stockingUnit}
    />
  );
};

export default {
  modal: true,
  name: ROUTE_NAME.MODAL_MOB_CREATE,
  header: MobCreateHeader,
  component: MobCreate,
};
