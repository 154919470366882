import React from 'react';
import classNames from 'classnames/bind';

import { useI18n } from '@mobble/i18n/src';
import {
  getLivestockAgeColor,
  type Mob,
  toMobDisplayName,
} from '@mobble/models/src/model/Mob';
import { type Paddock } from '@mobble/models/src/model/Paddock';
import { ConfiguredPropertyType } from '@mobble/models/src/model/Property';
import {
  formatStockingRate,
  StockingUnit,
} from '@mobble/models/src/model/Settings';
import { dateIsInThePast } from '@mobble/shared/src/core/Date';
import { formatNumber } from '@mobble/shared/src/core/Number';

import { useSetting } from '@src/context/settings';
import { getStockingUnitI18n } from '@src/stories/Components/Locale/LocaleStockingUnit';

import BadgeList from '../BadgeList';
import Card, { type CardProps } from '../Card';

import styles from './MobCard.scss';
const cx = classNames.bind(styles);

export interface MobCardProps extends Omit<CardProps, 'onClick'> {
  /**
   * Mob object
   */
  mob: Mob;

  /**
   * Displays the Paddock name underneath the mob name
   */
  paddockName?: Paddock['name'];

  /**
   * Configured property types
   * Used to get colours of badges for ages/classes
   */
  propertyTypes: ConfiguredPropertyType[];

  /**
   * Size of the card, defaults to `medium`
   */
  size?: CardProps['size'];

  /**
   * Optional click handler
   * Wrap in an `<a>` if it should be a link
   */
  onClick?: (mob: Mob) => void;
}

/**
 * MobCard displays the Mob name, size, ages, classes and Paddock name
 * It is a clickable card that navigates to the href
 */
const MobCard: React.FC<MobCardProps> = ({
  mob,
  paddockName,
  propertyTypes,
  size = 'medium',
  onClick,
  className,
  ...others
}) => {
  const { formatMessage } = useI18n();
  const stockingUnit = useSetting('stockingUnit') as StockingUnit;
  const stockingUnitLabel = formatMessage(getStockingUnitI18n(stockingUnit));
  const convertStockingRate = (stockingRate: number) =>
    formatStockingRate(stockingRate, stockingUnit);
  const hasClickHandler = typeof onClick === 'function';
  const isSafe = mob.safeDate ? dateIsInThePast(mob.safeDate) : true;
  const filteredClasses = (mob.classes || []).filter(Boolean);

  const rootClasses = cx(
    {
      MobCard: true,
      clickable: hasClickHandler,
      [size]: true,
    },
    className
  );

  const topRight = (
    <div className={styles.mobSize}>
      {!isSafe && (
        <div
          aria-label={formatMessage({
            defaultMessage: 'Within a withhold',
            description: 'Mob within a withhold icon description',
          })}
          data-safe-date={mob.safeDate}
          className={styles.unSafeDot}
        />
      )}
      <p>
        <span>{formatNumber(mob.size)}</span>
        <span>
          {formatMessage(
            {
              defaultMessage: '{STOCKING_UNIT}/head: <b>{STOCKING_RATE}</b>',
              description: 'Stocking unit per head label',
            },
            {
              STOCKING_UNIT: stockingUnitLabel,
              STOCKING_RATE: convertStockingRate(mob.DSE),
            }
          )}
        </span>
      </p>
    </div>
  );

  const title = (
    <div className={styles.title}>
      <h1>{toMobDisplayName(mob)}</h1>
      {paddockName ? <h2>{paddockName}</h2> : null}
    </div>
  );

  const handleClick = () => {
    if (onClick) {
      onClick(mob);
    }
  };

  return (
    <Card
      title={title}
      topRight={topRight}
      size={size}
      className={rootClasses}
      onClick={handleClick}
      {...others}
    >
      <BadgeList
        label={formatMessage({
          defaultMessage: 'Ages:',
          description: 'mobs.list.mob.card.ages',
        })}
        badges={mob.ages.map((age) => ({
          label: `${age}`,
          color: getLivestockAgeColor(propertyTypes, mob.type)(age),
        }))}
        badgesToShow={3}
        className={styles.badgeList}
      />

      {filteredClasses.length > 0 ? (
        <BadgeList
          label={formatMessage({
            defaultMessage: 'Classes:',
            description: 'mobs.list.mob.card.classes',
          })}
          defaultColor="var(--neutrals-bg-default)"
          badges={filteredClasses.map((label) => ({
            label,
          }))}
          badgesToShow={3}
          className={styles.badgeList}
        />
      ) : null}
    </Card>
  );
};

export default MobCard;
