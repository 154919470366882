import React from 'react';
import { useNavigate } from 'react-router-dom';

import { useI18n } from '@mobble/i18n';
import { CustomMapLayer } from '@mobble/models/src';
import { filterCustomMapLayers } from '@mobble/models/src/model/CustomMapLayers';
import { useEntitiesRefresher } from '@mobble/shared/src/hooks/useEntitiesRefresher';
import { useCustomMapLayers } from '@mobble/store/src/hooks';
import { useProperties } from '@mobble/store/src/hooks/properties';

import { Button, Text } from '@src/components';
import { toPath } from '@src/interfaces/Routing';
import { Box } from '@src/stories/Components/Layout/Box';
import { Spacer } from '@src/stories/Components/Layout/Spacer';
import { CustomMapLayerCard } from '@src/stories/Views/CustomMapLayer/CustomMapLayerCard';
import { makeCustomMapLayersTableColumns } from '@src/stories/Views/CustomMapLayer/List/customMapLayersTableColumns';
import {
  EntitiesViewer,
  EntitiesViewerProps,
} from '@src/stories/Views/Entities/EntitiesViewer';
import { ScreenHeader } from '@src/stories/Views/Misc/ScreenHeader';

import { useLinking } from '../../hooks/useLinking';
import { EntitySliceFactoryPrelude } from '../../stories/Views/Misc/EntitySliceFactoryPrelude';
import * as ROUTE_NAME from '../config/routeNames';
import { ScreenRendererProps } from '../config/types';

import { contactEmail, toContactEmailURL } from './hooks/useBilling';

import styles from './settingsPropertyCustomMapLayers.scss';

export const SettingsPropertyCustomMapLayersHeader: React.FC<
  ScreenRendererProps
> = () => {
  const { formatMessage } = useI18n();
  const linkTo = useLinking();

  return (
    <ScreenHeader
      title={formatMessage({
        defaultMessage: 'Custom map layers',
        description: 'screen.title.settings_property_custom_map_layers',
      })}
      breadcrumbs={[
        {
          title: formatMessage({
            description: 'screen.title.settings',
            defaultMessage: 'Settings',
          }),
          href: ROUTE_NAME.SETTINGS_LIST,
        },
      ]}
      onGoBack={() => {
        linkTo(ROUTE_NAME.SETTINGS_LIST);
      }}
    />
  );
};

const handleContactMobble = () => {
  window.location.href = toContactEmailURL(contactEmail);
};

export const SettingsPropertyCustomMapLayers: React.FC<
  ScreenRendererProps
> = () => {
  const { formatMessage } = useI18n();
  const navigate = useNavigate();

  const properties = useProperties();
  const propertyId = properties.selected?.id;
  const customMapLayers = useCustomMapLayers(properties.selected?.id);

  const Prelude = EntitySliceFactoryPrelude({
    preludes: [properties.prelude, customMapLayers.prelude],
    required: [properties.selected],
  });

  const { refresh } = useEntitiesRefresher([customMapLayers], propertyId);

  if (Prelude) {
    return Prelude;
  }

  const handleClick = (customMapLayer: CustomMapLayer) => {
    navigate(
      toPath(ROUTE_NAME.CUSTOM_MAP_LAYER_DETAIL, {
        customMapLayerId: customMapLayer.id,
      })
    );
  };

  if (!customMapLayers?.entities?.length) {
    return (
      <Box className={styles.settingsPropertyCustomMapLayers}>
        <Text bold variant="larger">
          {formatMessage({
            description: 'customised map layers title',
            defaultMessage: 'Display your own map layers on mobble',
          })}
        </Text>
        <Spacer y={1} />

        <Text>
          {formatMessage({
            description: 'customised map layers description',
            defaultMessage:
              "Custom map layers are an enterprise-level feature, please reach out to us if you're interested.",
          })}
        </Text>
        <Spacer y={2} />
        <Button
          onClick={handleContactMobble}
          label={contactEmail}
          icon="mail"
        />
        <Spacer x={1} />
      </Box>
    );
  }

  const viewerProps: EntitiesViewerProps<CustomMapLayer> = {
    provider: customMapLayers,
    onEmpty: formatMessage({
      defaultMessage: 'There are no custom map layers to display',
      description: 'custom_map_layers.table.empty',
    }),
    applyFilter: filterCustomMapLayers,
    onRefresh: refresh,
    onClickTableRow: handleClick,
    renderEntityCard: (customMapLayer) => {
      return (
        <Box spacing={1}>
          <CustomMapLayerCard
            customMapLayer={customMapLayer}
            onClick={handleClick}
          />
        </Box>
      );
    },
    tableColumns: makeCustomMapLayersTableColumns({
      formatMessage,
    }),
  };
  return <EntitiesViewer key={properties.selected?.id} {...viewerProps} />;
};

export default {
  name: ROUTE_NAME.SETTINGS_PROPERTY_CUSTOM_MAP_LAYERS,
  header: SettingsPropertyCustomMapLayersHeader,
  component: SettingsPropertyCustomMapLayers,
};
