import React, { useState } from 'react';

import { Color } from '@mobble/colors';
import { useI18n } from '@mobble/i18n';

import { Box } from '@src/stories/Components/Layout/Box';
import { Spacer } from '@src/stories/Components/Layout/Spacer';
import { HStack } from '@src/stories/Components/Layout/Stack';
import { Icon } from '@src/stories/Components/UI/Icon';
import { Text } from '@src/stories/Components/UI/Text';
import { Clickable } from '@src/stories/Components/UX/Clickable';
import { ModalSelect } from '@src/stories/Components/UX/ModalSelect';

export interface RainGaugeYearSelectorProps {
  year: number;
  availableYears: number[];
  onChange: (year: number) => void;
}

export const RainGaugeYearSelector: React.FC<RainGaugeYearSelectorProps> = ({
  year,
  availableYears,
  onChange,
}) => {
  const { formatMessage } = useI18n();
  const [modalVisible, setModalVisible] = useState(false);

  const styles = {
    disabledButton: {
      opacity: 0.25,
    },
  };

  const previousYear = year - 1;
  const previousYearAvailable = availableYears.includes(previousYear);
  const nextYear = year + 1;
  const nextYearAvailable = availableYears.includes(nextYear);

  return (
    <Box background={Color.LightGrey}>
      <HStack alignment="center">
        <Box>
          <Clickable
            href={() => onChange(previousYear)}
            disabled={!previousYearAvailable}
          >
            <Box
              spacing={1.5}
              style={previousYearAvailable ? {} : styles.disabledButton}
            >
              <HStack alignment="center">
                <Icon name="chevron-left" />
                <Spacer x={0.5} />
                <Text>{previousYear}</Text>
              </HStack>
            </Box>
          </Clickable>
        </Box>
        <Spacer flex />
        <Box>
          <Clickable
            href={() => {
              if (availableYears.length > 1) {
                setModalVisible(true);
              }
            }}
          >
            <HStack>
              <Spacer flex />
              <Text bold>{year}</Text>
              <Spacer flex />
            </HStack>
          </Clickable>
        </Box>
        <Spacer flex />
        <Box>
          <Clickable
            href={() => onChange(nextYear)}
            disabled={!nextYearAvailable}
          >
            <Box
              spacing={1.5}
              style={nextYearAvailable ? {} : styles.disabledButton}
            >
              <HStack alignment="center">
                <Text>{nextYear}</Text>
                <Spacer x={0.5} />
                <Icon name="chevron-right" />
              </HStack>
            </Box>
          </Clickable>
        </Box>
      </HStack>
      <ModalSelect
        title={formatMessage({
          defaultMessage: 'Select year',
          description: 'rain-gauges.rain-gauge.year_selector.modal.title',
        })}
        visible={modalVisible}
        onCancel={() => {
          setModalVisible(false);
        }}
        onChange={(value) => {
          setModalVisible(false);
          onChange(parseInt(value, 10));
        }}
        options={availableYears.map((y) => ({
          label: String(y),
          value: String(y),
        }))}
      />
    </Box>
  );
};
