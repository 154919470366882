import React from 'react';

import { Box } from '@src/stories/Components/Layout/Box';

import { Button } from '../../Components/UX/Button';

import styles from './signoutForm.scss';

export interface SettingsSignOutFormProps {
  onSignOut: () => void;
}

export const SettingsSignOutForm: React.FC<SettingsSignOutFormProps> = ({
  onSignOut,
}) => {
  return (
    <Box className={styles.form}>
      <Button
        flex
        icon="sign-out"
        label={{ key: 'settings.sign_out.button.label' }}
        intent="destructive"
        onClick={onSignOut}
      />
    </Box>
  );
};
