import React from 'react';

import { useI18n } from '@mobble/i18n';

import { useLinking } from '@src/hooks/useLinking';
import { HistoricEvents } from '@src/screens/HistoricEvents/HistoricEvents';
import { ScreenHeader } from '@src/stories/Views/Misc/ScreenHeader';

import * as ROUTE_NAME from '../config/routeNames';
import { ScreenRendererProps } from '../config/types';

export const SummaryEventsHeader: React.FC<ScreenRendererProps> = () => {
  const { formatMessage } = useI18n();
  const linkTo = useLinking();

  return (
    <ScreenHeader
      title={formatMessage({
        description: 'screen.title.summary_events',
        defaultMessage: 'Event log',
      })}
      breadcrumbs={[
        {
          title: formatMessage({
            description: 'screen.title.summaries',
            defaultMessage: 'Summaries',
          }),
          href: ROUTE_NAME.SUMMARIES_LIST,
        },
      ]}
      onGoBack={() => {
        linkTo(ROUTE_NAME.SUMMARIES_LIST);
      }}
    />
  );
};

export const SummaryEvents: React.FC<ScreenRendererProps> = () => {
  return <HistoricEvents asList />;
};

export default {
  name: ROUTE_NAME.SUMMARY_EVENTS_LIST,
  header: SummaryEventsHeader,
  component: SummaryEvents,
};
