import { I18nItem } from '@mobble/i18n';
import { MobbleApp, MobbleApps } from '@mobble/models/src/model/MobbleApps';
import mobbleService from '@mobble/service';
import { useProperties } from '@mobble/store/src/hooks/properties';

const DELAY_PAY_LINK =
  'https://docs.google.com/forms/d/e/1FAIpQLSfWSVdfcjx77UtWUmHM-QbvJHyiBIPxxi_d9RZ8USPvnTbBiA/viewform';

export interface MobbleAppDisplayItem {
  id: MobbleApps;
  title: I18nItem;
  icon: string;
  description: I18nItem;

  enableText: I18nItem | null;
  disableText: I18nItem | null;
  authenticated: boolean;
  enabled: boolean;
  learnMore: string;
}

const mobbleAppsDisplayAssets = [
  {
    id: MobbleApps.AgWorld,
    title: 'mobble-apps.app.agworld.title',
    icon: require('@assets/mobble-apps-icons/agworld.png'),

    description: 'mobble-apps.app.agworld.description',

    enableText: 'mobble-apps.app.agworld.enableText',
    disableText: 'mobble-apps.app.agworld.disableText',
    learnMore: 'https://www.mobble.io/post/agworld-integration',
  },
  {
    id: MobbleApps.AgWorldImportPaddocks,
    title: 'mobble-apps.app.import-agworld-paddocks.title',
    icon: require('@assets/mobble-apps-icons/agworld-import-paddocks.png'),

    description: 'mobble-apps.app.import-agworld-paddocks.description',

    enableText: 'mobble-apps.app.import-agworld-paddocks.enableText',
    disableText: null,
    learnMore: 'https://www.mobble.io/post/agworld-integration',
  },
  {
    id: MobbleApps.DelayPay,
    title: 'mobble-apps.app.delaypay.title',
    icon: require('@assets/mobble-apps-icons/delay-pay.png'),

    description: 'mobble-apps.app.delaypay.description',

    enableText: 'mobble-apps.app.delaypay.enableText',
    disableText: null,
    learnMore: 'https://www.delaypay.com.au/',
  },
];

export const getMobbleApp =
  (mobbleApps: MobbleAppDisplayItem[]) =>
  (id: string): MobbleAppDisplayItem | null => {
    const app = mobbleApps.find((app) => app.id === id) ?? null;
    return app;
  };

export const useGetMobbeAppAction = (apps: MobbleAppDisplayItem[]) => {
  const properties = useProperties();

  const getAppFromId = getMobbleApp(apps);

  const getMobbleAppAction = async (id: MobbleApps) => {
    switch (id) {
      case MobbleApps.AgWorld:
        if (!getAppFromId(id).authenticated) {
          return await mobbleService.api.mobbleApps
            .createAuthURI({
              organisationId: properties.selected?.organisationId,
              integrationId: id,
              context: 'authorise-agworld',
            })
            .then(({ entity }) => {
              window.location.href = entity?.authorizeURI ?? '';
            });
        }

        if (getAppFromId(id).enabled) {
          return await mobbleService.api.mobbleApps.disableMobbleApp({
            organisationId: properties.selected?.organisationId,
            integrationId: id,
          });
        }
        if (!getAppFromId(id).enabled) {
          return await mobbleService.api.mobbleApps.enableMobbleApp({
            organisationId: properties.selected?.organisationId,
            integrationId: id,
          });
        }

        break;
      case MobbleApps.AgWorldImportPaddocks:
        return await mobbleService.api.mobbleApps
          .createAuthURI({
            organisationId: properties.selected?.organisationId,
            integrationId: MobbleApps.AgWorld,
            context: 'import-paddocks',
          })
          .then(({ entity }) => {
            window.location.href = entity?.authorizeURI ?? '';
          });

      case MobbleApps.DelayPay:
        window.location.href = DELAY_PAY_LINK;
        break;

      default:
        break;
    }
  };

  return getMobbleAppAction;
};

export const getMobbleAppDisplayItems =
  (mobbleApps: MobbleApp[]) =>
  (id: string): MobbleAppDisplayItem => {
    const mobbleApp = mobbleAppsDisplayAssets.find((app) => app.id === id);

    const appState = mobbleApps.find((app) => app.id === id);

    return {
      id: mobbleApp.id,
      title: { key: mobbleApp.title },
      icon: mobbleApp.icon,
      description: { key: mobbleApp.description },
      enableText: { key: mobbleApp.enableText },
      disableText: { key: mobbleApp.disableText },
      learnMore: mobbleApp.learnMore,
      authenticated: Boolean(appState),

      enabled: appState?.enabled,
    };
  };

export const getMobbleAppsDisplayItems =
  (mobbleApps: MobbleApp[]) =>
  (ids: string[]): MobbleAppDisplayItem[] => {
    return ids.map(getMobbleAppDisplayItems(mobbleApps));
  };
