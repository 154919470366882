import React from 'react';

import { useI18n } from '@mobble/i18n';
import { useEntitiesRefresher } from '@mobble/shared/src/hooks/useEntitiesRefresher';
import { useMobActions } from '@mobble/store/src/hooks/mobActions';
import { useProperties } from '@mobble/store/src/hooks/properties';

import { ScreenHeader } from '@src/stories/Views/Misc/ScreenHeader';
import { MobActionsViewer } from '@src/stories/Views/MobAction/List/MobActionsViewer';

import { useLinking } from '../../hooks/useLinking';
import { EntitySliceFactoryPrelude } from '../../stories/Views/Misc/EntitySliceFactoryPrelude';
import * as ROUTE_NAME from '../config/routeNames';
import { type ScreenRendererProps } from '../config/types';

export const SummaryMobActionsHeader: React.FC<ScreenRendererProps> = () => {
  const { formatMessage } = useI18n();
  const linkTo = useLinking();

  return (
    <ScreenHeader
      title={formatMessage({
        defaultMessage: 'Mob actions',
        description: 'screen.title.summary_mob_actions',
      })}
      breadcrumbs={[
        {
          title: formatMessage({
            defaultMessage: 'Summaries',
            description: 'screen.title.summaries',
          }),
          href: ROUTE_NAME.SUMMARIES_LIST,
        },
      ]}
      onGoBack={() => {
        linkTo(ROUTE_NAME.SUMMARIES_LIST);
      }}
    />
  );
};

export const SummaryMobActions: React.FC<ScreenRendererProps> = () => {
  const { formatMessage } = useI18n();
  const properties = useProperties();
  const propertyId = properties.selected?.id;
  const mobActions = useMobActions(propertyId);

  const Prelude = EntitySliceFactoryPrelude({
    preludes: [properties.prelude, mobActions.prelude],
    required: [properties.selected],
  });

  useEntitiesRefresher([mobActions], propertyId);

  if (Prelude) {
    return Prelude;
  }

  return (
    <MobActionsViewer
      title={formatMessage({
        defaultMessage: 'Mob actions',
        description: 'screen.title.summary_mob_actions',
      })}
      provider={mobActions}
    />
  );
};

export default {
  name: ROUTE_NAME.SUMMARY_MOB_ACTIONS_LIST,
  header: SummaryMobActionsHeader,
  component: SummaryMobActions,
};
