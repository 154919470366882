import React from 'react';

import { type I18nItem } from '@mobble/i18n';

import { Box } from '../Layout/Box';
import { Spacer } from '../Layout/Spacer';
import { Circle, CircleProps } from '../UI/Circle';
import { Text } from '../UI/Text';

import { Clickable } from './Clickable';

export type CircularButton = {
  onClick: () => void;
  label?: I18nItem;
} & CircleProps;

export const CircularButton: React.FC<CircularButton> = ({
  onClick,
  label,
  ...delegated
}) => {
  return (
    <Clickable href={onClick}>
      <Box>
        <Circle {...delegated} />
        {label && (
          <>
            <Spacer y={0.5} />
            <Text
              align="center"
              i18n={label}
              color={delegated.color ?? delegated.stroke ?? delegated.fill}
            />
          </>
        )}
      </Box>
    </Clickable>
  );
};
