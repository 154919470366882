import React from 'react';

import { useI18n } from '@mobble/i18n';
import { FilterItem } from '@mobble/models/src/model/Filter';
import {
  availableSortOptions,
  filterMapAssets,
  type MapAsset,
  sortMapAssets,
} from '@mobble/models/src/model/MapAsset';
import { type SortSetting } from '@mobble/models/src/model/Sort';
import { useEntitiesRefresher } from '@mobble/shared/src/hooks/useEntitiesRefresher';
import {
  useMapAssets,
  usePaddocks,
  useProperties,
} from '@mobble/store/src/hooks';

import { useLinking } from '@src/hooks/useLinking';
import { useMyLocation } from '@src/hooks/useMyLocation';
import { useNavigateBack } from '@src/hooks/useNavigateBack';
import * as ROUTE_NAME from '@src/screens/config/routeNames';
import { type ScreenRendererProps } from '@src/screens/config/types';
import { Box } from '@src/stories/Components/Layout/Box';
import {
  EntitiesViewer,
  EntitiesViewerProps,
} from '@src/stories/Views/Entities/EntitiesViewer';
import { makeMapAssetsTableColumns } from '@src/stories/Views/MapAsset/List/mapAssetsTableColumns';
import { MapAssetCard } from '@src/stories/Views/MapAsset/MapAssetCard';
import { EntitySliceFactoryPrelude } from '@src/stories/Views/Misc/EntitySliceFactoryPrelude';
import { ScreenHeader } from '@src/stories/Views/Misc/ScreenHeader';

export const MapAssetsHeader: React.FC<ScreenRendererProps> = () => {
  const { formatMessage } = useI18n();
  const goBack = useNavigateBack();

  return (
    <ScreenHeader
      title={formatMessage({
        description: 'screen.title.summary_map_assets',
        defaultMessage: 'Map Assets',
      })}
      breadcrumbs={[
        {
          title: formatMessage({
            description: 'screen.title.summaries',
            defaultMessage: 'Summaries',
          }),
          href: ROUTE_NAME.SUMMARIES_LIST,
        },
      ]}
      onGoBack={goBack}
    />
  );
};

export const MapAssets: React.FC<ScreenRendererProps> = () => {
  const { formatMessage, translate } = useI18n();
  const { location } = useMyLocation();
  const linkTo = useLinking();

  const properties = useProperties();
  const propertyId = properties.selected?.id;
  const paddocks = usePaddocks(properties.selected?.id);
  const mapAssets = useMapAssets(properties.selected?.id);

  const { refresh } = useEntitiesRefresher([paddocks, mapAssets], propertyId);

  const Prelude = EntitySliceFactoryPrelude({
    preludes: [properties.prelude, paddocks.prelude, mapAssets.prelude],
    required: [properties.selected],
  });

  if (Prelude) {
    return Prelude;
  }

  const onClick = (mapAsset: MapAsset) => {
    linkTo(ROUTE_NAME.MAP_ASSET_DETAIL, { mapAssetId: mapAsset.id });
  };

  const applySort = sortMapAssets({ origin: location ?? undefined });

  const sortOptions = () => availableSortOptions;

  const applyFilter = (entities: MapAsset[], filter: FilterItem[]) => {
    return filterMapAssets()(entities, filter);
  };

  const filterItems = () => [
    {
      title: formatMessage({
        defaultMessage: 'Map asset type',
        description: 'map_assets.filter.sections.type.title',
      }),
      group: 'type',
      type: 'select-multiple',
      data: Array.from(
        new Set(mapAssets.entities.map((entity) => entity.map.type))
      )
        .map((value) => ({
          label: formatMessage({
            id: `map_assets.type.${value}`,
            defaultMessage: [],
          }),
          value,
        }))
        .sort((a, b) => a.value.localeCompare(b.value)),
    },
  ];

  const summaryCounter = (entities: MapAsset[]) => {
    return formatMessage(
      {
        defaultMessage: '<b>{TOTAL}</b> assets',
        description: 'map_assets.list.result.total',
      },
      {
        TOTAL: entities.length,
      }
    );
  };

  const tableColumns = makeMapAssetsTableColumns({
    translate,
  });

  const viewerProps: EntitiesViewerProps<MapAsset> = {
    provider: mapAssets,
    tableColumns,
    title: formatMessage({
      description: 'screen.title.summary_map_assets',
      defaultMessage: 'Map Assets',
    }),
    onEmpty: formatMessage({
      defaultMessage: 'Add a map asset below',
      description: 'map_assets.list.empty.add',
    }),
    applySort,
    summaryCounter,
    sortOptions,
    filterItems,
    applyFilter,
    onRefresh: refresh,
    onClickTableRow: onClick,
    renderEntityCard: (mapAsset, { sort }) => {
      const sortingByDistance = Boolean(
        sort.find((a: SortSetting) => a.column === 'distance')
      );
      return (
        <Box spacing={1}>
          <MapAssetCard
            mapAsset={mapAsset}
            onClick={onClick}
            showDistanceFrom={sortingByDistance ? location : undefined}
          />
        </Box>
      );
    },
  };

  return <EntitiesViewer {...viewerProps} />;
};

export default {
  name: ROUTE_NAME.MAP_ASSETS_LIST,
  header: MapAssetsHeader,
  component: MapAssets,
};
