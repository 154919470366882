/* eslint-disable react-native/no-inline-styles */
import React from 'react';
import { type LineLayout, type LinePaint } from 'mapbox-gl';

import { Color, colorToHex } from '@mobble/colors/src/Color';
import { type MapStyle } from '@mobble/models/src/model/MapStyle';

import { Polygon } from '@src/stories/Mapbox/Polygon';

import { type MapItemPaddockGroup } from './MapItemType';

export interface PaddockGroup {
  mapStyle: MapStyle;
  item: MapItemPaddockGroup;
}

export const PaddockGroup: React.FC<PaddockGroup> = ({ item }) => {
  if (!item.visible) {
    return null;
  }

  const linePaint: LinePaint = {
    'line-color': colorToHex(Color.White),
    'line-width': 3,
    'line-offset': -2,
    'line-dasharray': [2, 1],
  };

  const lineLayout: LineLayout = { 'line-cap': 'square', 'line-join': 'bevel' };

  if (item.polygon.coordinates.length > 1) {
    return (
      <>
        {item.polygon.coordinates.map((polygon, index) => (
          <Polygon
            key={`paddock_group-${item.id}-${index}`}
            id={`paddock_group-${item.id}-${index}`}
            // @TODO fix type
            // @ts-ignore
            data={{ type: 'Polygon', coordinates: polygon }}
            linePaint={linePaint}
            lineLayout={lineLayout}
          />
        ))}
      </>
    );
  }

  return (
    <Polygon
      id={`paddock_group-${item.id}`}
      data={item.polygon}
      linePaint={linePaint}
      lineLayout={lineLayout}
    />
  );
};
