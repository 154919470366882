import React from 'react';

import { Color } from '@mobble/colors/src';
import { CustomMapLayer } from '@mobble/models/src';

import { Clickable } from '@src/stories/Components/UX/Clickable';

import { Card } from '../../Components/Layout/Card';
import { Spacer } from '../../Components/Layout/Spacer';
import { Text } from '../../Components/UI/Text';

interface CustomMapLayerCardProps {
  customMapLayer: CustomMapLayer;
  href?: string;
  onClick?: (customMapLayer: CustomMapLayer) => void;
}

export const CustomMapLayerCard: React.FC<CustomMapLayerCardProps> = ({
  customMapLayer,
  href,
  onClick,
}) => {
  const color = customMapLayer.properties.color ?? Color.Grey;

  const card = (
    <Card color={color} title={customMapLayer.name}>
      <Spacer y={1} />
      <Text variant="small" color={Color.Grey}>
        {customMapLayer.description}
      </Text>
      <Spacer y={1} />
    </Card>
  );

  return href || typeof onClick === 'function' ? (
    <Clickable fullWidth href={href ? href : () => onClick(customMapLayer)}>
      {card}
    </Clickable>
  ) : (
    card
  );
};
