import React from 'react';
import * as Yup from 'yup';

import { Point } from '@mobble/models/src/model/MapGeometry';
import { TAG_COLORS } from '@mobble/models/src/model/Property';
import {
  type QuantityOfArea,
  type QuantityOfLength,
  type QuantityOfMass,
  type QuantityOfMassVolume,
  type QuantityOfVolume,
  Type as QuantityType,
} from '@mobble/shared/src/core/Quantity';
import {
  type SortOption,
  type SortSetting,
} from '@mobble/shared/src/core/Sort';

import ListSelect, {
  ListSelectOption,
  type ListSelectProps,
} from '@src/components/ListSelect';
import RadioGroup, { RadioGroupProps } from '@src/components/RadioGroup';
import { Display, type DisplayProps } from '@src/stories/Components/UX/Display';
import {
  Input,
  type InputProps,
  type InputTypes,
} from '@src/stories/Components/UX/Input';
import {
  InputColor,
  type InputColorProps,
} from '@src/stories/Components/UX/InputColor';
import { InputContainer } from '@src/stories/Components/UX/InputContainer';
import {
  InputLocation,
  type InputLocationProps,
} from '@src/stories/Components/UX/InputLocation';
import {
  InputMapCreator,
  type InputMapCreatorProps,
} from '@src/stories/Components/UX/InputMapCreator';
import {
  InputQuantity,
  type InputQuantityProps,
} from '@src/stories/Components/UX/InputQuantity';
import { MapPluginsPointsContextProps } from '@src/stories/Map/Context';

export type FormBuilderFieldValue =
  | number
  | string
  | (string | number)[]
  | QuantityOfVolume
  | QuantityOfMass
  | QuantityOfMassVolume
  | QuantityOfArea
  | QuantityOfLength
  | Point
  | Point[];

type QuantityTypes =
  | 'quantity-volume'
  | 'quantity-mass'
  | 'quantity-mass-volume'
  | 'quantity-area'
  | 'quantity-length';
type SelectTypes = 'select' | 'select-multiple';
type DisplayTypes = 'display';
type RadioTypes = 'radio-as-buttons';
type ColorTypes = 'color' | 'color-tags';
type CustomTypes = 'custom';
type LocationTypes = 'location';
type MapTypes = 'map-creator';

export interface FormBuilderFieldConfigBase<FormValues> {
  name: string;
  label?: string;
  placeholder?: string;
  type:
    | InputTypes
    | QuantityTypes
    | SelectTypes
    | DisplayTypes
    | RadioTypes
    | ColorTypes
    | CustomTypes
    | LocationTypes
    | MapTypes;
  initialValue?: FormBuilderFieldValue;
  required?: boolean;
  error?: string;
  validation?:
    | ((args: {
        t: (...args: string[]) => string;
        requiredTranslated: (fieldName: string) => undefined | string;
        field: FormBuilderFieldConfig<FormValues>;
        formValues: FormValues;
      }) => Yup.AnySchema)
    | Yup.AnySchema;
  options?: ListSelectOption[] | ((values: FormValues) => ListSelectOption[]);
  sortOptions?: SortOption[];
  defaultSortSettings?: SortSetting[];
  sortFunction?: (
    options: ListSelectOption[],
    sortSettings: SortSetting[]
  ) => ListSelectOption[];
  show?: (formValues: FormValues) => boolean;
  disabled?: (formValues: FormValues) => boolean;
  onChange?: (
    formValues: Partial<FormValues>,
    fieldName: string
  ) => Partial<FormValues>;
  transform?: (value: FormBuilderFieldValue) => FormBuilderFieldValue;
  addNew?: {
    onSubmit: (value: string) => Promise<void>;
    placeholder?: string;
    button?: string;
  };
  allSelectable?: boolean;
  component?: React.FC<FormBuilderFieldProps<FormValues>>;
  containerComponent?:
    | boolean
    | ((props: FormBuilderFieldContainerProps<FormValues>) => JSX.Element);
  mapCreator?: Partial<MapPluginsPointsContextProps>;
}

type OmitProps<T> = Omit<T, 'disabled' | 'onChange'>;

export type FormBuilderFieldConfig<FormValues> =
  | (FormBuilderFieldConfigBase<FormValues> & {
      type: InputTypes;
    } & OmitProps<InputProps>)
  | (FormBuilderFieldConfigBase<FormValues> & { type: QuantityTypes } & Omit<
        OmitProps<InputQuantityProps<QuantityType>>,
        'quantityType'
      >)
  | (FormBuilderFieldConfigBase<FormValues> & { type: SelectTypes } & Omit<
        OmitProps<ListSelectProps>,
        'id' | 'options'
      >)
  | (FormBuilderFieldConfigBase<FormValues> & {
      type: DisplayTypes;
    } & OmitProps<DisplayProps>)
  | (FormBuilderFieldConfigBase<FormValues> & {
      type: RadioTypes;
    } & Omit<OmitProps<RadioGroupProps>, 'id'>)
  | (FormBuilderFieldConfigBase<FormValues> & {
      type: ColorTypes;
    } & OmitProps<InputColorProps>)
  | (FormBuilderFieldConfigBase<FormValues> & { type: CustomTypes } & Pick<
        FormBuilderFieldConfigBase<FormValues>,
        'component' | 'name'
      >)
  | (FormBuilderFieldConfigBase<FormValues> & {
      type: LocationTypes;
    } & OmitProps<InputLocationProps>)
  | (FormBuilderFieldConfigBase<FormValues> & {
      type: MapTypes;
    } & OmitProps<InputMapCreatorProps>);

type NumberField<FormValues> = FormBuilderFieldConfigBase<FormValues> & {
  type:
    | 'number'
    | 'float'
    | 'quantity-volume'
    | 'quantity-mass'
    | 'quantity-mass-volume'
    | 'quantity-area'
    | 'quantity-length';
};

export function isNumberField<FormValues>(
  field: FormBuilderFieldConfig<FormValues>
): field is NumberField<FormValues> {
  return [
    'number',
    'float',
    'quantity-volume',
    'quantity-mass',
    'quantity-mass-volume',
    'quantity-area',
    'quantity-length',
  ].includes(field.type);
}

export interface FormBuilderFieldProps<FormValues = any> {
  field: FormBuilderFieldConfig<FormValues>;
  values: FormValues;
  touched?: boolean;
  error?: string | string[] | Record<string, string>;
  makeI18n: (...keys: string[]) => string;
  containerComponent?: (
    props: FormBuilderFieldContainerProps<FormValues>
  ) => JSX.Element;
  onChange: (value: FormBuilderFieldValue) => void;
  onSetValue: (value: FormBuilderFieldValue) => void;
  onTouched?: () => void;
  onBlur?: (value: FormBuilderFieldValue, ev?: React.FocusEvent<any>) => void;
}

export type FormBuilderFieldContainerProps<FormValues> = {
  input: React.ReactNode;
} & FormBuilderFieldProps<FormValues>;

export const FormBuilderField: React.FC<FormBuilderFieldProps> = (props) => {
  const value = props.values[props.field.name];

  const makeInput = () => {
    const options =
      typeof props.field?.options === 'function'
        ? props.field.options(props.values)
        : props.field?.options || [];

    switch (props.field.type) {
      case 'display':
        return <Display value={props.values[props.field.name]} />;

      case 'select':
      case 'select-multiple':
        return (
          <ListSelect
            id={props.field.name}
            selectionLabel={props.makeI18n('selection') as string}
            showFilter={
              (props.field.sortOptions &&
                props.field.sortOptions?.length > 0) ||
              options.length > 8
            }
            multiple={props.field.type === 'select-multiple'}
            sortOptions={props.field.sortOptions}
            sortFunction={props.field.sortFunction}
            defaultSortSettings={props.field.defaultSortSettings}
            options={options.map((option) => ({
              ...option,
              selected:
                props.field.type === 'select-multiple'
                  ? value.includes(option.value)
                  : option.value === value,
            }))}
            placeholder={
              props.field.placeholder ||
              (props.makeI18n('placeholder') as string)
            }
            onChange={(val) => {
              if (props.field.type === 'select-multiple') {
                props.onSetValue(val);
              } else {
                props.onChange(val[0]);
              }
            }}
            onBlur={() => {
              // TODO: remove prop
              props.onTouched();
            }}
            disabled={
              props.field.disabled ? props.field.disabled(props.values) : false
            }
            // TOOD: remove 'as string'
            addNewPlaceholder={props.field.addNew?.placeholder as string}
            addNewButtonLabel={props.field.addNew?.button as string}
            onAddNew={props.field.addNew?.onSubmit}
            // TODO: rename to showSelectAll or something
            // allSelectable={props.field.allSelectable}
          />
        );
      case 'quantity-area':
        return (
          <InputQuantity
            id={props.field.name}
            quantityType={QuantityType.Area}
            value={value as QuantityOfArea}
            placeholder={
              props.field.placeholder ||
              (props.makeI18n('placeholder') as string)
            }
            min={props.field.min}
            max={props.field.max}
            step={props.field.step}
            disabled={
              props.field.disabled ? props.field.disabled(props.values) : false
            }
            onChange={(area) => {
              props.onSetValue(area as QuantityOfArea);
            }}
          />
        );
      case 'quantity-volume':
        return (
          <InputQuantity
            id={props.field.name}
            quantityType={QuantityType.Volume}
            value={value as QuantityOfVolume}
            placeholder={
              props.field.placeholder ||
              (props.makeI18n('placeholder') as string)
            }
            min={props.field.min}
            max={props.field.max}
            step={props.field.step}
            onChange={(volume) => {
              props.onSetValue(volume as QuantityOfVolume);
            }}
          />
        );
      case 'quantity-mass':
        return (
          <InputQuantity
            id={props.field.name}
            quantityType={QuantityType.Mass}
            value={value as QuantityOfMass}
            placeholder={
              props.field.placeholder ||
              (props.makeI18n('placeholder') as string)
            }
            min={props.field.min}
            max={props.field.max}
            step={props.field.step}
            onChange={(mass) => {
              props.onSetValue(mass as QuantityOfMass);
            }}
          />
        );
      case 'quantity-mass-volume':
        return (
          <InputQuantity
            id={props.field.name}
            quantityType={QuantityType.MassVolume}
            value={value as QuantityOfMassVolume}
            placeholder={
              props.field.placeholder ||
              (props.makeI18n('placeholder') as string)
            }
            min={props.field.min}
            max={props.field.max}
            step={props.field.step}
            onChange={(massVolume) => {
              props.onSetValue(massVolume as QuantityOfMassVolume);
            }}
          />
        );
      case 'quantity-length':
        return (
          <InputQuantity
            id={props.field.name}
            quantityType={QuantityType.Length}
            value={value as QuantityOfLength}
            placeholder={
              props.field.placeholder ||
              (props.makeI18n('placeholder') as string)
            }
            min={props.field.min}
            max={props.field.max}
            step={props.field.step}
            onChange={(length) => {
              props.onSetValue(length as QuantityOfLength);
            }}
          />
        );
      case 'radio-as-buttons':
        return (
          <RadioGroup
            id={props.field.name}
            aria-labelledby={`${props.field.name}-label`}
            name={props.field.name}
            options={options.map((option) => ({
              id: option.id,
              label: option.label,
              value: option.value,
            }))}
            checked={value}
            button
            onChange={props.onChange}
          />
        );
      case 'color-tags':
      case 'color': {
        return (
          <InputColor
            colors={props.field.type === 'color-tags' ? TAG_COLORS : undefined}
            placeholder={
              props.field.placeholder ||
              (props.makeI18n('placeholder') as string)
            }
            value={value as string}
            onChange={props.onChange}
            onBlur={() => {
              props.onTouched();
            }}
          />
        );
      }
      case 'custom': {
        if (!props.field.component) {
          console.warn('`component` is required for custom field type');
          return null;
        }
        return props.field.component(props);
      }

      case 'location': {
        return (
          <InputLocation
            value={(value ?? null) as null | Point}
            onChange={props.onChange}
          />
        );
      }

      case 'map-creator': {
        return (
          <InputMapCreator
            value={(value ?? null) as null | Point[]}
            single={props.field.mapCreator?.options?.single}
            mode={props.field.mapCreator?.options?.mode}
            onChange={props.onChange}
          />
        );
      }

      default:
        return (
          <Input
            id={props.field.name}
            name={props.field.name}
            type={props.field.type}
            autoComplete={props.field.autoComplete}
            min={props.field.min}
            max={props.field.max}
            maxLength={props.field.maxLength}
            step={props.field.step}
            placeholder={
              props.field.placeholder ||
              (props.makeI18n('placeholder') as string)
            }
            value={value}
            onChange={props.onChange}
            onBlur={props.onBlur}
          />
        );
    }
  };

  const input = makeInput();

  if (props.field.containerComponent === false) {
    return input;
  } else if (typeof props.field.containerComponent === 'function') {
    return props.field.containerComponent({
      ...props,
      input,
    });
  }

  if (props.containerComponent) {
    return props.containerComponent({
      ...props,
      input,
    });
  }

  return (
    <InputContainer
      id={props.field.name}
      label={props.field.label || props.makeI18n('label')}
      error={props.touched && props.error}
      required={props.field.required}
    >
      {input}
    </InputContainer>
  );
};
