import omit from 'lodash/omit';
import {
  makeQuantityOfArea,
  areaUnits,
  type QuantityOfArea,
  convertArea,
} from '@mobble/shared/src/core/Quantity';
import { type Paddock } from '@mobble/models/src/model/Paddock';
import { type HistoricEvent } from '@mobble/models/src/model/HistoricEvent';
import { type ApiPrelude, type ExtCursor } from '../types';

export const decodePaddocks = (raw: any[]): Paddock[] => {
  return raw.map(decodePaddock);
};

export const decodePaddock = (raw: any): Paddock => {
  return {
    id: raw?.id ?? '',
    propertyId: raw?.propertyId ?? '',
    name: raw?.name ?? '',
    type: raw?.type ?? '',
    status: raw?.status ?? '',
    mobs: (raw?.mobs?.nodes ?? []).map((m: any) => m?.id).filter(Boolean),

    properties: {
      safeDate: raw?.safeDate ?? undefined,
      averageAreaStockingRate: raw?.averageAreaStockingRate,
      lastGrazingHistory:
        raw?.lastGrazingHistory?.type && raw?.lastGrazingHistory?.date
          ? {
              type: raw.lastGrazingHistory.type,
              date: raw.lastGrazingHistory.date,
            }
          : undefined,

      size: decodeAreaSize(raw?.size),
    },
  };
};

const decodeAreaSize = (raw: any): undefined | QuantityOfArea => {
  const rawUnit = raw?.unit?.toLowerCase();
  const rawValue = raw?.value;

  if (typeof rawUnit !== 'undefined' && typeof rawValue !== 'undefined') {
    const value = parseFloat(rawValue) ?? 0;
    if (areaUnits.includes(rawUnit)) {
      return makeQuantityOfArea(rawUnit, value);
    }
    return makeQuantityOfArea('ha', value);
  }
};

export const get =
  (prelude: ApiPrelude) =>
  async ({
    parentId,
  }: {
    parentId: string;
  }): Promise<{
    entities: Paddock[];
    historicEvents: {
      paddockId: string;
      entities: HistoricEvent[];
      extCursor: ExtCursor;
    }[];
  }> => {
    const response = await prelude.fetch({
      uri: `/property/${parentId}/paddocks`,
      method: 'GET',
    });
    const entities = decodePaddocks(response?.nodes ?? []);
    const historicEvents: any[] = [];

    return { entities, historicEvents };
  };

export const find =
  (prelude: ApiPrelude) =>
  async ({
    parentId,
    id,
  }: {
    parentId: string;
    id: Paddock['id'];
  }): Promise<Paddock> => {
    const response = await prelude.fetch({
      uri: `/property/${parentId}/paddock/${id}`,
      method: 'GET',
    });

    const paddock = decodePaddock(response || []);

    return paddock;
  };

export const create =
  (prelude: ApiPrelude) =>
  async ({ entity }: { entity: Omit<Paddock, 'id'> }): Promise<Paddock> => {
    const input = {
      propertyId: entity.propertyId,
      name: entity.name,
      type: entity.type,
      size: entity.properties.size
        ? omit(convertArea('ha')(entity.properties.size), 'type')
        : null,
      geometry: entity.geometry?.polygon,
    };

    const response = await prelude.fetch({
      uri: `/property/${input.propertyId}/paddock`,
      method: 'POST',
      variables: input,
    });
    const paddock = decodePaddock(response);

    return paddock;
  };

export const update =
  (prelude: ApiPrelude) =>
  async ({ updatedEntity }: { updatedEntity: Paddock }): Promise<Paddock> => {
    const input = {
      id: updatedEntity.id,
      propertyId: updatedEntity.propertyId,
      name: updatedEntity.name,
      type: updatedEntity.type,
      size: updatedEntity.properties.size
        ? omit(convertArea('ha')(updatedEntity.properties.size), 'type')
        : null,
      geometry: updatedEntity.geometry?.polygon,
    };

    const response = await prelude.fetch({
      uri: `/property/${input.propertyId}/paddock/${input.id}`,
      method: 'PATCH',
      variables: input,
    });
    const paddock = decodePaddock(response);

    return paddock;
  };

export const remove =
  (prelude: ApiPrelude) =>
  async ({ entity }: { entity: Paddock }): Promise<void> => {
    const input = {
      id: entity.id,
      propertyId: entity.propertyId,
    };

    await prelude.fetch({
      uri: `/property/${input.propertyId}/paddock/${input.id}`,
      method: 'DELETE',
      variables: input,
    });
    return;
  };
