import React from 'react';
import { defineMessages } from 'react-intl';

import { useMessages } from '@mobble/i18n';

import {
  FormBuilder,
  type FormBuilderProps,
} from '@src/stories/Views/Form/FormBuilder';

export interface MobCreateWizardTypeOfAdditionProps {
  formValues?: MobCreateWizardTypeOfAdditionFormValues;
  onChange: (values: MobCreateWizardTypeOfAdditionFormValues) => void;
}

export interface MobCreateWizardTypeOfAdditionFormValues {
  addition_type: 'setup' | 'natural_increase' | 'purchase';
}

const messages = defineMessages({
  setup: {
    description: 'mobs.mob.create.form.addition_type.option.setup.label',
    defaultMessage: 'Set up/other',
  },
  natural_increase: {
    description:
      'mobs.mob.create.form.addition_type.option.natural_increase.label',
    defaultMessage: 'Natural increase (birth)',
  },
  purchase: {
    description: 'mobs.mob.create.form.addition_type.option.purchase.label',
    defaultMessage: 'Purchase',
  },
});

export const MobCreateWizardTypeOfAddition: React.FC<
  MobCreateWizardTypeOfAdditionProps
> = ({ formValues, onChange }) => {
  const strings = useMessages(messages);

  const formProps: FormBuilderProps<any> = {
    i18nRootKey: 'mobs.mob.create.form',
    footer: false,
    fields: [
      {
        name: 'addition_type',
        type: 'radio-as-buttons',
        initialValue: formValues?.addition_type,
        options: [
          {
            id: 'setup',
            label: strings.setup,
            value: 'setup',
          },
          {
            id: 'natural_increase',
            label: strings.natural_increase,
            value: 'natural_increase',
          },
          {
            id: 'purchase',
            label: strings.purchase,
            value: 'purchase',
          },
        ],
      },
    ],
    onSubmit: () => {},
    onChange,
  };

  return <FormBuilder {...formProps} />;
};
