import React from 'react';

import {
  DashboardSuggestion,
  Suggestions,
} from '@src/screens/Dashboard/hooks/useDashboardSuggestions';
import { Box } from '@src/stories/Components/Layout/Box';
import { Spacer } from '@src/stories/Components/Layout/Spacer';
import { HStack } from '@src/stories/Components/Layout/Stack';
import { Icon, type IconName } from '@src/stories/Components/UI/Icon';
import { Text } from '@src/stories/Components/UI/Text';
import { Clickable } from '@src/stories/Components/UX/Clickable';

import styles from './suggestionCard.scss';

export const SuggestionCard = ({
  option,
  removeSuggestion,
}: {
  option: DashboardSuggestion;
  removeSuggestion: (id: Suggestions) => void;
}) => (
  <Box className={styles.suggestionCard}>
    <Box>
      <Box spacing={1}>
        <HStack>
          <Text
            variant="card-title"
            i18n={{
              key: `dashboard.tile.suggestions.${option.id}.card.title`,
            }}
          />
          <Box flex />
          <Clickable
            href={() => removeSuggestion(option.id)}
            className={styles.removeSuggestionButton}
          >
            <Icon name="check" />
          </Clickable>
        </HStack>
      </Box>
      <Box spacing={1}>
        <Text
          i18n={{
            key: `dashboard.tile.suggestions.${option.id}.card.description`,
          }}
        />
      </Box>
    </Box>
    <Spacer y={1} />
    <Box>
      <HStack wrap>
        {option.buttons.map((button) => (
          <Box key={button.href} spacing={0.5}>
            <Clickable fullWidth href={button.href}>
              <HStack
                className={styles.suggestionCardButton}
                alignment="center"
              >
                {button.image ? (
                  <button.image className={styles.suggestionCardButtonImage} />
                ) : button.icon ? (
                  <Icon name={button.icon as IconName} />
                ) : null}
                {(button.icon || button.image) && (
                  <Box spacing={{ right: 1 }} />
                )}
                <Text i18n={button.label} />
              </HStack>
            </Clickable>
          </Box>
        ))}
      </HStack>
    </Box>
  </Box>
);
