import React from 'react';
import isEqual from 'lodash/isEqual';

import { Color } from '@mobble/colors';
import { useI18n } from '@mobble/i18n';
import {
  type MapAsset,
  type MapAssetType,
  mapAssetTypeToColor,
  toLength,
} from '@mobble/models/src/model/MapAsset';
import {
  distanceBetweenPointAndFeature,
  type Point,
} from '@mobble/models/src/model/MapGeometry';
import { truncate } from '@mobble/shared/src/core/String';

import { Box } from '@src/stories/Components/Layout/Box';
import { Card } from '@src/stories/Components/Layout/Card';
import { Spacer } from '@src/stories/Components/Layout/Spacer';
import { HStack } from '@src/stories/Components/Layout/Stack';
import { LocaleQuantity } from '@src/stories/Components/Locale/LocaleQuantity';
import { Icon } from '@src/stories/Components/UI/Icon';
import { Text } from '@src/stories/Components/UI/Text';
import { Clickable } from '@src/stories/Components/UX/Clickable';

interface MapAssetCardProps {
  mapAsset: MapAsset;
  showDistanceFrom?: false | null | Point;
  onClick: (mapAsset: MapAsset) => void;
}

export const MapAssetCardComponent: React.FC<MapAssetCardProps> = ({
  mapAsset,
  showDistanceFrom,
  onClick,
}) => {
  const { formatMessage } = useI18n();
  const typeToI18n = (type: MapAssetType) => ({
    id: `map_assets.type.${type}`,
    defaultMessage: [],
  });

  const distance = showDistanceFrom
    ? distanceBetweenPointAndFeature(showDistanceFrom, mapAsset.map.geometry)
    : null;

  const length = toLength(mapAsset);

  return (
    <Clickable
      fullWidth
      href={() => {
        onClick(mapAsset);
      }}
    >
      <Card
        title={mapAsset.name}
        color={mapAssetTypeToColor(mapAsset.map.type)}
        topRight={
          <Box spacing={{ bottom: 1 }}>
            <Text
              color={Color.DarkGrey}
              i18n={formatMessage(typeToI18n(mapAsset.map.type))}
            />
          </Box>
        }
      >
        {distance ? (
          <Box flex spacing={{ bottom: 1 }}>
            <HStack>
              <Spacer flex />
              <Icon size="small" name="distance" color={Color.DarkGrey} />
              <Spacer x={1} />
              <LocaleQuantity
                color={Color.DarkGrey}
                variant="small"
                quantity={distance}
              />
            </HStack>
          </Box>
        ) : null}

        {length ? (
          <Box flex spacing={{ bottom: 1 }}>
            <HStack>
              <Text
                color={Color.Grey}
                variant="small"
                i18n={formatMessage({
                  description: 'map_asset.info.table.row.length.label',
                  defaultMessage: 'Length',
                })}
              />
              <Spacer x={0.5} />
              <LocaleQuantity
                color={Color.Grey}
                variant="small"
                quantity={length}
              />
            </HStack>
          </Box>
        ) : null}

        {mapAsset.description ? (
          <Box spacing={{ bottom: 1 }}>
            <Text color={Color.Grey} variant="body">
              {truncate(mapAsset.description, 100)}
            </Text>
          </Box>
        ) : null}
      </Card>
    </Clickable>
  );
};

export const MapAssetCard = React.memo(MapAssetCardComponent, (a, b) => {
  return isEqual(a.mapAsset, b.mapAsset);
});
