import React from 'react';
import { ResponsivePie } from '@nivo/pie';

import { contrastingTextColor } from '@mobble/colors';

import { chartColors, theme } from './config';

export interface PieProps {
  data: Datum[];
}

export interface Datum {
  id: string;
  label: string;
  value: number;
  color?: string;
}

export const Pie: React.FC<PieProps> = ({ data }) => {
  const preparedData = data.map(
    (datum, index) =>
      ({
        ...datum,
        color: chartColors[index % chartColors.length],
        textColor: contrastingTextColor(
          chartColors[index % chartColors.length]
        ),
      } as Datum)
  );

  return (
    <div style={{ height: '400px' }}>
      <ResponsivePie
        data={preparedData}
        colors={{ datum: 'data.color' }}
        margin={{ top: 20, right: 0, bottom: 10, left: 0 }}
        innerRadius={0.5}
        animate={true}
        padAngle={2}
        arcLabelsSkipAngle={10}
        cornerRadius={1}
        enableArcLinkLabels={false}
        legends={[
          {
            toggleSerie: true,
            anchor: 'top-left',
            direction: 'column',
            itemsSpacing: 10,
            itemWidth: 130,
            itemHeight: 14,
            symbolShape: 'circle',
          },
        ]}
        arcLabelsTextColor={(text) => contrastingTextColor(text.data.color)}
        theme={theme}
      />
    </div>
  );
};
