import React from 'react';

import { Color, colorToHex } from '@mobble/colors';
import { type MapStyle } from '@mobble/models/src/model/MapStyle';

import { Circle } from '@src/stories/Mapbox/Circle';

import { type MapItemTask } from './MapItemType';

export interface TaskProps {
  mapStyle: MapStyle;
  item: MapItemTask;
}

export const Task: React.FC<TaskProps> = ({ item }) => {
  if (!item.visible) {
    return null;
  }

  return (
    <Circle
      id={`task-${item.id}`}
      data={item.point}
      circlePaint={{
        'circle-radius': 9,
        'circle-color': 'rgba(255,255,255,0.65)',
        'circle-stroke-width': 5,
        'circle-stroke-color': colorToHex(Color.Red),
      }}
      onClick={item.onClick}
    />
  );
};
