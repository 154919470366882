import React from 'react';

import { type I18nItem } from '@mobble/i18n';
import { type AnyQuantity } from '@mobble/shared/src/core/Quantity';

import { Box } from '../../Components/Layout/Box';
import { LocaleQuantity } from '../../Components/Locale/LocaleQuantity';
import { Text } from '../../Components/UI/Text';

import styles from './mapInfoTable.scss';

export interface MapInfoTableProps {
  items: MapInfoTableItem[];
}

export interface MapInfoTableItem {
  key?: string;
  label: I18nItem;
  value: AnyQuantity | number | string;
}

export const MapInfoTable: React.FC<MapInfoTableProps> = ({ items }) => {
  return (
    <Box className={styles.containerInfo}>
      {items.map(({ key, label, value }) => (
        <Box className={styles.row} key={key ?? JSON.stringify(label)}>
          <Text variant="mono" i18n={label} />&nbsp;
          {typeof value === 'object' ? (
            <LocaleQuantity variant="mono" quantity={value as any} />
          ) : (
            <Text variant="mono">{String(value)}</Text>
          )}
        </Box>
      ))}
    </Box>
  );
};
