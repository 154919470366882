import React from 'react';

import {
  Point,
  pointsToDistance,
  pointsToFeaturePolygon,
  Polygon,
  polygonToArea,
} from '@mobble/models/src/model/MapGeometry';

import { MapInfoTable } from './MapInfoTable';

export interface MapInfoTablePointsProps {
  points?: Point[];
  type?: string;
}

export const MapInfoTablePoints: React.FC<MapInfoTablePointsProps> = ({
  points,
  type = 'area',
}) => {
  if (!points || points.length < 2) {
    return null;
  }

  const info = (() => {
    if (type === 'area' && points.length > 2) {
      return [
        {
          label: { key: 'map.measure.perimeter.label' },
          value: pointsToDistance([...points, points[0]]),
        },
        {
          label: { key: 'map.measure.area.label' },
          value: polygonToArea(
            pointsToFeaturePolygon(points)?.geometry as Polygon
          ),
        },
      ];
    }
    return [
      {
        label: { key: 'map.measure.length.label' },
        value: pointsToDistance(points),
      },
    ];
  })();

  return <MapInfoTable items={info} />;
};
