import React from 'react';
import { defineMessages } from 'react-intl';

import { useI18n, useMessages } from '@mobble/i18n';
import { type PaddockAction,SortOption, SortSetting } from '@mobble/models';
import { toISO8601 } from '@mobble/shared/src/core/Date';

import Alert from '@src/components/Alert';
import { type ListSelectOption } from '@src/components/ListSelect';
import { InputContainer } from '@src/stories/Components/UX/InputContainer';

import {
  FormBuilder,
  type FormBuilderFieldProps,
  type FormBuilderProps,
} from '../Form/FormBuilder';
import {
  InputInventoryItems,
  // validateInputInventoryItems,
} from '../Misc/InputInventoryItems';

import { useInputInventoryItems } from './useInputInventoryItems';

import styles from './paddockActionCreateForm.scss';

export interface PaddockActionEditFormProps {
  paddockOptions: ListSelectOption[];
  paddockSortOptions: SortOption[];
  sortPaddockOptions: (
    options: ListSelectOption[],
    sortSettings: SortSetting[]
  ) => ListSelectOption[];

  paddockAction: PaddockAction;
  paddockActions: { value: string; label: string }[];
  //
  error?: string;
  loading?: boolean;
  onAddPaddockAction: (label: string) => Promise<void>;
  onCancel: () => void;
  onTouched: (dirty: boolean) => void;
  onSubmit: (formValues: PaddockActionEditFormValues) => void;
}

export interface PaddockActionEditFormValues {
  date: string;
  paddock: string[];
  action_type: string;
  inventory_items: string;
  note: string;
}

const messages = defineMessages({
  addNewActionType: {
    defaultMessage: 'Add a new action type',
    description: 'paddock_action.edit.form.action_type.add.placeholder.label',
  },
});

const ConnectedInventoryItems = (
  props: FormBuilderFieldProps<PaddockActionEditFormValues>
) => {
  const inputProps = useInputInventoryItems(props);
  const { formatMessage } = useI18n();
  const label = formatMessage({
    defaultMessage:
      'Products cannot be applied to paddocks without any arable area.',
    description:
      'Info alert when inventory items are applied to a paddock with no area.',
  });
  const displayAlert = inputProps?.totalValue.paddockArea <= 0;
  const ContainerComponent = displayAlert ? InputContainer : React.Fragment;

  return (
    <>
      <ContainerComponent>
        <Alert variant="info" title={label} open={displayAlert} />
      </ContainerComponent>
      {!displayAlert && inputProps.paddocksAffected.length ? (
        <InputInventoryItems {...inputProps} />
      ) : null}
    </>
  );
};

export const PaddockActionEditForm: React.FC<PaddockActionEditFormProps> = ({
  paddockOptions,
  paddockSortOptions,
  sortPaddockOptions,
  paddockAction,
  paddockActions,
  error,
  loading,
  onAddPaddockAction,
  onCancel,
  onTouched,
  onSubmit,
}) => {
  const strings = useMessages(messages);

  const form: FormBuilderProps<PaddockActionEditFormValues> = {
    i18nRootKey: 'paddock_action.edit.form',
    className: styles.paddockActionCreateForm,
    flex: true,
    fields: [
      {
        name: 'paddock',
        type: 'select-multiple',
        options: paddockOptions,
        sortOptions: paddockSortOptions,
        sortFunction: sortPaddockOptions,
        initialValue: paddockAction.paddocks.map((a) => a.paddockId),
        required: true,
      },
      {
        name: 'date',
        type: 'date',
        required: true,
        initialValue: toISO8601(paddockAction.date),
      },
      {
        name: 'action_type',
        type: 'select',
        options: paddockActions,
        required: true,
        initialValue: paddockAction.type,
        addNew: {
          onSubmit: onAddPaddockAction,
          placeholder: strings.addNewActionType,
        },
      },
      {
        name: 'inventory_items',
        type: 'custom',
        initialValue: JSON.stringify(paddockAction.appliedInventoryItems),
        component: ConnectedInventoryItems,
        containerComponent: false,
        // validation: validateInputInventoryItems,
      },
      {
        name: 'note',
        type: 'textarea',
        initialValue: paddockAction.note,
      },
    ],
    error,
    loading,
    onSubmit,
    onCancel,
    onTouched,
  };

  return <FormBuilder {...form} />;
};
