import React from 'react';

import { useI18n } from '@mobble/i18n';
import {
  findPaddock,
  sortAnyContainingPaddocks,
  sortOptionsNameDistanceFromMe,
} from '@mobble/models/src/model/Paddock';
import {
  ConfiguredPropertyTypeGroup,
  getOptionsFromTypesByGroup,
} from '@mobble/models/src/model/Property';
import { toISO8601 } from '@mobble/shared/src/core/Date';
import { useEntitiesRefresher } from '@mobble/shared/src/hooks/useEntitiesRefresher';
import {
  useExtStatus,
  useInventories,
  usePaddockAction,
  usePaddockGeometries,
  usePaddocks,
  useProperties,
  useRootDispatch,
} from '@mobble/store/src/hooks';
import { thunkGetAll as thunkGetInventoryBatches } from '@mobble/store/src/reducers/inventoryItemBatches';

import { useFormError } from '@src/hooks/useFormError';
import { useLinking } from '@src/hooks/useLinking';
import { useMyLocation } from '@src/hooks/useMyLocation';
import { useNavigateBack } from '@src/hooks/useNavigateBack';
import { toPath } from '@src/interfaces/Routing';
import { DeleteForm } from '@src/stories/Views/Form/DeleteForm/DeleteForm';
import { EntitySliceFactoryPrelude } from '@src/stories/Views/Misc/EntitySliceFactoryPrelude';
import { ScreenHeader } from '@src/stories/Views/Misc/ScreenHeader';
import {
  PaddockActionEditForm,
  type PaddockActionEditFormValues,
} from '@src/stories/Views/PaddockAction/PaddockActionEditForm';

import * as ROUTE_NAME from '../config/routeNames';
import { type ScreenRendererProps } from '../config/types';

export const PaddockActionEditHeader: React.FC<ScreenRendererProps> = (
  props
) => {
  const { formatMessage } = useI18n();
  const goBack = useNavigateBack();
  const { paddockActionId = '' } = props.route.params;
  const properties = useProperties();
  const paddockAction = usePaddockAction(
    paddockActionId,
    properties.selected?.id
  );

  return (
    <ScreenHeader
      title={formatMessage(
        {
          description: 'screen.title.edit_x',
          defaultMessage: 'Edit "{TITLE}"',
        },
        {
          TITLE: paddockAction.entity?.title,
        }
      )}
      breadcrumbs={[
        {
          title: formatMessage({
            description: 'screen.title.summaries',
            defaultMessage: 'Summaries',
          }),
          href: ROUTE_NAME.SUMMARIES_LIST,
        },
        {
          title: formatMessage({
            description: 'screen.title.summary_paddock_actions',
            defaultMessage: 'Paddock actions',
          }),
          href: toPath(ROUTE_NAME.SUMMARY_PADDOCK_ACTIONS_LIST),
        },
        {
          title: paddockAction.entity?.title,
          href: toPath(ROUTE_NAME.PADDOCK_ACTION_DETAIL, { paddockActionId }),
        },
      ]}
      onGoBack={goBack}
    />
  );
};

export const PaddockActionEdit: React.FC<ScreenRendererProps> = (props) => {
  const { paddockActionId = '' } = props.route.params;
  const dispatch = useRootDispatch();
  const { formatMessage } = useI18n();
  const { location } = useMyLocation();
  const linkTo = useLinking();
  const goBack = useNavigateBack();
  const properties = useProperties();
  const propertyId = properties.selected?.id;

  const inventories = useInventories(propertyId);

  const paddocks = usePaddocks(properties.selected?.id);
  const paddockAction = usePaddockAction(
    paddockActionId,
    properties.selected?.id
  );
  const paddockGeometries = usePaddockGeometries(propertyId);
  const extStatus = useExtStatus('paddockActions', 'update', paddockActionId);
  const formLoading = extStatus?.loading;
  const extStatusDelete = useExtStatus(
    'paddockActions',
    'delete',
    paddockActionId
  );
  const deleteLoading = extStatusDelete?.loading;
  const formError = useFormError({
    entityName: formatMessage({
      defaultMessage: 'Paddock action',
      description: 'screen.title.paddock_action_detail',
    }),
    active: Boolean(extStatus?.error),
  });

  const paddockSortMeta = {
    origin: location || undefined,
    paddockGeometries: paddockGeometries.entities,
    mobs: [],
  };

  const sortPaddockOptions = sortAnyContainingPaddocks((o) => o.entity)(
    paddockSortMeta
  );

  const paddockOptions = paddocks.entities
    .sort((a, b) => a.name.localeCompare(b.name))
    .map((p) => ({
      label: p.name,
      labelExtra: `${p.mobs.length} Mob${
        p.mobs.length === 0 || p.mobs.length > 1 ? 's' : ''
      }`,
      value: p.id,
      entity: p,
    }));

  useEntitiesRefresher([inventories, paddockAction, paddocks], propertyId);

  const Prelude = EntitySliceFactoryPrelude({
    preludes: [inventories.prelude, paddocks.prelude, paddockAction.prelude],
    required: [properties.selected],
    notFound: [paddockAction.entity],
  });

  if (Prelude) {
    return Prelude;
  }

  const handleSubmit = (formValues: PaddockActionEditFormValues) => {
    if (!paddockAction.entity || !properties.selected) {
      return;
    }

    const updatedPaddockAction = {
      ...paddockAction.entity,
      date: toISO8601(formValues.date),
      type: formValues.action_type,
      note: formValues.note,
      paddocks: formValues.paddock.map((paddockId) => ({
        name: findPaddock(paddocks.entities)(paddockId)?.name ?? '',
        paddockId,
      })),
      appliedInventoryItems: JSON.parse(formValues.inventory_items),
    };

    paddockAction
      .update(updatedPaddockAction)
      .then((paddockActionRes) => {
        // Fetch updated batch data for all applied chemicals
        paddockActionRes.appliedInventoryItems.forEach((item) => {
          dispatch(
            thunkGetInventoryBatches({
              parentId: propertyId,
              extFilter: {
                chemicalIds: [item.inventoryItemId],
              },
            })
          );
        });

        if (paddockActionRes.appliedInventoryItems.length) {
          inventories.refresh();
        }

        goBack();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleDelete = () => {
    paddockAction
      .remove()
      .then(() => {
        linkTo(ROUTE_NAME.SUMMARY_PADDOCK_ACTIONS_LIST);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <PaddockActionEditForm
        paddockOptions={paddockOptions}
        paddockSortOptions={sortOptionsNameDistanceFromMe}
        sortPaddockOptions={sortPaddockOptions}
        paddockAction={paddockAction.entity}
        paddockActions={getOptionsFromTypesByGroup(
          properties.selected?.types || []
        )(ConfiguredPropertyTypeGroup.paddockAction)}
        onAddPaddockAction={(label) => {
          if (!properties.selected) {
            return Promise.reject();
          }
          return properties.addPropertyType({
            group: ConfiguredPropertyTypeGroup.paddockAction,
            label,
          });
        }}
        error={formError}
        loading={formLoading}
        onCancel={goBack}
        onTouched={() => {}}
        onSubmit={handleSubmit}
      />

      <DeleteForm
        i18nRootKey="paddockActions.paddockAction.delete"
        loading={deleteLoading}
        onDelete={handleDelete}
      />
    </>
  );
};

export default {
  name: ROUTE_NAME.PADDOCK_ACTION_EDIT,
  header: PaddockActionEditHeader,
  component: PaddockActionEdit,
};
