import React from 'react';

import { contrastingTextColor } from '@mobble/colors';
import { toColor, toInitials } from '@mobble/models/src/model/User';

import { Box, type BoxProps } from '../Layout/Box';

import { Text } from './Text';

import styles from './avatar.scss';

type AvatarProps = Omit<BoxProps, 'children'> & {
  size?: 'tiny' | 'small' | 'normal';
  name?: string;
  loading?: boolean;
};

export const Avatar: React.FC<AvatarProps> = ({
  size = 'normal',
  name = '',
  ...others
}) => {
  const color = toColor(name);

  const classNames = [styles.avatar, styles[`size-${size}`]]
    .filter(Boolean)
    .join(' ');
  return (
    <Box
      className={classNames}
      style={{ backgroundColor: color }}
      {...others}
    >
      <Text
        color={contrastingTextColor(color)}
        bold
        variant={size === 'tiny' ? 'tiny' : 'body'}
      >
        {toInitials(name)}
      </Text>
    </Box>
  );
};
