import React from 'react';

import { type Point } from '@mobble/models/src/model/MapGeometry';
import { type User } from '@mobble/models/src/model/User';
import {
  type SortOption,
  type SortSetting,
} from '@mobble/shared/src/core/Sort';

import { type ListSelectOption } from '@src/components/ListSelect';
import {
  FormBuilder,
  type FormBuilderProps,
} from '@src/stories/Views/Form/FormBuilder';

export interface TaskCreateFormProps {
  paddockOptions: ListSelectOption[];
  paddockSortOptions: SortOption[];
  sortPaddockOptions: (
    options: ListSelectOption[],
    sortSettings: SortSetting[]
  ) => ListSelectOption[];
  users: User[];
  error?: string;
  loading?: boolean;
  initialValues?: Partial<TaskCreateFormValues>;

  findPaddockIdForPoint: (point: Point) => undefined | string;

  onCancel: () => void;
  onTouched: (dirty: boolean) => void;
  onSubmit: (formValues: TaskCreateFormValues) => void;
}

export interface TaskCreateFormValues {
  title: string;
  description?: string;
  due_date?: string;
  assigned_to?: string[];
  paddock_id?: string;
  location?: null | Point;
}

export const TaskCreateForm: React.FC<TaskCreateFormProps> = ({
  paddockOptions,
  paddockSortOptions,
  sortPaddockOptions,
  users,
  error,
  loading,
  initialValues,
  findPaddockIdForPoint,
  onCancel,
  onTouched,
  onSubmit,
}) => {
  const handleChange: FormBuilderProps['onChange'] = (values, _, setValues) => {
    if (values.location) {
      setValues({
        ...values,
        paddock_id: findPaddockIdForPoint(values.location),
      });
    }
  };

  const form: FormBuilderProps<TaskCreateFormValues> = {
    flex: true,
    i18nRootKey: 'tasks.task.create.form',
    fields: [
      { name: 'title', type: 'text', required: true },
      { name: 'description', type: 'textarea' },
      { name: 'due_date', type: 'date' },
      {
        name: 'assigned_to',
        type: 'select-multiple',
        options: users
          .map((user) => ({
            label: user.name,
            value: user.id,
          }))
          .sort((a, b) => a.label.localeCompare(b.label)),
      },
      {
        name: 'paddock_id',
        type: 'select',
        options: paddockOptions,
        sortOptions: paddockSortOptions,
        sortFunction: sortPaddockOptions,
        initialValue: initialValues?.paddock_id,
        disabled: (values) => Boolean(values.location),
      },
      {
        name: 'location',
        type: 'location',
        initialValue: initialValues?.location,
      },
    ],
    error,
    loading,
    onChange: handleChange,
    onSubmit,
    onCancel,
    onTouched,
  };

  return <FormBuilder {...form} />;
};
