import React from 'react';

import { useMapPluginsContext } from '../Context';
import { mapItemsToBoundaryBox } from '../Items/helper';
import { MAP_ITEM_PADDOCK_BOUNDARY } from '../Items/MapItemType';
import { ToolbarButton } from '../Shared/ToolbarButtons';

const Toolbar: React.FC = () => {
  const { types, mapProperties, map } = useMapPluginsContext();

  const onFitToBounds = () => {
    if (map) {
      const onlyUsePaddockBoundariesForBoundingBox = types.includes(
        'items-while-drawing'
      );

      const items = onlyUsePaddockBoundariesForBoundingBox
        ? mapProperties.items.filter(
            (item) => item.type === MAP_ITEM_PADDOCK_BOUNDARY
          )
        : mapProperties.items;

      const boundingBox =
        mapItemsToBoundaryBox(items) ?? mapProperties.boundingBox;
      if (!boundingBox) {
        return;
      }

      map.fitBounds(boundingBox as any, {
        animate: false,
      });
    }
  };

  return (
    <ToolbarButton
      icon="maximise"
      hint={{ key: 'map.tools.bounds.hint' }}
      onClick={onFitToBounds}
    />
  );
};

export default {
  Toolbar,
};
