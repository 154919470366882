import storage from 'reduxjs-toolkit-persist/lib/storage';

import { makePersistedStore, type State } from '@mobble/store';

export type RootState = State;
export type RootDispatch = typeof store.dispatch;

export const [store, persistor] = makePersistedStore({
  storage,
});
