import React from 'react';

import { useI18n } from '@mobble/i18n';

import {
  useMapPluginsContext,
  useMapPluginsDispatchContext,
  useMapPluginsStateContextProps,
} from '../Context';
import { SettingsMapModal } from '../Shared/SettingsMapModal';
import { ToolbarButton } from '../Shared/ToolbarButtons';

const KEY_ENABLED = 'plugin.style_detail.enabled';

const Toolbar: React.FC = () => {
  const { formatMessage } = useI18n();
  const { mapProperties } = useMapPluginsContext();
  const { updateState } = useMapPluginsDispatchContext();
  const { dict } = useMapPluginsStateContextProps();

  const handleToggleModal = () => {
    updateState({ [KEY_ENABLED]: !dict[KEY_ENABLED] });
  };

  return (
    <ToolbarButton
      icon="layers"
      hint={formatMessage({
        defaultMessage: "Map options, change map style and what's showing",
        description: 'map.tools.style_detail.hint',
      })}
      active={mapProperties.mobsFilter.length > 0}
      onClick={handleToggleModal}
    />
  );
};

export const Content: React.FC = () => {
  const { mapProperties } = useMapPluginsContext();
  const { dict } = useMapPluginsStateContextProps();
  const { updateState } = useMapPluginsDispatchContext();

  return (
    <SettingsMapModal
      visible={dict[KEY_ENABLED]}
      selectedMapDetails={mapProperties.mapDetails}
      selectedMapStyle={mapProperties.mapStyle}
      selectedMobsFilter={mapProperties.mobsFilter}
      selectedCustomMapLayersFilter={mapProperties.customMapLayersFilter}
      onChangeMapDetails={mapProperties.updateMapDetails}
      onChangeMapStyle={mapProperties.updateMapStyle}
      onChangeMobsFilter={mapProperties.updateMobsFilter}
      onChangeCustomMapLayersFilter={mapProperties.updateCustomMapLayersFilter}
      onClose={() => {
        updateState({ [KEY_ENABLED]: false });
      }}
    />
  );
};

export default {
  Toolbar,
  Content,
};
