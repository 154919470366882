import React from 'react';

import { Box } from '../../Components/Layout/Box';
import { FormBuilder, type FormBuilderProps } from '../Form/FormBuilder';

export interface ForgotPasswordFormProps {
  error?: null | string;
  loading?: boolean;
  onSubmit: (args: ForgotPasswordFormValues) => void;
  onCancel: () => void;
}

export interface ForgotPasswordFormValues {
  email: string;
}

export const ForgotPasswordForm: React.FC<ForgotPasswordFormProps> = ({
  onSubmit,
  onCancel,
  loading,
  error,
}) => {
  const form: FormBuilderProps<ForgotPasswordFormValues> = {
    i18nRootKey: 'auth.forgot_password.form',
    fields: [
      {
        type: 'text',
        name: 'email',
        required: true,
      },
    ],
    error,
    onSubmit,
    onCancel,
    loading,
  };

  return (
    <Box>
      <FormBuilder {...form} />
    </Box>
  );
};
