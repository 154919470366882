import React from 'react';
import { type IntlFormatters } from 'react-intl';

import { ColorSwatch } from '@src/components';
import { Spacer } from '@src/stories/Components/Layout/Spacer';
import { HStack } from '@src/stories/Components/Layout/Stack';
import { TableColumn } from '@src/stories/Components/Layout/Table';
import { Text } from '@src/stories/Components/UI/Text';

export const makeCustomMapLayersTableColumns = ({
  formatMessage,
}: {
  formatMessage: IntlFormatters['formatMessage'];
}): TableColumn[] => {
  return [
    {
      key: 'name',
      label: formatMessage({
        description: 'custom_map_layers.table.heading.column.name',
        defaultMessage: 'Name',
      }),
      totals: false,
      toValue: (customMapLayer) => customMapLayer.name,
    },
    {
      key: 'description',
      label: formatMessage({
        description: 'custom_map_layers.table.heading.column.description',
        defaultMessage: 'Description',
      }),
      totals: false,
      toValue: (customMapLayer) => customMapLayer.description,
    },
    {
      key: 'color',
      label: formatMessage({
        description: 'custom_map_layers.table.heading.column.color',
        defaultMessage: 'Colour',
      }),
      totals: false,
      toValue: (customMapLayer) => customMapLayer.properties.color,
      render: (color: string) => {
        return (
          <HStack alignment="center">
            <ColorSwatch color={color} />
            <Spacer x={1} />
            <Text variant="small">{color}</Text>
          </HStack>
        );
      },
    },
  ];
};
