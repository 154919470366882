import React from 'react';

import { Color } from '@mobble/colors';
import { type MapAsset } from '@mobble/models/src/model/MapAsset';
import { mapDetails } from '@mobble/models/src/model/MapDetail';
import {
  type BoundingBox,
  enlargeBoundingBox,
} from '@mobble/models/src/model/MapGeometry';

import { Box } from '../../Components/Layout/Box';
import { CircularButton } from '../../Components/UX/CircularButton';
import { Map } from '../../Map';
import {
  makeMapItemMapAsset,
  mapItemsToBoundaryBox,
} from '../../Map/Items/helper';
import { MapItem } from '../../Map/Items/MapItemType';

import styles from './mapAssetMap.scss';

export interface MapAssetMapProps {
  mapAsset: MapAsset;
  onMaximise: () => void;
}

export const MapAssetMap: React.FC<MapAssetMapProps> = ({
  mapAsset,
  onMaximise,
}) => {
  const factoryMapItemMapAsset = makeMapItemMapAsset();

  const items: MapItem[] = [factoryMapItemMapAsset({ mapAsset })];

  const boundingBox = enlargeBoundingBox(
    mapItemsToBoundaryBox(items) as BoundingBox,
    50
  );

  if (!boundingBox) {
    return null;
  }
  const overrideMapProperties = {
    boundingBox,
    items,
    mapDetails: [...mapDetails],
  };

  return (
    <Box className={styles.container}>
      <Box className={styles.buttonContainer}>
        <CircularButton
          icon="maximise"
          fill={Color.White}
          elevation="low"
          onClick={onMaximise}
        />
      </Box>
      <Map
        key={mapAsset.name}
        static
        types="display"
        overrideMapProperties={overrideMapProperties}
      />
    </Box>
  );
};
