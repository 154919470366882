import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import mobbleService from '@mobble/service';
import { InvitationMeta } from '@mobble/service/src/ext/organisation';

const INVITE_META_TOKEN_KEY = 'invitationMetaToken';

const useInvitationMetaLocalStorage = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const urlToken = searchParams.get('token');

  useEffect(() => {
    if (urlToken) {
      localStorage.setItem(INVITE_META_TOKEN_KEY, urlToken);
      if (token) {
        setSearchParams('');
      }
    }
  }, [urlToken]);

  const token = urlToken || localStorage.getItem(INVITE_META_TOKEN_KEY);

  return token;
};

export const useGetInvitationMeta = () => {
  const token = useInvitationMetaLocalStorage();

  const [invitationMeta, setInvitationMeta] = useState<InvitationMeta>();

  useEffect(() => {
    if (token) {
      mobbleService.api.organisation
        .getInvitationMeta(token)
        .then((meta) => {
          setInvitationMeta(meta);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [token]);

  return invitationMeta;
};
