import React from 'react';

import { type I18nItem } from '@mobble/i18n';

import { Box } from '../../Components/Layout/Box';
import { Spacer } from '../../Components/Layout/Spacer';
import { Button } from '../../Components/UX/Button';

import styles from './formFooter.scss';

export interface FormFooterProps {
  submitLabel: I18nItem | string;
  cancelLabel?: I18nItem | string;
  disabled?: boolean;
  loading?: boolean;
  fullWidth?: boolean;
  onCancel?: () => void;
  onSubmit?: () => void;
}

export const FormFooter: React.FC<FormFooterProps> = ({
  submitLabel,
  cancelLabel,
  disabled,
  fullWidth,
  loading,
  onCancel,
  onSubmit,
}) => {
  return (
    <Box className={styles.formFooter}>
      {cancelLabel && typeof onCancel === 'function' ? (
        <>
          <Button
            type="reset"
            flex={fullWidth}
            outline
            intent="destructive"
            disabled={loading}
            onClick={onCancel}
            label={cancelLabel}
          />
          <Spacer x={2} />
        </>
      ) : null}
      {/* TODO: onClick event is not required for button[type=submit] */}
      <Button
        type="submit"
        flex={fullWidth}
        loading={loading}
        disabled={disabled}
        onClick={typeof onSubmit === 'function' ? onSubmit : () => {}}
        label={submitLabel}
      />
    </Box>
  );
};
