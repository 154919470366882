import React from 'react';
import isEqual from 'lodash/isEqual';

import { Color } from '@mobble/colors';
import { useI18n } from '@mobble/i18n';
import { RainGauge } from '@mobble/models/src/model/RainGauge';
import { formatDate } from '@mobble/shared/src/core/Date';
import { useSetting } from '@mobble/store/src/hooks/settings';

import { Box } from '@src/stories/Components/Layout/Box';
import { Card } from '@src/stories/Components/Layout/Card';
import { Spacer } from '@src/stories/Components/Layout/Spacer';
import { HStack } from '@src/stories/Components/Layout/Stack';
import { LocaleQuantity } from '@src/stories/Components/Locale/LocaleQuantity';
import { Text } from '@src/stories/Components/UI/Text';
import { Clickable } from '@src/stories/Components/UX/Clickable';

import { ChartRainGaugeMonthlyRainfall } from './Charts/ChartRainGaugeMonthlyRainfall';

interface RainGaugeCardProps {
  rainGauge: RainGauge;
  onClick?: (rainGauge: RainGauge) => void;
  chart?: boolean;
}

export const RainGaugeCardComponent: React.FC<RainGaugeCardProps> = ({
  rainGauge,
  onClick,
  chart,
}) => {
  const { formatMessage } = useI18n();
  const dateFormat = useSetting('dateFormat') as string;

  return (
    <Clickable fullWidth href={() => onClick(rainGauge)}>
      <Card title={rainGauge.name} color={Color.Blue}>
        <Spacer y={1} />
        {rainGauge?.lastReading?.date && (
          <Box>
            <HStack>
              <Text color={Color.Black}>
                {formatMessage(
                  {
                    defaultMessage: '<b>Last reading:</b> {DATE}',
                    description:
                      'rain-gauges.list.rain-gauge.card.last_reading',
                  },
                  {
                    DATE: formatDate(rainGauge.lastReading.date, dateFormat),
                  }
                )}
              </Text>
            </HStack>
            <Spacer y={1} />
          </Box>
        )}
        <Box>
          <HStack>
            <Text color={Color.Black}>YTD</Text>
            <Spacer flex />
            <LocaleQuantity quantity={rainGauge.ytd} />
          </HStack>
          <HStack>
            <Text color={Color.Black}>MTD</Text>
            <Spacer flex />

            <LocaleQuantity quantity={rainGauge.mtd} />
          </HStack>
        </Box>

        {chart && (
          <Box>
            <ChartRainGaugeMonthlyRainfall
              rainGauge={rainGauge}
              year={new Date().getFullYear()}
              height="200px"
            />
          </Box>
        )}
      </Card>
    </Clickable>
  );
};

export const RainGaugeCard = React.memo(RainGaugeCardComponent, (a, b) => {
  return isEqual(a.rainGauge, b.rainGauge) && isEqual(a.onClick, b.onClick);
});
