import React from 'react';
import { defineMessages } from 'react-intl';

import { useI18n } from '@mobble/i18n';
import {
  addDays,
  DateRange,
  DateRangeOption,
  dateRangeOptions,
  formatDate,
  getDateRange,
  toISO8601,
} from '@mobble/shared/src/core/Date';
import { useSettings } from '@mobble/store/src/hooks/settings';

import { Icon } from '@src/stories/Components/UI/Icon';
import { Text } from '@src/stories/Components/UI/Text';
import { ModalFlyUp } from '@src/stories/Components/UX/ModalFlyUp';

import { FormBuilder, FormBuilderProps } from '../Form/FormBuilder';

import styles from './dateRangeSelector.scss';

export interface DateRangeSelectorProps {
  range: DateRange;
  onChange: (range: DateRange) => void;
  min?: Date;
  max?: Date;
}

const d = (date: Date) => formatDate(date, 'YYYY-MM-DD');

const messages = defineMessages({
  today: {
    defaultMessage: 'Today',
    description: 'today',
  },
  'last-7-days': {
    defaultMessage: 'Last 7 days',
    description: 'the last 7 days',
  },
  'last-4-weeks': {
    defaultMessage: 'Last 4 weeks',
    description: 'the last 4 weeks',
  },
  'last-3-months': {
    defaultMessage: 'Last 3 months',
    description: 'the last 3 months',
  },
  'last-12-months': {
    defaultMessage: 'Last 12 months',
    description: 'the last 12 months',
  },
  'month-to-date': {
    defaultMessage: 'Month to date',
    description: 'the current month',
  },
  'quarter-to-date': {
    defaultMessage: 'Quarter to date',
    description: 'the current quarter',
  },
  'year-to-date': {
    defaultMessage: 'Year to date',
    description: 'the current year',
  },
  custom: {
    defaultMessage: 'Custom',
    description: 'custom date range',
  },
});

export const DateRangeSelector: React.FC<DateRangeSelectorProps> = ({
  range,
  onChange,
  min,
  max,
}) => {
  const { formatMessage } = useI18n();
  const { settings } = useSettings();

  const [modalIsOpen, setModalIsOpen] = React.useState(false);

  const options: Option[] = dateRangeOptions.map((value) => ({
    range: getDateRange(value),
    value,
    label: formatMessage(messages[value as keyof typeof messages]),
  }));

  const selected = options.find(
    (option) =>
      option.range &&
      option.range.start.getTime() === range.start.getTime() &&
      option.range.end.getTime() === range.end.getTime()
  );

  if (!selected) {
    options.push({
      value: 'custom',
      label: formatMessage(messages.custom),
    });
  }

  const formattedStart = formatDate(range.start, settings.dateFormat);
  const formattedEnd = formatDate(range.end, settings.dateFormat);

  const onSubmit = (formValues: FormValues) => {
    onChange({
      start: formValues.start ? new Date(formValues.start) : range.start,
      end: formValues.end ? new Date(formValues.end) : range.end,
    });
    setModalIsOpen(false);
  };

  return (
    <DateRangeSelectorForm
      selected={selected}
      onChange={onChange}
      formattedStart={formattedStart}
      formattedEnd={formattedEnd}
      options={options}
      modalIsOpen={modalIsOpen}
      setModalIsOpen={setModalIsOpen}
      onSubmit={onSubmit}
      range={range}
      min={min}
      max={max}
    />
  );
};

export const ReportsDateRangeSelector: React.FC<DateRangeSelectorProps> = ({
  range,
  onChange,
  min,
  max,
}) => {
  const { formatMessage } = useI18n();
  const { settings } = useSettings();

  const [modalIsOpen, setModalIsOpen] = React.useState(false);

  const options: Option[] = dateRangeOptions
    .filter((e) => e !== 'today')
    .map((value) => {
      const range = getDateRange(value);
      const rangeDeviated = {
        start: new Date(addDays(range.start, -1).toISOString()),
        end: new Date(addDays(range.end, -1).toISOString()),
      };

      return {
        range: rangeDeviated,
        value,
        label: formatMessage(messages[value as keyof typeof messages]),
      };
    });

  const selected = options.find(
    (option) =>
      option.range &&
      option.range.start.toDateString() === range.start.toDateString() &&
      option.range.end.toDateString() === range.end.toDateString()
  );

  if (!selected) {
    options.push({
      value: 'custom',
      label: formatMessage(messages.custom),
    });
  }

  const formattedStart = formatDate(range.start, settings.dateFormat);
  const formattedEnd = formatDate(range.end, settings.dateFormat);

  const handleSubmit = (formValues: FormValues) => {
    onChange({
      start: formValues.start ? new Date(formValues.start) : range.start,
      end: formValues.end ? new Date(formValues.end) : range.end,
    });
    setModalIsOpen(false);
  };

  const handleOnChange = (range: DateRange) => {
    onChange({
      start: new Date(addDays(range.start, -1).toISOString()),
      end: new Date(addDays(range.end, -1).toISOString()),
    });
    setModalIsOpen(false);
  };

  return (
    <DateRangeSelectorForm
      selected={selected}
      onChange={handleOnChange}
      formattedStart={formattedStart}
      formattedEnd={formattedEnd}
      options={options}
      modalIsOpen={modalIsOpen}
      setModalIsOpen={setModalIsOpen}
      onSubmit={handleSubmit}
      range={range}
      min={min}
      max={max}
    />
  );
};

const DateRangeSelectorForm = ({
  selected,
  range,
  onChange,
  formattedStart,
  formattedEnd,
  options,
  modalIsOpen,
  setModalIsOpen,
  min,
  max,
  onSubmit,
}: {
  selected: Option;
  range: DateRange;
  onChange: (range: DateRange) => void;
  formattedStart: string;
  formattedEnd: string;
  options: Option[];
  modalIsOpen: boolean;
  setModalIsOpen: (isOpen: boolean) => void;
  min?: Date;
  max?: Date;
  onSubmit: (formValues: FormValues) => void;
}) => {
  const { formatMessage } = useI18n();
  const form: FormBuilderProps<FormValues> = {
    i18nRootKey: 'date_range.form',
    className: styles.form,
    flex: true,
    reinitialize: true,
    fields: [
      {
        name: 'start',
        type: 'date',
        min: min ? d(min) : undefined,
        max: max ? d(max) : undefined,
        initialValue: toISO8601(range.start),
      },
      {
        name: 'end',
        type: 'date',
        min: min ? d(min) : undefined,
        max: max ? d(max) : undefined,
        initialValue: toISO8601(range.end),
      },
    ],
    onSubmit,
  };

  return (
    <>
      <div className={styles.container}>
        <div className={styles.inner}>
          <select
            className={styles.dateRangeSelect}
            value={selected?.value ?? 'custom'}
            onChange={(ev) => {
              if (ev.target.value !== 'custom') {
                onChange(getDateRange(ev.target.value as any));
              }
            }}
          >
            {options.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
          <span className={styles.dateRangeDivider} />
          <button
            className={styles.dateRangeSelection}
            onClick={() => {
              setModalIsOpen(true);
            }}
          >
            <Icon name="calendar" />
            <span className={styles.dates}>
              <Text>{formattedStart}</Text>
              <Text>{' — '}</Text>
              <Text>{formattedEnd}</Text>
            </span>
          </button>
        </div>
      </div>
      <ModalFlyUp
        isOpen={modalIsOpen}
        title={formatMessage({
          defaultMessage: 'Select Date Range',
          description: 'Select Date Range',
        })}
        onClose={() => {
          setModalIsOpen(false);
        }}
        className={styles.modal}
      >
        <FormBuilder {...form} />
      </ModalFlyUp>
    </>
  );
};

//
type Option = {
  range?: DateRange;
  value: DateRangeOption | 'custom';
  label: string;
};

export interface FormValues {
  start: Date;
  end: Date;
}
