import React from 'react';
import * as Yup from 'yup';

import { useI18n } from '@mobble/i18n';
import {
  Point,
  pointsToFeaturePolygon,
  polygonToArea,
  polygonToPoints,
} from '@mobble/models/src/model/MapGeometry';
import { type Paddock } from '@mobble/models/src/model/Paddock';
import { PaddockGeometry } from '@mobble/models/src/model/PaddockGeometry';
import {
  type AreaUnits,
  areaUnits,
  convertTo,
  type QuantityOfArea,
} from '@mobble/shared/src/core/Quantity';
import { useSettings } from '@mobble/store/src/hooks/settings';

import { ColorSwatch } from '@src/components';

import { FormBuilder, type FormBuilderProps } from '../Form/FormBuilder';

export interface PaddockEditFormProps {
  paddock: Paddock;
  paddockGeometry?: PaddockGeometry;
  paddockTypes: { value: string; color: string }[];
  otherPaddockNames: Paddock['name'][];
  error?: string;
  loading?: boolean;
  onChange?: FormBuilderProps['onChange'];
  onCancel: () => void;
  onSubmit: (formValues: PaddockEditFormValues) => void;
  onCreateCustomField: (label: string) => Promise<void>;
}

export interface PaddockEditFormValues {
  name: string;
  paddock_type: Paddock['type'];
  grazeable_area: QuantityOfArea;
  total_area: QuantityOfArea;
  points?: Point[];
}

export const PaddockEditForm: React.FC<PaddockEditFormProps> = ({
  paddock,
  paddockGeometry,
  paddockTypes,
  otherPaddockNames,
  error,
  loading,
  onChange,
  onCancel,
  onSubmit,
  onCreateCustomField,
}) => {
  const { formatMessage } = useI18n();
  const settings = useSettings();

  const paddockTypeOptions = paddockTypes.map(({ value, color }) => ({
    label: value,
    value,
    labelExtra: <ColorSwatch color={color} />,
  }));

  const form: FormBuilderProps<PaddockEditFormValues> = {
    flex: true,
    i18nRootKey: 'paddocks.paddock.edit.form',
    fields: [
      {
        name: 'name',
        type: 'text',
        initialValue: paddock.name,
        required: true,
        validation: Yup.string()
          .required(
            formatMessage(
              {
                description: 'generic.form.validation.required',
                defaultMessage: '{FIELD} is a required field.',
              },
              {
                FIELD: formatMessage({
                  defaultMessage: 'Paddock name',
                  description: 'paddocks.paddock.edit.form.name.label',
                }),
              }
            )
          )
          .test(
            'unique',
            formatMessage({
              defaultMessage: 'A paddock with this name already exists.',
              description: 'Paddock name validation error',
            }),
            (value) =>
              !value || !otherPaddockNames.includes(value.toLowerCase())
          ),
      },
      {
        name: 'paddock_type',
        type: 'select',
        options: paddockTypeOptions,
        required: true,
        initialValue: paddock.type,
        addNew: {
          onSubmit: onCreateCustomField,
          placeholder: formatMessage({
            defaultMessage: 'Add new paddock type',
            description:
              'paddocks.paddock.create.form.paddock_form.paddockTypes.add_new.placeholder.label',
          }),
        },
      },
      {
        name: 'points',
        type: 'map-creator',
        required: false,
        initialValue: paddockGeometry?.polygon
          ? polygonToPoints(paddockGeometry.polygon)
          : null,
        mapCreator: {
          options: {
            single: false,
            mode: 'area',
          },
        },
        onChange: (values) => {
          const polygon = values.points
            ? pointsToFeaturePolygon(values.points)
            : null;

          if (!polygon) {
            return values;
          }

          const total_area = convertTo(
            settings.settings.areaUnit,
            2
          )(polygonToArea(polygon.geometry)) as QuantityOfArea;

          return {
            ...values,
            total_area,
          };
        },
      },
      {
        name: 'total_area',
        type: 'quantity-area',
        disabled: () => true,
        initialValue: paddockGeometry?.polygon?.coordinates
          ? (convertTo(
              settings.settings.areaUnit,
              2
            )(polygonToArea(paddockGeometry?.polygon)) as QuantityOfArea)
          : undefined,
      },
      {
        name: 'grazeable_area',
        type: 'quantity-area',
        initialValue: paddock.properties.size
          ? (convertTo(
              settings.settings.areaUnit,
              2
            )(paddock.properties.size) as QuantityOfArea)
          : undefined,
        required: true,
        show: (values: PaddockEditFormValues) => values.paddock_type !== 'Yard',
        validation: Yup.object().when(
          '$paddock_type',
          ([paddock_type], schema) => {
            return paddock_type === 'Yard'
              ? schema.optional()
              : schema.shape({
                  value: Yup.number()
                    .positive(
                      formatMessage(
                        {
                          defaultMessage: '{FIELD} must be a positive value.',
                          description: 'generic.form.validation.positive',
                        },
                        {
                          FIELD: formatMessage({
                            defaultMessage: 'Grazeable area',
                            description:
                              'paddocks.paddock.edit.form.grazeable_area.label',
                          }),
                        }
                      )
                    )
                    .required(
                      formatMessage(
                        {
                          defaultMessage: '{FIELD} is a required field.',
                          description: 'generic.form.validation.required',
                        },
                        {
                          FIELD: formatMessage({
                            defaultMessage: 'Grazeable area',
                            description:
                              'paddocks.paddock.edit.form.grazeable_area.label',
                          }),
                        }
                      )
                    ),
                  unit: Yup.string().test(
                    'valid-unit',
                    formatMessage({
                      defaultMessage: 'Please select a valid area unit.',
                      description: 'generic.form.validation.invalid_area_unit',
                    }),
                    (value) => areaUnits.includes(value as AreaUnits)
                  ),
                });
          }
        ),
      },
    ],
    error,
    loading,
    onChange,
    onSubmit,
    onCancel,
  };

  return <FormBuilder {...form} />;
};
