import React from 'react';
import { defineMessages } from 'react-intl';

import { useI18n,useMessages } from '@mobble/i18n';
import { inventoryCategoryToColor } from '@mobble/models/src/model/Inventory';
import {
  type InventoryItem,
  InventoryItemStatus,
} from '@mobble/models/src/model/InventoryItem';
import { formatDate } from '@mobble/shared/src/core/Date';
import { useSetting } from '@mobble/store/src/hooks';
import { Color } from '@mobble/theme';

import { Box } from '@src/stories/Components/Layout/Box';
import { Card } from '@src/stories/Components/Layout/Card';
import { Spacer } from '@src/stories/Components/Layout/Spacer';
import { HStack } from '@src/stories/Components/Layout/Stack';
import { makeQuantityFormatMessageItem } from '@src/stories/Components/Locale/LocaleQuantity';
import { Text } from '@src/stories/Components/UI/Text';
import { Clickable } from '@src/stories/Components/UX/Clickable';

interface InventoryItemCardProps {
  inventoryItem: InventoryItem;
  href?: string;
  onClick?: (inventoryItem: InventoryItem) => void;
}

interface InfoRow {
  label: string;
  value?: string;
}

const messages = defineMessages({
  batchId: {
    defaultMessage: 'Batch ID',
    description: 'inventories.list.inventory_item.card.batchId.label',
  },
  quantity: {
    defaultMessage: 'Quantity',
    description: 'inventories.list.inventory_item.card.quantity.label',
  },
  supplierName: {
    defaultMessage: 'Supplier',
    description: 'inventories.list.inventory_item.card.supplierName.label',
  },
  lastUsed: {
    defaultMessage: 'Last Used',
    description: 'inventories.list.inventory_item.card.lastUsed.label',
  },
});

export const InventoryItemCard: React.FC<InventoryItemCardProps> = ({
  inventoryItem,
  href,
  onClick,
}) => {
  const { formatMessage } = useI18n();
  const strings = useMessages(messages);
  const dateFormat = useSetting('dateFormat') as string;
  const isDeleted = inventoryItem.status === InventoryItemStatus.Deleted;

  const details: InfoRow[] = [
    {
      label: strings.quantity,
      value: formatMessage(
        ...makeQuantityFormatMessageItem(inventoryItem.quantity)
      ),
    },
    ...(inventoryItem?.lastUsed
      ? [
          {
            label: strings.lastUsed,
            value: formatDate(inventoryItem?.lastUsed, dateFormat),
          },
        ]
      : []),
  ];

  const card = (
    <Card
      title={inventoryItem.name}
      color={
        isDeleted
          ? Color.DangerCoreDefault
          : inventoryCategoryToColor(inventoryItem.category)
      }
    >
      <Spacer y={1} />
      <Box>
        {details.map(({ label, value }, index) => (
          <Box key={index} spacing={{ bottom: 1 }}>
            <HStack>
              <Text variant="small" i18n={label} />
              <Spacer flex />

              {typeof value === 'string' ? (
                <Text variant="small">{value}</Text>
              ) : (
                <Text variant="small" i18n={value} />
              )}
            </HStack>
          </Box>
        ))}
      </Box>
    </Card>
  );

  return href || typeof onClick === 'function' ? (
    <Clickable fullWidth href={href ? href : () => onClick(inventoryItem)}>
      {card}
    </Clickable>
  ) : (
    card
  );
};
