import React, { createContext, useState } from 'react';

import Button, { type ButtonProps } from '@src/components/Button';
import Dialog from '@src/components/Dialog';
import DialogFooter from '@src/components/Dialog/Footer';
import { Portal } from '@src/stories/Components/Layout/Portal';

type DialogAlertFunction = (
  title: string,
  message: string,
  buttons: ButtonProps[]
) => void;

interface DialogAlertProps {
  title: string;
  message: string;
  buttons: ButtonProps[];
}

export interface DialogContextProps {
  alert: DialogAlertFunction;
  close: () => void;
}

export const DialogContext = createContext<DialogContextProps | undefined>(
  undefined
);

export interface DialogProviderProps {
  children: React.ReactNode;
}

export const DialogProvider: React.FC<DialogProviderProps> = ({ children }) => {
  const [dialog, setDialog] = useState<DialogAlertProps | null>(null);

  const open: DialogAlertFunction = (title, message, buttons) => {
    setDialog({ title, message, buttons });
  };

  const close = () => {
    setDialog(null);
  };

  return (
    <DialogContext.Provider value={{ alert: open, close }}>
      {children}
      <Portal>
        <Dialog
          isOpen={!!dialog}
          header={{ heading: dialog?.title, hideCloseButton: true }}
          content={dialog?.message}
          onRequestClose={close}
        >
          <DialogFooter>
            {dialog?.buttons.map((button, i) => (
              <Button key={i} {...button} />
            ))}
          </DialogFooter>
        </Dialog>
      </Portal>
    </DialogContext.Provider>
  );
};
