import React from 'react';

import { type I18nItem } from '@mobble/i18n';
import {
  type BaseEntity,
  type FilterItem,
  type SortOption,
  type SortSetting,
} from '@mobble/models';

import { Responsive } from '@src/stories/Components/Layout/Responsive';
import { TableColumn } from '@src/stories/Components/Layout/Table';

import { EntitiesFilter, type EntitiesFilterItem } from './EntitiesFilter';
import { EntitiesList } from './EntitiesList';
import { EntitiesSort } from './EntitiesSort';
import { EntitiesTable } from './EntitiesTable';
import { type Provider } from './types';

export interface EntitiesViewerProps<Entity extends BaseEntity> {
  className?: string;
  provider: Provider<Entity>;
  title?: I18nItem | string;
  showTitle?: boolean;
  hideSortAndFilter?: boolean;
  filterItems?: () => EntitiesFilterItem<Entity>[];
  applyFilter?: (entities: Entity[], filter: FilterItem[]) => Entity[];
  sortOptions?: () => SortOption[];
  applySort?: (entities: Entity[], sort: SortSetting[]) => Entity[];
  onEmpty?: I18nItem | (() => React.ReactElement) | string;
  onRefresh?: () => void;
  summaryCounter?: (entities: Entity[]) => I18nItem | string;
  renderEntityCard: (
    entity: Entity,
    meta: {
      filter: FilterItem[];
      sort: SortSetting[];
    }
  ) => React.ReactNode;
  tableColumns: TableColumn[];
  asList?: boolean;
  onClickTableRow?: (entity: Entity) => void;
  headerButtons?: {
    label: I18nItem | string;
    onClick: () => void;
    loading?: boolean;
  }[];
  withSelection?: (selected: Entity['id'][]) => React.ReactElement;
}

export function EntitiesViewer<Entity extends BaseEntity>(
  props: EntitiesViewerProps<Entity>
) {
  const {
    className,
    provider,
    title,
    showTitle,
    hideSortAndFilter,
    filterItems,
    applyFilter,
    sortOptions,
    applySort,
    onRefresh,
    onEmpty,
    summaryCounter,
    renderEntityCard,
    tableColumns,
    onClickTableRow,
    headerButtons,
    withSelection,
  } = props;

  const [filterIsOpen, setFilterIsOpen] = React.useState(false);
  const [sortIsOpen, setSortIsOpen] = React.useState(false);
  const [selectedEntities, onSelectEntities] = React.useState<Entity['id'][]>(
    []
  );

  const providerIsArray = Array.isArray(provider);

  const baseProps = {
    // provider, // don't use provider here cause it needs it as a reference to the original provider
    onEmpty,
    title,
    showTitle,
    hideSortAndFilter,
    selectedEntities,
    onSelectEntities,
    withSelection,
  };

  const filterRefreshSortProps = {
    applyFilter,
    applySort,
    onRefresh,
    onShowFilter: filterItems ? () => setFilterIsOpen(true) : undefined,
    onShowSort: sortOptions ? () => setSortIsOpen(true) : undefined,
  };

  const entitiesListBaseProps = {
    ...baseProps,
    renderEntity: renderEntityCard,
    headerButtons,
  };

  const entitiesListProps = providerIsArray
    ? entitiesListBaseProps
    : {
        ...entitiesListBaseProps,
        ...filterRefreshSortProps,
        summaryCounter,
        renderEntity: renderEntityCard,
        showSearch: Boolean(applyFilter),
        showCounts: Boolean(filterItems),
      };

  const entitiesTableBaseProps = {
    ...baseProps,
    onClickTableRow,
    columns: tableColumns,
  };

  const entitiesTableProps = providerIsArray
    ? entitiesTableBaseProps
    : {
        ...entitiesTableBaseProps,
        ...filterRefreshSortProps,
      };
  return (
    <>
      {props.asList ? (
        <EntitiesList
          className={className}
          provider={provider}
          {...entitiesListProps}
        />
      ) : (
        <>
          <Responsive mobile>
            <EntitiesList
              className={className}
              provider={provider}
              {...entitiesListProps}
            />
          </Responsive>
          <Responsive desktop>
            <EntitiesTable provider={provider} {...entitiesTableProps} />
          </Responsive>
        </>
      )}
      {!providerIsArray && filterItems && applyFilter ? (
        <EntitiesFilter
          isOpen={filterIsOpen}
          onClose={() => setFilterIsOpen(false)}
          items={filterItems}
          entities={provider.entities}
          filter={provider.filter}
          setFilter={provider.setFilter}
          toggleFilter={provider.toggleFilter}
          clearFilter={provider.clearFilter}
          applyFilter={applyFilter}
        />
      ) : null}
      {!providerIsArray && sortOptions ? (
        <EntitiesSort
          isOpen={sortIsOpen}
          onClose={() => setSortIsOpen(false)}
          items={sortOptions}
          provider={provider}
        />
      ) : null}
    </>
  );
}
