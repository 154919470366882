import React from 'react';
import * as Yup from 'yup';

import { useI18n } from '@mobble/i18n';
import {
  type Paddock,
  sortOptionsNameDistanceFromPaddock,
} from '@mobble/models/src/model/Paddock';
import { type SortSetting } from '@mobble/shared/src/core/Sort';

import { type ListSelectOption } from '@src/components/ListSelect';

import { FormBuilder, type FormBuilderProps } from '../../Form/FormBuilder';

export interface PaddockGroupCreateFormProps {
  paddock: Paddock;
  error?: string;
  loading?: boolean;
  paddockOptions: ListSelectOption[];
  sortPaddockOptions: (
    options: ListSelectOption[],
    sortSettings: SortSetting[]
  ) => ListSelectOption[];

  onCancel: () => void;
  onSubmit: (formValues: PaddockGroupCreateFormValues) => void;
}

export interface PaddockGroupCreateFormValues {
  paddocks: string[];
}

export const PaddockGroupCreateForm: React.FC<PaddockGroupCreateFormProps> = ({
  paddockOptions,
  sortPaddockOptions,
  error,
  paddock,
  loading,
  onCancel,
  onSubmit,
}) => {
  const { translate } = useI18n();

  const form: FormBuilderProps<PaddockGroupCreateFormValues> = {
    flex: true,
    i18nRootKey: 'paddocks.paddock_group.create.form',
    fields: [
      {
        name: 'paddocks',
        type: 'select-multiple',
        options: paddockOptions,
        sortOptions: sortOptionsNameDistanceFromPaddock,
        defaultSortSettings: sortOptionsNameDistanceFromPaddock.find(
          (a) => a.name === 'distance_from_paddock'
        )?.settings,
        sortFunction: sortPaddockOptions,
        initialValue: [paddock.id],
        allSelectable: false,
        validation: Yup.array()
          .min(
            2,
            translate({
              key: 'paddocks.paddock_group.create.form.paddocks.validation.message',
            })
          )
          .required(),
      },
    ],
    error,
    loading,
    onSubmit,
    onCancel,
  };

  return <FormBuilder {...form} />;
};
