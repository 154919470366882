import React from 'react';

import { type I18nItem, useI18n } from '@mobble/i18n';
import { RainGaugeReading } from '@mobble/models/src/model/RainGaugeReading';
import { formatDateAndTime, fromRawDate } from '@mobble/shared/src/core/Date';
import { UsePropertiesResult } from '@mobble/store/src/hooks';
import { type EntitySliceFactoryProxyEntitiesResponse } from '@mobble/store/src/lib/entitySliceFactory';

import { LocaleQuantity } from '@src/stories/Components/Locale/LocaleQuantity';
import { EntitiesViewer } from '@src/stories/Views/Entities/EntitiesViewer';
import { RainGaugeReadingCard } from '@src/stories/Views/RainGauge/RainGaugeReadingCard';

export interface RainGaugeReadingProps {
  title?: I18nItem;
  className?: string;
  rainGaugeReadings: EntitySliceFactoryProxyEntitiesResponse<RainGaugeReading>;
  properties: UsePropertiesResult;
  onClick: (rainGaugeReading: RainGaugeReading) => void;
}

export const RainGaugeReadings: React.FC<RainGaugeReadingProps> = ({
  title,
  className,
  rainGaugeReadings,
  properties,
  onClick,
}) => {
  const { formatMessage } = useI18n();

  return (
    <EntitiesViewer
      title={title}
      className={className}
      asList
      provider={rainGaugeReadings}
      onEmpty={formatMessage({
        defaultMessage: 'There are currently no readings for this rain gauge.',
        description: 'Empty rain gauge readings message',
      })}
      tableColumns={[
        {
          key: 'reading',
          label: { key: 'reading' },
          totals: false,
          toValue: (rainGaugeReading) => rainGaugeReading.reading,
          render: (_, rainGaugeReading) => (
            <LocaleQuantity quantity={rainGaugeReading.value} />
          ),
        },
        {
          key: 'time',
          label: { key: 'time' },
          totals: false,
          toValue: (rainGaugeReading) =>
            fromRawDate(rainGaugeReading.date).toDate().getTime(),
          valueToString: (_, rainGaugeReading) =>
            formatDateAndTime(fromRawDate(rainGaugeReading.date)),
        },
      ]}
      onClickTableRow={onClick}
      renderEntityCard={(item) => {
        return (
          <RainGaugeReadingCard
            users={properties.selected?.users ?? []}
            rainGaugeReading={item}
            onClick={onClick}
          />
        );
      }}
    />
  );
};
