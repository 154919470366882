import React from 'react';

import { useI18n } from '@mobble/i18n';
import {
  filterMobActions,
  MobAction,
} from '@mobble/models/src/model/MobAction';
import { usePaddocks } from '@mobble/store/src/hooks/paddocks';
import { useProperties } from '@mobble/store/src/hooks/properties';

import { useLinking } from '@src/hooks/useLinking';
import * as ROUTE_NAME from '@src/screens/config/routeNames';
import { Box } from '@src/stories/Components/Layout/Box';
import {
  EntitiesViewer,
  type EntitiesViewerProps,
} from '@src/stories/Views/Entities/EntitiesViewer';

import { type Provider } from '../../Entities/types';
import { MobActionCard } from '../MobActionCard';

import { makeMobActionTableColumns } from './mobActionTableColumns';

export interface MobActionsViewerProps {
  title?: string;
  showTitle?: boolean;
  provider: Provider<MobAction>;
  renderEntityCard?: (casualty: MobAction) => React.ReactNode;
}

export const MobActionsViewer: React.FC<MobActionsViewerProps> = ({
  title,
  showTitle,
  provider,
  renderEntityCard,
}) => {
  const { formatMessage } = useI18n();
  const properties = useProperties();
  const paddocks = usePaddocks(properties.selected?.id);
  const linkTo = useLinking();

  const applyFilter = filterMobActions;
  const onClick = (mobAction: MobAction) => {
    linkTo(ROUTE_NAME.MOB_ACTION_DETAIL, {
      mobActionId: mobAction.id,
    });
  };

  const baseViewerProps: EntitiesViewerProps<MobAction> = {
    provider,
    applyFilter,
    title,
    showTitle,
    onEmpty: formatMessage({
      defaultMessage: 'There are no Mob Actions to display',
      description: 'mob_actions.list.empty',
    }),
    onRefresh: undefined,
    onClickTableRow: onClick,
    renderEntityCard:
      renderEntityCard ??
      ((item) => {
        return (
          <Box spacing={1}>
            <MobActionCard mobAction={item} onClick={onClick} />
          </Box>
        );
      }),
    tableColumns: makeMobActionTableColumns({
      paddocks: paddocks.entities,
      formatMessage,
    }),
  };

  if (Array.isArray(provider)) {
    return (
      <EntitiesViewer key={properties.selected?.id} {...baseViewerProps} />
    );
  }

  const filterItems = () => [
    {
      type: 'select-multiple',
      group: 'type',
      title: formatMessage({
        defaultMessage: 'Action type',
        description: 'mob_actions.filter.group.type.label',
      }),
      data: Array.from(
        new Set(provider.entities.map((entity) => entity.type))
      ).map((value) => ({
        value,
      })),
    },
  ];

  const extendedViewerProps: EntitiesViewerProps<MobAction> = {
    ...baseViewerProps,
    filterItems,
    onEmpty: formatMessage({
      defaultMessage: 'There are no Mob Actions to display',
      description: 'mob_actions.table.empty',
    }),
    onRefresh: () => provider.refresh(),
  };

  return (
    <EntitiesViewer key={properties.selected?.id} {...extendedViewerProps} />
  );
};
