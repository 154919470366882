import React from 'react';

import logger from '@mobble/shared/src/core/Logger';
import { useRootDispatch } from '@mobble/store/src/hooks/root';
import { updateNetworkInformation } from '@mobble/store/src/reducers/network';

export const useNetworkInformation = () => {
  const dispatch = useRootDispatch();

  React.useEffect(() => {
    const onNetworkChange = () => {
      const type = 'browser';
      const isConnected = navigator.onLine;
      const isInternetReachable = navigator.onLine;
      const netInfo = {
        type,
        isConnected,
        isInternetReachable,
      };

      logger.info('useNetworkInformation', JSON.stringify(netInfo));
      dispatch(updateNetworkInformation(netInfo));
    };

    window.addEventListener('offline', onNetworkChange);
    window.addEventListener('online', onNetworkChange);

    onNetworkChange();

    return () => {
      window.removeEventListener('offline', onNetworkChange);
      window.removeEventListener('online', onNetworkChange);
    };
  }, [dispatch, window]);
};

export const useIsOnline = () => {
  return navigator.onLine;
};
