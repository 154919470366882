import React from 'react';
import isEqual from 'lodash/isEqual';

import { MapStyle } from '@mobble/models/src/model/MapStyle';

import {
  useMapEventsContext,
  useMapPluginsContext,
  useMapPluginsPointsContext,
} from '../Context';
import { type MapEvent } from '../Events';
import { CustomFeature } from '../Items/CustomFeature';
import { MapAsset } from '../Items/MapAsset';
import {
  MAP_ITEM_CUSTOM_FEATURE,
  MAP_ITEM_MAP_ASSET,
  MAP_ITEM_MOBS,
  MAP_ITEM_PADDOCK_BOUNDARY,
  MAP_ITEM_PADDOCK_GROUP,
  MAP_ITEM_PADDOCK_LABEL,
  MAP_ITEM_TASK,
  type MapItem,
} from '../Items/MapItemType';
import { Mobs } from '../Items/Mob';
import { PaddockBoundary } from '../Items/PaddockBoundary';
import { PaddockGroup } from '../Items/PaddockGroup';
import { PaddockLabel } from '../Items/PaddockLabel';
import { Task } from '../Items/Task';

import ItemsWhileDrawing from './ItemsWhileDrawing';

const MAP_ITEMS_ORDER = [
  MAP_ITEM_MOBS,
  MAP_ITEM_TASK,
  // MAP_ITEM_MAP_ASSET,
  'Point',
  'LineString',
  MAP_ITEM_PADDOCK_LABEL,
  MAP_ITEM_PADDOCK_BOUNDARY,
  MAP_ITEM_PADDOCK_GROUP,
  MAP_ITEM_CUSTOM_FEATURE,
];

const sortMapItem = (a: MapItem, b: MapItem) => {
  // order the unsafe paddocks on a higher layer than the other paddocks
  if (
    a.type === MAP_ITEM_PADDOCK_BOUNDARY &&
    b.type === MAP_ITEM_PADDOCK_BOUNDARY &&
    a.meta.isSafe
  ) {
    return -1;
  }

  const getIndex = (mapItem: MapItem) => {
    if (mapItem.type === MAP_ITEM_MAP_ASSET) {
      return MAP_ITEMS_ORDER.indexOf(mapItem.geometry.type);
    }
    return MAP_ITEMS_ORDER.indexOf(mapItem.type);
  };

  const aIndex = getIndex(a);
  const bIndex = getIndex(b);

  return bIndex - aIndex;
};

export const Effect: React.FC = React.memo(() => {
  const { mapProperties } = useMapPluginsContext();
  const { onEvent } = useMapEventsContext();

  const pointStorage = useMapPluginsPointsContext();

  if (pointStorage.enabled) {
    return <ItemsWhileDrawing.Effect />;
  }

  return (
    <EffectMemoized
      mapStyle={mapProperties.mapStyle}
      items={[...mapProperties.items, ...mapProperties.additionalMapItems]}
      onEvent={onEvent}
    />
  );
});

interface EffectMemoizableProps {
  mapStyle: MapStyle;
  items: MapItem[];
  onEvent?: (event: MapEvent) => void;
}

const EffectMemoizable: React.FC<EffectMemoizableProps> = ({
  mapStyle,
  items,
  onEvent,
}) => {
  const sortedItems = [...items].sort(sortMapItem);
  const children = sortedItems.map((item, index) => {
    switch (item.type) {
      case MAP_ITEM_PADDOCK_BOUNDARY:
        return (
          <PaddockBoundary
            key={`${item.type}-${item.id}-${index}`}
            item={item}
            mapStyle={mapStyle}
          />
        );

      case MAP_ITEM_PADDOCK_GROUP:
        return (
          <PaddockGroup
            key={`${item.type}-${item.id}-${index}`}
            item={item}
            mapStyle={mapStyle}
          />
        );

      case MAP_ITEM_PADDOCK_LABEL:
        return (
          <PaddockLabel
            key={`${item.type}-${item.id}-${index}`}
            item={item}
            mapStyle={mapStyle}
            withOffset
          />
        );

      case MAP_ITEM_MOBS: {
        return (
          <Mobs
            key={`${item.type}-${item.id}-${
              item.meta.size
            }-${item.meta.mobTypes.join('-')}-${index}`}
            item={item}
            mapStyle={mapStyle}
            onEvent={onEvent}
          />
        );
      }

      case MAP_ITEM_TASK: {
        return (
          <Task
            key={`${item.type}-${item.id}-${index}`}
            item={item}
            mapStyle={mapStyle}
          />
        );
      }

      case MAP_ITEM_MAP_ASSET: {
        return (
          <MapAsset
            key={`${item.type}-${item.id}`}
            item={item}
            mapStyle={mapStyle}
          />
        );
      }

      case MAP_ITEM_CUSTOM_FEATURE: {
        return (
          <CustomFeature
            key={`${item.type}-${item.id}`}
            mapStyle={mapStyle}
            item={item}
          />
        );
      }

      default:
        return null;
    }
  });

  return <>{children}</>;
};

const EffectMemoized = React.memo(EffectMemoizable, (prev, next) =>
  isEqual(prev, next)
);

export default {
  Effect,
};
