import React from 'react';

import { Color } from '@mobble/colors';

import { Box } from './Box';

import styles from './well.scss';

export interface WellProps {
  background?: Color;
  children?: React.ReactNode;
}

export const Well: React.FC<WellProps> = ({ background, children }) => {
  return (
    <Box
      className={styles.well}
      background={background ?? Color.LightGrey}
      spacing={2}
    >
      {children}
    </Box>
  );
};
