import React from 'react';

import { useI18n } from '@mobble/i18n/src';
import { StockingUnit } from '@mobble/models/src/model/Settings';
import { type PropertyStockingRate } from '@mobble/service/src/ext/property-stocking-rates';
import { fromRawDate } from '@mobble/shared/src/core/Date';
import { useSetting } from '@mobble/store/src/hooks';

import { ChartWrapper } from '@src/stories/Components/Charts/ChartWrapper';

import { getStockingUnitI18n } from '../../Locale/LocaleStockingUnit';

import { ChartLivestockHistoryGraph } from './ChartLivestockHistoryGraph';
import {
  useConvertStockingRatesData,
  useConvertStockingRatesDataUnits,
  useYearSelector,
} from './useConverStockingRatesData';

export type Mode = 'head' | 'stocking_rate' | 'area_stocking_rate';

const modeToRecordOption = {
  head: 'head',
  stocking_rate: 'DSE',
  area_stocking_rate: 'areaDSE',
};

const useModes = () => {
  const { formatMessage } = useI18n();
  const stockingUnit = useSetting('stockingUnit') as StockingUnit;
  const stockingUnitLabel = formatMessage(getStockingUnitI18n(stockingUnit));

  return [
    {
      label: formatMessage({
        defaultMessage: 'Head',
        description: 'chart.historic-livestock.option.head',
      }),
      value: 'head',
    },
    {
      label: stockingUnitLabel,
      value: 'stocking_rate',
    },
    {
      label: formatMessage(
        {
          defaultMessage: '{STOCKING_UNIT}/Ha',
          description: 'chart.historic-livestock.option.area_stocking_rate',
        },
        {
          STOCKING_UNIT: stockingUnitLabel,
        }
      ),
      value: 'area_stocking_rate',
    },
  ];
};

export interface ChartHistoricPropertyLivestockProps {
  stockingRates: PropertyStockingRate[];
}

export const ChartHistoricPropertyLivestock: React.FC<
  ChartHistoricPropertyLivestockProps
> = ({ stockingRates }) => {
  const [mode, setMode] = React.useState<Mode>('head');
  const modeOptions = useModes();

  const years = [
    ...new Set(stockingRates.map((sr) => fromRawDate(sr.dateRaw).year())),
  ];

  const { year, setYear, yearOptions } = useYearSelector(years);

  const stockingRateData = useConvertStockingRatesData({
    year,
    stockingRates,
    mode: modeToRecordOption[mode] as any,
  });

  const { data, dataAverage, dataAverageByYmd, maxY } =
    useConvertStockingRatesDataUnits(stockingRateData)(mode);

  const options = [
    {
      options: yearOptions,
      onChange: (value) => setYear(Number(value)),
    },
    {
      options: modeOptions,
      onChange: (value: Mode) => setMode(value),
    },
  ];

  return (
    <ChartWrapper
      title={{ key: 'chart.historic-livestock.title' }}
      options={options}
    >
      <div style={{ height: '400px' }}>
        <ChartLivestockHistoryGraph
          data={data}
          dataAverage={dataAverage}
          dataAverageByYmd={dataAverageByYmd}
          maxY={maxY}
        />
      </div>
    </ChartWrapper>
  );
};

export interface ChartHistoricPropertyLivestockReportsPageProps {
  stockingRates: PropertyStockingRate[];
  multiProperty?: boolean;
}

export const ChartHistoricReportsLivestock: React.FC<
  ChartHistoricPropertyLivestockReportsPageProps
> = ({ stockingRates, multiProperty }) => {
  const [mode, setMode] = React.useState<Mode>('head');
  const modeOptions = useModes();

  const stockingRateData = useConvertStockingRatesData({
    stockingRates,
    mode: modeToRecordOption[mode] as any,
  });

  const { data, dataAverage, dataAverageByYmd, maxY } =
    useConvertStockingRatesDataUnits(stockingRateData)(mode);

  const options = !multiProperty && [
    {
      options: modeOptions,
      onChange: (value: Mode) => setMode(value),
    },
  ];

  return (
    <ChartWrapper
      title={{ key: 'chart.historic-livestock.title' }}
      options={options}
    >
      <div style={{ height: '400px' }}>
        <ChartLivestockHistoryGraph
          data={data}
          dataAverage={dataAverage}
          dataAverageByYmd={dataAverageByYmd}
          maxY={maxY}
        />
      </div>
    </ChartWrapper>
  );
};
