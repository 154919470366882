import React from 'react';

import { useI18n } from '@mobble/i18n';
import { useExtStatus } from '@mobble/store/src/hooks';

import { useFormError } from '@src/hooks/useFormError';
import { useLinking } from '@src/hooks/useLinking';
import { useNavigateBack } from '@src/hooks/useNavigateBack';
import { DeleteForm } from '@src/stories/Views/Form/DeleteForm/DeleteForm';
import { ScreenHeader } from '@src/stories/Views/Misc/ScreenHeader';
import {
  SaleEditForm,
  SaleEditFormValues,
} from '@src/stories/Views/Sale/SaleEditForm';

import * as ROUTE_NAME from '../config/routeNames';
import { ScreenRendererProps } from '../config/types';

import { useSaleScreen,useSaleScreenHeader } from './useSaleScreen';

export const SaleEditHeader: React.FC<ScreenRendererProps> = ({ route }) => {
  const { saleId } = route.params;
  const props = useSaleScreenHeader(saleId, true);

  return <ScreenHeader {...props} />;
};

export const SaleEdit: React.FC<ScreenRendererProps> = ({ route }) => {
  const { saleId } = route.params;
  const { formatMessage } = useI18n();
  const linkTo = useLinking();
  const goBack = useNavigateBack();

  const { Prelude, sale, paddocks, properties } = useSaleScreen(saleId);
  const extStatus = useExtStatus('sales', 'update', saleId);
  const formLoading = extStatus?.loading;
  const extStatusDelete = useExtStatus('sales', 'delete', saleId);
  const deleteLoading = extStatusDelete?.loading;
  const formError = useFormError({
    entityName: formatMessage({
      defaultMessage: 'Sale',
      description: 'screen.title.sale_detail',
    }),
    active: Boolean(extStatus?.error),
  });

  if (Prelude) {
    return Prelude;
  }

  const handleSubmit = (formValues: SaleEditFormValues) => {
    if (!sale.entity || !properties.selected) {
      return;
    }

    const updatedSale = {
      ...sale.entity,
      date: formValues.date,
      pricePerHeadCents: formValues.price_per_head
        ? Number(formValues.price_per_head) * 100 || 0
        : 0,
      number: formValues.number,
      soldToName: formValues.sold_to,
      notes: formValues.notes,
    };

    sale
      .update(updatedSale)
      .then(() => {
        goBack();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleDelete = () => {
    sale
      .remove()
      .then(() => {
        linkTo(ROUTE_NAME.SUMMARY_SALES_LIST);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <SaleEditForm
        sale={sale.entity}
        paddocks={paddocks.entities}
        error={formError}
        loading={formLoading}
        onCancel={goBack}
        onSubmit={handleSubmit}
      />
      <DeleteForm
        i18nRootKey="sales.sale.delete"
        loading={deleteLoading}
        onDelete={handleDelete}
      />
    </>
  );
};

export default {
  name: ROUTE_NAME.SALE_EDIT,
  header: SaleEditHeader,
  component: SaleEdit,
};
