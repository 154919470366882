import React, { useEffect, useRef, useState } from 'react';
import mapboxgl from 'mapbox-gl';

import { MapContextProvider } from './lib/React/Context';
import { type MapChildren } from './lib/types';

import 'mapbox-gl/dist/mapbox-gl.css';

export type MapOptions = Omit<
  mapboxgl.MapboxOptions,
  'container' | 'accessToken'
>;

export interface MapProps {
  accessToken: string;
  className?: string;
  options?: MapOptions;
  children?: MapChildren;
  onMapReady?: (map: mapboxgl.Map) => void;
}

export const Map: React.FC<MapProps> = (props) => {
  const { accessToken, children, options, onMapReady } = props;
  const refContainer = useRef<HTMLDivElement>(null);
  const refMapboxGlMap = useRef<mapboxgl.Map>(null);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (!refContainer.current) {
      return;
    }

    const map = new mapboxgl.Map({
      container: refContainer.current,
      accessToken: accessToken,
      attributionControl: false,
      ...defaultOptions,
      ...options,
    });

    const zoom = new mapboxgl.NavigationControl({
      showZoom: true,
      showCompass: true,
    });
    map.addControl(zoom, 'top-left');

    map.on('load', () => {
      map.resize();
      onMapReady?.(map);
      setLoaded(true);
    });

    refMapboxGlMap.current = map;

    return () => {
      map.remove();
    };
  }, []);

  const classNames = ['mapbox-map-container', props.className]
    .filter(Boolean)
    .join(' ');

  return (
    <>
      <style
        type="text/css"
        dangerouslySetInnerHTML={{ __html: DEFAULT_STYLES }}
      />
      <div ref={refContainer} className={classNames}>
        {loaded && refMapboxGlMap.current && (
          <MapContextProvider map={refMapboxGlMap.current}>
            {children}
          </MapContextProvider>
        )}
      </div>
    </>
  );
};

const defaultOptions: Partial<mapboxgl.MapboxOptions> = {
  // touchPitch: false,
  // pitchWithRotate: false,
};

const DEFAULT_STYLES = `
  .mapbox-map-container {
    width: 100%;
    height: 100%;
  }
`;
