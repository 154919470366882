import React from 'react';
import { defineMessages } from 'react-intl';

import { useI18n, useMessages } from '@mobble/i18n';
import { EntitySliceFactoryProxyPrelude, PreludeStatus } from '@mobble/store';

import { store } from '@src/store';
import { ErrorScreen } from '@src/stories/Views/Error/ErrorScreen';
import { LoadingScreen } from '@src/stories/Views/Misc/LoadingScreen';

export interface EntitySliceFactoryPreludeProps {
  preludes: EntitySliceFactoryProxyPrelude[];
  required?: any[];
  notFound?: any[];
  type?: string;
}

const messages = defineMessages({
  'prelude.error.actionQueue': {
    description: 'prelude.error.actionQueue',
    defaultMessage: 'Error gathering offline requests.',
  },
  'prelude.error.auth': {
    description: 'prelude.error.auth',
    defaultMessage: 'Error gathering authentication information.',
  },
  'prelude.error.casualties': {
    description: 'prelude.error.casualties',
    defaultMessage: 'Error gathering casualties.',
  },
  'prelude.error.chats': {
    description: 'prelude.error.chats',
    defaultMessage: 'Error gathering messages.',
  },
  'prelude.error.FORBIDDEN': {
    description: 'prelude.error.FORBIDDEN',
    defaultMessage: 'You do not have permission to access this resource.',
  },
  'prelude.error.historicEvents': {
    description: 'prelude.error.historicEvents',
    defaultMessage: 'Error gathering events.',
  },
  'prelude.error.inventories': {
    description: 'prelude.error.inventories',
    defaultMessage: 'Error gathering inventories.',
  },
  'prelude.error.inventoryItems': {
    description: 'prelude.error.inventoryItems',
    defaultMessage: 'Error gathering inventory items.',
  },
  'prelude.error.mobActions': {
    description: 'prelude.error.mobActions',
    defaultMessage: 'Error gathering mob action.',
  },
  'prelude.error.mobs': {
    description: 'prelude.error.mobs',
    defaultMessage: 'Error gathering mobs.',
  },
  'prelude.error.paddockActions': {
    description: 'prelude.error.paddockActions',
    defaultMessage: 'Error gathering paddock action.',
  },
  'prelude.error.paddockGeometry': {
    description: 'prelude.error.paddockGeometry',
    defaultMessage: 'Error gathering paddocks geometry.',
  },
  'prelude.error.paddockGroupedStockingRate': {
    description: 'prelude.error.paddockGroupedStockingRate',
    defaultMessage: 'Error gathering paddock stocking rates.',
  },
  'prelude.error.paddockGroupGeometry': {
    description: 'prelude.error.paddockGroupGeometry',
    defaultMessage: 'Error gathering open gates geometry.',
  },
  'prelude.error.paddockGroups': {
    description: 'prelude.error.paddockGroups',
    defaultMessage: 'Error gathering paddocks.',
  },
  'prelude.error.paddocks': {
    description: 'prelude.error.paddocks',
    defaultMessage: 'Error gathering paddocks.',
  },
  'prelude.error.properties': {
    description: 'prelude.error.properties',
    defaultMessage: 'Error gathering properties.',
  },
  'prelude.error.purchases': {
    description: 'prelude.error.purchases',
    defaultMessage: 'Error gathering purchases.',
  },
  'prelude.error.rainGaugeReadings': {
    description: 'prelude.error.rainGaugeReadings',
    defaultMessage: 'Error gathering rain gauge readings.',
  },
  'prelude.error.rainGauges': {
    description: 'prelude.error.rainGauges',
    defaultMessage: 'Error gathering rain gauges.',
  },
  'prelude.error.sales': {
    description: 'prelude.error.sales',
    defaultMessage: 'Error gathering sales.',
  },
  'prelude.error.settings': {
    description: 'prelude.error.settings',
    defaultMessage: 'Error gathering settings.',
  },
  'prelude.error.tasks': {
    description: 'prelude.error.tasks',
    defaultMessage: 'Error gathering tasks.',
  },
  'prelude.loading.actionQueue': {
    description: 'prelude.loading.actionQueue',
    defaultMessage: 'Loading offline requests.',
  },
  'prelude.loading.auth': {
    description: 'prelude.loading.auth',
    defaultMessage: 'Loading authentication.',
  },
  'prelude.loading.casualties': {
    description: 'prelude.loading.casualties',
    defaultMessage: 'Loading casualties.',
  },
  'prelude.loading.chats': {
    description: 'prelude.loading.chats',
    defaultMessage: 'Loading messages.',
  },
  'prelude.loading.historicEvents': {
    description: 'prelude.loading.historicEvents',
    defaultMessage: 'Loading events.',
  },
  'prelude.loading.inventories': {
    description: 'prelude.loading.inventories',
    defaultMessage: 'Loading inventories.',
  },
  'prelude.loading.inventoryItems': {
    description: 'prelude.loading.inventoryItems',
    defaultMessage: 'Loading inventory items.',
  },
  'prelude.loading.maps': {
    description: 'prelude.loading.maps',
    defaultMessage: 'Loading maps.',
  },
  'prelude.loading.mobActions': {
    description: 'prelude.loading.mobActions',
    defaultMessage: 'Loading mob action.',
  },
  'prelude.loading.mobs': {
    description: 'prelude.loading.mobs',
    defaultMessage: 'Loading mobs.',
  },
  'prelude.loading.naturalIncreases': {
    description: 'prelude.loading.naturalIncreases',
    defaultMessage: 'Loading natural increase...',
  },
  'prelude.loading.paddockActions': {
    description: 'prelude.loading.paddockActions',
    defaultMessage: 'Loading paddock action...',
  },
  'prelude.loading.paddockGeometry': {
    description: 'prelude.loading.paddockGeometry',
    defaultMessage: 'Loading paddocks geometry...',
  },
  'prelude.loading.paddockGroupedStockingRate': {
    description: 'prelude.loading.paddockGroupedStockingRate',
    defaultMessage: 'Loading stocking rates...',
  },
  'prelude.loading.paddockGroupGeometry': {
    description: 'prelude.loading.paddockGroupGeometry',
    defaultMessage: 'Loading open gates geometry.',
  },
  'prelude.loading.paddockGroups': {
    description: 'prelude.loading.paddockGroups',
    defaultMessage: 'Loading paddocks.',
  },
  'prelude.loading.paddocks': {
    description: 'prelude.loading.paddocks',
    defaultMessage: 'Loading paddocks.',
  },
  'prelude.loading.properties': {
    description: 'prelude.loading.properties',
    defaultMessage: 'Loading properties.',
  },
  'prelude.loading.purchases': {
    description: 'prelude.loading.purchases',
    defaultMessage: 'Loading purchases.',
  },
  'prelude.loading.rainGaugeReadings': {
    description: 'prelude.loading.rainGaugeReadings',
    defaultMessage: 'Loading rain gauge readings.',
  },
  'prelude.loading.rainGauges': {
    description: 'prelude.loading.rainGauges',
    defaultMessage: 'Loading rain gauges.',
  },
  'prelude.loading.required': {
    description: 'prelude.loading.required',
    defaultMessage: 'Loading remaining required items.',
  },
  'prelude.loading.sales': {
    description: 'prelude.loading.sales',
    defaultMessage: 'Loading sales.',
  },
  'prelude.loading.settings': {
    description: 'prelude.loading.settings',
    defaultMessage: 'Loading settings.',
  },
  'prelude.loading.tasks': {
    description: 'prelude.loading.tasks',
    defaultMessage: 'Loading tasks.',
  },
});

export const EntitySliceFactoryPrelude = ({
  required = [],
  notFound = [],
  preludes,
  type,
}: EntitySliceFactoryPreludeProps) => {
  const { formatMessage } = useI18n();
  const strings = useMessages(messages);
  const isOnline = store.getState().network.connected;
  if (!isOnline) {
    return null;
  }

  const firstFailed = preludes.find((p) => p.status === PreludeStatus.Error);
  const firstLoading = preludes.find((p) => p.status === PreludeStatus.Loading);

  if (firstFailed) {
    return (
      <ErrorScreen
        label={strings[`prelude.error.${type || firstFailed.name}`]}
        error={firstFailed.lastError}
        onRetry={firstFailed.onRetry}
      />
    );
  }

  if (firstLoading) {
    return (
      <LoadingScreen
        label={strings[`prelude.loading.${type || firstLoading.name}`]}
      />
    );
  }

  if (required.some((a) => !a)) {
    return <LoadingScreen label={strings['prelude.loading.required']} />;
  }

  if (notFound.some((a) => !a)) {
    return (
      <ErrorScreen
        label={formatMessage({
          description: 'prelude.error.not_found.title',
          defaultMessage: 'Item not found.',
        })}
        error={formatMessage({
          description: 'prelude.error.not_found.description',
          defaultMessage: 'The requested item was not found.',
        })}
      />
    );
  }

  return null;
};
