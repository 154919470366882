import React from 'react';

import { Pill, PillProps } from '@src/stories/Components/UI/Pill';

import { Clickable } from './Clickable';

export type PillButton = {
  onClick: () => void;
} & PillProps;

export const PillButton: React.FC<PillButton> = ({ onClick, ...delegated }) => {
  return (
    <Clickable href={onClick}>
      <Pill {...delegated} />
    </Clickable>
  );
};
