import React from 'react';

import { mapAssetTypeToColor } from '@mobble/models/src/model/MapAsset';
import {
  type LineString,
  type Point,
} from '@mobble/models/src/model/MapGeometry';
import { type MapStyle } from '@mobble/models/src/model/MapStyle';

import { Line } from '@src/stories/Mapbox/Line';
import { Marker } from '@src/stories/Mapbox/Marker';

import { type MapItemMapAsset } from './MapItemType';

import styles from './mapAsset.scss';

export interface MapAssetProps {
  mapStyle: MapStyle;
  item: MapItemMapAsset;
}

export const MapAsset: React.FC<MapAssetProps> = ({ item }) => {
  const color = mapAssetTypeToColor(item.mapAssetType);

  switch (item.geometry.type) {
    case 'Point':
      return <MapAssetPoint item={item} point={item.geometry} color={color} />;
    case 'LineString':
      return (
        <MapAssetLineString
          item={item}
          lineString={item.geometry}
          color={color}
        />
      );
    default:
      return null;
  }
};

interface MapAssetPointProps {
  item: MapItemMapAsset;
  point: Point;
  color: string;
}

const MapAssetPoint: React.FC<MapAssetPointProps> = ({
  item,
  point,
  color,
}) => {
  const label = item.name.slice(0, 2).toLocaleUpperCase();
  return (
    <Marker
      id={`map_asset__point-${item.id}`}
      data={point}
      options={{ anchor: 'bottom' }}
    >
      <button
        onClick={(ev) => {
          ev.stopPropagation();
          item.onClick?.();
        }}
        className={styles.assetPointMarker}
      >
        <svg width={40} height={50} viewBox="0 0 17.967 21.772">
          <path
            d="M8.939 0C6.249.03 3.59 1.286 1.94 3.424-.094 5.927-.582 9.576.728 12.526c.893 2.15 2.686 3.892 4.836 4.779l3.416 4.467c1.179-1.492 2.278-3.081 3.506-4.512 3.13-1.286 5.356-4.477 5.46-7.86.298-4.077-2.636-8.07-6.587-9.077A8.834 8.834 0 0 0 8.94 0Z"
            fill="#FFFFFF"
          />
          <path
            d="M8.98.527A8.467 8.467 0 0 0 .513 8.994a8.467 8.467 0 0 0 5.395 7.888L8.98 20.9l3.072-4.018a8.467 8.467 0 0 0 5.394-7.888A8.467 8.467 0 0 0 8.98.527z"
            fill={color}
          />
          <path
            d="M8.98.527V20.9l3.072-4.018a8.467 8.467 0 0 0 5.394-7.888A8.467 8.467 0 0 0 8.98.527z"
            fill="#00000040"
          />
          <circle cx={8.979} cy={8.994} r={5.4} fill="#FFFFFF" />
        </svg>
        <div className={styles.assetPointMarkerInnerText}>
          {/* <Text color={Color.Black} align="center" variant="tiny" bold>
            {label}
          </Text> */}
          {label}
        </div>
      </button>
    </Marker>
  );
};

interface MapAssetLineStringProps {
  item: MapItemMapAsset;
  lineString: LineString;
  color: string;
}

const MapAssetLineString: React.FC<MapAssetLineStringProps> = ({
  item,
  lineString,
  color,
}) => {
  return (
    <Line
      id={`map_asset__line-${item.id}`}
      data={lineString}
      onClick={item.onClick}
      linePaint={{
        'line-width': 5,
        'line-color': color,
      }}
      symbolLayout={{
        'symbol-avoid-edges': true,
        'symbol-placement': 'line-center',
        'text-allow-overlap': true,
        'text-anchor': 'bottom',
        'text-field': item.name,
        'text-font': ['Ubuntu Bold'],
        'text-offset': [0, 0.1],
        'text-size': 12,
      }}
      symbolPaint={{
        'text-color': '#FFFFFF',
        'text-halo-blur': 1,
        'text-halo-color': '#000000',
        'text-halo-width': 1,
      }}
    />
  );
};
