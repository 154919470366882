import React, { ComponentPropsWithoutRef } from 'react';
import classNames from 'classnames/bind';

import { Color } from '@mobble/theme';

import { Spinner } from '@src/stories/Components/UI/Spinner';

import Icon, { IconName } from '../Icon';

import styles from './Card.scss';
const cx = classNames.bind(styles);

export type CardSize = 'small' | 'medium';
export type CardElevation = 'none' | 'low' | 'high';

export interface CardProps
  extends Omit<ComponentPropsWithoutRef<'div'>, 'title'> {
  /**
   * Card title
   */
  title?: string | React.ReactNode;

  /**
   * Card background color
   */
  color?: string;

  /**
   * Icon to display after the title
   */
  icon?: IconName;

  /**
   * Card elevation
   */
  elevation?: CardElevation;

  /**
   * Size of the card
   */
  size?: CardSize;

  /**
   * Loading state
   */
  loading?: boolean;

  /**
   * Render custom content in the top right corner
   */
  topRight?: React.ReactNode;
}

/**
 * Card component
 */
const Card: React.FC<CardProps> = ({
  color,
  elevation = 'high',
  size = 'medium',
  title,
  icon,
  children,
  loading,
  topRight,
  className,
  style,
  ...others
}) => {
  const rootClasses = cx(
    {
      Card: true,
      [elevation]: true,
      [size]: true,
    },
    className
  );

  const mergedStyle = {
    ...style,
    '--card-color': color,
  };

  return (
    <div className={rootClasses} style={mergedStyle} {...others}>
      {title ? (
        <header>
          {icon ? <Icon name={icon} size={size} /> : null}
          {loading ? <Spinner color={Color.BodyText} /> : null}
          {typeof title === 'string' ? (
            <>
              <h1>{title}</h1>
            </>
          ) : (
            title
          )}
          {topRight ? <div className={styles.topRight}>{topRight}</div> : null}
        </header>
      ) : null}
      <section>{children}</section>
    </div>
  );
};

export default Card;
