import React from 'react';

import { FormBuilder, type FormBuilderProps } from '../Form/FormBuilder';

export interface SettingsPropertyTypesCreateFormProps {
  showColor?: boolean;
  loading?: boolean;
  onSubmit: (formValues: SettingsPropertyTypesCreateFormValues) => void;
  onCancel: () => void;
}

export interface SettingsPropertyTypesCreateFormValues {
  label: string;
  color?: string;
}

export const SettingsPropertyTypesCreateForm: React.FC<
  SettingsPropertyTypesCreateFormProps
> = ({ showColor, loading, onSubmit, onCancel }) => {
  const form: FormBuilderProps<SettingsPropertyTypesCreateFormValues> = {
    flex: true,
    i18nRootKey: 'settings.property.types.new.form',
    fields: [
      {
        name: 'label',
        type: 'text',
        required: true,
      },
      {
        name: 'color',
        type: 'color',
        show: () => showColor ?? false,
        required: showColor,
      },
    ],
    loading,
    onSubmit,
    onCancel,
  };

  return <FormBuilder {...form} />;
};
