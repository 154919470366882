import React from 'react';

import {
  Color,
  colorToHex,
  contrastingTextColor,
} from '@mobble/colors/src/Color';
import { type I18nItem } from '@mobble/i18n';

import { Box } from '@src/stories/Components/Layout/Box';
import { Spacer } from '@src/stories/Components/Layout/Spacer';
import { HStack } from '@src/stories/Components/Layout/Stack';
import { Circle } from '@src/stories/Components/UI/Circle';
import { Text } from '@src/stories/Components/UI/Text';
import { Clickable } from '@src/stories/Components/UX/Clickable';

import styles from './tabBar.scss';

export interface TabBarProps<T extends string = any> {
  tabs: TabBarTab<T>[];
  backgroundColor?: Color;
  indicatorColor?: Color;
  className?: string;
}

export interface TabBarTab<T extends string> {
  label: I18nItem | string;
  value?: T;
  count?: string | number | React.ReactNode;
  isActive?: boolean;
  onClick: (value?: T) => void;
}

export const TabBar: React.FC<TabBarProps> = ({
  tabs,
  className,
  backgroundColor,
  indicatorColor,
}) => {
  const animatedIndicatorContainerRef = React.useRef<HTMLDivElement>(null);
  const [width, setWidth] = React.useState(window.innerWidth / tabs.length);

  React.useEffect(() => {
    const onResize = () => {
      setWidth(
        animatedIndicatorContainerRef.current?.getBoundingClientRect().width /
          tabs.length
      );
    };

    onResize();

    window.addEventListener('resize', onResize);
    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, [
    tabs.length,
    animatedIndicatorContainerRef.current?.getBoundingClientRect().width,
    window.innerWidth,
  ]);

  const activeIndex = tabs.findIndex((tab) => tab.isActive);
  const textColor = contrastingTextColor(backgroundColor ?? Color.Green);

  return (
    <Box
      background={backgroundColor ?? Color.Green}
      className={[styles.container, className]}
    >
      <div
        className={styles.animatedIndicatorContainer}
        ref={animatedIndicatorContainerRef}
      >
        <Box
          className={styles.animatedIndicatorPill}
          style={{
            width: `${width}px`,
            left: `${activeIndex * width}px`,
            backgroundColor: colorToHex(indicatorColor ?? Color.AlmostWhite),
          }}
        />
      </div>
      <HStack>
        {tabs.map((tab: TabBarTab<any>, index: number) => {
          const onClick = () => {
            tab.onClick(tab.value);
          };

          return (
            <Box flex key={`${tab.value}${index}`}>
              <Clickable className={styles.tabButton} href={onClick}>
                <Box flex spacing={1}>
                  <HStack alignment="center">
                    <HStack alignment="center">
                      <Spacer flex />
                      <Text
                        bold
                        align="center"
                        color={textColor}
                        i18n={tab.label}
                      />

                      {typeof tab.count !== 'undefined' ? (
                        <HStack>
                          <Spacer x={2} />
                          <Circle size="small" fill={textColor}>
                            <Text
                              variant="mono-tiny"
                              color={backgroundColor ?? Color.Green}
                            >
                              {tab.count}
                            </Text>
                          </Circle>
                        </HStack>
                      ) : null}
                      <Spacer flex />
                    </HStack>
                  </HStack>
                </Box>
              </Clickable>
            </Box>
          );
        })}
      </HStack>
    </Box>
  );
};
