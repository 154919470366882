import React from 'react';

import { User } from '@mobble/models/src/model/User';

import { ListSelectOption } from '@src/components/ListSelect';
import {
  FormBuilder,
  FormBuilderProps,
} from '@src/stories/Views/Form/FormBuilder';
import { UserCard } from '@src/stories/Views/Settings/SettingsPropertyMobbleConnectUserView';

export interface MobbleConnectEditUserFormProps {
  loading?: boolean;
  onSubmit: (formValues: MobbleConnectEditUserFormValues) => void;
  onCancel: () => void;

  user: User | undefined;
  propertyOptions: ListSelectOption[];
}

export interface MobbleConnectEditUserFormValues {
  email: string;
  propertyIds: string[];
}

export const MobbleConnectEditUserForm: React.FC<
  MobbleConnectEditUserFormProps
> = ({ loading, onSubmit, onCancel, user, propertyOptions }) => {
  const form: FormBuilderProps<MobbleConnectEditUserFormValues> = {
    flex: true,
    i18nRootKey: 'settings.property.mobble_connect.edit_user.form',
    fields: [
      {
        name: 'user',
        type: 'custom',
        containerComponent: false,
        component: () => (user ? <UserCard user={user} /> : null),
      },
      {
        name: 'propertyIds',
        type: 'select-multiple',
        options: propertyOptions,
        required: true,
      },
    ],
    loading,
    onSubmit,
    onCancel,
  };

  return <FormBuilder {...form} />;
};
