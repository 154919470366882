import React from 'react';
import isEqual from 'lodash/isEqual';

import { type MapStyle } from '@mobble/models/src/model/MapStyle';

import { useMapPluginsContext } from '../Context';
import {
  MAP_ITEM_PADDOCK_BOUNDARY,
  MAP_ITEM_PADDOCK_LABEL,
  type MapItem,
} from '../Items/MapItemType';
import { PaddockBoundary } from '../Items/PaddockBoundary';
import { PaddockLabel } from '../Items/PaddockLabel';

export const Effect: React.FC = () => {
  const { mapProperties } = useMapPluginsContext();

  return (
    <EffectMemoized
      mapStyle={mapProperties.mapStyle}
      items={mapProperties.items}
    />
  );
};

interface EffectMemoizableProps {
  mapStyle: MapStyle;
  items: MapItem[];
}

const EffectMemoizable: React.FC<EffectMemoizableProps> = ({
  mapStyle,
  items,
}) => {
  const children = items.map((item, index) => {
    switch (item.type) {
      case MAP_ITEM_PADDOCK_BOUNDARY:
        return (
          <PaddockBoundary
            key={`${item.type}-${item.id}-${index}`}
            item={item}
            mapStyle={mapStyle}
            disabled
          />
        );
      case MAP_ITEM_PADDOCK_LABEL:
        return (
          <PaddockLabel
            key={`${item.type}-${item.id}-${index}`}
            item={item}
            mapStyle={mapStyle}
            withOffset
            disabled
          />
        );

      default:
        return null;
    }
  });

  return <>{children}</>;
};

const EffectMemoized = React.memo(EffectMemoizable, (prev, next) =>
  isEqual(prev, next)
);

export default {
  Effect,
};
