import React from 'react';

import { Color } from '@mobble/colors';

import { Box, type BoxProps } from '../Layout/Box';
import { HStack } from '../Layout/Stack';
import { Text } from '../UI/Text';

import styles from './display.scss';

export type DisplayProps = BoxProps & {
  backgroundColor?: Color;
  color?: Color;
  size?: 'small' | 'normal';
  value?: null | string | number;
};

export const Display: React.FC<DisplayProps> = ({
  backgroundColor = Color.White,
  color = Color.DarkGrey,
  size = 'normal',
  value,
}) => {
  const textVariant = size === 'small' ? 'small' : 'normal';

  return (
    <Box background={backgroundColor} className={styles.displayWrapper}>
      <HStack>
        <>
          <Box className={[styles.display]}>
            <Text variant={textVariant} color={color}>
              {value ?? ''}
            </Text>
          </Box>
        </>
      </HStack>
    </Box>
  );
};
