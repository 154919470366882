import React from 'react';
import { defineMessages } from 'react-intl';

import { useMessages } from '@mobble/i18n';
import { type I18nItem } from '@mobble/i18n';
import { BaseEntity } from '@mobble/models/src/model/BaseEntity';
import { type FilterItem } from '@mobble/models/src/model/Filter';
import { type SortSetting } from '@mobble/shared/src/core/Sort';
import { EntitySliceFactoryProxyEntitiesResponse } from '@mobble/store/src/lib/entitySliceFactory';

import { Box } from '@src/stories/Components/Layout/Box';
import {
  Table,
  type TableColumn,
  type TableProps,
} from '@src/stories/Components/Layout/Table';

type Provider<Entity extends BaseEntity> =
  | EntitySliceFactoryProxyEntitiesResponse<Entity>
  | Entity[]
  | {
      title: I18nItem;
      right?: I18nItem;
      data: Entity[];
    }[];

export interface EntitiesTableProps<Entity extends BaseEntity> {
  provider: Provider<Entity> | Entity[];
  title?: I18nItem | string;
  showTitle?: boolean;
  onEmpty?: I18nItem | (() => React.ReactElement) | string;
  header?: (
    entities: Entity[],
    meta: EntitiesTableArgMeta
  ) => React.ReactElement;
  onShowFilter?: () => void;
  onShowSort?: () => void;
  applyFilter?: (entities: Entity[], filter: FilterItem[]) => Entity[];
  applySort?: (entities: Entity[], sort: SortSetting[]) => Entity[];
  onRefresh?: () => Promise<void>;
  columns: TableColumn[];
  onClickTableRow?: (entity: Entity) => void;
  selectedEntities?: Entity['id'][];
  onSelectEntities?: (entityIds: Entity['id'][]) => void;
  withSelection?: (entityIds: Entity['id'][]) => React.ReactElement;
}

export interface EntitiesTableArgMeta {
  filter: FilterItem[];
  sort: SortSetting[];
}

const messages = defineMessages({
  'count.all': {
    description: 'entities.entities_list.count.all',
    defaultMessage: 'Showing <b>{count}</b> of {total}',
  },
  'count.filtered': {
    description: 'entities.entities_list.count.filtered',
    defaultMessage: 'Filtered <b>{count}</b> of {total}',
  },
});

const makeTableData = <Entity extends BaseEntity>({
  provider,
  applyFilter,
  applySort,
}: {
  provider: EntitySliceFactoryProxyEntitiesResponse<Entity>;
  applyFilter?: (entities: Entity[], filter: FilterItem[]) => Entity[];
  applySort?: (entities: Entity[], sort: SortSetting[]) => Entity[];
}): TableProps<Entity>['data'] => {
  const isSearching =
    applyFilter &&
    Boolean(
      provider.filter.find(
        (a) => a.group === 'search' && a.filter?.value !== ''
      )
    );

  const filteredData = applyFilter
    ? applyFilter(provider.entities, provider.filter)
    : provider.entities;

  const data =
    applySort && !isSearching
      ? applySort(filteredData, provider.sort)
      : filteredData;

  return data;
};

export function EntitiesTable<Entity extends BaseEntity>({
  provider,
  onEmpty,
  onShowSort,
  onShowFilter,
  applyFilter,
  applySort,
  header,
  columns,
  title,
  showTitle,
  onClickTableRow,
  onRefresh,
  selectedEntities,
  onSelectEntities,
  withSelection,
}: EntitiesTableProps<Entity>) {
  const providerIsArray = Array.isArray(provider);

  const [manualRefreshing, setManualRefreshing] = React.useState(false);
  const proxyOnRefresh = onRefresh
    ? () => {
        setManualRefreshing(true);
        onRefresh().then(() => {
          setManualRefreshing(false);
        });
      }
    : undefined;

  const data = React.useMemo(() => {
    return providerIsArray
      ? provider
      : makeTableData({
          provider,
          applyFilter,
          applySort,
        });
  }, [provider]);

  const strings = useMessages(messages, {
    count: data.length,
    total: (provider as any).total,
  });

  const key =
    applyFilter && provider.filter.length > 0 ? 'count.filtered' : 'count.all';

  const counts = (provider as any)?.total ? strings[key] : undefined;

  const entitiesFromData = React.useMemo(() => {
    if (!data || data.length === 0) {
      return [];
    } else if (Array.isArray((data[0] as any).data)) {
      return (data[0] as any).data as Entity[];
    }
    return data as Entity[];
  }, [data]);

  const searchQuery = React.useMemo(() => {
    return providerIsArray
      ? undefined
      : (provider.filter.find((a) => a.group === 'search')?.filter?.value as
          | string
          | undefined);
  }, [provider.filter, providerIsArray]);

  const onSearch = (value: string) => {
    if (providerIsArray) {
      return;
    }

    provider.setFilter({
      group: 'search',
      filter: { type: 'search', value },
    });
  };

  const additionalHeader = header
    ? header(entitiesFromData, {
        filter: providerIsArray ? [] : provider.filter,
        sort: providerIsArray ? [] : provider.sort,
      })
    : null;

  const headerComponent = providerIsArray ? additionalHeader : null;
  const activeFilterCount = providerIsArray ? 0 : provider.filter.length;

  const onClearFilter = providerIsArray
    ? undefined
    : () => {
        provider.clearFilter();
      };

  return (
    <Box flex>
      <Table
        sticky={!providerIsArray}
        title={title}
        showTitle={showTitle}
        counts={counts}
        selected={selectedEntities}
        onSelect={onSelectEntities}
        withSelection={withSelection}
        columns={columns}
        header={headerComponent}
        data={data}
        idExtractor={(item: Entity) => item.id}
        loading={
          Array.isArray(provider)
            ? manualRefreshing
            : manualRefreshing || provider.refreshing || provider.loading
        }
        onEmpty={onEmpty}
        onShowSort={onShowSort}
        onShowFilter={onShowFilter}
        onRefresh={proxyOnRefresh}
        onClearFilter={onClearFilter}
        onSearch={onSearch}
        searchQuery={searchQuery}
        activeFilterCount={activeFilterCount}
        onClickRow={onClickTableRow}
      />
    </Box>
  );
}
