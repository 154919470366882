import React from 'react';
import { useNavigate } from 'react-router-dom';

import { useI18n } from '@mobble/i18n';
import { Property } from '@mobble/models/src/model/Property';
import { User, UserRole } from '@mobble/models/src/model/User';
import {
  addDays,
  type DateRange,
  formatDate,
  getDateRange,
} from '@mobble/shared/src/core/Date';
import { useGetUser,useProperties } from '@mobble/store/src/hooks';

import { useDateRange } from '@src/hooks/useDateRange';
import * as ROUTE_NAME from '@src/screens/config/routeNames';
import { type ScreenRendererProps } from '@src/screens/config/types';
import { ChartHistoricReportsLivestock } from '@src/stories/Components/Charts/LivestockHistoricGraph/LivestockHistoricGraph';
import { Box } from '@src/stories/Components/Layout/Box';
import { Text } from '@src/stories/Components/UI/Text';
import { ScreenHeader } from '@src/stories/Views/Misc/ScreenHeader';
import { ReportsDateRangeSelector } from '@src/stories/Views/Reports/DateRangeSelector';
import { LivestockReconciliationReport } from '@src/stories/Views/Reports/LivestockReconciliationReport';
import { LivestockReconciliationTiles } from '@src/stories/Views/Reports/LivestockReconciliationTiles';
import { LivestockSalesReport } from '@src/stories/Views/Reports/LivestockSalesReport';
import { LivestockSalesTiles } from '@src/stories/Views/Reports/LivestockSalesTiles';
import { PropertySelector } from '@src/stories/Views/Reports/PropertySelector';
import { PropertyTotalsGraphs } from '@src/stories/Views/Reports/PropertyTotalsGraphs';
import {
  mergeReportItems,
  usePropertyAggregateReportForRange,
} from '@src/stories/Views/Reports/usePropertyReportForRange';
import { usePropertiesStockingRatesForRange } from '@src/stories/Views/Reports/usePropertyStockingRatesForRange';

import styles from './reports.scss';

export const ReportsHeader: React.FC<ScreenRendererProps> = (props) => {
  const { formatMessage } = useI18n();

  return (
    <ScreenHeader
      title={formatMessage({
        defaultMessage: 'Reports',
        description: 'screen.title.reports',
      })}
      onToggleDrawer={props.drawer.toggle}
    />
  );
};

const getPropertiesWithReportingAccess = (
  properties: Property[],
  userId: User['id'],
  organisationId: Property['organisationId']
) => {
  return properties.filter(
    (property) =>
      property.organisationId === organisationId &&
      property.users.find(
        (user) =>
          user.id === userId &&
          (user.role === UserRole.Admin ||
            user.role === UserRole.Owner ||
            user.role === UserRole.Accountant ||
            user.role === UserRole.FarmAdvisor ||
            user.role === UserRole.StockAgent)
      )
  );
};

export const Reports: React.FC<ScreenRendererProps> = (props) => {
  const navigate = useNavigate();
  const properties = useProperties();
  const user = useGetUser();

  const rangeFromRoute = props.route.params?.range;
  const defaultDateRange = getDateRange('last-4-weeks');
  const { range, changeRange } = useDateRange({
    rangeFromRoute,
    defaultRange: {
      start: new Date(addDays(defaultDateRange.start, -1).toISOString()),
      end: new Date(addDays(defaultDateRange.end, -1).toISOString()),
    },
  });

  const handleDateRangeChange = (range: DateRange) => {
    changeRange(range);

    const formattedRange = `${formatDate(
      range.start,
      'YYYY-MM-DD'
    )}--${formatDate(range.end, 'YYYY-MM-DD')}`;
    const newPath = `/reports/${formattedRange}`;

    navigate(newPath, { replace: true });
  };

  const reportableProperties = getPropertiesWithReportingAccess(
    properties.entities,
    user.id,
    properties.selected?.organisationId
  );

  const moreThanOnePropertyExists = reportableProperties.length > 1;

  const [selectedPropertyIds, setSelectedPropertyIds] = React.useState<
    Property['id'][]
  >([properties.selected.id]);

  const moreThanOnePropertySelected = selectedPropertyIds.length > 1;

  const propertiesReports = usePropertyAggregateReportForRange({
    propertyIds: selectedPropertyIds,
    range,
  });
  const propertyStockingRatesData = usePropertiesStockingRatesForRange({
    propertyIds: selectedPropertyIds,
    range,
  });

  if (!properties.selected || !user) {
    return null;
  }

  const reportData =
    propertiesReports.type === 'ready'
      ? mergeReportItems(propertiesReports)
      : null;

  const hasSales = reportData && reportData.map((a) => a.sales.sold).length > 0;

  const oneDayBeforeToday = new Date(addDays(new Date(), -1).toISOString());

  return (
    <Box className={styles.report}>
      <Box className={styles.dateRangeSelector}>
        {moreThanOnePropertyExists && (
          <PropertySelector
            properties={reportableProperties}
            selectedPropertyIds={selectedPropertyIds}
            setSelectedPropertyIds={setSelectedPropertyIds}
          />
        )}
        <ReportsDateRangeSelector
          range={range}
          onChange={handleDateRangeChange}
          max={oneDayBeforeToday}
        />
      </Box>
      {selectedPropertyIds.length ? (
        <>
          {propertyStockingRatesData.type === 'ready' && (
            <Box className={styles.tilesContainer}>
              <ChartHistoricReportsLivestock
                stockingRates={propertyStockingRatesData.raw}
                multiProperty={moreThanOnePropertySelected}
              />
            </Box>
          )}
          <Box className={styles.tilesContainer}>
            <LivestockReconciliationTiles reportData={reportData} />
          </Box>

          <Box className={styles.reportContainer}>
            <LivestockReconciliationReport
              propertyTypes={properties.selected?.types ?? []}
              reportData={reportData}
            />
          </Box>
          <Box className={styles.dashboardLayout}>
            <PropertyTotalsGraphs
              propertiesReports={propertiesReports}
              properties={properties.entities}
            />
          </Box>
          {hasSales && (
            <>
              <Box className={styles.tilesContainer}>
                <LivestockSalesTiles reportData={reportData} />
              </Box>

              <Box className={styles.reportContainer}>
                <LivestockSalesReport
                  propertyTypes={properties.selected?.types ?? []}
                  reportData={reportData}
                />
              </Box>
            </>
          )}
        </>
      ) : (
        <Box className={styles.tilesContainer}>
          <Text bold i18n={{ key: 'reports.no_properties_selected.label' }} />
        </Box>
      )}
    </Box>
  );
};

export default {
  name: ROUTE_NAME.REPORTS,
  header: ReportsHeader,
  component: Reports,
};
