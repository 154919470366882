import React from 'react';
import { useNavigate } from 'react-router';

import { useI18n } from '@mobble/i18n';
import { useEntitiesRefresher } from '@mobble/shared/src/hooks/useEntitiesRefresher';
import {
  useCustomMapLayer,
  useExtStatus,
  useProperties,
} from '@mobble/store/src/hooks';

import { useNavigateBack } from '@src/hooks/useNavigateBack';
import { toPath } from '@src/interfaces/Routing';
import {
  CustomMapLayerEditForm,
  CustomMapLayerEditFormValues,
} from '@src/stories/Views/CustomMapLayer/CustomMapLayerEditForm';
import { EntitySliceFactoryPrelude } from '@src/stories/Views/Misc/EntitySliceFactoryPrelude';
import { ScreenHeader } from '@src/stories/Views/Misc/ScreenHeader';

import * as ROUTE_NAME from '../config/routeNames';
import { ScreenRendererProps } from '../config/types';

export const CustomMapLayerEditHeader: React.FC<ScreenRendererProps> = (
  props
) => {
  const { formatMessage } = useI18n();
  const { customMapLayerId = '' } = props.route.params;
  const properties = useProperties();
  const propertyId = properties.selected?.id;
  const customMapLayer = useCustomMapLayer(customMapLayerId, propertyId);
  const goBack = useNavigateBack();

  return (
    <ScreenHeader
      title={formatMessage({
        description: 'screen.title.custom_map_layer_edit',
        defaultMessage: 'Edit custom map layer',
      })}
      breadcrumbs={[
        {
          title: formatMessage({
            description: 'screen.title.settings',
            defaultMessage: 'Settings',
          }),
          href: ROUTE_NAME.SETTINGS_LIST,
        },
        {
          title: formatMessage({
            description: 'screen.title.summary_property_custom_map_layers',
            defaultMessage: 'Custom map layers',
          }),
          href: ROUTE_NAME.SETTINGS_PROPERTY_CUSTOM_MAP_LAYERS,
        },
        {
          title: customMapLayer?.entity
            ? customMapLayer.entity.name
            : formatMessage({
                defaultMessage: 'Custom map layer',
                description: 'screen.title.custom_map_layer_detail',
              }),
          href: toPath(ROUTE_NAME.CUSTOM_MAP_LAYER_DETAIL, {
            customMapLayerId,
          }),
        },
      ]}
      onGoBack={goBack}
    />
  );
};

export const CustomMapLayerEdit: React.FC<ScreenRendererProps> = (props) => {
  const { customMapLayerId = '' } = props.route.params;
  const properties = useProperties();
  const propertyId = properties.selected?.id;
  const customMapLayer = useCustomMapLayer(customMapLayerId, propertyId);
  const navigate = useNavigate();

  useEntitiesRefresher([customMapLayer], propertyId);

  const extStatus = useExtStatus('customMapLayers', 'update', customMapLayerId);
  const formLoading = extStatus?.loading;

  const Prelude = EntitySliceFactoryPrelude({
    preludes: [properties.prelude, customMapLayer.prelude],
    required: [properties.selected?.types],
    notFound: [customMapLayer.entity],
  });

  if (Prelude) {
    return Prelude;
  }

  const handleSubmit = (formValues: CustomMapLayerEditFormValues) => {
    if (!customMapLayer.entity || !properties.selected) {
      return;
    }

    const updatedPurchase = {
      ...customMapLayer.entity,
      name: formValues.name,
      description: formValues.description,
      properties: {
        ...customMapLayer.entity.properties,
        color: formValues.color,
      },
    };

    customMapLayer.update(updatedPurchase).then(() => {
      navigate(
        toPath(ROUTE_NAME.CUSTOM_MAP_LAYER_DETAIL, { customMapLayerId })
      );
    });
  };

  const handleCancel = () => {
    navigate(toPath(ROUTE_NAME.CUSTOM_MAP_LAYER_DETAIL, { customMapLayerId }));
  };

  return (
    <CustomMapLayerEditForm
      customMapLayer={customMapLayer.entity}
      loading={formLoading}
      error={extStatus?.error}
      onCancel={handleCancel}
      onSubmit={handleSubmit}
    />
  );
};

export default {
  name: ROUTE_NAME.CUSTOM_MAP_LAYER_EDIT,
  component: CustomMapLayerEdit,
  header: CustomMapLayerEditHeader,
};
