import React, { useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';

import {
  findActiveSubscription,
  findLatestSubscription,
  type MobbleSubscription,
  type StripeProduct,
  SubscriptionStatus,
} from '@mobble/models/src/model/Organisation';
import { BillingRegion } from '@mobble/models/src/model/User';
import mobbleService from '@mobble/service';
import { daysBetween } from '@mobble/shared/src/core/Date';
import { useGetUser } from '@mobble/store/src/hooks/auth';

import * as ROUTE_NAME from '@src/screens/config/routeNames';

export type LoadingStatus = 'loading' | 'ready' | 'error';

export const contactEmail = 'hello@mobble.io';
export const contactPhoneAU = '+61 481 614 546';
export const contactPhoneNZ = '+64 20 4120 8383';

export const toContactEmailURL = (email: string) => `mailto:${email}`;
export const toContactPhoneURL = (phone: string) =>
  `tel:${phone.replace(/ /g, '')}`;

export const billingRegionToPhoneNumber = (billingRegion: BillingRegion) => {
  switch (billingRegion) {
    case BillingRegion.NZ:
      return contactPhoneNZ;

    case BillingRegion.AU:
    default:
      return contactPhoneAU;
  }
};

const returnURL = `${window.location.origin}${ROUTE_NAME.SETTINGS_BILLING}`;

const REACT_APP_STRIPE_PUBLISHABLE_KEY =
  process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;
const REACT_APP_STRIPE_PUBLISHABLE_KEY_NZ =
  process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY_NZ;

export interface SubscriptionDetails {
  currentSubscription: MobbleSubscription | null;
  loadingStatus: LoadingStatus;
  isTrial: boolean;
  trialEnded: boolean;
  daysUntilTrialEnd: number;
  displayOptions: boolean;
  billingRegion: string;
}

export const useSubscription = (): SubscriptionDetails => {
  const user = useGetUser();

  const [currentSubscription, setCurrentSubscription] =
    React.useState<MobbleSubscription | null>(null);

  const [loadingStatus, setLoadingStatus] = useState<LoadingStatus>('loading');

  React.useEffect(() => {
    let fetchTimer = null;

    const fetchSubscriptions: () => void = () => {
      mobbleService.api.organisation
        .getSubscriptions()
        .then((subscriptions) => {
          if (subscriptions?.length) {
            const currentSubscription =
              findActiveSubscription(subscriptions) ||
              findLatestSubscription(subscriptions);
            setCurrentSubscription(currentSubscription);

            setLoadingStatus('ready');
            return;
          } else {
            // wait 5s and try again
            fetchTimer = setTimeout(() => {
              fetchSubscriptions();
            }, 5000);
          }
        })
        .catch(() => {
          setLoadingStatus('error');
        });
    };

    if (user) {
      fetchSubscriptions();
    }

    return () => {
      if (fetchTimer) {
        clearTimeout(fetchTimer);
      }
    };
  }, [user.id, user.subcriptionStatus]);

  const status = currentSubscription?.status;
  const isTrial =
    status === SubscriptionStatus.trialing ||
    currentSubscription?.subscriptionName === 'Trial';
  const displayOptions = isTrial || status === SubscriptionStatus.canceled;

  const trialEnded =
    currentSubscription?.subscriptionName === 'Trial' &&
    status === SubscriptionStatus.canceled;

  const daysUntilTrialEnd = !trialEnded
    ? daysBetween(new Date().toDateString())(
        new Date(currentSubscription?.currentPeriodEnd).toDateString()
      )
    : 0;

  return {
    currentSubscription,
    isTrial,
    loadingStatus,
    trialEnded,
    daysUntilTrialEnd,
    displayOptions,
    billingRegion: user.billingRegion,
  };
};

const navigateToCheckout = async (priceId: string) =>
  mobbleService.api.organisation.getStripeCheckoutURL({
    priceId,
    successURL: returnURL,
    cancelURL: returnURL,
  });

const getManageSubscriptionUrl = async () =>
  mobbleService.api.organisation.getStripeURL({
    successURL: returnURL,
    cancelURL: returnURL,
  });

export const useBilling = () => {
  const user = useGetUser();
  const [products, setProducts] = React.useState<StripeProduct[] | null>(null);
  const [error, setError] = React.useState<boolean>(false);

  const handleCheckout = async (priceId: string) => {
    const billingRegion = user?.billingRegion;

    return navigateToCheckout(priceId)
      .then(async (res) => {
        const stripePromise = loadStripe(
          billingRegion === 'NZ'
            ? REACT_APP_STRIPE_PUBLISHABLE_KEY_NZ
            : REACT_APP_STRIPE_PUBLISHABLE_KEY
        );
        const stripe = await stripePromise;
        stripe.redirectToCheckout({
          sessionId: res,
        });
      })
      .catch(() => {
        console.error("Couldn't open checkout URL");
        setError(true);
      });
  };

  const handleManageSubscription = async () => {
    return getManageSubscriptionUrl()
      .then((res) => {
        window.open(res, '_blank');
      })
      .catch(() => {
        console.error("Couldn't open manage subscription URL");
        setError(true);
      });
  };

  React.useEffect(() => {
    mobbleService.api.organisation.getStripeProducts().then((res) => {
      setProducts(res);
    });
  }, []);

  const subscription = useSubscription();

  return {
    subscription,
    products,
    error,
    handleCheckout,
    handleManageSubscription,
  };
};
