import React from 'react';

import { useI18n } from '@mobble/i18n';
import { type Settings } from '@mobble/models/src/model/Settings';
import { useSettings } from '@mobble/store/src/hooks/settings';

import { useLinking } from '@src/hooks/useLinking';
import * as ROUTE_NAME from '@src/screens/config/routeNames';
import { ScreenRendererProps } from '@src/screens/config/types';
import { ScreenHeader } from '@src/stories/Views/Misc/ScreenHeader';
import { SettingsUnitsForm } from '@src/stories/Views/Settings/SettingsUnitsForm';

export const SettingsUnitsHeader: React.FC<ScreenRendererProps> = () => {
  const { formatMessage } = useI18n();
  const linkTo = useLinking();

  return (
    <ScreenHeader
      title={formatMessage({
        description: 'screen.title.settings_units',
        defaultMessage: 'Units and formatting',
      })}
      breadcrumbs={[
        {
          title: formatMessage({
            description: 'screen.title.settings',
            defaultMessage: 'Settings',
          }),
          href: ROUTE_NAME.SETTINGS_LIST,
        },
      ]}
      onGoBack={() => {
        linkTo(ROUTE_NAME.SETTINGS_LIST);
      }}
    />
  );
};

export const SettingsUnits: React.FC<ScreenRendererProps> = () => {
  const { settings, update } = useSettings();
  const linkTo = useLinking();
  const [formLoading, setFormLoading] = React.useState(false);

  const handleCancel = () => {
    linkTo(ROUTE_NAME.SETTINGS_LIST);
  };

  const handleSubmit = (updatedSettings: Settings) => {
    setFormLoading(true);
    update(updatedSettings);
    setTimeout(() => {
      setFormLoading(false);
    }, 1000); // artificial delay
  };

  return (
    <SettingsUnitsForm
      loading={formLoading}
      settings={settings}
      onCancel={handleCancel}
      onSubmit={handleSubmit}
    />
  );
};

export default {
  name: ROUTE_NAME.SETTINGS_UNITS,
  header: SettingsUnitsHeader,
  component: SettingsUnits,
};
