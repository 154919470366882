import { Feature, Geometry } from 'geojson';
import { Map, SymbolLayout } from 'mapbox-gl';

import {
  type FeatureLineString,
  type FeaturePolygon,
  type Point,
  pointsToDistance,
  polygonToArea,
  polygonToLabelCenterPoint,
  polygonToPoints,
} from '@mobble/models/src/model/MapGeometry';
import { MapStyle } from '@mobble/models/src/model/MapStyle';
import { type Quantities } from '@mobble/shared/src/core/Quantity';
import { Color } from '@mobble/theme';

import { safeAddLayer,safeAddSource } from './map';

const layerLayout: SymbolLayout = {
  'text-size': ['interpolate', ['linear'], ['zoom'], 12, 10, 15, 13],
  'text-offset': [0, 0.9],
  'text-font': ['Ubuntu Medium'],
};

const layerPaint = {
  'text-size': ['interpolate', ['linear'], ['zoom'], 12, 10, 15, 13],
  'text-offset': [0, 0.9],
  'text-font': ['Ubuntu Medium'],
};

export function addLabelsToMap(
  mapRef: Map,
  feature: Feature<Geometry>,
  getMeasurementLabel: (q: Quantities) => string = (q) =>
    `${q.value.toFixed(2)} ${q.unit}`
) {
  const { id, geometry } = feature;
  const isPolygon = geometry.type === 'Polygon';
  const isLineString = geometry.type === 'LineString';

  // Label text colour
  const mapStyle = mapRef.getStyle().name.includes('Satellite')
    ? MapStyle.Satellite
    : MapStyle.Outdoor;
  const labelColor =
    mapStyle === MapStyle.Satellite ? Color.White : Color.Black;
  const labelHaloColor =
    mapStyle === MapStyle.Satellite ? Color.Black : Color.White;

  // Static IDs
  const sourceCenterId = 'area_center_source';
  const areaLabelId = 'area_label';

  // Return IDs so they can be removed on dismount
  const sourceIds = [sourceCenterId];
  const layerIds = [areaLabelId];

  // Add area label
  if (isPolygon) {
    const source = mapRef.getSource(sourceCenterId);
    const center = polygonToLabelCenterPoint(feature as FeaturePolygon);

    if (source?.type === 'geojson') {
      // @ts-ignore
      source.setData(center);
    } else {
      safeAddSource(mapRef)(sourceCenterId, {
        type: 'geojson',
        data: center,
      });
    }

    const layer = mapRef.getLayer(areaLabelId);
    const area = polygonToArea(geometry as FeaturePolygon['geometry']);
    if (layer) {
      mapRef.setLayoutProperty(
        areaLabelId,
        'text-field',
        getMeasurementLabel(area)
      );
    } else {
      safeAddLayer(mapRef)({
        type: 'symbol',
        id: areaLabelId,
        source: sourceCenterId,
        layout: {
          'symbol-placement': 'point',
          'text-field': getMeasurementLabel(area),
          ...layerLayout,
        },
        paint: {
          'text-color': labelColor,
          'text-halo-color': labelHaloColor,
          ...layerPaint,
        },
      });
    }
  }

  // Create points from the polygon so we can add distance labels
  let points: Point[] = [];

  if (isPolygon) {
    points = polygonToPoints(geometry as FeaturePolygon['geometry']);
  }

  if (isLineString) {
    points = (geometry as FeatureLineString['geometry']).coordinates.map(
      (coordinates) => {
        return {
          type: 'Point',
          coordinates,
        };
      }
    );
  }

  points.forEach((point, index) => {
    const nextIndex = index === points.length - 1 ? 0 : index + 1;
    const nextPoint = points[nextIndex];

    // Don't add a label for the last point if it's a line string
    if (index === points.length - 1 && isLineString) {
      return;
    }

    const sourceId = `${id}_${index}_line_source`;
    const source = mapRef.getSource(sourceId);
    const layerId = `${id}_${index}_line_label`;
    const layer = mapRef.getLayer(layerId);
    const distance = pointsToDistance([point, nextPoint]);

    // Update source data if it exists
    if (source) {
      // @ts-ignore
      source.setData({
        type: 'LineString',
        coordinates: [point.coordinates, nextPoint.coordinates],
      });
    } else {
      safeAddSource(mapRef)(sourceId, {
        type: 'geojson',
        data: {
          type: 'LineString',
          coordinates: [point.coordinates, nextPoint.coordinates],
        },
      });
    }

    // Update layer text if it exists
    if (layer) {
      mapRef.setLayoutProperty(
        layerId,
        'text-field',
        getMeasurementLabel(distance)
      );
    } else {
      safeAddLayer(mapRef)({
        type: 'symbol',
        id: layerId,
        minzoom: 8,
        source: sourceId,
        layout: {
          'text-field': getMeasurementLabel(distance),
          'symbol-placement': 'line-center',
          ...layerLayout,
        },
        paint: {
          'text-color': labelColor,
          'text-halo-color': labelHaloColor,
          ...layerPaint,
        },
      });
    }

    sourceIds.push(sourceId);
    layerIds.push(layerId);
  });

  return {
    sourceIds,
    layerIds,
  };
}
