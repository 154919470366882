import React from 'react';
import classNames from 'classnames/bind';

import { type I18nItem } from '@mobble/i18n';

import { Box } from '@src/stories/Components/Layout/Box';
import { Text } from '@src/stories/Components/UI/Text';
import { InputError } from '@src/stories/Components/UX/InputError';

import styles from './inputContainer.scss';
const cx = classNames.bind(styles);

interface InputContainerProps extends React.ComponentPropsWithoutRef<'div'> {
  id?: string; // TODO: make required
  label?: I18nItem | string;
  error?: false | string | string[] | Record<string, string>;
  required?: boolean;
}

export const InputContainer: React.FC<InputContainerProps> = ({
  id,
  label,
  error,
  required,
  className,
  children,
}) => {
  const rootClasses = cx(
    {
      inputContainer: true,
      hasLabel: !!label,
      required: required,
    },
    className
  );

  return (
    <Box className={rootClasses}>
      {label && (
        <Text id={`${id}-label`} tagName="label" htmlFor={id} i18n={label} />
      )}
      {children}
      <InputError error={error} />
    </Box>
  );
};
