import React from 'react';

import { type I18nItem,useI18n } from '@mobble/i18n';
import {
  filterPaddockActions,
  PaddockAction,
} from '@mobble/models/src/model/PaddockAction';
import { useProperties } from '@mobble/store/src/hooks/properties';

import { useLinking } from '@src/hooks/useLinking';
import {
  EntitiesViewer,
  type EntitiesViewerProps,
} from '@src/stories/Views/Entities/EntitiesViewer';

import * as ROUTE_NAME from '../../../../screens/config/routeNames';
import { type Provider } from '../../Entities/types';
import { PaddockActionCard } from '../PaddockActionCard';

import { makePaddockActionsTableColumns } from './paddockActionsTableColumns';

export interface PaddockActionsViewerProps {
  title?: I18nItem | string;
  provider: Provider<PaddockAction>;
  renderEntityCard?: (casualty: PaddockAction) => React.ReactNode;
}

export const PaddockActionsViewer: React.FC<PaddockActionsViewerProps> = ({
  title,
  provider,
}) => {
  const properties = useProperties();
  const { translate } = useI18n();
  const linkTo = useLinking();

  const onSelectPaddockAction = (paddockAction: PaddockAction) => {
    linkTo(ROUTE_NAME.PADDOCK_ACTION_DETAIL, {
      paddockActionId: paddockAction.id,
    });
  };

  const baseViewerProps: EntitiesViewerProps<PaddockAction> = {
    provider,
    applyFilter: filterPaddockActions,
    title,
    showTitle: true,
    onEmpty: { key: 'paddock_actions.table.empty' },
    onRefresh: undefined,
    onClickTableRow: onSelectPaddockAction,
    renderEntityCard: (item) => {
      return (
        <PaddockActionCard
          paddockAction={item}
          onClick={onSelectPaddockAction}
        />
      );
    },
    tableColumns: makePaddockActionsTableColumns({ translate }),
  };

  if (Array.isArray(provider)) {
    return (
      <EntitiesViewer key={properties.selected?.id} {...baseViewerProps} />
    );
  }

  const filterItems = () => [
    {
      title: { key: 'paddock_actions.filter.group.type.label' },
      type: 'select-multiple',
      group: 'type',
      data: Array.from(
        new Set(provider.entities.map((entity) => entity.type))
      ).map((value) => ({
        value,
      })),
    },
  ];

  const extendedViewerProps: EntitiesViewerProps<PaddockAction> = {
    ...baseViewerProps,
    filterItems,
    onRefresh: () => provider.refresh(),
  };

  return (
    <EntitiesViewer key={properties.selected?.id} {...extendedViewerProps} />
  );
};
