import { I18nItem } from '@mobble/i18n/src';
import { CustomMapFeature, CustomMapLayer } from '@mobble/models/src';
import { findCustomMapLayer } from '@mobble/models/src/model/CustomMapLayers';
import { MapAsset } from '@mobble/models/src/model/MapAsset';
import {
  type BoundingBox,
  lineStringToPolygon,
  type Point,
  type Polygon,
  polygonsAndPointsToBoundingBox,
  polygonToArea,
  polygonToLabelCenterPoint,
} from '@mobble/models/src/model/MapGeometry';
import { findMob, getTotalHead, type Mob } from '@mobble/models/src/model/Mob';
import {
  getPaddockColor,
  isSafe as paddockIsSafe,
  type Paddock,
} from '@mobble/models/src/model/Paddock';
import {
  findPaddockGeometry,
  type PaddockGeometry,
} from '@mobble/models/src/model/PaddockGeometry';
import { type PaddockGroup } from '@mobble/models/src/model/PaddockGroup';
import {
  findPaddockGroupGeometry,
  PaddockGroupGeometry,
} from '@mobble/models/src/model/PaddockGroupGeometry';
import {
  ConfiguredPropertyType,
  ConfiguredPropertyTypeGroup,
} from '@mobble/models/src/model/Property';
import { type Task } from '@mobble/models/src/model/Task';
import {
  findTaskGeometry,
  type TaskGeometry,
} from '@mobble/models/src/model/TaskGeometry';
import { arrayUnique } from '@mobble/shared/src/core/Array';

import { quantityToLocaleI18nItem } from '../../Components/Locale/LocaleQuantity';

import {
  MAP_ITEM_CUSTOM_FEATURE,
  MAP_ITEM_MAP_ASSET,
  MAP_ITEM_MOBS,
  MAP_ITEM_PADDOCK_BOUNDARY,
  MAP_ITEM_PADDOCK_GROUP,
  MAP_ITEM_PADDOCK_LABEL,
  MAP_ITEM_TASK,
  type MapItem,
  MapItemMapAsset,
  type MapItemMobs,
  type MapItemPaddockBoundary,
  type MapItemPaddockGroup,
  type MapItemPaddockLabel,
  type MapItemTask,
} from './MapItemType';

export const makeMapItemPaddockBoundary =
  ({
    propertyTypes,
    paddockGeometries,
  }: {
    propertyTypes: any[];
    paddockGeometries: PaddockGeometry[];
  }) =>
  ({
    paddock,
    onClick,
  }: {
    paddock: Paddock;
    onClick?: () => void;
  }): null | MapItemPaddockBoundary => {
    const paddockGeometry = findPaddockGeometry(paddockGeometries)(paddock.id);

    if (!paddockGeometry) {
      return null;
    }

    const color = getPaddockColor(propertyTypes)(paddock) ?? '#ff0000';

    return {
      type: MAP_ITEM_PADDOCK_BOUNDARY,
      id: paddock.id,
      visible: true,
      polygon: paddockGeometry.polygon,
      color,
      meta: {
        isSafe: paddockIsSafe(paddock),
      },
      onClick,
    };
  };

export const makeMapItemPaddockLabel =
  ({
    paddockGeometries,
    translate,
  }: {
    paddockGeometries: PaddockGeometry[];
    translate: (i18nItem: I18nItem) => undefined | string;
  }) =>
  ({ paddock }: { paddock: Paddock }): null | MapItemPaddockLabel => {
    const paddockGeometry = findPaddockGeometry(paddockGeometries)(paddock.id);

    if (!paddockGeometry) {
      return null;
    }

    const point = polygonToLabelCenterPoint(paddockGeometry.polygon);
    const title = paddock.name;
    const sizeQty = polygonToArea(paddockGeometry.polygon);
    const size = translate(quantityToLocaleI18nItem(sizeQty));

    return {
      type: MAP_ITEM_PADDOCK_LABEL,
      id: paddock.id,
      visible: true,
      polygon: paddockGeometry.polygon,
      point,
      meta: {
        title,
        size,
      },
    };
  };

export const makeMapItemPaddockGroup =
  ({
    paddockGroupGeometries,
  }: {
    paddockGroupGeometries: PaddockGroupGeometry[];
  }) =>
  ({
    paddockGroup,
  }: {
    paddockGroup: PaddockGroup;
  }): null | MapItemPaddockGroup => {
    const paddockGroupGeometry = findPaddockGroupGeometry(
      paddockGroupGeometries
    )(paddockGroup.id);

    if (!paddockGroupGeometry) {
      return null;
    }

    return {
      type: MAP_ITEM_PADDOCK_GROUP,
      id: paddockGroup.id,
      visible: true,
      polygon: paddockGroupGeometry.polygon,
    };
  };

export const makeMapItemMobs =
  ({
    paddockGeometries,
    propertyTypes,
    mobs,
  }: {
    paddockGeometries: PaddockGeometry[];
    propertyTypes: ConfiguredPropertyType[];
    mobs: Mob[];
  }) =>
  ({
    paddock,
    onClick,
  }: {
    paddock: Paddock;
    onClick?: () => void;
  }): null | MapItemMobs => {
    const getColorForYear = (mobType: string, year: number | string) => {
      const livestockTypeId = propertyTypes.find(
        (pt) =>
          pt.group === ConfiguredPropertyTypeGroup.livestockType &&
          pt.type === mobType
      )?.id;

      return propertyTypes.find(
        (pt) =>
          pt.parentId === livestockTypeId &&
          pt.group === ConfiguredPropertyTypeGroup.tag &&
          pt.type === `${year}`
      )?.color;
    };

    const paddockGeometry = findPaddockGeometry(paddockGeometries)(paddock.id);

    if (!paddockGeometry) {
      return null;
    }

    const mobsOnPaddock = paddock.mobs
      .map(findMob(mobs))
      .filter(Boolean) as Mob[];

    if (mobsOnPaddock.length < 1) {
      return null;
    }

    const mobTypes = arrayUnique(mobsOnPaddock.map((a) => a.type));
    const amountOfMobs = paddock.mobs.length;
    const size = getTotalHead(mobsOnPaddock);
    const point = polygonToLabelCenterPoint(paddockGeometry.polygon);
    const isSafe = !mobsOnPaddock.find((mob) => mob.safeDate);
    const ageColors = arrayUnique(
      mobsOnPaddock.reduce<string[]>((acc, mob) => {
        mob.ages.map((age) => {
          acc.push(getColorForYear(mob.type, age) ?? '#fff');
        });
        return acc;
      }, [])
    );

    return {
      type: MAP_ITEM_MOBS,
      id: paddock.id,
      visible: true,
      point,
      onClick,
      meta: {
        ageColors,
        size,
        isSafe,
        mobTypes,
        amountOfMobs,
      },
    };
  };

export const makeMapItemTask =
  ({
    taskGeometries,
    userId,
  }: {
    taskGeometries: TaskGeometry[];
    userId: string;
  }) =>
  ({
    task,
    onClick,
  }: {
    task: Task;
    onClick?: () => void;
  }): null | MapItemTask => {
    if (task.status !== 'pending') {
      return null;
    }

    const taskGeometry = findTaskGeometry(taskGeometries)(task.id);

    if (!taskGeometry) {
      return null;
    }

    const ownedByUser = task.assignedToUsers
      ? Boolean(task.assignedToUsers.find((u) => u.id === userId))
      : false;

    return {
      type: MAP_ITEM_TASK,
      id: task.id,
      ownedByUser,
      visible: true,
      point: taskGeometry.point,
      onClick,
    };
  };

export const makeMapItemCustomFeature =
  ({ customMapLayers }: { customMapLayers: CustomMapLayer[] }) =>
  ({
    customMapFeature,
    customMapLayer,
    onClick,
  }: {
    customMapFeature: CustomMapFeature;
    customMapLayer: CustomMapLayer;
    onClick?: () => void;
  }): MapItem => {
    const isActive = findCustomMapLayer(customMapLayers)(customMapLayer.id);

    if (!isActive) {
      return null;
    }

    return {
      type: MAP_ITEM_CUSTOM_FEATURE,
      id: customMapFeature.id,
      visible: true,
      polygon: customMapFeature.geometry,
      properties: customMapFeature.properties,
      color: customMapLayer.properties.color,
      onClick,
    };
  };

export const makeMapItemMapAsset =
  () =>
  ({
    mapAsset,
    onClick,
  }: {
    mapAsset: MapAsset;
    onClick?: () => void;
  }): MapItemMapAsset => {
    return {
      type: MAP_ITEM_MAP_ASSET,
      id: mapAsset.id,
      name: mapAsset.name,
      visible: true,
      mapAssetType: mapAsset.map.type,
      geometry: mapAsset.map.geometry,
      onClick,
    };
  };

export const makeTemporaryMapItemTask = (point: Point): MapItem => {
  return {
    type: MAP_ITEM_TASK,
    ownedByUser: true,
    id: 'temporary',
    visible: true,
    point,
  };
};

export const mapItemsToBoundaryBox = (
  items: MapItem[],
  padding?: number
): null | BoundingBox => {
  if (items.length === 0) {
    return null;
  }

  const [polygons, points] = items.reduce<[Polygon[], Point[]]>(
    ([a, b], item) => {
      switch (item.type) {
        case MAP_ITEM_PADDOCK_BOUNDARY:
        case MAP_ITEM_PADDOCK_GROUP:
        case MAP_ITEM_CUSTOM_FEATURE:
          return [[...a, item.polygon], b];
        case MAP_ITEM_PADDOCK_LABEL:
        case MAP_ITEM_MOBS:
        case MAP_ITEM_TASK:
          return [a, [...b, item.point]];
        case MAP_ITEM_MAP_ASSET:
          if (item?.geometry?.type === 'Point') {
            return [a, [...b, item.geometry]];
          } else if (item?.geometry?.type === 'LineString') {
            return [[...a, lineStringToPolygon(item.geometry)], b];
          }
          return [a, b];
      }
    },
    [[], []]
  );

  return polygonsAndPointsToBoundingBox(polygons, points, padding);
};

export const countMapItems = (mapItems: MapItem[], type: string): number => {
  return mapItems.filter((item) => item.type === type).length;
};
