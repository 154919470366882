import React from 'react';

import { useI18n } from '@mobble/i18n';

import { type IconName } from '@src/stories/Components/UI/Icon';
import {
  CardedNavigationList,
  CardedNavigationListGroupProps,
} from '@src/stories/Components/UX/CardedNavigationList';
import { ScreenHeader } from '@src/stories/Views/Misc/ScreenHeader';

import { useAccessHelper } from '../../hooks/useAccessHelper';
import * as ROUTE_NAME from '../config/routeNames';
import { RouteName, ScreenRendererProps } from '../config/types';

export const SettingsHeader: React.FC<ScreenRendererProps> = (props) => {
  const { formatMessage } = useI18n();

  return (
    <ScreenHeader
      title={formatMessage({
        description: 'screen.title.settings',
        defaultMessage: 'Settings',
      })}
      onToggleDrawer={props.drawer.toggle}
    />
  );
};

const HELP_URI = 'https://mobble.io/help';

export const Settings: React.FC<ScreenRendererProps> = () => {
  const { formatMessage } = useI18n();
  const { hasAccessToRoute, showNoAccessAlert } = useAccessHelper();

  const checkAccess = (route: string) => {
    if (route === HELP_URI) {
      return true;
    }

    return hasAccessToRoute(route as RouteName);
  };

  const items: CardedNavigationListGroupProps[] = [
    {
      title: formatMessage({
        description: 'settings.toc.user',
        defaultMessage: 'User',
      }),
      data: [
        {
          label: formatMessage({
            description: 'screen.title.settings_user',
            defaultMessage: 'Profile',
          }),
          description: formatMessage({
            description: 'settings.toc.user_description',
            defaultMessage: 'Update your profile details',
          }),
          href: ROUTE_NAME.SETTINGS_USER,
          icon: 'user',
        },
        {
          label: formatMessage({
            description: 'screen.title.settings_units',
            defaultMessage: 'Units and formatting',
          }),
          description: formatMessage({
            description: 'settings.toc.units_description',
            defaultMessage: 'Set your preferred units and formatting',
          }),
          href: ROUTE_NAME.SETTINGS_UNITS,
          icon: 'ruler',
        },
      ],
    },
    {
      title: formatMessage({
        description: 'settings.toc.organisation',
        defaultMessage: 'Organisation',
      }),
      data: [
        {
          label: formatMessage({
            description: 'screen.title.mobble_apps',
            defaultMessage: 'Mobble Apps',
          }),
          description: formatMessage({
            description: 'settings.toc.mobble_apps_description',
            defaultMessage: 'Manage your Mobble Apps',
          }),
          href: ROUTE_NAME.SETTINGS_MOBBLE_APPS,
          icon: 'grid',
        },
        {
          label: formatMessage({
            description: 'screen.title.billing',
            defaultMessage: 'Billing',
          }),
          description: formatMessage({
            description: 'settings.toc.billing_description',
            defaultMessage: 'Manage your billing details',
          }),
          href: ROUTE_NAME.SETTINGS_BILLING,
          icon: 'credit-card',
        },
      ],
    },
    {
      title: formatMessage({
        description: 'settings.toc.property',
        defaultMessage: 'Property',
      }),
      data: [
        {
          label: formatMessage({
            description: 'screen.title.settings_property_information',
            defaultMessage: 'Property information',
          }),
          description: formatMessage({
            description: 'settings.toc.property_information_description',
            defaultMessage: 'Update your property details',
          }),
          href: ROUTE_NAME.SETTINGS_PROPERTY_INFORMATION,
          icon: 'farm',
        },
        {
          label: formatMessage({
            description: 'screen.title.settings_property_users',
            defaultMessage: 'Users',
          }),
          description: formatMessage({
            description: 'settings.toc.property_users_description',
            defaultMessage: 'Manage your property users',
          }),
          href: ROUTE_NAME.SETTINGS_PROPERTY_USERS,
          icon: 'hamburger',
        },
        {
          label: formatMessage({
            description: 'settings.toc.mobble_connect',
            defaultMessage: 'Mobble Connect',
          }),
          description: formatMessage({
            description: 'settings.toc.mobble_connect_description',
            defaultMessage: 'Add and manage third party access',
          }),
          href: ROUTE_NAME.MOBBLE_CONNECT_USERS,
          icon: 'mail',
        },
        {
          label: formatMessage({
            description: 'screen.title.settings_property_classes_types',
            defaultMessage: 'Classes, types & tags',
          }),
          description: formatMessage({
            description: 'settings.toc.property_classes_types_description',
            defaultMessage: 'Customise the way you organise your livestock',
          }),
          href: ROUTE_NAME.SETTINGS_PROPERTY_CLASSES_TYPES,
          icon: 'split',
        },
        {
          label: formatMessage({
            description: 'screen.title.settings_property_custom_map_layers',
            defaultMessage: 'Custom map layers',
          }),
          description: formatMessage({
            description: 'settings.toc.custom_map_layers',
            defaultMessage: 'View custom map layers',
          }),
          href: ROUTE_NAME.SETTINGS_PROPERTY_CUSTOM_MAP_LAYERS,
          icon: 'layers',
        },
      ],
    },
    {
      title: formatMessage({
        description: 'settings.toc.about',
        defaultMessage: 'About',
      }),
      data: [
        {
          label: formatMessage({
            description: 'screen.title.settings_help',
            defaultMessage: 'Help',
          }),
          description: formatMessage({
            description: 'settings.toc.help_description',
            defaultMessage: 'Get help',
          }),
          href: HELP_URI,
          icon: 'help-circle',
        },
      ],
    },
  ].map((item) => ({
    title: item.title,
    data: item.data.map((item) => ({
      title: item.label,
      subtitle: item.description,
      href: checkAccess(item.href)
        ? (item.href as RouteName)
        : showNoAccessAlert,
      icon: item.icon as IconName,
      disabled: !checkAccess(item.href),
    })),
  }));

  return <CardedNavigationList items={items} />;
};

export default {
  name: ROUTE_NAME.SETTINGS_LIST,
  header: SettingsHeader,
  component: Settings,
};
