import React from 'react';
import ReactDOM from 'react-dom/client';
import mapboxgl from 'mapbox-gl';

import { Point } from '@mobble/models/src/model/MapGeometry';

import { useMap } from './lib/React/Context';

export type DragEventListener = (
  ev: Object,
  point: Point,
  type?: string
) => void;

export interface MarkerProps {
  id: string;
  data: GeoJSON.Point;
  options?: mapboxgl.MarkerOptions;
  children: React.ReactElement<any, string | React.JSXElementConstructor<any>>;

  draggable?: boolean;
  resetOnDragEnd?: boolean;
  onDragEnd?: DragEventListener;
  onDragStart?: DragEventListener;
  onDrag?: DragEventListener;
}

export const Marker = (props: MarkerProps) => {
  const map = useMap();
  const markerRef = React.useRef<mapboxgl.Marker>(null);

  const onDragEvent =
    (listener: DragEventListener, resetAfterEvent?: boolean) => (ev: any) => {
      const point: Point = {
        type: 'Point',
        coordinates: markerRef.current.getLngLat().toArray(),
      };
      listener(ev, point, ev.type);

      if (ev?.type === 'dragend' && props.resetOnDragEnd) {
        addMarker();
      }
    };

  const cleanUp = () => {
    markerRef.current?.remove();
  };

  const addMarker = () => {
    cleanUp();

    const div = document.createElement('div');
    const root = ReactDOM.createRoot(div);
    root.render(props.children);

    const marker = new mapboxgl.Marker(div, props.options)
      .setLngLat(props.data.coordinates as [number, number])
      .addTo(map);

    marker.setDraggable(props.draggable ?? false);

    if (props.onDrag || props.onDragStart) {
      marker.on('dragstart', onDragEvent(props.onDragStart ?? props.onDrag));
    }
    if (props.onDrag) {
      marker.on('drag', onDragEvent(props.onDrag));
    }
    if (props.onDrag || props.onDragEnd) {
      marker.on('dragend', onDragEvent(props.onDragEnd ?? props.onDrag));
    }

    markerRef.current = marker;
  };

  React.useEffect(() => {
    addMarker();

    return () => {
      cleanUp();
    };
  }, []);

  return null;
};
