import React from 'react';

import { useI18n } from '@mobble/i18n';
import { type Mob } from '@mobble/models/src/model/Mob';
import {
  stockingRateToDSE,
  StockingUnit,
} from '@mobble/models/src/model/Settings';
import { useSetting } from '@mobble/store/src/hooks';

import { ChartWrapper } from '@src/stories/Components/Charts/ChartWrapper';
import { type Datum, Pie } from '@src/stories/Components/Charts/Pie';
import { getStockingUnitI18n } from '@src/stories/Components/Locale/LocaleStockingUnit';

export interface ChartLivestockTotalsProps {
  mobs: Mob[];
}

type Mode = 'head' | 'stocking_rate';

const MAX_LIVESTOCK_GROUPS = 15;

const makeData = (mobs: Mob[], toValue: (mob: Mob) => number): Datum[] => {
  const map = new Map<string, Datum>();
  const mobToDataLabel = (mob: Mob) => `${mob.breed} ${mob.gender}`;

  mobs.forEach((mob) => {
    const id = mobToDataLabel(mob);
    const value = Number(toValue(mob).toFixed(1));
    let entry = map.get(id);

    // Update existing entry
    if (entry) {
      const newValue = Number((entry.value += value).toFixed(1));
      entry.value = newValue;
      entry.label = `${newValue} ${id}`;
    } else {
      entry = {
        id,
        value,
        label: `${value} ${id}`,
      };
    }

    map.set(id, entry);
  });

  return [...map.entries()].map(([, entry]) => entry);
};

const makeOther = (data: Datum[], otherLabel: string): Datum => {
  const total = data.reduce((acc, datum) => acc + datum.value, 0);
  return {
    id: 'other',
    label: `${total} ${otherLabel}`,
    value: total,
  };
};

const makeSizeData = (mobs: Mob[]): Datum[] =>
  makeData(mobs, (mob) => mob.size);

const makeDSEData =
  (stockingUnit: StockingUnit) =>
  (mobs: Mob[]): Datum[] =>
    makeData(
      mobs,
      (mob) => stockingRateToDSE(stockingUnit)(mob.DSE) * mob.size
    );

const useModes = (stockingUnit: StockingUnit) => {
  const { formatMessage } = useI18n();
  const stockingUnitLabel = formatMessage(getStockingUnitI18n(stockingUnit));

  return [
    {
      label: formatMessage({
        defaultMessage: 'Head',
        description: 'chart.livestock-totals.option.head',
      }),
      value: 'head',
    },
    {
      label: stockingUnitLabel,
      value: 'stocking_rate',
    },
  ];
};

export const ChartLivestockTotals: React.FC<ChartLivestockTotalsProps> = ({
  mobs,
}) => {
  const { translate } = useI18n();
  const stockingUnit = useSetting('stockingUnit') as StockingUnit;

  const [mode, setMode] = React.useState<Mode>('head');
  const modeOptions = useModes(stockingUnit);

  const data = React.useMemo(() => {
    switch (mode) {
      case 'head':
        return makeSizeData(mobs);
      case 'stocking_rate':
        return makeDSEData(stockingUnit)(mobs);
    }
  }, [mode, mobs]).sort((a, b) => b.value - a.value);

  // Merge remaining into single 'Other' group
  const slicedData = data.slice(0, MAX_LIVESTOCK_GROUPS);
  const otherData = makeOther(
    data.slice(MAX_LIVESTOCK_GROUPS),
    translate({ key: 'chart.livestock-totals.legend.other' })
  );
  if (otherData.value > 0) {
    slicedData.push(otherData);
  }

  return (
    <ChartWrapper
      title={{ key: 'chart.livestock-totals.title' }}
      options={[
        {
          options: modeOptions,
          onChange: (value: Mode) => setMode(value),
        },
      ]}
    >
      <Pie data={slicedData} />
    </ChartWrapper>
  );
};
