import React from 'react';
import { type AuthError } from 'firebase/auth';

import { useI18n } from '@mobble/i18n';
import { timeZoneToStockingUnit } from '@mobble/models/src/model/Settings';
import { useAuthentication } from '@mobble/shared/src/hooks/useAuthentication';
import { useSettings } from '@mobble/store/src/hooks';

import { useTitle } from '@src/hooks/useTitle';
import { AuthHelp } from '@src/stories/Views/Auth/AuthHelp';
import { AuthWrapper } from '@src/stories/Views/Auth/AuthWrapper';
import {
  SignInForm,
  type SignInFormValues,
} from '@src/stories/Views/Auth/SignInForm';

import * as ROUTE_NAME from '../config/routeNames';

export const SignIn: React.FC = () => {
  const settings = useSettings();
  const { translate } = useI18n();
  const { signIn } = useAuthentication();
  const [stateLoading, setStateLoading] = React.useState(false);
  const [error, setError] = React.useState<null | string>(null);

  useTitle({
    key: 'auth.sign_in.form.submit.label',
  });

  const handleSubmit = ({ email, password }: SignInFormValues) => {
    setStateLoading(true);
    setError(null);

    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const stockingUnit = timeZoneToStockingUnit(timeZone);
    settings.update({ stockingUnit });

    signIn(email, password)
      .then(() => {
        setStateLoading(false);
      })
      .catch((e: AuthError) => {
        console.error(e);
        setStateLoading(false);
        setError(
          translate({
            key: 'auth.sign_in.form.error',
          })
        );
      });
  };

  return (
    <AuthWrapper>
      <SignInForm
        error={error}
        loading={stateLoading}
        onSubmit={handleSubmit}
      />
      <AuthHelp
        forgotPassword={ROUTE_NAME.AUTH_FORGOT_PASSWORD}
        openHelp={'https://www.mobble.io/help'}
        signUp={ROUTE_NAME.AUTH_SIGN_UP}
      />
    </AuthWrapper>
  );
};

export default {
  name: ROUTE_NAME.AUTH_SIGN_IN,
  component: SignIn,
};
