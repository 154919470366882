import { QuantityOfMassVolume } from '@mobble/shared/src/core/Quantity';
import Fuse from 'fuse.js';
import { InventoryCategory } from './Inventory';
import { FilterItem } from './Filter';

export enum InventoryItemBatchStatus {
  Active = 'active',
  Deleted = 'deleted',
  Finished = 'finished',
}

export interface BaseInventoryItemBatch {
  id: string;
  inventoryItemId: string;
  propertyId: string;

  quantity: QuantityOfMassVolume;

  dateReceived?: string;
  created: string;
  status: InventoryItemBatchStatus;
  notes?: string;
}

export type InventoryItemBatchFeed = {
  category: InventoryCategory.Feed;
  supplierName?: string;
  pricePerUnitCents?: number;
} & BaseInventoryItemBatch;

export type InventoryItemBatchChemical = {
  category: InventoryCategory.Chemicals;
  name: string;
  dateOfManufacture?: string;
  dateExpiry?: string;
} & BaseInventoryItemBatch;

export type InventoryItemBatch =
  | InventoryItemBatchFeed
  | InventoryItemBatchChemical;

export const filterInventoryItemBatches = (
  inventoryItemBatches: InventoryItemBatch[],
  filter?: FilterItem[]
): InventoryItemBatch[] => {
  if (!filter || filter.length === 0) {
    return inventoryItemBatches;
  }
  const searchQuery = filter.find((a) => a.group === 'search')?.filter;

  const preFilteredInventoryItems =
    searchQuery && searchQuery.type === 'search'
      ? searchInventoryItemBatches(inventoryItemBatches, searchQuery.value)
      : inventoryItemBatches;

  return preFilteredInventoryItems;
};

export const searchInventoryItemBatches = (
  inventoryItemBatches: InventoryItemBatch[],
  searchQuery: string
): InventoryItemBatch[] => {
  const fuse = new Fuse(
    inventoryItemBatches.map((t) => ({
      ...t,
    })),
    {
      keys: [
        {
          name: 'name',
          weight: 2,
        },
        {
          name: 'supplierName',
          weight: 2,
        },
        {
          name: 'notes',
          weight: 1,
        },
      ],
      threshold: 0.5,
      shouldSort: true,
    }
  );

  return fuse.search(searchQuery).map((a) => a.item);
};

export const getInventoryItemBatch =
  (inventoryItemBatches: InventoryItemBatch[]) =>
  (id: string): InventoryItemBatch =>
    inventoryItemBatches.find((p) => p.id === id) || null;
