import React from 'react';

import { Color } from '@mobble/colors/src';

import { Text } from '@src/stories/Components/UI/Text';

import { Box } from '../Layout/Box';

import {
  CardedNavigationListItem,
  CardedNavigationListItemProps,
} from './CardedNavigationListItem';

import styles from './cardedNavigationList.scss';

export interface CardedNavigationListGroupProps {
  title: string;
  data: CardedNavigationListItemProps[];
}

export interface CardedNavigationListProps {
  items: CardedNavigationListGroupProps[];
}

export const CardedNavigationList: React.FC<CardedNavigationListProps> = ({
  items,
}) => {
  return (
    <Box>
      {items.map((item, index) => (
        <React.Fragment key={index}>
          <Box className={styles.cardsContainer}>
            <Text bold i18n={item.title} value="larger" color={Color.Grey} />
          </Box>

          <ul className={styles.cardNavigationListGrid}>
            {item.data.map((navItem, index) => (
              <li key={navItem.title}>
                <CardedNavigationListItem
                  title={navItem.title}
                  subtitle={navItem.subtitle}
                  icon={navItem.icon}
                  disabled={navItem.disabled}
                  href={navItem.href}
                  isLastElement={index === item.data.length - 1}
                />
              </li>
            ))}
          </ul>
        </React.Fragment>
      ))}
    </Box>
  );
};
