import React, { useCallback } from 'react';

import { Color } from '@mobble/colors';
import { type I18nItem, useI18n } from '@mobble/i18n';
import { BaseEntity } from '@mobble/models/src/model/BaseEntity';
import {
  filterCompare,
  type FilterItem,
  makeFilterDateRange,
  makeFilterEquals,
  makeFilterRange,
} from '@mobble/models/src/model/Filter';
import { endOfYear, RawDate, startOfYear } from '@mobble/shared/src/core/Date';

import Button from '@src/components/Button';
import { type ListProps } from '@src/components/List';
import { Box } from '@src/stories/Components/Layout/Box';
import { Spacer } from '@src/stories/Components/Layout/Spacer';
import { HStack } from '@src/stories/Components/Layout/Stack';
import { Text } from '@src/stories/Components/UI/Text';
import { InlineOption } from '@src/stories/Components/UX/InlineOption';
import { Input } from '@src/stories/Components/UX/Input';
import {
  ModalFlyUp,
  ModalFlyUpProps,
} from '@src/stories/Components/UX/ModalFlyUp';

export interface EntitiesFilterProps<Entity extends BaseEntity>
  extends ModalFlyUpProps {
  entities: Entity[];
  filter: FilterItem[];
  setFilter: (filter: FilterItem) => void;
  toggleFilter: (filter: FilterItem) => void;
  clearFilter: () => void;

  items: () => EntitiesFilterItem<Entity>[];
  applyFilter?: (entities: Entity[], filter: FilterItem[]) => Entity[];
  onClose: () => void;
}

export interface EntitiesFilterItem<Entity> {
  type: string;
  group: string;
  title: I18nItem | string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  meta?: any;
  data?:
    | EntitiesFilterItemData[]
    | ((entities: Entity[]) => EntitiesFilterItemData[]);
}

export interface EntitiesFilterItemData {
  value: string | number;
  label?: string;
  color?: Color;
}

function EntitiesFilterComponent<Entity extends BaseEntity>({
  isOpen,
  items,
  entities,
  filter,
  setFilter,
  toggleFilter,
  clearFilter,
  applyFilter,
  onClose,
  ...others
}: EntitiesFilterProps<Entity>) {
  const { formatMessage } = useI18n();
  const makeData = useCallback(() => {
    const filteredEntities = applyFilter
      ? applyFilter(entities, filter)
      : entities;
    return items().map((item) => ({
      ...item,
      data:
        typeof item.data === 'function'
          ? item.data(filteredEntities)
          : typeof item.data === 'undefined'
          ? [null]
          : item.data,
    }));
  }, [applyFilter, items, entities, filter]);

  const renderSectionHeader: ListProps['renderSectionHeader'] = (section) => {
    return (
      <header style={{ position: 'sticky', top: 0, zIndex: 2 }}>
        <Text variant="larger" bold i18n={section?.title} />
      </header>
    );
  };

  const renderFilter = (
    group: string,
    type: string,
    item: EntitiesFilterItemData,
    meta?: any
  ) => {
    switch (type) {
      case 'select':
      case 'select-multiple': {
        const equalsFilter = makeFilterEquals(String(item.value));

        return (
          <InlineOption
            type={type === 'select' ? undefined : 'checkbox'}
            label={item.label ?? item.value}
            value={item.value}
            selected={Boolean(
              filter.find(
                (a) =>
                  a.group === group && filterCompare(a.filter)(equalsFilter)
              )
            )}
            onClick={() => {
              toggleFilter({
                group,
                filter: equalsFilter,
              });
            }}
          />
        );
      }
      case 'range':
        return (
          <RangeSelect
            value={
              (filter.find((a) => a.group === group)?.filter?.value || [
                null,
                null,
              ]) as RangeValue
            }
            onChange={(range) => {
              setFilter({
                group,
                filter: makeFilterRange(range),
              });
            }}
          />
        );

      case 'date-range': {
        return (
          <DateRangeSelect
            years={meta?.years}
            value={
              (filter.find((a) => a.group === group)?.filter?.value || [
                null,
                null,
              ]) as DateRangeValue
            }
            onChange={(range) => {
              setFilter({
                group,
                filter: makeFilterDateRange(range),
              });
            }}
            onClose={onClose}
          />
        );
      }

      default: {
        return null;
      }
    }
  };

  const footer = (
    <>
      <Button
        disabled={filter.length === 0}
        outline
        intent="secondary"
        label={formatMessage({
          defaultMessage: 'Clear filter',
          description: 'entities.entities_filter.footer.button.clear.label',
        })}
        onClick={() => {
          clearFilter();
          onClose();
        }}
      />
      <Button
        outline
        label={formatMessage({
          defaultMessage: 'Close',
          description: 'entities.entities_filter.footer.button.close.label',
        })}
        onClick={() => {
          onClose();
        }}
      />
    </>
  );

  return (
    <ModalFlyUp
      isOpen={isOpen}
      onClose={onClose}
      title={formatMessage({
        defaultMessage: 'Filter options',
        description: 'entities.entities_filter.title',
      })}
      footer={footer}
      listProps={{
        items: makeData(),
        keyExtractor: (item, index) => item?.group ?? index,
        renderSectionHeader,
        renderItem: (item, index: number, section: any) => {
          if (!section) {
            return <strong>Missing section</strong>;
          }
          return renderFilter(section.group, section.type, item, section.meta);
        },
      }}
      {...others}
    />
  );
}

export const EntitiesFilter = React.memo(EntitiesFilterComponent, (a, b) => {
  return a.isOpen === b.isOpen && a.filter === b.filter;
});

// @TODO Move to components

type RangeValue = [null | number, null | number];

interface RangeSelectProps {
  value: RangeValue;
  onChange: (range: RangeValue) => void;
}

const RangeSelect: React.FC<RangeSelectProps> = ({ value, onChange }) => {
  const { formatMessage } = useI18n();
  return (
    <Box spacing={2}>
      <HStack>
        <Input
          value={value[0]}
          type="number"
          onChange={(val) =>
            onChange([val === '' ? null : Number(val), value[1]])
          }
          placeholder={formatMessage({
            defaultMessage: 'From',
            description: 'paddocks.filter.sections.range.from.placeholder',
          })}
        />
        <Spacer x={2} />
        <Input
          type="number"
          value={value[1]}
          onChange={(val) =>
            onChange([value[0], val === '' ? null : Number(val)])
          }
          placeholder={formatMessage({
            defaultMessage: 'To',
            description: 'paddocks.filter.sections.range.to.placeholder',
          })}
        />
      </HStack>
    </Box>
  );
};

type DateRangeValue = [null | RawDate, null | RawDate];

interface DateRangeSelectProps {
  value: DateRangeValue;
  years?: number[];
  onClose: () => void;
  onChange: (range: DateRangeValue) => void;
}

function getPrevYears(): number[] | undefined {
  return Array(3)
    .fill(0)
    .map((_, index) => {
      const date = new Date();
      date.setFullYear(date.getFullYear() - index);
      return date.getFullYear();
    });
}

const DateRangeSelect: React.FC<DateRangeSelectProps> = ({
  value,
  years = getPrevYears(),
  onChange,
  onClose,
}) => {
  const { formatMessage } = useI18n();
  return (
    <Box spacing={2}>
      <HStack>
        <Box flex>
          <Input
            showClear
            value={value[0] as string}
            type="date"
            onChange={(val) => onChange([val === '' ? null : val, value[1]])}
            placeholder={formatMessage({
              defaultMessage: 'From',
              description: 'paddocks.filter.sections.range.from.placeholder',
            })}
          />
        </Box>
        <Spacer x={2} />
        <Box flex>
          <Input
            showClear
            type="date"
            value={value[1] as string}
            onChange={(val) => onChange([value[0], val === '' ? null : val])}
            placeholder={formatMessage({
              defaultMessage: 'To',
              description: 'paddocks.filter.sections.range.to.placeholder',
            })}
          />
        </Box>
      </HStack>
      <Spacer y={2} />
      <Text color={Color.DarkGrey}>
        {formatMessage({
          defaultMessage: 'Or select a year',
          description: 'generic.input.date_range.or_select_year.label',
        })}
      </Text>
      <Spacer y={1} />
      <HStack>
        {years.map((year) => (
          <React.Fragment key={year}>
            <Button
              outline
              size="small"
              label={String(year)}
              onClick={() => {
                onChange([startOfYear(year), endOfYear(year)]);
                onClose();
              }}
            />
            <Spacer x={2} />
          </React.Fragment>
        ))}
      </HStack>
    </Box>
  );
};
