import React from 'react';

import { I18nItem } from '@mobble/i18n';
import { Color } from '@mobble/theme';

import { ColorSwatch } from '@src/components';

import { Box } from '../Layout/Box';
import { Spacer } from '../Layout/Spacer';
import { HStack, VStack } from '../Layout/Stack';
import { Icon } from '../UI/Icon';
import { Text } from '../UI/Text';

import { Clickable } from './Clickable';

import styles from './navigationListItem.scss';

export interface NavigationListItemProps {
  label: string | I18nItem;
  description?: string | I18nItem;
  first?: boolean;
  last?: boolean;
  href?: string | (() => void);
  disabled?: boolean;
  color?: string;
}

export const NavigationListItem: React.FC<NavigationListItemProps> = ({
  label,
  description,
  href,
  first,
  last,
  disabled,
  color,
}) => (
  <Clickable fullWidth href={href}>
    <Box
      flex
      background={disabled ? Color.LightGrey : Color.White}
      className={[first ? styles.first : null, last ? styles.last : null]}
    >
      <Box spacing={2} flex>
        <HStack>
          {color && (
            <>
              <ColorSwatch color={color} />
              <Spacer x={2} />
            </>
          )}
          <VStack>
            <HStack alignment="center">
              {typeof label === 'string' ? (
                <Text>{label}</Text>
              ) : (
                <Text i18n={label} />
              )}
            </HStack>
            {description && (
              <Text variant="body" color={Color.DarkGrey}>
                {typeof description === 'string' ? (
                  <Text>{description}</Text>
                ) : (
                  <Text i18n={description} />
                )}
              </Text>
            )}
          </VStack>
          {href && <Icon size="small" name={'chevron-right'} />}
        </HStack>
      </Box>
    </Box>
  </Clickable>
);
