import React from 'react';

import Dialog, { DialogProps } from '@src/components/Dialog/Dialog';
import DialogHeader from '@src/components/Dialog/Header';
import List, { type ListProps } from '@src/components/List';
import { MediaQuerySize, useMediaQuery } from '@src/hooks/useMediaQuery';

import styles from './modalFlyUp.scss';

export interface ModalFlyUpProps
  extends Omit<DialogProps, 'header' | 'footer'> {
  id?: string;
  title?: string;
  header?: React.ReactNode;
  additionalHeader?: React.ReactNode;
  footer?: React.ReactNode;
  footerProps?: DialogProps['footer'];
  listProps?: ListProps;
  hideHeader?: boolean;
  onClose: () => void;
}

export const ModalFlyUp: React.FC<ModalFlyUpProps> = ({
  id,
  title,
  onClose,
  isOpen,
  children,
  header,
  additionalHeader,
  footer,
  footerProps,
  listProps,
  hideHeader,
  ...others
}) => {
  const size = useMediaQuery();
  const isSmall = size === MediaQuerySize.Small;

  const showTitleHeader = !hideHeader && !header && title?.length > 0;

  return (
    <Dialog
      id={id}
      isModal
      isOpen={isOpen}
      drawerPosition={isSmall ? 'bottom' : undefined}
      header={showTitleHeader ? { heading: title } : undefined}
      shouldCloseOnOutsideClick={false}
      footer={
        footer
          ? {
              children: footer,
            }
          : footerProps
      }
      onRequestClose={onClose}
      {...others}
    >
      {!!header && <DialogHeader onCloseClick={onClose}>{header}</DialogHeader>}
      {additionalHeader}
      {listProps ? (
        <section className={styles.listWrapper}>
          <List {...listProps} />
        </section>
      ) : (
        children
      )}
    </Dialog>
  );
};
