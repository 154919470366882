import React from 'react';

import { useI18n } from '@mobble/i18n';
import { UserRole } from '@mobble/models';

import { Text } from '@src/components';
import { useLinking } from '@src/hooks/useLinking';
import { toPath } from '@src/interfaces/Routing';
import { Box } from '@src/stories/Components/Layout/Box';
import { NavigationList } from '@src/stories/Components/UX/NavigationList';
import { NavigationListItemProps } from '@src/stories/Components/UX/NavigationListItem';
import { ScreenHeader } from '@src/stories/Views/Misc/ScreenHeader';

import * as ROUTE_NAME from '../config/routeNames';
import { ScreenRendererProps } from '../config/types';

export const SettingsPropertyMobbleConnectUsersHeader: React.FC<
  ScreenRendererProps
> = () => {
  const linkTo = useLinking();
  const { formatMessage } = useI18n();

  return (
    <ScreenHeader
      title={formatMessage({
        description: 'screen.title.mobble_connect',
        defaultMessage: 'Mobble Connect',
      })}
      breadcrumbs={[
        {
          title: formatMessage({
            description: 'screen.title.settings',
            defaultMessage: 'Settings',
          }),
          href: ROUTE_NAME.SETTINGS_LIST,
        },
      ]}
      onGoBack={() => {
        linkTo(ROUTE_NAME.SETTINGS_LIST);
      }}
    />
  );
};

export const SettingsPropertyMobbleConnectUsers: React.FC<
  ScreenRendererProps
> = () => {
  const { formatMessage } = useI18n();

  const items: NavigationListItemProps[] = [
    {
      label: formatMessage({
        description: 'settings.toc.accountant',
        defaultMessage: 'Accountant',
      }),
      href: toPath(ROUTE_NAME.MOBBLE_CONNECT_USER, {
        role: UserRole.Accountant,
      }),
    },
    {
      label: formatMessage({
        description: 'settings.toc.map_view',
        defaultMessage: 'Contractor',
      }),
      href: toPath(ROUTE_NAME.MOBBLE_CONNECT_USER, {
        role: UserRole.Contractor,
      }),
    },
    {
      label: formatMessage({
        description: 'settings.toc.farm_advisor',
        defaultMessage: 'Farm Advisor',
      }),
      href: toPath(ROUTE_NAME.MOBBLE_CONNECT_USER, {
        role: UserRole.FarmAdvisor,
      }),
    },
    {
      label: formatMessage({
        description: 'settings.toc.stock_agent',
        defaultMessage: 'Stock Agent',
      }),
      href: toPath(ROUTE_NAME.MOBBLE_CONNECT_USER, {
        role: UserRole.StockAgent,
      }),
    },
    {
      label: formatMessage({
        description: 'settings.toc.auditor',
        defaultMessage: 'Auditor',
      }),
      href: toPath(ROUTE_NAME.MOBBLE_CONNECT_USER, {
        role: UserRole.Auditor,
      }),
    },
  ];

  return (
    <>
      <Box
        mobileSpacing={{ top: 2, right: 1, bottom: 2, left: 1 }}
        spacing={{ bottom: 2, right: 0, left: 0 }}
      >
        <Text tagName="p">
          {formatMessage({
            description: 'settings.property.mobble_connect.description',
            defaultMessage:
              'Mobble Connect lets you securely share relevant farm data with your partners, boosting collaboration while keeping you in full control of your information.',
          })}
        </Text>
      </Box>
      <NavigationList items={items} />
    </>
  );
};

export default {
  name: ROUTE_NAME.MOBBLE_CONNECT_USERS,
  header: SettingsPropertyMobbleConnectUsersHeader,
  component: SettingsPropertyMobbleConnectUsers,
};
