import React from 'react';

import { concatStyles,type Styles } from './Styles';

import styles from './divider.scss';

export interface DividerProps {
  vertical?: boolean;
  bold?: boolean;
  style?: Styles;
}

export const Divider: React.FC<DividerProps> = ({ vertical, bold, style }) => {
  const classNames = [
    styles.divider,
    vertical ? styles.vertical : styles.horizontal,
    bold ? styles.bold : null,
  ]
    .filter(Boolean)
    .join(' ');

  return <hr className={classNames} style={concatStyles(style)} />;
};
