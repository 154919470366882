import React from 'react';

import { type Point } from '@mobble/models/src/model/MapGeometry';
import { findPaddocks, type Paddock } from '@mobble/models/src/model/Paddock';
import { findPaddockGeometry } from '@mobble/models/src/model/PaddockGeometry';
import { findPaddockGroupForPaddock } from '@mobble/models/src/model/PaddockGroup';
import { findProperty } from '@mobble/models/src/model/Property';
import { useMobs } from '@mobble/store/src/hooks/mobs';
import { usePaddockGeometries } from '@mobble/store/src/hooks/paddockGeometries';
import { usePaddockGroupedStockingRates } from '@mobble/store/src/hooks/paddockGroupedStockingRates';
import { usePaddockGroups } from '@mobble/store/src/hooks/paddockGroups';
import { usePaddocks } from '@mobble/store/src/hooks/paddocks';
import { useProperties } from '@mobble/store/src/hooks/properties';

import { PaddockCard } from '../stories/Views/Paddock/PaddockCard';

export const usePaddockCardFactory = ({
  propertyId,
  location,
}: {
  propertyId?: string;
  location?: null | Point;
}) => {
  const properties = useProperties();

  propertyId = propertyId ?? properties.selected?.id;

  const paddocks = usePaddocks(propertyId);
  const paddockGeometries = usePaddockGeometries(propertyId);
  const paddockGroups = usePaddockGroups(propertyId);
  const mobs = useMobs(propertyId);
  const paddockGroupedStockingRates =
    usePaddockGroupedStockingRates(propertyId);

  const property = findProperty(properties.entities)(
    propertyId ?? properties.selected?.id ?? ''
  );

  return (paddock: Paddock, onClick?: (paddock: Paddock) => void) => {
    const paddockGeometry = findPaddockGeometry(paddockGeometries.entities)(
      paddock.id
    );
    const paddockGroup = findPaddockGroupForPaddock(paddockGroups.entities)(
      paddock.id
    );
    const paddocksInSamePaddockGroup = paddockGroup
      ? findPaddocks(paddocks.entities)(
          paddockGroup.paddockIds.filter((id) => id !== paddock.id)
        )
      : [];
    return (
      <PaddockCard
        paddockGroupedStockingRates={paddockGroupedStockingRates.entities}
        paddocksInSamePaddockGroup={paddocksInSamePaddockGroup}
        showDistanceFrom={location}
        paddockGeometry={paddockGeometry}
        paddock={paddock}
        mobs={mobs.entities}
        propertyTypes={property?.types ?? []}
        tasks={[]}
        onClick={onClick}
      />
    );
  };
};
