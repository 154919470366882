import tokml from 'geojson-to-kml';

import { Paddock } from '@mobble/models/src/model/Paddock';
import { PaddockGeometry } from '@mobble/models/src/model/PaddockGeometry';
import { usePaddockGeometries } from '@mobble/store/src/hooks/paddockGeometries';
import { usePaddocks } from '@mobble/store/src/hooks/paddocks';

export type PaddockGeoJson = {
  id: string;
  type: string;
  geometry: any;
  properties: {
    name: string;
    'feature-type': string;
  };
}[];

const getGeometryOfPaddock =
  (paddockGeometries: PaddockGeometry[]) => (paddockId: string) => {
    return paddockGeometries?.find(
      (geometry) => geometry.paddockId === paddockId
    );
  };

const formatToGEOJSON = (
  paddocks: Paddock[],
  paddockGeometries: PaddockGeometry[]
): PaddockGeoJson =>
  paddocks.reduce((paddocksGeoJson: PaddockGeoJson, paddock) => {
    const geometry = getGeometryOfPaddock(paddockGeometries)(paddock.id);

    return [
      ...paddocksGeoJson,
      {
        id: paddock.id,
        type: 'Feature',
        geometry: geometry?.polygon,
        properties: {
          name: String(paddock?.name),
          'feature-type': 'Paddock',
        },
      },
    ];
  }, []);

type FeatureCollection = {
  type: 'FeatureCollection';
  features: PaddockGeoJson;
};

export const useGetGeoJSONFromGeometries = (
  propertyId: string
): FeatureCollection | undefined => {
  const paddocks = usePaddocks(propertyId);
  const paddockGeometries = usePaddockGeometries(propertyId);

  if (!paddocks.entities || !paddockGeometries.entities) {
    return;
  }

  const geoJSON: FeatureCollection = {
    type: 'FeatureCollection',
    features: formatToGEOJSON(paddocks.entities, paddockGeometries.entities),
  };

  return geoJSON;
};

export const downloadKML = ({
  geoJsonObject,
  fileName,
  documentName,
}: {
  geoJsonObject: FeatureCollection;
  fileName: string;
  documentName: string;
}) => {
  if (!geoJsonObject) {
    return;
  }

  const kmlDocumentName = tokml(geoJsonObject, {
    documentName,
    name: 'name',
    description: 'feature-type',
  });
  const blob = new Blob([kmlDocumentName]);
  const url = window.URL.createObjectURL(blob);

  const a = document.createElement('a');
  a.style.display = 'none';
  a.href = url;
  a.download = `${fileName}.kml`;
  document.body.appendChild(a);
  a.click();
  window.URL.revokeObjectURL(url);
};
