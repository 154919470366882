import React from 'react';
import { defineMessages } from 'react-intl';

import { useI18n,useMessages } from '@mobble/i18n';
import {
  type Inventory,
  INVENTORY_TYPE,
} from '@mobble/models/src/model/Inventory';
import { InventoryCategory } from '@mobble/models/src/model/Inventory';
import { type InventoryItem } from '@mobble/models/src/model/InventoryItem';

import { makeQuantityFormatMessageItem } from '@src/stories/Components/Locale/LocaleQuantity';

import { InfoRow, type InfoRowProps } from '../../Components/UI/InfoRow';

export interface InventoryItemInfoTableProps {
  inventory: Inventory;
  inventoryItem: InventoryItem;
  onClickInventory: (inventory: Inventory) => void;
}

const messages = defineMessages({
  esi: {
    defaultMessage: 'ESI',
    description: 'inventory_item.info.table.row.esi.label',
  },
  inventory: {
    defaultMessage: 'Inventory',
    description: 'inventory_item.info.table.row.inventory.label',
  },
  name: {
    defaultMessage: 'Name',
    description: 'inventory_item.info.table.row.name.label',
  },
  notes: {
    defaultMessage: 'Notes',
    description: 'inventory_item.info.table.row.notes.label',
  },
  quantity: {
    defaultMessage: 'Quantity',
    description: 'inventory_item.info.table.row.quantity.label',
  },
  whp: {
    defaultMessage: 'WHP',
    description: 'inventory_item.info.table.row.whp.label',
  },
  woolWhp: {
    defaultMessage: 'Wool WHP',
    description: 'inventory_item.info.table.row.woolWhp.label',
  },
});

export const InventoryItemInfoTable: React.FC<InventoryItemInfoTableProps> = ({
  inventory,
  inventoryItem,
  onClickInventory,
}) => {
  const strings = useMessages(messages);
  const { formatMessage } = useI18n();

  const rows: (InfoRowProps | InfoRowProps[])[] = (() => {
    switch (inventoryItem.category) {
      case InventoryCategory.Chemicals:
        return [
          { label: strings.name, value: inventoryItem.name },
          {
            label: strings.inventory,
            value: inventory.name,
          },
          {
            label: strings.quantity,
            value:
              inventoryItem.quantity &&
              formatMessage(
                ...makeQuantityFormatMessageItem(inventoryItem.quantity)
              ),
          },
          {
            label: strings.whp,
            value: String(inventoryItem.whp || 0),
          },

          ...(inventory.type === INVENTORY_TYPE.ANIMAL_HEALTH
            ? [
                {
                  label: strings.esi,
                  value: String(inventoryItem?.esi || 0),
                },
                {
                  label: strings.woolWhp,
                  value: String(inventoryItem?.woolWhp || 0),
                },
              ]
            : []),

          {
            label: strings.notes,
            value: inventoryItem.notes,
          },
        ];

      case InventoryCategory.Feed:
        return [
          { label: strings.name, value: inventoryItem.name },
          {
            label: strings.inventory,
            value: inventory.name,
            onClick: () => onClickInventory(inventory),
          },
          {
            label: strings.quantity,
            value:
              inventoryItem.quantity &&
              formatMessage(
                ...makeQuantityFormatMessageItem(inventoryItem.quantity)
              ),
          },
          {
            label: strings.notes,
            value: inventoryItem.notes,
          },
        ];
    }
  })();

  return (
    <>
      {rows.map((row) =>
        Array.isArray(row) ? (
          <>
            {row.map((r) => (
              <InfoRow {...r} key={r.label as string} />
            ))}
          </>
        ) : (
          <InfoRow key={row.label} {...row} />
        )
      )}
    </>
  );
};
