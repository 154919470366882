import { useEffect } from 'react';
import { useLocation, useParams } from 'react-router';

import {
  isStockingUnit,
  StockingUnit,
} from '@mobble/models/src/model/Settings';
import { useSetting, useSettings } from '@mobble/store/src/hooks';

import { useAppLayoutContext } from '../stories/Components/Layout/AppLayout';

import { ScreenRendererProps, ScreenRouteProps } from './config/types';

export const ScreenRenderer = (props: ScreenRouteProps) => {
  const Component = props.component;
  const { setHeader, setStyle, setParams, drawerOpen, toggleDrawer } =
    useAppLayoutContext();
  const params = useParams();
  const location = useLocation();
  const paramsWithMode = {
    ...params,
  };

  const stockingUnit = useSetting('stockingUnit') as StockingUnit;
  const { update } = useSettings();

  if (location.hash.length > 1) {
    paramsWithMode.mode = location.hash.slice(1);
  }

  const screenProps: ScreenRendererProps = {
    route: {
      params: paramsWithMode,
      location,
    },
    drawer: {
      isOpen: drawerOpen,
      toggle: toggleDrawer,
    },
  };

  useEffect(() => {
    if (!stockingUnit || !isStockingUnit(stockingUnit)) {
      update({ stockingUnit: StockingUnit.DSE });
    }
  }, [stockingUnit]);

  useEffect(() => {
    const Header = props.header ?? null;
    setHeader(Header ? <Header {...(screenProps as any)} /> : null);
    setStyle({
      modal: Boolean(props.modal),
      noPadding: Boolean(props.noPadding),
    });
    setParams(paramsWithMode);

    return () => {
      setHeader(null);
    };
  }, [props.name, location, drawerOpen]);

  return <Component {...(screenProps as any)} />;
};
