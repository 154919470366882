import React from 'react';
import { FillPaint, LinePaint } from 'mapbox-gl';

import { colorToHex } from '@mobble/colors';
import {
  MapStyle,
  mapStyleIsSatellite,
} from '@mobble/models/src/model/MapStyle';
import { Color } from '@mobble/theme';

import { Polygon } from '@src/stories/Mapbox/Polygon';

import { type MapItemPaddockBoundary } from './MapItemType';

export interface PaddockBoundaryProps {
  mapStyle: MapStyle;
  item: MapItemPaddockBoundary;
  disabled?: boolean;
  fill?: boolean;
  color?: Color;
}

export const PaddockBoundary: React.FC<PaddockBoundaryProps> = ({
  mapStyle,
  item,
  color,
  disabled,
}) => {
  if (!item.visible) {
    return null;
  }

  const defaultColor = mapStyleIsSatellite(mapStyle)
    ? 'rgba(255,255,255,0.85)'
    : 'rgba(0,0,0,0.85)';

  const paddockColor = (() => {
    if (color) {
      return colorToHex(color);
    } else if (item.meta.isSafe) {
      return colorToHex(item.color ?? defaultColor);
    }
    return colorToHex(Color.Red);
  })();

  const fillPaint = (color: string): FillPaint => ({
    'fill-color': color,
    'fill-opacity': 0.15,
  });

  const linePaint = (color: string): LinePaint => ({
    'line-color': color,
    'line-width': disabled ? 2 : 4,
  });

  const paintColor = disabled ? defaultColor : paddockColor;

  return (
    <Polygon
      id={`paddock_boundary-${item.id}`}
      data={item.polygon}
      linePaint={linePaint(paintColor)}
      lineLayout={{ 'line-cap': 'round', 'line-join': 'round' }}
      fillPaint={disabled ? undefined : fillPaint(paintColor)}
      onClick={disabled ? undefined : item.onClick}
    />
  );
};
