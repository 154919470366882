import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';

import { MediaQuerySize,useMediaQuery } from '@src/hooks/useMediaQuery';
import { AppBottomTabBar } from '@src/screens/Navigation/AppBottomTabBar';
import { MainDrawer } from '@src/screens/Navigation/MainDrawer';

import styles from './appLayout.scss';

export interface AppLayoutProps {
  children: ReactNode;
}

export const AppLayout: React.FC<AppLayoutProps> = ({ children }) => {
  const size = useMediaQuery();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [drawerCollapsed, setDrawerCollapsed] = useState(false);
  const [header, setHeader] = useState<null | ReactNode>(null);
  const [style, setStyle] = useState<AppLayoutStyle>({
    modal: false,
  });
  const [params, setParams] = useState<Record<string, string>>({});

  useEffect(() => {
    switch (size) {
      case MediaQuerySize.Small:
      case MediaQuerySize.Medium:
        setDrawerOpen(false);
        setDrawerCollapsed(false);
        break;
      case MediaQuerySize.Large:
        setDrawerCollapsed(true);
        break;
      case MediaQuerySize.XLarge:
        setDrawerCollapsed(false);
        break;
      default:
        break;
    }
  }, [size]);

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  const toggleCollapsed = () => {
    setDrawerCollapsed(!drawerCollapsed);
  };

  const classNames = [
    styles.appLayout,
    style.modal ? styles.modal : null,
    style.noPadding ? styles.noPadding : null,
    drawerOpen ? styles.drawerOpen : null,
    drawerCollapsed ? styles.drawerCollapsed : null,
  ]
    .filter(Boolean)
    .join(' ');

  const value = {
    setHeader,
    drawerOpen,
    toggleDrawer,
    toggleCollapsed,
    setStyle,
    setParams,
    params,
    style,
  };

  return (
    <AppLayoutContext.Provider value={value}>
      <div className={classNames}>
        <MainDrawer collapsed={drawerCollapsed} className={styles.drawer} />

        <div
          onClick={() => setDrawerOpen(false)}
          className={styles.drawerBackdrop}
        />

        <div className={styles.contentContainer}>
          <div className={styles.headerContainer}>{header}</div>
          <div className={styles.childrenContainer}>{children}</div>

          <div className={styles.footerContainer}>
            <AppBottomTabBar />
          </div>
        </div>
      </div>
    </AppLayoutContext.Provider>
  );
};

// Context
export interface AppLayoutContextProps {
  setHeader: (component?: null | ReactNode) => void;
  drawerOpen: boolean;
  toggleDrawer: () => void;
  toggleCollapsed: () => void;
  setStyle: (style: AppLayoutStyle) => void;
  setParams: (params: Record<string, string>) => void;
  params: Record<string, string>;
  style: AppLayoutStyle;
}

export interface AppLayoutStyle {
  modal?: boolean;
  noPadding?: boolean;
}

export const AppLayoutContext = createContext<AppLayoutContextProps>({
  setHeader: () => null,
  drawerOpen: false,
  toggleDrawer: () => null,
  toggleCollapsed: () => null,
  setStyle: () => null,
  setParams: () => null,
  params: {},
  style: {},
});

export const useAppLayoutContext = () => {
  return useContext(AppLayoutContext);
};

export interface AppLayoutContextProviderProps {
  children: ReactNode;
}
