import React, { ComponentPropsWithoutRef, HTMLAttributes } from 'react';

import { Color as LegacyColor, colorToHex } from '@mobble/colors';
import { type I18nItem,useI18n } from '@mobble/i18n';
import { Color } from '@mobble/theme';

import { concatStyles } from '@src/stories/Components/Layout/Styles';

import styles from './text.scss';

const DEFAULT_TAG = 'span' as const;

export type TextVariants =
  | 'ultra-tiny'
  | 'tiny'
  | 'small'
  | 'body'
  | 'normal'
  | 'larger'
  | 'screen-title'
  | 'card-title'
  | 'jumbo'
  | 'mono'
  | 'mono-tiny';

export type TagName = 'h1' | 'h2' | 'h3' | 'h4' | 'label' | 'p' | 'span' | 'li';

export type TextProps<T extends TagName = typeof DEFAULT_TAG> = {
  tagName?: T | TagName;
  align?: 'left' | 'center' | 'right';
  bold?: boolean;
  color?: Color | LegacyColor;
  i18n?: I18nItem | string;
  shadow?: boolean;
  variant?: TextVariants;
  underline?: boolean;
  wrap?: boolean;
} & (ComponentPropsWithoutRef<T> & HTMLAttributes<HTMLElement>);

export const Text: React.FC<TextProps<TagName>> = ({
  align = 'left',
  bold,
  children,
  color,
  i18n,
  shadow,
  variant = 'body',
  underline,
  wrap,
  tagName = DEFAULT_TAG,
  className,
  style,
  ...others
}) => {
  const { translate } = useI18n();

  const classNames = [
    align ? styles[`align-${align}`] : null,
    variant ? styles[`variant-${variant}`] : null,
    bold ? styles.bold : null,
    shadow ? styles.shadow : null,
    underline ? styles.underline : null,
    wrap ? styles.wrap : null,
    className,
  ]
    .filter(Boolean)
    .join(' ');

  const styleProp = concatStyles([
    color ? { color: colorToHex(color) } : {},
    //
    style,
  ]);

  const TagName = tagName;

  // Children takes priority
  let label = children;

  // Legacy translation
  if (typeof i18n === 'object' && (i18n?.key || i18n?.defaultTranslation)) {
    label = translate(i18n as I18nItem);

    // New translation - can be string or React element in case of rich text
  } else if (typeof i18n === 'string' || Array.isArray(i18n)) {
    label = i18n;
  }

  if (!label) {
    return null;
  }

  return (
    <TagName style={styleProp} className={classNames} {...others}>
      {label}
    </TagName>
  );
};
