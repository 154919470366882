import React from 'react';

import { Color } from '@mobble/colors';
import { type I18nItem,useI18n } from '@mobble/i18n';

import { Box } from '../../Components/Layout/Box';
import { Spacer } from '../../Components/Layout/Spacer';
import { VStack } from '../../Components/Layout/Stack';
import { Well } from '../../Components/Layout/Well';
import { Icon } from '../../Components/UI/Icon';
import { Text } from '../../Components/UI/Text';
import { Button } from '../../Components/UX/Button';

export interface ErrorScreenProps {
  label: string;
  error?: string | I18nItem;
  onRetry?: () => Promise<void>;
}

export const ErrorScreen: React.FC<ErrorScreenProps> = ({
  label,
  error,
  onRetry,
}) => {
  const { formatMessage } = useI18n();
  const [loading, setLoading] = React.useState(false);

  const handleRetry = () => {
    if (onRetry) {
      setLoading(true);
      onRetry().then(() => setLoading(false));
    }
  };

  return (
    <Box flex spacing={2}>
      <VStack flex>
        <Spacer flex />
        <VStack alignment="center">
          <Icon size="large" name="error" color={Color.Orange} />
          <Spacer y={1} />
          <Text variant="small" color={Color.DarkGrey} i18n={label} />
        </VStack>
        {error && (
          <Box>
            <Spacer y={1} />
            <Well>
              <Text
                variant="tiny"
                align="center"
                color={Color.DarkGrey}
                i18n={error}
              />
            </Well>
          </Box>
        )}
        <Spacer y={2} />

        {onRetry && (
          <Button
            iconAfter="undo"
            outline
            loading={loading}
            label={formatMessage({
              description: 'prelude.error.retry.button.label',
              defaultMessage: 'Retry',
            })}
            onClick={handleRetry}
          />
        )}
        <Spacer y={16} />
        <Spacer flex />
      </VStack>
    </Box>
  );
};
