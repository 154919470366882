import React from 'react';

import { Logo } from '@src/stories/Components/UI/Logo';

import styles from './authWrapper.scss';

export interface AuthWrapperProps {
  children: React.ReactNode;
}

export const AuthWrapper: React.FC<AuthWrapperProps> = ({ children }) => {
  return (
    <div className={styles.AuthWrapper}>
      <div className={styles.logoContainer}>
        <Logo />
      </div>

      {children}
    </div>
  );
};
