import React from 'react';

import { Color } from '@mobble/colors';

import { Box } from '@src/stories/Components/Layout/Box';
import { Responsive } from '@src/stories/Components/Layout/Responsive';
import { Text } from '@src/stories/Components/UI/Text';
import { Button } from '@src/stories/Components/UX/Button';

export interface SettingsPropertyUsersInviteLimitReachedProps {
  i18nUserCountCurrentlyInvited: string;
  goBack: () => void;
}

export const SettingsPropertyUsersInviteLimitReached: React.FC<
  SettingsPropertyUsersInviteLimitReachedProps
> = ({ i18nUserCountCurrentlyInvited, goBack }) => (
  <>
    <Box spacing={2}>
      <Text
        color={Color.DarkGrey}
        variant="small"
        i18n={i18nUserCountCurrentlyInvited}
      />
    </Box>
    <Box spacing={2}>
      <Box background={Color.White}>
        <Box spacing={2}>
          <Text
            color={Color.DarkGrey}
            variant="larger"
            bold
            i18n={{
              key: 'settings.property.users.invite_user.user_limit_reached.title',
            }}
          />
        </Box>
        <Box spacing={2}>
          <Text
            color={Color.DarkGrey}
            variant="body"
            i18n={{
              key: 'settings.property.users.invite_user.user_limit_reached.description',
            }}
          />
        </Box>
      </Box>
    </Box>

    <Box spacing={2}>
      <Responsive desktop>
        <Button
          flex
          label={{
            key: 'settings.property.users.invite_user.go_back.button',
          }}
          intent="secondary"
          outline
          onClick={goBack}
        />
      </Responsive>
      <Responsive mobile>
        <Button
          flex
          label={{ key: 'settings.property.users.invite_user.go_back.button' }}
          intent="secondary"
          outline
          onClick={goBack}
        />
      </Responsive>
    </Box>
  </>
);
