import React from 'react';

import { type IconName } from '@mobble/icon';
import {
  getUserActionsForScenario,
  ScenarioType,
} from '@mobble/models/src/model/Intentions/Scenarios';
import { metaForUserActionType } from '@mobble/models/src/model/Intentions/UserActions';
import { type Mob, toMobDisplayName } from '@mobble/models/src/model/Mob';
import { paddockForMob } from '@mobble/models/src/model/Paddock';
import { base64encode } from '@mobble/shared/src/core/String';
import { usePaddocks } from '@mobble/store/src/hooks/paddocks';

import { useAccessHelper } from '@src/hooks/useAccessHelper';
import { toPath } from '@src/interfaces/Routing';
import {
  Actions,
  type ActionsOption,
} from '@src/stories/Components/UX/Actions';
import { ModalFlyUp } from '@src/stories/Components/UX/ModalFlyUp';

import { userActionToRoute } from '../config/userActionToRoute';

export interface ActionSheetOnMobSelectProps {
  mob: Mob;
  onClose: () => void;
}

export const ActionSheetOnMobSelect: React.FC<ActionSheetOnMobSelectProps> = ({
  mob,
  onClose,
}) => {
  const { hasAccessToRoute } = useAccessHelper();
  const paddocks = usePaddocks(mob.propertyId);
  const paddock = paddockForMob(paddocks.entities)(mob);

  if (!mob) {
    return null;
  }

  const mobIds = base64encode(JSON.stringify([mob.id]));
  const mobParams = {
    mobId: mob.id,
    paddockId: paddock?.id,
    mobIds,
  };

  const paramsValid = Object.values(mobParams).every(
    (param) => param !== undefined
  );

  const userActions = getUserActionsForScenario(ScenarioType.MobSelect);
  const options: ActionsOption[] = userActions.map((ua) => {
    const meta = metaForUserActionType(ua);
    const { route, params } = userActionToRoute(ua, mobParams);

    return {
      icon: meta.icon as IconName,
      label: { key: meta.titleKey },
      description: { key: meta.descriptionKey },
      disabled: !hasAccessToRoute(route),
      invalid: !paramsValid,
      href: paramsValid ? toPath(route, params) : '#',
    };
  });

  return (
    <ModalFlyUp title={toMobDisplayName(mob)} isOpen={true} onClose={onClose}>
      <Actions options={options} onClose={onClose} />
    </ModalFlyUp>
  );
};
