import React from 'react';

import { useI18n } from '@mobble/i18n';
import mobbleService from '@mobble/service';
import { UserInvitation } from '@mobble/service/src/ext/organisation';
import { useGetUser } from '@mobble/store/src/hooks/auth';

import { useNavigateBack } from '@src/hooks/useNavigateBack';
import { toPath } from '@src/interfaces/Routing';
import * as ROUTE_NAME from '@src/screens/config/routeNames';
import { ScreenRendererProps } from '@src/screens/config/types';
import { Box } from '@src/stories/Components/Layout/Box';
import { Text } from '@src/stories/Components/UI/Text';
import { Clickable } from '@src/stories/Components/UX/Clickable';
import { ScreenHeader } from '@src/stories/Views/Misc/ScreenHeader';
import {
  ReferFarmerForm,
  ReferFarmerFormValues,
} from '@src/stories/Views/ReferFarmer/ReferFarmerForm';

import styles from './referFarmer.scss';

export const ReferFarmerHeader: React.FC<ScreenRendererProps> = () => {
  const goBack = useNavigateBack();

  return (
    <ScreenHeader
      modal
      title={{
        key: 'screen.title.refer-farmer',
      }}
      onClose={goBack}
    />
  );
};

const ACCEPT_INVITE_URL = 'https://app.mobble.io/sign-up';

export enum Relations {
  FarmAdvisor = 'farm-advisor',
  StockAgent = 'stock-agent',
  Accountant = 'accountant',
  Acquaintance = 'acquaintance',
}

export const RELATION_OPTIONS = [
  Relations.FarmAdvisor,
  Relations.StockAgent,
  Relations.Accountant,
  Relations.Acquaintance,
];

export const ReferFarmer: React.FC<ScreenRendererProps> = () => {
  const goBack = useNavigateBack();
  const [formLoading, setFormLoading] = React.useState(false);
  const [submitted, setSubmitted] = React.useState('');
  const user = useGetUser();

  const invitersName = user.name;
  const invitersEmail = user.email;

  const { translate } = useI18n();
  const t = (key: string) =>
    translate({
      key: `refer-farmer.options.${key}.label`,
    }) || key;

  const roleOptions = RELATION_OPTIONS.map((role) => ({
    label: t(role),
    value: role,
  }));

  const handleSubmit = async (invitationVariables: ReferFarmerFormValues) => {
    setFormLoading(true);

    const userInvitation: UserInvitation = {
      email: invitationVariables.email,
      meta: {
        farmersFirstName: invitationVariables.firstName,
        farmersLastName: invitationVariables.lastName,
        invitersRelationship: invitationVariables.relationship,
        farmersRole: invitationVariables.role,
        inviteMessage: invitationVariables.message,
        farmName: invitationVariables.farmName,
        invitersName,
        invitersEmail,
        acceptInviteURL: ACCEPT_INVITE_URL,
      },
    };

    mobbleService.api.organisation
      .createUserInvitation(userInvitation)
      .then(() => {
        setFormLoading(true);
        setSubmitted(invitationVariables.firstName);
      });
  };

  return (
    <>
      {submitted ? (
        <Box className={styles.success}>
          <Text
            tagName="h1"
            bold
            variant="larger"
            i18n={{
              key: 'refer-farmer.refer-farmer-form.submit.success.heading',
            }}
          />
          <Text
            tagName="p"
            i18n={{
              key: 'refer-farmer.refer-farmer-form.submit.success.message',
              params: { '%FIRST.NAME': submitted },
            }}
          />
          <Clickable href={toPath(ROUTE_NAME.DASHBOARD)}>
            <Text
              tagName="p"
              i18n={{
                key: 'refer-farmer.refer-farmer-form.submit.success.button',
              }}
            />
          </Clickable>
        </Box>
      ) : (
        <ReferFarmerForm
          loading={formLoading}
          onSubmit={handleSubmit}
          onCancel={goBack}
          roleOptions={roleOptions}
        />
      )}
    </>
  );
};

export default {
  name: ROUTE_NAME.REFER_FARMER,
  header: ReferFarmerHeader,
  component: ReferFarmer,
};
