import React from 'react';

import { I18nItem } from '@mobble/i18n/src';
import { InventoryCategory } from '@mobble/models/src';
import { PaddockAction } from '@mobble/models/src/model/PaddockAction';
import { formatDate, fromRawDate,RawDate } from '@mobble/shared/src/core/Date';

import { toPath } from '@src/interfaces/Routing';
import { HStack } from '@src/stories/Components/Layout/Stack';
import { TableColumn } from '@src/stories/Components/Layout/Table';
import { makeQuantityI18nItem } from '@src/stories/Components/Locale/LocaleQuantity';
import { Text } from '@src/stories/Components/UI/Text';
import { Clickable } from '@src/stories/Components/UX/Clickable';

import * as ROUTE_NAME from '../../../../screens/config/routeNames';

import styles from './paddockActionsTableColumns.scss';

const toProductString = (
  paddockAction: PaddockAction,
  translate: (i18nItem: I18nItem) => undefined | string
) =>
  paddockAction.appliedInventoryItems
    .map(
      (item) =>
        `${translate(makeQuantityI18nItem(item.quantity))} ${
          item.category === InventoryCategory.Chemicals
            ? item.name
            : item.supplierName
        }`
    )
    .join(', ');

export const makePaddockActionsTableColumns = ({
  translate,
}: {
  translate: (i18nItem: I18nItem) => undefined | string;
}): TableColumn[] => {
  const handleClick = (event) => {
    event.stopPropagation();
  };

  return [
    {
      key: 'type',
      label: { key: 'paddock_actions.table.heading.column.type' },
      toValue: (paddockActions) => paddockActions.type,
    },
    {
      key: 'date',
      label: { key: 'paddock_actions.table.heading.column.date' },
      totals: false,
      toValue: (paddockActions) =>
        paddockActions.date
          ? fromRawDate(paddockActions.date).toDate().getTime()
          : null,
      valueToString: (value) =>
        value ? formatDate(fromRawDate(value as RawDate)) : '',
    },
    {
      key: 'created_by',
      label: { key: 'paddock_actions.table.heading.column.created_by' },
      toValue: (paddockActions) => paddockActions.createdBy.name,
    },
    {
      key: 'products',
      label: { key: 'paddock_actions.table.heading.column.products' },
      toValue: (paddockAction) => toProductString(paddockAction, translate),
    },
    {
      key: 'applied_to',
      label: { key: 'paddock_actions.table.heading.column.applied_to' },
      toValue: (paddockAction: PaddockAction) =>
        paddockAction.paddocks.map((paddock) => paddock.name).join(', '),
      render: (_, paddockAction) => {
        return (
          <HStack className={styles.appliedTo}>
            {paddockAction?.paddocks.map((paddock) => (
              <Clickable
                key={paddock.paddockId}
                onClick={handleClick}
                href={toPath(ROUTE_NAME.PADDOCK_DETAIL, {
                  paddockId: paddock.paddockId,
                })}
              >
                <Text underline variant="small">
                  {paddock.name}
                </Text>
              </Clickable>
            ))}
          </HStack>
        );
      },
    },
    {
      key: 'notes',
      label: { key: 'paddock_actions.table.heading.column.notes' },
      totals: false,
      toValue: (paddockActions) => paddockActions.note,
    },
  ];
};
