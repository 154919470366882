import React from 'react';

import { Color } from '@mobble/colors';

import { Box } from '@src/stories/Components/Layout/Box';
import { Text } from '@src/stories/Components/UI/Text';
import {
  FormBuilder,
  type FormBuilderProps,
} from '@src/stories/Views/Form/FormBuilder';

export interface SignInFormProps {
  error?: null | string;
  loading?: boolean;
  onSubmit: (args: SignInFormValues) => void;
}

export interface SignInFormValues {
  email: string;
  password: string;
}

export const SignInForm: React.FC<SignInFormProps> = ({
  error,
  loading,
  onSubmit,
}) => {
  const form: FormBuilderProps<SignInFormValues> = {
    flex: true,
    i18nRootKey: 'auth.sign_in.form',
    fields: [
      {
        type: 'email',
        name: 'email',
        required: true,
      },
      {
        type: 'password',
        name: 'password',
        required: true,
      },
    ],

    loading,
    onSubmit,
  };

  return (
    <Box spacing={{ top: 1 }}>
      <Box role="alert" spacing={{ bottom: 1 }}>
        {error && (
          <Text tagName="p" align="center" color={Color.Red}>
            {error}
          </Text>
        )}
      </Box>

      <FormBuilder {...form} />
    </Box>
  );
};
