import React from 'react';
import * as Yup from 'yup';

import { useI18n } from '@mobble/i18n';
import {
  Point,
  pointsToFeaturePolygon,
  polygonToArea,
} from '@mobble/models/src/model/MapGeometry';
import { type Paddock } from '@mobble/models/src/model/Paddock';
import {
  type AreaUnits,
  areaUnits,
  convertTo,
  type QuantityOfArea,
} from '@mobble/shared/src/core/Quantity';
import { useSettings } from '@mobble/store/src/hooks/settings';

import { ColorSwatch } from '@src/components';

import { FormBuilder, type FormBuilderProps } from '../Form/FormBuilder';

export interface PaddockCreateFormProps {
  paddockTypes: { value: string; color: string }[];
  allPaddockNames: Paddock['name'][];
  error?: string;
  loading?: boolean;
  initialValues?: Partial<PaddockCreateFormValues>;
  onCancel: () => void;
  onSubmit: (formValues: PaddockCreateFormValues) => void;
  onCreateCustomField: (label: string) => Promise<void>;
}

export interface PaddockCreateFormValues {
  name: string;
  paddock_type: Paddock['type'];
  points?: Point[];
  grazeable_area: QuantityOfArea;
}

export const PaddockCreateForm: React.FC<PaddockCreateFormProps> = ({
  paddockTypes,
  allPaddockNames,
  error,
  loading,
  initialValues,
  onCancel,
  onSubmit,
  onCreateCustomField,
}) => {
  const { formatMessage } = useI18n();
  const settings = useSettings();

  const paddockTypeOptions = paddockTypes.map(({ value, color }) => ({
    label: value,
    value,
    labelExtra: <ColorSwatch color={color} />,
  }));

  const form: FormBuilderProps<PaddockCreateFormValues> = {
    flex: true,
    i18nRootKey: 'paddocks.paddock.create.form',
    fields: [
      {
        name: 'name',
        type: 'text',
        required: true,
        initialValue: initialValues?.name,
        validation: Yup.string()
          .required(
            formatMessage(
              {
                description: 'generic.form.validation.required',
                defaultMessage: '{FIELD} is a required field.',
              },
              {
                FIELD: formatMessage({
                  defaultMessage: 'Paddock name',
                  description: 'paddocks.paddock.edit.form.name.label',
                }),
              }
            )
          )
          .test(
            'unique',
            formatMessage({
              defaultMessage: 'A paddock with this name already exists.',
              description: 'Paddock name validation error',
            }),
            (value) => !value || !allPaddockNames.includes(value.toLowerCase())
          ),
      },
      {
        name: 'paddock_type',
        type: 'select',
        options: paddockTypeOptions,
        required: true,
        initialValue: initialValues?.paddock_type,
        addNew: {
          onSubmit: onCreateCustomField,
          placeholder: formatMessage({
            defaultMessage: 'Add new paddock type',
            description:
              'paddocks.paddock.create.form.paddock_form.paddockTypes.add_new.placeholder.label',
          }),
        },
      },
      {
        name: 'points',
        type: 'map-creator',
        required: false,
        initialValue: initialValues?.points,
        mapCreator: {
          options: {
            single: false,
            mode: 'area',
          },
        },
        onChange: (values) => {
          const polygon = values.points
            ? pointsToFeaturePolygon(values.points)
            : null;

          if (!polygon) {
            return values;
          }

          const total_area = convertTo(
            settings.settings.areaUnit,
            2
          )(polygonToArea(polygon.geometry)) as QuantityOfArea;

          return {
            ...values,
            total_area,
            // Update grazeble area if not set
            grazeable_area: values.grazeable_area || total_area,
          };
        },
      },
      {
        name: 'total_area',
        type: 'quantity-area',
        disabled: () => true,
        initialValue: initialValues.grazeable_area,
      },
      {
        name: 'grazeable_area',
        type: 'quantity-area',
        initialValue: initialValues.grazeable_area,
        required: true,
        show: (values: PaddockCreateFormValues) =>
          values.paddock_type !== 'Yard',
        validation: Yup.object().when(
          '$paddock_type',
          ([paddock_type], schema) => {
            return paddock_type === 'Yard'
              ? schema.optional()
              : schema.shape({
                  value: Yup.number()
                    .positive(
                      formatMessage(
                        {
                          defaultMessage: '{FIELD} must be a positive value.',
                          description: 'generic.form.validation.positive',
                        },
                        {
                          FIELD: formatMessage({
                            defaultMessage: 'Grazeable area',
                            description:
                              'paddocks.paddock.edit.form.grazeable_area.label',
                          }),
                        }
                      )
                    )
                    .required(
                      formatMessage(
                        {
                          defaultMessage: '{FIELD} is a required field.',
                          description: 'generic.form.validation.required',
                        },
                        {
                          FIELD: formatMessage({
                            defaultMessage: 'Grazeable area',
                            description:
                              'paddocks.paddock.edit.form.grazeable_area.label',
                          }),
                        }
                      )
                    ),
                  unit: Yup.string().test(
                    'valid-unit',
                    formatMessage({
                      defaultMessage: 'Please select a valid area unit.',
                      description: 'generic.form.validation.invalid_area_unit',
                    }),
                    (value) => areaUnits.includes(value as AreaUnits)
                  ),
                });
          }
        ),
      },
    ],
    error,
    loading,
    onSubmit,
    onCancel,
  };

  return <FormBuilder {...form} />;
};
