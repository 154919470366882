import React from 'react';

import { Color, colorToHex, contrastingTextColor } from '@mobble/colors';

import { Box, type BoxProps } from '../Layout/Box';

import { Icon, type IconName,type IconSize } from './Icon';
import { Spinner } from './Spinner';

import styles from './circle.scss';

export type CircleProps = Omit<BoxProps, 'fill'> & {
  size?: 'tiny' | 'small' | 'normal';
  iconSize?: IconSize;
  icon?: IconName;
  fill?: Color;
  stroke?: Color;
  color?: Color;
  elevation?: 'none' | 'low';
  loading?: boolean;
  className?: string;
};

export const Circle: React.FC<CircleProps> = ({
  size = 'normal',
  iconSize,
  icon,
  children,
  style,
  fill,
  color,
  stroke,
  elevation = 'none',
  loading = false,
  className,
  ...delegated
}) => {
  const borderStyle = stroke
    ? {
        'borderColor': colorToHex(stroke),
      }
    : {};

  const classNames = [
    styles.circle,
    styles[`size-${size}`],
    styles[`elevation-${elevation}`],
    className,
  ]
    .filter(Boolean)
    .join(' ');
  const iconColor = color
    ? color
    : stroke || typeof fill !== 'undefined'
    ? contrastingTextColor(fill ?? stroke ?? color ?? '')
    : undefined;
  return (
    <Box
      className={classNames}
      style={{ ...style, ...borderStyle }}
      background={fill}
      {...delegated}
    >
      {loading ? (
        <Spinner color={iconColor} />
      ) : icon ? (
        <Icon name={icon} color={iconColor} size={iconSize || size} />
      ) : (
        children
      )}
    </Box>
  );
};
