import { type LngLat } from 'mapbox-gl';

import { latLngToPoint } from '@mobble/models/src/model/MapGeometry';

export const mapBoxLngLatToPoint = (latLng: LngLat) => {
  const { lat, lng } = latLng;
  return latLngToPoint({
    latitude: lat,
    longitude: lng,
  });
};

export const geolocationPositionToPoint = (
  geolocationPosition: GeolocationPosition
) => {
  const { latitude, longitude } = geolocationPosition.coords;
  return latLngToPoint({
    latitude,
    longitude,
  });
};
