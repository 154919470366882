import React from 'react';

import { Color } from '@mobble/colors';

import { Icon } from '../UI/Icon';

import { Clickable } from './Clickable';

import styles from './actionButton.scss';

export interface ActionButtonProps {
  onClick: () => void;
  isOpen: boolean;
  disabled?: boolean;
}

export const ActionButton: React.FC<ActionButtonProps> = ({
  onClick,
  disabled,
  isOpen,
}) => {
  const classNames = [
    styles.actionButton,
    disabled
      ? styles.disabled
      : isOpen
      ? styles.open
      : null,
  ].filter(Boolean).join(' ');

  return (
    <Clickable
      href={onClick}
      disabled={disabled}
      className={classNames}
    >
      <Icon size="normal" name="plus" color={Color.White} />
    </Clickable>
  );
};
