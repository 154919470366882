import React, { useEffect, useRef,useState } from 'react';

import { useI18n } from '@mobble/i18n';
import { type Chat, type User } from '@mobble/models';
import { formatDate, groupItemsByDate } from '@mobble/shared/src/core/Date';

import List, { type SectionedItems } from '@src/components/List';
import { Icon } from '@src/stories/Components/UI/Icon';
import { Text } from '@src/stories/Components/UI/Text';

import { ChatForm } from './ChatForm';
import { ChatPill } from './ChatPill';

import styles from './ChatScreen.scss';

export const ChatScreen: React.FC<{
  chats: Chat[];
  loading: boolean;
  user: User;
  hasNextPage: boolean;
  loadMore: () => Promise<void>;
  onSubmit: (_: string) => Promise<void>;
}> = ({ chats, loading, hasNextPage, loadMore, onSubmit, user }) => {
  const { formatMessage } = useI18n();
  const listRef = useRef(null);
  const [loadingMore, setLoadingMore] = useState(false);

  useEffect(() => {
    // scroll to bottom of list on mount
    if (listRef?.current && !loading && chats.length > 0) {
      window.requestAnimationFrame(() => {
        listRef.current?.scrollIntoView?.();
      });
    }
  }, [loading]);

  const handleNext = () => {
    if (!loadingMore) {
      setLoadingMore(true);

      return loadMore()
        .then(() => {
          setLoadingMore(false);
        })
        .catch(() => {
          setLoadingMore(false);
        });
    }
  };

  const renderItem = (item: Chat) => (
    <ChatPill
      item={item}
      isUserAuthor={item.user.id === user.id}
      className={styles.messageItem}
    />
  );

  const renderSectionHeader = (section: SectionedItems<Chat>) => (
    <div className={styles.dateDivider} role="separator">
      <Text>{section.title}</Text>
    </div>
  );

  const sectionedChats: SectionedItems<Chat>[] = groupItemsByDate(
    chats,
    (chat) => chat.date,
    formatDate
  ).map((section) => ({
    title: section.formattedDate,
    ...section,
  }));

  return (
    <div className={styles.ChatScreen}>
      <List
        role="log"
        items={sectionedChats}
        loading={loadingMore}
        renderItem={renderItem}
        renderSectionHeader={renderSectionHeader}
        header={<div ref={listRef} role="presentation" />}
        next={hasNextPage ? handleNext : undefined}
        className={styles.chatList}
        keyExtractor={(chat: Chat) => chat.id}
        empty={
          <div className={styles.empty} data-testid="chat-screen-empty-state">
            <Icon name="chat" className={styles.emptyIcon} />
            <Text tagName="p" className={styles.emptyText}>
              {formatMessage({
                description: 'Property Chat empty state message',
                defaultMessage:
                  'Start chatting with your farm team or added third parties here.',
              })}
            </Text>
          </div>
        }
      />
      <ChatForm onSubmit={onSubmit} className={styles.chatForm} />
    </div>
  );
};
