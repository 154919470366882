import React, {
  FocusEvent,
  forwardRef,
  InputHTMLAttributes,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

import { Color } from '@mobble/colors';
import { type I18nItem,useI18n } from '@mobble/i18n';
import { formatDate } from '@mobble/shared/src/core/Date';

import { reactIntersperse } from '@src/interfaces/React';

import { Box } from '../Layout/Box';
import { Spacer } from '../Layout/Spacer';
import { HStack } from '../Layout/Stack';
import { Icon, type IconName } from '../UI/Icon';

import styles from './input.scss';

export type InputTypes =
  | 'text'
  | 'textarea'
  | 'email'
  | 'number'
  | 'float'
  | 'password'
  | 'date';

type PickedInputAttributes = Partial<
  Pick<
    InputHTMLAttributes<HTMLInputElement | HTMLTextAreaElement>,
    | 'autoComplete'
    | 'autoFocus'
    | 'min'
    | 'max'
    | 'maxLength'
    | 'step'
    | 'pattern'
    | 'className'
    | 'onKeyUp'
    | 'onKeyDown'
  >
>;

export interface InputProps extends PickedInputAttributes {
  id?: string; // TODO: make required
  name?: string;
  size?: 'small' | 'normal';
  type?: InputTypes;
  icon?: IconName;
  value?: null | string | number;
  placeholder?: I18nItem | string;
  showClear?: boolean;
  disabled?: boolean;
  onChange: (value: string) => void;
  onBlur?: (
    value: string,
    ev: FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  children?: React.ReactNode;
  backgroundColor?: Color;
}

const typeToInputType = (type: InputTypes, isSecure?: boolean) => {
  switch (type) {
    default:
      return 'text';
    case 'email':
      return 'email';
    case 'number':
    case 'float':
      return 'number';
    case 'password':
      return isSecure ? 'password' : 'text';
    case 'date':
      return 'date';
  }
};

export const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      id,
      name,
      size = 'normal',
      type = 'text',
      placeholder,
      icon,
      value,
      onChange,
      onBlur,
      showClear,
      disabled,
      children,
      backgroundColor,
      className,
      ...others
    },
    forwardRef
  ) => {
    const { translate } = useI18n();
    const ref = useRef<HTMLInputElement>();
    useImperativeHandle(forwardRef, () => ref.current);

    const [isSecure, setIsSecure] = useState(type === 'password');
    const inputType = typeToInputType(type, isSecure);

    const normalisedValue =
      typeof value === 'number' ? String(value) : value || undefined;

    useEffect(() => {
      const handleWheel = (e) => e.target.blur();

      if (inputType === 'number' && ref.current) {
        ref.current.addEventListener('wheel', handleWheel);
      }

      return () => {
        if (inputType === 'number' && ref.current) {
          ref.current.removeEventListener('wheel', handleWheel);
        }
      };
    }, []);

    const renderInputEl = () => {
      const className = [styles.input, styles[`input_${size}`]].join(' ');

      switch (type) {
        case 'date': {
          return (
            <input
              {...others}
              ref={ref}
              id={id}
              name={name}
              className={className}
              type="date"
              placeholder={
                typeof placeholder === 'string'
                  ? placeholder
                  : translate(placeholder as I18nItem)
              }
              disabled={disabled}
              onChange={(ev) => {
                onChange(
                  formatDate(ev.target.value, 'YYYY-MM-DDTHH:mm:ss.sssZ')
                );
              }}
              onBlur={(ev: FocusEvent<HTMLInputElement>) =>
                onBlur && onBlur(formatDate(ev.target.value, 'YYYY-MM-DD'), ev)
              }
              value={formatDate(value, 'YYYY-MM-DD')}
            />
          );
        }
        case 'textarea':
          return (
            <textarea
              {...others}
              id={id}
              name={name}
              className={className}
              placeholder={
                typeof placeholder === 'string'
                  ? placeholder
                  : translate(placeholder as I18nItem)
              }
              disabled={disabled}
              onChange={(ev) => onChange(ev.target.value)}
              onBlur={(ev: FocusEvent<HTMLTextAreaElement>) =>
                onBlur && onBlur(ev.target.value, ev)
              }
              value={value}
            />
          );
        default: {
          return (
            <input
              {...others}
              ref={ref}
              id={id}
              name={name}
              className={className}
              type={inputType}
              placeholder={
                typeof placeholder === 'string'
                  ? placeholder
                  : translate(placeholder as I18nItem)
              }
              disabled={disabled}
              onChange={(ev) => onChange(ev.target.value)}
              onBlur={(ev: FocusEvent<HTMLInputElement>) =>
                onBlur && onBlur(ev.target.value, ev)
              }
              value={normalisedValue ?? ''}
            />
          );
        }
      }
    };
    const icons = [
      showClear
        ? {
            name: 'close',
            onClick: () => {
              onChange('');
            },
            hidden: !normalisedValue || normalisedValue.trim().length === 0,
          }
        : null,
      type === 'password'
        ? {
            name: isSecure ? 'eye' : 'eye-off',
            onClick: () => {
              setIsSecure(!isSecure);
            },
          }
        : null,
      icon ? { name: icon } : null,
    ]
      .filter(Boolean)
      .map((a, index) => {
        const iconEl = (
          <Icon
            key={`icon-${index}`}
            name={a.name as IconName}
            color={Color.DarkGrey}
            size="small"
          />
        );

        const className = [
          styles.iconWrapper,
          a.hidden ? styles.iconWrapperHidden : null,
        ]
          .filter(Boolean)
          .join(' ');

        if (a.onClick) {
          return (
            <button
              key={`button-${index}`}
              type="button"
              onClick={a.onClick}
              className={className}
            >
              {iconEl}
            </button>
          );
        }
        return (
          <div key={`container-${index}`} className={className}>
            {iconEl}
          </div>
        );
      });

    return (
      <Box
        background={backgroundColor}
        className={[styles.inputWrapper, className].filter(Boolean).join(' ')}
      >
        <HStack>
          {renderInputEl()}
          {children}
          {icons.length > 0 && (
            <HStack>
              {reactIntersperse(<Spacer x={0.25} />, icons)}
              <Spacer x={1} />
            </HStack>
          )}
        </HStack>
      </Box>
    );
  }
);

Input.displayName = 'Input';
