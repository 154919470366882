import React from 'react';
import classNames from 'classnames/bind';

import { Color } from '@mobble/theme';

import styles from './ColorSwatch.scss';
const cx = classNames.bind(styles);

export interface ColorSwatchProps
  extends React.ComponentPropsWithoutRef<'div'> {
  color: string | Color;
  circle?: boolean;
}

/**
 * ColorSwatch displays a fixed colour as a rectangle (default) or a circle.
 */
const ColorSwatch: React.FC<ColorSwatchProps> = ({
  color = Color.PrimaryDefault,
  circle,
  children,
  className,
  ...others
}) => {
  const rootClasses = cx(
    {
      ColorSwatch: true,
      circle,
    },
    className
  );

  return (
    <div
      role="presentation"
      style={{ backgroundColor: color }}
      className={rootClasses}
      {...others}
    >
      {children}
    </div>
  );
};

export default ColorSwatch;
