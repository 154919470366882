import React from 'react';
import { useNavigate } from 'react-router';

import { useI18n } from '@mobble/i18n';
import { useProperties } from '@mobble/store/src/hooks/properties';

import { useFormError } from '@src/hooks/useFormError';
import { useNavigateBack } from '@src/hooks/useNavigateBack';
import { ScreenHeader } from '@src/stories/Views/Misc/ScreenHeader';
import {
  PropertyCreateForm,
  PropertyCreateFormValues,
} from '@src/stories/Views/Property/PropertyCreateForm';

import { EntitySliceFactoryPrelude } from '../../stories/Views/Misc/EntitySliceFactoryPrelude';
import * as ROUTE_NAME from '../config/routeNames';
import { ScreenRendererProps } from '../config/types';

export const PropertyCreateHeader: React.FC<ScreenRendererProps> = () => {
  const { formatMessage } = useI18n();
  const navigate = useNavigate();

  return (
    <ScreenHeader
      modal
      onClose={() => {
        navigate(ROUTE_NAME.PADDOCKS_LIST);
      }}
      title={formatMessage({
        description: 'screen.title.property_create',
        defaultMessage: 'Create new property',
      })}
    />
  );
};

export const PropertyCreate: React.FC<ScreenRendererProps> = () => {
  const { formatMessage } = useI18n();
  const properties = useProperties();
  const navigate = useNavigate();
  const goBack = useNavigateBack();
  const [error, setError] = React.useState('');
  const [formLoading, setFormLoading] = React.useState(false);
  const formErrorMessage = useFormError({
    entityName: formatMessage({
      defaultMessage: 'Property',
      description: 'screen.title.property_detail',
    }),
  });

  const Prelude = EntitySliceFactoryPrelude({
    preludes: [properties.prelude],
    required: [properties.selected],
  });

  if (Prelude) {
    return Prelude;
  }

  const handleSubmit = (formValues: PropertyCreateFormValues) => {
    setFormLoading(true);

    const values: Omit<PropertyCreateFormValues, 'stockingUnit'> = {
      name: formValues.name,
      pic: formValues.pic,
    };

    properties
      .create(values)
      .then((newProperty) => {
        console.log(`Property '${newProperty.name}' successfully created`);
        properties.selectProperty(newProperty.id);
        navigate(ROUTE_NAME.DASHBOARD);
      })
      .catch((err) => {
        console.log(err);
        setFormLoading(false);
        setError(formErrorMessage);
      });
  };

  return (
    <PropertyCreateForm
      error={error}
      loading={formLoading}
      onCancel={goBack}
      onSubmit={handleSubmit}
    />
  );
};

export default {
  name: ROUTE_NAME.MODAL_PROPERTY_CREATE,
  header: PropertyCreateHeader,
  component: PropertyCreate,
};
