import React from 'react';

import { useI18n } from '@mobble/i18n';
import { Mob } from '@mobble/models/src/model/Mob';
import { RainGauge } from '@mobble/models/src/model/RainGauge';

import * as ROUTE_NAME from '@src/screens/config/routeNames';
import { DashboardSuggestionDetails } from '@src/screens/Dashboard/hooks/useDashboardSuggestions';
import { HistoricEvents } from '@src/screens/HistoricEvents/HistoricEvents';
import { ChartWrapper } from '@src/stories/Components/Charts/ChartWrapper';
import { Box } from '@src/stories/Components/Layout/Box';
import {
  type InfoTileProps,
  InfoTiles,
} from '@src/stories/Components/UI/InfoTile';

import { ChartHistoricPropertyLivestock } from '../../Components/Charts/LivestockHistoricGraph/LivestockHistoricGraph';
import {
  ChartLivestockTotals,
  type ChartLivestockTotalsProps,
} from '../Paddock/Charts/ChartLivestockTotals';
import { ChartRainGaugeMonthlyRainfall } from '../RainGauge/Charts/ChartRainGaugeMonthlyRainfall';
import { PropertyStockingRatesData } from '../Reports/usePropertyStockingRatesForRange';

import { DashboardSetUpProperty } from './DashboardSetUpProperty';
import { DashboardTrialCountDown } from './DashboardTrialCountDown';
import { DashboardTrialEnded } from './DashboardTrialEnded';

import styles from './dashboard.scss';

export type DashboardProps = {
  infoTiles: InfoTileProps[];
  mobs?: Mob[];
  rainGauges?: RainGauge[];
  dashboardSuggestions: DashboardSuggestionDetails;
  propertyStockingRatesData: PropertyStockingRatesData;
  displayTialStatus: boolean;
  trialCountDown: number | false;
} & RainGaugeGraphProps &
  ChartLivestockTotalsProps;

export const Dashboard: React.FC<DashboardProps> = ({
  infoTiles,
  mobs,
  rainGauges,
  dashboardSuggestions,
  propertyStockingRatesData,
  displayTialStatus,
  trialCountDown,
}) => {
  return (
    <Box>
      {displayTialStatus ? (
        trialCountDown ? (
          <DashboardTrialCountDown daysLeft={trialCountDown} />
        ) : (
          <DashboardTrialEnded />
        )
      ) : null}
      {dashboardSuggestions.suggestions.length > 0 && (
        <DashboardSetUpProperty dashboardSuggestions={dashboardSuggestions} />
      )}
      <InfoTiles items={infoTiles} />
      <Box className={styles.dashboardLayout}>
        {mobs && (
          <Box className={styles.dashboardTab}>
            <ChartLivestockTotals mobs={mobs} />
          </Box>
        )}

        <Box className={styles.dashboardTab}>
          <HistoricEventsTab />
        </Box>

        {rainGauges && (
          <Box className={styles.dashboardTab}>
            <RainGaugeGraph rainGauges={rainGauges} />
          </Box>
        )}

        {propertyStockingRatesData.type === 'ready' && (
          <Box className={styles.dashboardTab}>
            <ChartHistoricPropertyLivestock
              stockingRates={propertyStockingRatesData.raw}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};

interface RainGaugeGraphProps {
  rainGauges?: RainGauge[];
}

const RainGaugeGraph: React.FC<RainGaugeGraphProps> = ({ rainGauges }) => {
  const { formatMessage } = useI18n();
  const [selected, setSelected] = React.useState<RainGauge['id']>(
    RainGaugeGraph[0]?.id || null
  );
  const modeOptions = rainGauges?.map((rainGauge) => ({
    value: rainGauge.id,
    label: rainGauge.name,
    selected: rainGauge.id === selected,
  }));

  React.useEffect(() => {
    setSelected(rainGauges[0]?.id || null);
  }, [rainGauges]);

  const rainGauge = selected
    ? rainGauges.find((rainGauge) => rainGauge.id === selected)
    : null;

  return rainGauges ? (
    <ChartWrapper
      title={formatMessage({
        defaultMessage: 'Rain Gauges',
        description: 'dashboard.chart.rain-gauge.title',
      })}
      titleHref={ROUTE_NAME.RAIN_GAUGES_LIST}
      options={[
        {
          options: modeOptions,
          onChange: (value) => setSelected(value),
        },
      ]}
    >
      {rainGauge ? (
        <ChartRainGaugeMonthlyRainfall
          rainGauge={rainGauge}
          year={new Date().getFullYear()}
        />
      ) : null}
    </ChartWrapper>
  ) : null;
};

const HistoricEventsTab = () => {
  const { formatMessage } = useI18n();

  return (
    <ChartWrapper
      title={formatMessage({
        defaultMessage: 'Event log',
        description: 'dashboard.activity_log.title',
      })}
      titleHref={ROUTE_NAME.SUMMARY_EVENTS_LIST}
      noPadding
    >
      <HistoricEvents className={styles.tabHistoryTimeline} asList />
    </ChartWrapper>
  );
};
