import { ActionType } from '@mobble/models/src/model/Actions';
import {
  useMobs,
  useProperties,
  useRootSelector,
} from '@mobble/store/src/hooks';

import { type FormBuilderFieldProps } from '@src/stories/Views/Form/FormBuilder';
import { type MobTotalValue } from '@src/stories/Views/Misc/InputInventoryItems';
import { type MobActionCreateFormValues } from '@src/stories/Views/MobAction/MobActionCreateForm';
import { type MobActionEditFormValues } from '@src/stories/Views/MobAction/MobActionEditForm';

export const useInputInventoryItems = (
  props: FormBuilderFieldProps<
    MobActionCreateFormValues | MobActionEditFormValues
  >
) => {
  const properties = useProperties();
  const propertyId = properties.selected?.id;
  const { entities: mobs } = useMobs(propertyId);

  const inventoriesRoot = useRootSelector((s) => s.inventories);
  const inventoryItems = useRootSelector((s) => s.inventoryItems);
  const inventoryItemBatches = useRootSelector((s) => s.inventoryItemBatches);

  const selectedMobs = mobs.filter((mob) =>
    props.values.mobIds.includes(mob.id)
  );

  const inventories =
    inventoriesRoot.entities.filter(
      (inventory) => inventory.propertyId === propertyId
    ) || [];

  const mobCount = selectedMobs.length
    ? selectedMobs.length === 1 && props.values.count
      ? props.values.count
      : selectedMobs.reduce((acc, curr) => acc + curr.size, 0)
    : 0;

  const totalValue: MobTotalValue = {
    type: ActionType.MobAction,
    mobCount: mobCount,
  };

  const value = (props.values as any)[props.field.name];

  return {
    label: props.makeI18n('label'),
    value: value && typeof value === 'string' ? JSON.parse(String(value)) : [],
    totalValue: totalValue,
    inventories,
    inventoryItems: inventoryItems.entities || [],
    inventoryItemBatches: inventoryItemBatches.entities || [],
    error: Array.isArray(props.error) ? props.error : [props.error],
    actionType: ActionType.MobAction,
    onChange: (val) => props.onChange(JSON.stringify(val)),
    onTouched: props.onTouched,
  };
};
