import React from 'react';
import {
  type BarDatum,
  type BarItemProps,
  type ComputedDatum,
  ResponsiveBar,
} from '@nivo/bar';
import { useTooltip } from '@nivo/tooltip';
import { animated, to } from '@react-spring/web';

import { Color, colorToHex } from '@mobble/colors';

import { chartColors, theme } from './config';

export interface BarProps {
  data: Datum[];
  showLegend?: boolean;
  maxValue?: number;
  color?: string | ((a: ComputedDatum<BarDatum>) => string);
  height?: string;
  formatValue?: (value: number) => string;
  formatLabelValue?: (value: number) => string;
  barStyle?: BarStyle;
  grouped?: boolean;
}

export type BarStyle = 'default' | 'valueOnTop';

export type Datum = {
  index: string | number;
  values: DatumValue[];
};

export type DatumValue = {
  label: string;
  value: number;
};

export const Bar: React.FC<BarProps> = ({
  height = '400px',
  data,
  color,
  showLegend,
  formatValue,
  formatLabelValue,
  barStyle = 'default',
  grouped = false,
}) => {
  const max = React.useRef(0);
  const keys = new Set<string>();

  const getColor = (props) => {
    if (typeof color === 'string') {
      return color;
    } else if (typeof color === 'function') {
      return color(props);
    }

    return chartColors[keys.size % chartColors.length];
  };

  const preparedData = data.map((d) => {
    return {
      index: d.index,
      //
      ...d.values.reduce((acc, v) => {
        keys.add(v.label);
        max.current = Math.max(max.current, v.value);
        return {
          ...acc,
          [v.label]: v.value,
        };
      }, []),
    };
  });

  const barComponent = (() => {
    switch (barStyle) {
      case 'valueOnTop':
        return BarValueOnTopComponent({ formatLabelValue });
      case 'default':
      default:
        return undefined;
    }
  })();

  return (
    <div style={{ height }}>
      <ResponsiveBar
        groupMode={grouped ? 'grouped' : 'stacked'}
        indexBy="index"
        colors={getColor}
        keys={[...keys]}
        data={preparedData as any}
        margin={{ top: 20, right: 0, bottom: 40, left: 40 }}
        animate={false}
        // enableGridY
        // gridYValues={[33]}
        maxValue={Math.ceil(max.current / 10) * 10}
        axisLeft={{
          tickValues: max.current > 10 ? undefined : [0, 5, 10],
        }}
        legends={
          showLegend
            ? [
                {
                  dataFrom: 'keys',
                  anchor: 'top-right',
                  direction: 'column',
                  itemsSpacing: 10,
                  itemWidth: 130,
                  itemHeight: 14,
                  itemTextColor: colorToHex(Color.Black),
                  symbolShape: 'circle',
                },
              ]
            : []
        }
        theme={theme}
        valueFormat={formatValue}
        barComponent={barComponent}
      />
    </div>
  );
};

const BarValueOnTopComponent =
  ({
    formatLabelValue,
  }: {
    formatLabelValue?: (a: string | number) => string;
  }) =>
  ({
    bar: { data, ...bar },
    style: { borderColor, color, height, width },
    borderRadius,
    borderWidth,
    label,
    shouldRenderLabel,
    isInteractive,
    // onClick,
    // onMouseEnter,
    // onMouseLeave,
    tooltip,
    isFocusable,
    ariaLabel,
    ariaLabelledBy,
    ariaDescribedBy,
  }: BarItemProps<any>) => {
    const { showTooltipFromEvent, hideTooltip } = useTooltip();

    const renderTooltip = React.useMemo(
      () => () => React.createElement(tooltip, { ...bar, ...data }),
      [tooltip, bar, data]
    );

    const handleTooltip = React.useCallback(
      (event) => showTooltipFromEvent(renderTooltip(), event),
      [showTooltipFromEvent, renderTooltip]
    );
    const handleMouseEnter = React.useCallback(
      (event) => {
        showTooltipFromEvent(renderTooltip(), event);
      },
      [data, showTooltipFromEvent, renderTooltip]
    );
    const handleMouseLeave = React.useCallback(
      (event) => {
        hideTooltip();
      },
      [data, hideTooltip]
    );

    return (
      <g
        transform={`translate(${bar.x}, ${bar.y})`}
        onMouseEnter={handleMouseEnter}
        onMouseMove={handleTooltip}
        onMouseLeave={handleMouseLeave}
      >
        <animated.rect
          width={to(width, (value) => Math.max(value, 0))}
          height={to(height, (value) => Math.max(value, 0))}
          rx={borderRadius}
          ry={borderRadius}
          fill={data.fill ?? color}
          strokeWidth={borderWidth}
          stroke={borderColor}
          focusable={isFocusable}
          tabIndex={isFocusable ? 0 : undefined}
          aria-label={ariaLabel ? ariaLabel(data) : undefined}
          aria-labelledby={ariaLabelledBy ? ariaLabelledBy(data) : undefined}
          aria-describedby={ariaDescribedBy ? ariaDescribedBy(data) : undefined}
          onMouseEnter={isInteractive ? handleMouseEnter : undefined}
          onMouseMove={isInteractive ? handleTooltip : undefined}
          onMouseLeave={isInteractive ? handleMouseLeave : undefined}
          // onClick={isInteractive ? handleClick : undefined}
          // onFocus={isInteractive && isFocusable ? handleFocus : undefined}
          // onBlur={isInteractive && isFocusable ? handleBlur : undefined}
        />
        {shouldRenderLabel && (
          <text
            x={bar.width / 2}
            y={-10}
            textAnchor="middle"
            dominantBaseline="central"
            style={{
              ...theme.legends.text,
              fontSize: 10,
            }}
          >
            {formatLabelValue ? formatLabelValue(data.value) : label}
          </text>
        )}
      </g>
    );
  };
