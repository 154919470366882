import React, { ComponentPropsWithoutRef, useState } from 'react';
import classNames from 'classnames/bind';

import { useI18n } from '@mobble/i18n';
import { allMobsCanMerge, Mob } from '@mobble/models/src/model/Mob';
import { paddockForMob } from '@mobble/models/src/model/Paddock';
import {
  stockingRateToDSE,
  StockingUnit,
} from '@mobble/models/src/model/Settings';
import { base64encode } from '@mobble/shared/src/core/String';
import {
  usePaddocks,
  useProperties,
  useRootDispatch,
  useSetting,
} from '@mobble/store/src/hooks';
import { thunkUpdate } from '@mobble/store/src/reducers/mobs';

import Button, { ButtonProps } from '@src/components/Button';
import { useLinking } from '@src/hooks/useLinking';
import { MediaQuerySize, useMediaQuery } from '@src/hooks/useMediaQuery';
import * as ROUTE_NAME from '@src/screens/config/routeNames';
import { ModalFlyUp } from '@src/stories/Components/UX/ModalFlyUp';

import { MobBulkEditForm } from '../MobBulkEditForm';

const cx = classNames.bind(styles);

import styles from './selectedMobsOptions.scss';

export interface SelectedMobsOptionsProps
  extends ComponentPropsWithoutRef<'div'> {
  mobs: Mob[];
  aboveNavigation?: boolean;
}

export const SelectedMobsOptions = ({
  mobs,
  aboveNavigation,
  className,
  ...others
}: SelectedMobsOptionsProps) => {
  const { formatMessage } = useI18n();
  const dispatch = useRootDispatch();
  const linkTo = useLinking();
  const size = useMediaQuery();
  const stockingUnit = useSetting('stockingUnit') as StockingUnit;
  const properties = useProperties();
  const paddocks = usePaddocks(mobs[0]?.propertyId);
  const paddockId = paddockForMob(paddocks.entities)(mobs[0])?.id ?? '?';
  const selectedIds = mobs.map((m) => m.id);
  const [loading, setLoading] = useState(false);
  const selectedMobsOnSamePaddock = mobs.every(
    (mob) => paddockForMob(paddocks.entities)(mob)?.id === paddockId
  );
  const selectedMobsSameBreed = mobs.every(
    (mob) => mob.breed === mobs[0].breed
  );

  const selectedPropertyTypes = properties.selected?.types ?? [];

  const [bulkEditVisible, setBulkEditVisible] = useState(false);

  const isSmall = size === MediaQuerySize.Small;

  const multipleSelected = mobs.length > 1;

  const handleBulkEditClick = () => {
    setBulkEditVisible(true);
  };

  const handleBulkEditDialogClose = () => {
    setBulkEditVisible(false);
  };

  const handleMobBulkEditFormSubmit = (formValues) => {
    const updatedMobClasses = formValues['bulk-classes'];
    const updatedMobDse = formValues['bulk-stocking-rate'];

    setLoading(true);

    const mobPromises = updatedMobClasses.map((mob: Mob) => {
      return dispatch(
        thunkUpdate({
          ...mob,
          DSE: updatedMobDse
            ? stockingRateToDSE(stockingUnit)(updatedMobDse)
            : mob.DSE,
        })
      );
    });

    Promise.all(mobPromises).then(() => {
      handleBulkEditDialogClose();
      setLoading(false);
    });
  };

  const buttons: ButtonProps[] = [
    {
      label: isSmall
        ? formatMessage({
            defaultMessage: 'Action',
            description:
              'Apply action to selected mobs button label small screen',
          })
        : formatMessage({
            defaultMessage: 'Apply action',
            description: 'Apply action to selected mobs button label',
          }),
      icon: 'mob-action',
      onClick: () => {
        linkTo(ROUTE_NAME.MODAL_MOB_ACTION_CREATE, {
          paddockId,
          mobIds: base64encode(JSON.stringify(selectedIds)),
        });
      },
    },
    {
      label: multipleSelected
        ? formatMessage({
            defaultMessage: 'Bulk edit',
            description: 'Bulk edit selected mobs button label',
          })
        : formatMessage({
            defaultMessage: 'Edit mob',
            description: 'Edit selected mob button label',
          }),
      disabled: multipleSelected ? !selectedMobsSameBreed : false,
      icon: 'edit',
      onClick: multipleSelected
        ? handleBulkEditClick
        : () => {
            linkTo(ROUTE_NAME.MOB_DETAIL_EDIT, {
              paddockId,
              mobId: selectedIds[0],
            });
          },
    },
    {
      label: isSmall
        ? formatMessage({
            defaultMessage: 'Move',
            description: 'Move mobs button label small screen',
          })
        : formatMessage(
            {
              defaultMessage:
                '{count, plural, one {Move mob} other {Move mobs}}',
              description: 'Move mobs button label',
            },
            {
              count: mobs.length,
            }
          ),
      icon: 'arrow-right-circle',
      onClick: () => {
        linkTo(ROUTE_NAME.MODAL_MOB_MOVE, {
          paddockId,
          mobIds: base64encode(JSON.stringify(selectedIds)),
        });
      },
    },
    {
      label: isSmall
        ? formatMessage({
            defaultMessage: 'Merge',
            description: 'Merge mobs button label small screen',
          })
        : formatMessage(
            {
              defaultMessage: 'Merge mobs',
              description: 'Merge mobs button label',
            },
            {
              count: mobs.length,
            }
          ),
      disabled: !(selectedMobsOnSamePaddock && allMobsCanMerge(mobs)),
      icon: 'merge',
      onClick: () => {
        linkTo(ROUTE_NAME.MODAL_MOB_MERGE, {
          paddockId,
          mobIds: base64encode(JSON.stringify(selectedIds)),
        });
      },
    },
  ];

  const rootClasses = cx(
    {
      SelectedMobsOptions: true,
      aboveNavigation: aboveNavigation,
    },
    className
  );

  return (
    <div className={rootClasses} {...others}>
      <div className={styles.buttonGroup}>
        {buttons.map((b, i) => (
          <Button {...b} key={i} outline />
        ))}
      </div>

      <ModalFlyUp
        title={formatMessage(
          {
            defaultMessage: 'Bulk edit {count} mobs',
            description: 'Bulk edit mobs dialog title',
          },
          {
            count: mobs.length,
          }
        )}
        isOpen={bulkEditVisible}
        footer={
          <>
            <Button
              label={formatMessage({
                defaultMessage: 'Close',
                description: 'Cancel button label',
              })}
              intent="secondary"
              outline
              onClick={handleBulkEditDialogClose}
            />
            <Button
              form="mob-bulk-edit-form"
              label={formatMessage({
                defaultMessage: 'Save',
                description: 'Save button label',
              })}
              type="submit"
              loading={loading}
            />
          </>
        }
        onClose={handleBulkEditDialogClose}
      >
        <section>
          {bulkEditVisible && (
            <MobBulkEditForm
              propertyTypes={selectedPropertyTypes}
              mobs={mobs}
              loading={loading}
              onTouched={() => {}}
              onCancel={handleBulkEditDialogClose}
              onSubmit={handleMobBulkEditFormSubmit}
            />
          )}
        </section>
      </ModalFlyUp>
    </div>
  );
};
