import React, { useState } from 'react';
import { useNavigate } from 'react-router';

import { useI18n } from '@mobble/i18n';
import { Quantity } from '@mobble/shared/src';
import { QuantityOfLength } from '@mobble/shared/src/core/Quantity';
import { useEntitiesRefresher } from '@mobble/shared/src/hooks/useEntitiesRefresher';
import {
  useProperties,
  useRainGauge,
  useRainGaugeReading,
  useSetting,
} from '@mobble/store/src/hooks';

import { useNavigateBack } from '@src/hooks/useNavigateBack';
import { toPath } from '@src/interfaces/Routing';
import { DeleteForm } from '@src/stories/Views/Form/DeleteForm/DeleteForm';
import { EntitySliceFactoryPrelude } from '@src/stories/Views/Misc/EntitySliceFactoryPrelude';
import { ScreenHeader } from '@src/stories/Views/Misc/ScreenHeader';
import {
  RainGaugeReadingEditForm,
  RainGaugeReadingEditFormValues,
} from '@src/stories/Views/RainGauge/RainGaugeReadingEditForm';

import * as ROUTE_NAME from '../config/routeNames';
import { ScreenRendererProps } from '../config/types';

export const RainGaugeReadingEditHeader: React.FC<ScreenRendererProps> = (
  props
) => {
  const { rainGaugeReadingId, rainGaugeId } = props.route.params;
  const { formatMessage } = useI18n();
  const goBack = useNavigateBack();
  const rainGauge = useRainGauge(rainGaugeId);

  return (
    <ScreenHeader
      modal
      title={formatMessage({
        description: 'root.modal.rain-gauge.reading_edit',
        defaultMessage: 'Edit a Reading',
      })}
      breadcrumbs={[
        {
          title: formatMessage({
            description: 'screen.title.rain-gauges',
            defaultMessage: 'Rain gauges',
          }),
          href: ROUTE_NAME.RAIN_GAUGES_LIST,
        },
        {
          title: rainGauge.entity?.name,
          href: toPath(ROUTE_NAME.RAIN_GAUGE_DETAIL, { rainGaugeId }),
        },
        {
          title: formatMessage({
            defaultMessage: 'Rain gauge reading',
            description: 'screen.title.rain-gauge_reading',
          }),
          href: toPath(ROUTE_NAME.RAIN_GAUGE_READING, {
            rainGaugeId,
            rainGaugeReadingId,
          }),
        },
      ]}
      onClose={goBack}
    />
  );
};

export const RainGaugeReadingEdit: React.FC<ScreenRendererProps> = ({
  route,
}) => {
  const { rainGaugeReadingId, rainGaugeId } = route.params;
  const properties = useProperties();
  const propertyId = properties.selected?.id;
  const lengthUnit = useSetting('lengthUnit') as Quantity.LengthUnits;
  const rainGaugeReading = useRainGaugeReading(rainGaugeReadingId);
  const [formLoading, setFormLoading] = useState(false);
  const navigate = useNavigate();
  const goBack = useNavigateBack();

  useEntitiesRefresher([rainGaugeReading], propertyId);

  const Prelude = EntitySliceFactoryPrelude({
    preludes: [rainGaugeReading.prelude],
    required: [rainGaugeReading.entity],
  });

  if (Prelude) {
    return Prelude;
  }

  const initialValues = {
    value: Quantity.convertTo(lengthUnit)(
      rainGaugeReading.entity?.value
    ) as QuantityOfLength,
  };

  const handleSubmit = (formValues: RainGaugeReadingEditFormValues) => {
    if (!rainGaugeReading.entity) {
      return;
    }

    setFormLoading(true);

    rainGaugeReading
      .update({
        ...rainGaugeReading.entity,
        date: formValues.date,
        value: formValues.value,
      })
      .then(() => {
        setFormLoading(false);
        goBack();
      })
      .catch((err) => {
        console.log(err);
        setFormLoading(false);
      });
  };

  const handleDelete = () => {
    setFormLoading(true);

    rainGaugeReading.remove().then(() => {
      setFormLoading(false);
      navigate(toPath(ROUTE_NAME.RAIN_GAUGE_DETAIL, { rainGaugeId }));
    });
  };

  return (
    <>
      <RainGaugeReadingEditForm
        rainGaugeReading={rainGaugeReading.entity}
        initialValues={initialValues}
        loading={formLoading}
        onSubmit={handleSubmit}
        onCancel={goBack}
      />
      <DeleteForm
        i18nRootKey="rain-gauges.rain-gauge-reading.delete"
        loading={formLoading}
        onDelete={handleDelete}
      />
    </>
  );
};

export default {
  name: ROUTE_NAME.MODAL_RAIN_GAUGE_READING_EDIT,
  header: RainGaugeReadingEditHeader,
  component: RainGaugeReadingEdit,
  modal: true,
};
