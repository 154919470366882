import { useEffect } from 'react';
import { matchRoutes, useLocation } from 'react-router';
import { usePostHog } from 'posthog-js/react';

import mobbleService from '@mobble/service';

import { SCREEN_ROUTES } from '../screens';

export const useConfiguredPostHog = () => {
  const posthog = usePostHog();
  const location = useLocation();
  const matches = matchRoutes(SCREEN_ROUTES, location.pathname);

  useEffect(() => {
    const unsubscribe = mobbleService.auth.onAuthStateChanged((user) => {
      if (user) {
        posthog.identify(user.id, {
          email: user.email,
        });
      } else {
        posthog.reset();
      }
    });
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (matches && matches.length > 0) {
      const path = matches[0].route.path;
      posthog.capture('$pageview', {
        $current_url: path,
      });
    }
  }, [location.pathname, posthog]);
};
