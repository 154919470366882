import React from 'react';

import { Color } from '@mobble/colors';
import { useI18n } from '@mobble/i18n';
import { formatMonetary } from '@mobble/shared/src/core/Monetary';
import { formatNumber } from '@mobble/shared/src/core/Number';
import { useSettings } from '@mobble/store/src/hooks/settings';

import { Box } from '@src/stories/Components/Layout/Box';
import { VStack } from '@src/stories/Components/Layout/Stack';
import {
  type InfoTileProps,
  InfoTiles,
} from '@src/stories/Components/UI/InfoTile';
import { Spinner } from '@src/stories/Components/UI/Spinner';

import { RootItem } from './usePropertyReportForRange';

export interface LivestockSalesTilesProps {
  reportData: RootItem[];
}

export const LivestockSalesTiles: React.FC<LivestockSalesTilesProps> = ({
  reportData,
}) => {
  const { formatMessage } = useI18n();
  const { settings } = useSettings();

  if (!reportData) {
    return (
      <VStack alignment="center">
        <Box spacing={4}>
          <Spinner color={Color.Black} />
        </Box>
      </VStack>
    );
  }

  const totalReceived = reportData.reduce((a, b) => a + b.sales.total, 0);
  const totalSold = reportData.reduce((a, b) => a + b.sales.sold, 0);

  const tiles: InfoTileProps[] = [
    {
      id: 'sold',
      label: formatMessage({
        defaultMessage: 'Livestock sold',
        description: 'reports.tile.livestock-sales.sold.title',
      }),
      value: formatNumber(totalSold),
    },
    {
      id: 'average-price-per-head',
      label: formatMessage({
        defaultMessage: 'Average price per head',
        description:
          'reports.tile.livestock-sales.average-price-per-head.title',
      }),
      value: formatMonetary(
        totalReceived / totalSold || 0,
        settings.currencySymbol
      ),
    },
    {
      id: 'total-received',
      label: formatMessage({
        defaultMessage: 'Total received',
        description: 'reports.tile.livestock-sales.total-received.title',
      }),
      value: formatMonetary(totalReceived, settings.currencySymbol),
    },
  ];

  return <InfoTiles items={tiles} />;
};
