import {
  I18nContextProps,
  makeQuantityFormatMessageItem,
} from '@mobble/i18n/src';
import {
  INVENTORY_TYPE,
  InventoryCategory,
  InventoryTypes,
} from '@mobble/models/src/model/Inventory';

import { TableColumn } from '@src/stories/Components/Layout/Table';

export const makeInventoryItemsTableColumns = ({
  category,
  type,
  formatMessage,
}: {
  category: InventoryCategory;
  type: InventoryTypes;
  formatMessage: I18nContextProps['formatMessage'];
}): TableColumn[] => {
  return [
    {
      key: 'name',
      label: { key: 'inventory_items.table.heading.column.name' },
      toValue: (ii) => ii.name,
    },
    {
      key: 'quantity',
      label: { key: 'inventory_items.table.heading.column.quantity' },
      toValue: (ii) =>
        formatMessage(...makeQuantityFormatMessageItem(ii.quantity)),
    },

    ...(category === InventoryCategory.Chemicals
      ? [
          {
            key: 'esi',
            label: { key: 'inventory_items.table.heading.column.esi' },
            toValue: (ii) => ii?.esi,
          },
          {
            key: 'whp',
            label: { key: 'inventory_items.table.heading.column.whp' },
            toValue: (ii) => ii?.whp,
          },
          ...(type === INVENTORY_TYPE.ANIMAL_HEALTH
            ? [
                {
                  key: 'woolWhp',
                  label: {
                    key: 'inventory_items.table.heading.column.woolWhp',
                  },
                  toValue: (ii) => ii?.woolWhp || '',
                },
              ]
            : []),
        ]
      : []),
    {
      key: 'notes',
      label: {
        key: 'inventory_items.table.heading.column.notes',
      },
      toValue: (ii) => ii?.notes,
    },
  ];
};
