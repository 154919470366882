import { useState } from 'react';

import { findMobs, someMobsCanMerge } from '@mobble/models/src/model/Mob';
import { findPaddock } from '@mobble/models/src/model/Paddock';
import { useRootDispatch } from '@mobble/store/src/hooks/root';
import {
  type MergeMobsInput,
  type MoveMobsInput,
  thunkGetAll,
  thunkMergeMobs,
  thunkMoveMobs,
} from '@mobble/store/src/reducers/paddocks';

import { store } from '../store';

export type MoveMobsArgs = {
  [key: string]: any;
} & MoveMobsInput;

export type MergeMobsArgs = {
  [key: string]: any;
} & MergeMobsInput;

export const useMoveMobs = (propertyId?: string) => {
  const dispatch = useRootDispatch();
  const [moveToPropertyId, setMoveToPropertyId] = useState<string>(
    propertyId ?? ''
  );

  const handleChangeProperty = (changedPropertyId: string) => {
    dispatch(thunkGetAll({ parentId: changedPropertyId }));
    setMoveToPropertyId(changedPropertyId);
  };

  const mergeMobs = async (input: MergeMobsArgs) => {
    try {
      const res = await dispatch(thunkMergeMobs(input)).unwrap();
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    }
  };

  const moveMobs = async (
    input: MoveMobsArgs
  ): Promise<{ mobsCanMergeOnTargetPaddock: boolean }> => {
    try {
      return dispatch(thunkMoveMobs(input)).then((payloadAction: any) => {
        const state = store.getState();
        const paddockTo = payloadAction.meta.arg.paddockTo;
        const paddock = findPaddock(state.paddocks.entities)(paddockTo.id);
        const mobs = paddock ? findMobs(state.mobs.entities)(paddock.mobs) : [];
        const mobsCanMergeOnTargetPaddock = someMobsCanMerge(mobs);

        return {
          mobsCanMergeOnTargetPaddock,
        };
      });
    } catch (error) {
      return Promise.reject(error);
    }
  };

  return {
    handleChangeProperty,
    moveToPropertyId,
    mergeMobs,
    moveMobs,
  };
};
