import React from 'react';

import { formatDate, RawDate } from '@mobble/shared/src/core/Date';
import { useSetting } from '@mobble/store/src/hooks/settings';

import { Text, type TextProps } from '../UI/Text';

export type LocaleDateProps = TextProps & {
  date: RawDate;
};

export const LocaleDate: React.FC<LocaleDateProps> = ({ date, ...rest }) => {
  const dateFormat = useSetting('dateFormat') as string;
  return <Text {...rest}>{formatDate(date, dateFormat)}</Text>;
};
