import React from 'react';
import { type FormikErrors } from 'formik';

import { FormBuilder, type FormBuilderProps } from '../../Form/FormBuilder';

export interface MobCreateWizardNaturalIncreaseProps {
  formValues?: MobCreateWizardNaturalIncreaseFormValues;
  onChange: (
    values: MobCreateWizardNaturalIncreaseFormValues,
    errors: FormikErrors<MobCreateWizardNaturalIncreaseFormValues>
  ) => void;
}

export interface MobCreateWizardNaturalIncreaseFormValues {
  date?: string;
  notes?: string;
}

export const MobCreateWizardNaturalIncrease: React.FC<
  MobCreateWizardNaturalIncreaseProps
> = ({ formValues, onChange }) => {
  const formProps: FormBuilderProps<MobCreateWizardNaturalIncreaseFormValues> =
    {
      i18nRootKey: 'mobs.mob.create.form.natural_increase_form',
      footer: false,
      fields: [
        {
          name: 'date',
          type: 'date',
          initialValue: formValues?.date,
          required: true,
        },
        {
          name: 'notes',
          type: 'textarea',
          initialValue: formValues?.notes,
        },
      ],
      onSubmit: () => {},
      onChange,
    };

  return <FormBuilder {...formProps} />;
};
