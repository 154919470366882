import React from 'react';

import { Color } from '@mobble/colors';

import { VStack } from '../Layout/Stack';
import { Spinner } from '../UI/Spinner';

import styles from './pullDown.scss';

export interface PullDownProps {
  onPullDown: (() => Promise<void>) | (() => void);
  loading?: boolean;
  children?: React.ReactNode;
}

export const PullDown: React.FC<PullDownProps> = ({
  onPullDown,
  children,
  loading,
}) => {
  const refWrapper = React.useRef<HTMLDivElement>(null);
  const refChildren = React.useRef<HTMLDivElement>(null);
  const [pullHeight, setPullHeight] = React.useState(0);

  const MAX_PULL = 220;

  React.useEffect(() => {
    if (!refWrapper.current) {
      return;
    }

    let startPoint = 0;
    let distance = 0;

    const pullStart = (ev) => {
      const { screenY } = ev.touches[0];

      startPoint = screenY;
    };

    const pull = (ev) => {
      const { screenY } = ev.touches[0];
      distance = screenY - startPoint;

      setPullHeight(Math.min(MAX_PULL, distance));
    };

    const endPull = () => {
      if (distance > MAX_PULL * 0.8) {
        onPullDown();
      }
      setPullHeight(0);
    };

    refWrapper.current.addEventListener('touchstart', pullStart);
    refWrapper.current.addEventListener('touchmove', pull);
    refWrapper.current.addEventListener('touchend', endPull);

    return () => {
      if (!refWrapper.current) {
        return;
      }
      refWrapper.current.removeEventListener('touchstart', pullStart);
      refWrapper.current.removeEventListener('touchmove', pull);
      refWrapper.current.removeEventListener('touchend', endPull);
    };
  }, [refWrapper]);

  const progress = pullHeight / MAX_PULL;

  const handleStyle = loading
    ? {}
    : {
        height: `${pullHeight}px`,
        opacity: progress,
      };

  const classNames = [styles.pullDown, loading ? styles.loading : false]
    .filter(Boolean)
    .join(' ');

  return (
    <div className={classNames} ref={refWrapper}>
      <div className={styles.pullDownHandle} style={handleStyle}>
        <VStack alignment="center">
          <Spinner color={Color.Black} progress={progress} />
        </VStack>
      </div>
      <div data-ref="children" ref={refChildren} className={styles.pullDown}>
        {children}
      </div>
    </div>
  );
};
