import React from 'react';
import { SymbolLayout, SymbolPaint } from 'mapbox-gl';

import {
  MapStyle,
  mapStyleIsSatellite,
} from '@mobble/models/src/model/MapStyle';
import { Color } from '@mobble/theme';

import { Symbol } from '@src/stories/Mapbox/Symbol';

import { type MapItemPaddockLabel } from './MapItemType';

export interface PaddockLabelProps {
  item: MapItemPaddockLabel;
  mapStyle: MapStyle;
  withOffset?: boolean;
  disabled?: boolean;
}

export const PaddockLabel: React.FC<PaddockLabelProps> = ({
  mapStyle,
  item,
  withOffset,
  disabled,
}) => {
  const textField = item.meta.size
    ? [item.meta.title, item.meta.size].join('\n')
    : item.meta.title;

  const textAnchor = withOffset ? 'top' : 'bottom';

  const symbolLayout: SymbolLayout = {
    'text-field': textField,
    'text-font': ['Roboto Medium'],
    'text-variable-anchor': [textAnchor],
    'text-justify': 'center',
    'text-size': ['interpolate', ['linear'], ['zoom'], 12, 10, 15, 15],
    'text-padding': 0,
    'text-allow-overlap': disabled ? true : false,
  };

  const [textColor, textHaloColor] = (() => {
    if (mapStyleIsSatellite(mapStyle)) {
      return [Color.BodyBackground, Color.BodyText];
    }
    return [Color.BodyText, Color.BodyBackground];
  })();

  const symbolPaint: SymbolPaint = {
    'text-halo-blur': ['interpolate', ['linear'], ['zoom'], 8, 1, 15, 0],
    'text-halo-width': 1,
    'text-halo-color': textHaloColor,
    'text-color': textColor,
    'text-translate': [0, -2],
  };

  return (
    <Symbol
      id={`paddock_group_label-${item.id}`}
      data={item.point}
      symbolLayout={symbolLayout}
      symbolPaint={symbolPaint}
    />
  );
};
