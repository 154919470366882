import React from 'react';
import { defineMessages } from 'react-intl';

import { useMessages } from '@mobble/i18n';
import { CustomMapLayer } from '@mobble/models';

import { InfoRow,type InfoRowProps } from '../../Components/UI/InfoRow';

export interface CustomMapLayerInfoTableProps {
  customMapLayer: CustomMapLayer;
}

const messages = defineMessages({
  name: {
    defaultMessage: 'Name',
    description: 'customMapLayer.info.table.row.name.label',
  },
  description: {
    defaultMessage: 'Description',
    description: 'customMapLayer.info.table.row.description.label',
  },
  color: {
    defaultMessage: 'Colour',
    description: 'customMapLayer.info.table.row.color.label',
  },
});

export const CustomMapLayerInfoTable: React.FC<
  CustomMapLayerInfoTableProps
> = ({ customMapLayer }) => {
  const strings = useMessages(messages);

  const rows: InfoRowProps[] = [
    {
      label: strings.name,
      value: customMapLayer.name,
    },
    {
      label: strings.description,
      value: customMapLayer.description,
    },
    {
      label: strings.color,
      value: customMapLayer?.properties?.color,
      color: customMapLayer?.properties?.color,
    },
  ];

  return (
    <>
      {rows.map((row) => (
        <InfoRow key={row.label} {...row} />
      ))}
    </>
  );
};
