import React, { InputHTMLAttributes,useEffect, useRef, useState } from 'react';

import { Color } from '@mobble/colors';
import { type I18nItem,useI18n } from '@mobble/i18n';
import * as Quantity from '@mobble/shared/src/core/Quantity';

import { Box } from '@src/stories/Components/Layout/Box';
import { Text } from '@src/stories/Components/UI/Text';
import { Clickable } from '@src/stories/Components/UX/Clickable';
import { Input } from '@src/stories/Components/UX/Input';
import {
  ModalSelect,
  ModalSelectOption,
} from '@src/stories/Components/UX/ModalSelect';

import styles from './inputQuantity.scss';

type PickedInputAttributes = Partial<
  Pick<
    InputHTMLAttributes<HTMLInputElement>,
    | 'autoComplete'
    | 'disabled'
    | 'min'
    | 'max'
    | 'step'
    | 'pattern'
    | 'className'
  >
>;

export interface InputQuantityProps<T extends Quantity.Type>
  extends PickedInputAttributes {
  id?: string; // TODO: make required
  quantityType: T;
  placeholder?: I18nItem | string;
  unitPlaceholder?: I18nItem | string;
  value?: Quantity.Quantity<T, Quantity.Units>;
  onChange: (quantity: Quantity.Quantity<T, Quantity.Units>) => void;
}

export function InputQuantity<T extends Quantity.Type>({
  id,
  quantityType,
  placeholder,
  value,
  onChange,
  className,
  ...others
}: InputQuantityProps<T>) {
  const { formatMessage } = useI18n();
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [stringVal, setStringVal] = useState(
    value?.value ? String(value?.value) : ''
  );
  const curUnit = value?.unit;
  const units = Quantity.unitsForType(quantityType);
  const rootClasses = [styles.inputQuantity, className]
    .filter(Boolean)
    .join(' ');

  const [options, setOptions] = useState<ModalSelectOption[]>(
    units.map((unit: string) => ({
      label: formatMessage({
        id: `generic.quantity.unit.${unit}.long`,
        defaultMessage: [],
      }),
      value: unit,
      selected: unit === curUnit,
    }))
  );

  const showUnitChangeMessage = options.find((option) => option.disabled);

  useEffect(() => {
    // If value is set on mount, disable changing between unit types (mass/volume)
    if (value?.unit) {
      const valueQuantityType = Quantity.unitToQuantityType(value.unit);

      setOptions(
        options.map((option) => ({
          ...option,
          disabled:
            valueQuantityType !==
            Quantity.unitToQuantityType(option.value as Quantity.Units),
        }))
      );
    }
  }, []);

  useEffect(() => {
    const newVal = value?.value ? String(value?.value) : '';
    if (newVal) {
      setStringVal(newVal);
    }
  }, [value]);

  const handleChangeValue = (strVal: string) => {
    setStringVal(strVal);
    onChange(
      Quantity.makeQuantity(quantityType, curUnit, parseFloat(strVal) || 0)
    );
  };

  const handleChangeUnit = (unit: string) => {
    if (units.includes(unit)) {
      if (value) {
        const updated = Quantity.changeTo(unit as Quantity.Units)(
          value as any
        ) as any;
        onChange(updated);
      } else {
        onChange(
          Quantity.makeQuantity(quantityType, unit as Quantity.Units, 0)
        );
      }
    }
  };

  const handleUnitSelectClose = () => {
    buttonRef.current?.focus();
    setModalVisible(false);
  };

  const handleButtonFocus = () => {
    if (!value?.unit) {
      setModalVisible(true);
    }
  };

  return (
    <Box className={rootClasses}>
      <ModalSelect
        title={formatMessage({
          id: 'generic.quantity.unit.select',
          defaultMessage: 'Select unit',
        })}
        additionalHeader={
          showUnitChangeMessage ? (
            <Box spacing={{ left: 2, right: 2, bottom: 2 }}>
              <Text>
                {formatMessage({
                  description: 'Description for unit selection modal',
                  defaultMessage:
                    'To change a unit category, re-add the product with the correct unit via inventory.',
                })}
              </Text>
            </Box>
          ) : undefined
        }
        options={options}
        visible={modalVisible}
        onChange={handleChangeUnit}
        onCancel={handleUnitSelectClose}
      />

      <Input
        {...others}
        id={id}
        type="float"
        placeholder={placeholder}
        value={stringVal}
        onChange={handleChangeValue}
      >
        <Clickable
          ref={buttonRef}
          id={`${id}.unit`}
          disabled={others.disabled}
          href={setModalVisible}
          onFocus={handleButtonFocus}
          className={styles.unitButton}
        >
          <Text
            variant="small"
            color={!curUnit ? Color.Grey : undefined}
            style={{ opacity: 0.5 }}
          >
            {curUnit
              ? formatMessage({
                  id: `generic.quantity.unit.${curUnit}.short`,
                  defaultMessage: [],
                })
              : formatMessage({
                  id: 'generic.quantity.unit.select.placeholder',
                  defaultMessage: 'Unit',
                })}
          </Text>
        </Clickable>
      </Input>
    </Box>
  );
}
