import React from 'react';

import { Property } from '@mobble/models/src/model/Property';
import mobbleService from '@mobble/service';
import { type PropertyStockingRate } from '@mobble/service/src/ext/property-stocking-rates';
import { type DateRange } from '@mobble/shared/src/core/Date';

import { checkPropertyIdsMatch } from './usePropertyReportForRange';

export interface usePropertyStockingRatesForRangeProps {
  propertyId: string;
  range: DateRange;
}

export type PropertyStockingRatesLoading = {
  type: 'loading';
  range: string;
  propertyIds: Property['id'][];
};
export type PropertyStockingRatesReady = {
  type: 'ready';
  raw: PropertyStockingRate[];
  range: string;
  items: any[];
  propertyIds: Property['id'][];
};

export type PropertyStockingRatesData =
  | PropertyStockingRatesLoading
  | PropertyStockingRatesReady;

export const usePropertyStockingRatesForRange = ({ propertyId, range }) => {
  const [data, setData] = React.useState<PropertyStockingRatesData>({
    type: 'loading',
    range: '',
    propertyIds: [],
  });

  React.useEffect(() => {
    const fetchPropertyStockingRatesForRange = async () => {
      const strRange = JSON.stringify(range);
      if (data.type === 'loading' && data.range === strRange) {
        return;
      }
      setData({ type: 'loading', range: strRange, propertyIds: [propertyId] });
      await mobbleService.api.propertyStockingRates
        .get({
          propertyId,
          range,
        })
        .then((raw) => {
          setData({
            type: 'ready',
            raw,
            items: [],
            propertyIds: [propertyId],
            range: strRange,
          });
        });
    };

    fetchPropertyStockingRatesForRange();
  }, [range, propertyId]);

  return data;
};

const fetchPropertiesReportForRange = (
  propertyIds: Property['id'][],
  range: DateRange
) =>
  new Promise<{ raw: PropertyStockingRate[]; propertyId: Property['id'] }[]>(
    (resolve) => {
      const reports = propertyIds.map((propertyId) => {
        return mobbleService.api.propertyStockingRates
          .get({
            propertyId,
            range,
          })
          .then((raw) => ({
            raw,
            propertyId,
          }));
      });

      Promise.all(reports).then((raw) => {
        resolve(raw);
      });
    }
  );

const combinePropertyStockingRatesByDate = (
  rates: PropertyStockingRate[]
): PropertyStockingRate[] => {
  const combinedMap = new Map<string, PropertyStockingRate>();

  rates.forEach((rate) => {
    const key = rate.dateRaw;
    const existingRate = combinedMap.get(key);

    if (existingRate) {
      Object.entries(rate.byLivestockType).forEach(
        ([livestockType, values]) => {
          if (!existingRate.byLivestockType[livestockType]) {
            existingRate.byLivestockType[livestockType] = { ...values };
          } else {
            Object.entries(values).forEach(([valueKey, value]) => {
              existingRate.byLivestockType[livestockType][valueKey] =
                (existingRate.byLivestockType[livestockType][valueKey] || 0) +
                value;
            });
          }
        }
      );

      Object.entries(rate.rollingAverage).forEach(([valueKey, value]) => {
        existingRate.rollingAverage[valueKey] =
          (existingRate.rollingAverage[valueKey] || 0) + value;
      });
    } else {
      combinedMap.set(key, JSON.parse(JSON.stringify(rate))); // Deep copy to prevent mutation issues
    }
  });

  return Array.from(combinedMap.values());
};

export const combineStockingRatesByDate = (
  aggregateStockingRates: {
    raw: PropertyStockingRate[];
    propertyId: Property['id'];
  }[]
): PropertyStockingRate[] =>
  combinePropertyStockingRatesByDate(
    aggregateStockingRates.map((item) => item.raw).flat(1)
  );

export const usePropertiesStockingRatesForRange = ({ propertyIds, range }) => {
  const [data, setData] = React.useState<PropertyStockingRatesData>({
    type: 'loading',
    range: '',
    propertyIds: [],
  });

  React.useEffect(() => {
    const strRange = JSON.stringify(range);
    if (
      strRange &&
      propertyIds.length &&
      (!checkPropertyIdsMatch(propertyIds, data.propertyIds) ||
        strRange !== data.range)
    ) {
      if (data.type === 'loading' && data.range === strRange) {
        return;
      }

      setData({ type: 'loading', range: strRange, propertyIds });

      fetchPropertiesReportForRange(propertyIds, range).then((raw) => {
        setData({
          type: 'ready',
          raw: combineStockingRatesByDate(raw),
          range: strRange,
          items: [],
          propertyIds,
        });
      });
    }
  }, [range, propertyIds, data.type]);

  return data;
};
