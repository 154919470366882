import React from 'react';

import * as ROUTE_NAME from '@src/screens/config/routeNames';
import { LoadingScreen } from '@src/stories/Views/Misc/LoadingScreen';

import { ScreenRendererProps } from '../config/types';

import { useAPICallback } from './hooks/useAPICallback';
import { useGetQueryParams } from './hooks/useGetQueryParams';

export const Callback: React.FC<ScreenRendererProps> = () => {
  const params = useGetQueryParams();

  useAPICallback(params);

  return (
    <LoadingScreen fullScreen />
  );
};

export default {
  name: ROUTE_NAME.CALLBACK,
  component: Callback,
};
