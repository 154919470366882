import React from 'react';

import { type Inventory } from '@mobble/models/src/model/Inventory';
import { type Property } from '@mobble/models/src/model/Property';

import { FormBuilder, type FormBuilderProps } from '../Form/FormBuilder';

export interface InventoryEditSharingFormProps {
  property: Property;
  inventory: Inventory;
  loading?: boolean;
  onCancel: () => void;
  onTouched: (dirty: boolean) => void;
  onSubmit: (inventory: Inventory) => void;
}

export interface InventoryEditSharingFormValues {
  name: string;
  sharingIds: string[];
}

export const InventoryEditSharingForm: React.FC<
  InventoryEditSharingFormProps
> = ({ inventory, property, loading, onCancel, onTouched, onSubmit }) => {
  const handleSubmit = (formValues: InventoryEditSharingFormValues) => {
    onSubmit({
      ...inventory,
      sharing: [
        ...inventory.sharing.map((a) => ({
          ...a,
          selected: formValues.sharingIds.includes(a.propertyId),
        })),
      ],
    });
  };

  const form: FormBuilderProps<InventoryEditSharingFormValues> = {
    i18nRootKey: 'inventories.inventory.edit.form',
    flex: true,
    fields: [
      {
        name: 'property',
        type: 'display',
        initialValue: inventory.shared?.propertyName ?? property.name,
      },
      {
        name: 'name',
        type: 'display',
        initialValue: inventory.name,
      },
      {
        name: 'sharingIds',
        type: 'select-multiple',
        initialValue: inventory.sharing
          .filter((a) => a.selected)
          .map((a) => a.propertyId),
        options: inventory.sharing.map((a) => ({
          label: a.propertyName,
          value: a.propertyId,
          selected: a.selected,
        })),
      },
    ],
    loading,
    onSubmit: handleSubmit,
    onCancel,
    onTouched,
  };

  return <FormBuilder {...form} />;
};
