import React from 'react';

import { Box } from './Box';
import { type Spacing } from './Spacing';

export interface SpacerProps {
  x?: Spacing;
  y?: Spacing;
  flex?: boolean;
}

export const Spacer: React.FC<SpacerProps> = ({ x, y, flex }) => {
  return <Box flex={flex} width={x} height={y} />;
};
