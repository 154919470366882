import React from 'react';
import { defineMessages } from 'react-intl';

import { useI18n,useMessages } from '@mobble/i18n';
import { type RainGaugeReading, type User } from '@mobble/models';
import { findUser } from '@mobble/models/src/model/User';
import { Quantity } from '@mobble/shared/src';
import { formatDate } from '@mobble/shared/src/core/Date';
import { useSetting } from '@mobble/store/src/hooks';

import { makeQuantityFormatMessageItem } from '../../Components/Locale/LocaleQuantity';
import { InfoRow, type InfoRowProps } from '../../Components/UI/InfoRow';

export interface RainGaugeReadingInfoTableProps {
  users: User[];
  rainGaugeReading: RainGaugeReading;
}

const messages = defineMessages({
  amount: {
    description: 'rain-gauge.reading.table.row.amount.label',
    defaultMessage: 'Reading',
  },
  date: {
    description: 'rain-gauge.reading.table.row.date.label',
    defaultMessage: 'Date',
  },
  user: {
    description: 'rain-gauge.reading.table.row.user.label',
    defaultMessage: 'Recorded by',
  },
});

export const RainGaugeReadingInfoTable: React.FC<
  RainGaugeReadingInfoTableProps
> = ({ rainGaugeReading, users }) => {
  const { formatMessage } = useI18n();
  const strings = useMessages(messages);
  const lengthUnit = useSetting('lengthUnit') as Quantity.LengthUnits;
  const dateFormat = useSetting('dateFormat') as string;

  const rows: InfoRowProps[] = [
    {
      label: strings.amount,
      value: formatMessage(
        ...makeQuantityFormatMessageItem(rainGaugeReading.value, lengthUnit)
      ),
    },
    {
      label: strings.date,
      value: formatDate(rainGaugeReading.date, dateFormat),
    },
    {
      label: strings.user,
      value: findUser(users)(String(rainGaugeReading.createdByID))?.name,
    },
  ];

  return (
    <>
      {rows.map((row) => (
        <InfoRow key={row.label} {...row} />
      ))}
    </>
  );
};
