export const toError = (unknown: unknown): Error => {
  if (unknown instanceof Error) {
    return unknown;
  }

  return new Error(String(unknown));
};

export enum RequestErrors {
  NetworkError = 'network-error',
  RequestFailed = 'request-failed',
  Forbidden = 'forbidden',
  SubscriptionExpired = 'subscription-expired',
  Validation = 'validation',
}

export const formatError = (error: Error): string => {
  const message = error.message;
  switch (message.toLowerCase()) {
    case 'internal_server_error':
      return RequestErrors.RequestFailed;
    case 'forbidden':
      return RequestErrors.Forbidden;
    default:
      return error.message;
  }
};
