import React from 'react';
import MapboxDraw, {
  DrawCreateEvent,
  DrawDeleteEvent,
  DrawUpdateEvent,
} from '@mapbox/mapbox-gl-draw';
import { Map } from 'mapbox-gl';

import {
  type FeatureLineString,
  type FeaturePoint,
  type FeaturePolygon,
  type LineString,
  type Point,
  pointsToFeatureLineString,
  pointsToFeaturePolygon,
  type Polygon,
  polygonToPoints,
} from '@mobble/models/src/model/MapGeometry';
import { updateAt } from '@mobble/shared/src/core/Array';
import { type Quantities } from '@mobble/shared/src/core/Quantity';

import { addLabelsToMap } from './helpers/addLabelsToMap';
import { safeRemoveLayer,safeRemoveSource } from './helpers/map';
import { snapPoint } from './helpers/snapPoint';

import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';

export interface MapDrawProps {
  /**
   * A reference to `mapboxgl.Map`
   */
  mapRef: Map;

  /**
   * A reference to `MapboxDraw`
   */
  drawRef: MapboxDraw;

  /**
   * Drawing mode type, defaults to `area`
   */
  type?: 'area' | 'path' | 'point';

  /**
   * Show measurements (area, length) on the map, defaults to `true`
   */
  showMeasurements?: boolean;

  /**
   * Provide a function to format the measurement quantity label, defaults to `${q.value.toFixed(2)} ${q.unit}`
   */
  getMeasurementLabel?: (q: Quantities) => string;

  /**
   * Map is in drawing mode, defaults to `true`
   */
  editable?: boolean;

  /**
   * Points to render as a Polygon, only appliable when `editable = false`
   */
  points?: Point[];

  /**
   * Enables snap to boundary. When dragging a point,
   * it will connect to an existing polygon if it is nearby.
   * Requires `itemsToSnapTo` to be populated.
   */
  snap?: boolean;

  /**
   * An array of Polygons to snap to when `snap` is enabled
   */
  itemsToSnapTo?: Polygon[];

  /**
   * Triggered on `draw.create` or `draw.update` event
   */
  onChange: (points: Point[]) => void;
}

/**
 * MapDraw is a wrapper for `mapbox-gl-draw`.
 * It enabled the drawing of points, paths and polygons and displays measurements.
 */
const MapDraw: React.FC<MapDrawProps> = ({
  mapRef,
  drawRef,
  type = 'area',
  editable = true,
  points,
  itemsToSnapTo,
  snap,
  showMeasurements = true,
  getMeasurementLabel,
  onChange,
}) => {
  const sourceList = React.useRef<string[]>([]);
  const layerList = React.useRef<string[]>([]);
  const typeRef = React.useRef(type);
  const snapRef = React.useRef(snap);

  const setDrawModeFromProps = () => {
    // If in edit mode and a feature is selected, do nothing
    if (editable && drawRef.getMode() === drawRef.modes.DIRECT_SELECT) {
      return;
    }

    // Set editable draw mode based on type
    let newMode: string = drawRef.modes.STATIC;
    if (type === 'area' && editable) {
      newMode = drawRef.modes.DRAW_POLYGON;
    } else if (type === 'path' && editable) {
      newMode = drawRef.modes.DRAW_LINE_STRING;
    } else if (type === 'point' && editable) {
      newMode = drawRef.modes.DRAW_POINT;
    }

    drawRef.changeMode(newMode);
  };

  const addEventListeners = () => {
    mapRef.on('draw.create', handleDrawCreateEdit);
    mapRef.on('draw.update', handleDrawCreateEdit);
    mapRef.on('draw.delete', handleDrawDelete);
  };

  const removeEventListeners = () => {
    mapRef.off('draw.create', handleDrawCreateEdit);
    mapRef.off('draw.update', handleDrawCreateEdit);
    mapRef.off('draw.delete', handleDrawDelete);
  };

  const removeSourcesAndLayers = () => {
    layerList.current.forEach(safeRemoveLayer(mapRef)); // layers need to be removed before source
    sourceList.current.forEach(safeRemoveSource(mapRef));
    layerList.current = [];
    sourceList.current = [];
  };

  const handleDrawCreateEdit = (e: DrawCreateEvent | DrawUpdateEvent) => {
    const feature = e.features[0];
    const { id, geometry } = feature;
    const isPolygon = geometry.type === 'Polygon';
    const isLineString = geometry.type === 'LineString';
    const isPoint = geometry.type === 'Point';
    let updatedPoints = [];

    if (isPolygon) {
      const selectedPoint = drawRef.getSelectedPoints()?.features[0]
        ?.geometry as Point;
      updatedPoints = polygonToPoints(feature.geometry as Polygon);

      if (selectedPoint && snapRef.current) {
        const snappedPoint = snapPoint(selectedPoint, itemsToSnapTo, {
          threshold: 1,
          zoom: mapRef.getZoom(),
        });
        const index = updatedPoints.findIndex((point) => {
          return (
            JSON.stringify(point.coordinates) ===
            JSON.stringify(selectedPoint.coordinates)
          );
        });
        updatedPoints = updateAt(index, snappedPoint, updatedPoints);
        drawRef.set({
          type: 'FeatureCollection',
          features: [
            {
              id,
              ...pointsToFeaturePolygon(updatedPoints),
            },
          ],
        });
      }
    }

    if (isLineString) {
      const geometry = feature.geometry as LineString;
      updatedPoints = geometry.coordinates.map((coordinates) => {
        return {
          type: 'Point',
          coordinates,
        };
      });
      drawRef.set({
        type: 'FeatureCollection',
        features: [
          {
            id,
            ...pointsToFeatureLineString(updatedPoints),
          },
        ],
      });
    }

    if (isPoint) {
      updatedPoints = [feature.geometry];

      if (editable) {
        drawRef.changeMode(drawRef.modes.DRAW_POINT);
      }
    }

    // Re-calculate measurement labels
    if (!isPoint && showMeasurements && updatedPoints.length) {
      removeSourcesAndLayers();
      const { sourceIds, layerIds } = addLabelsToMap(
        mapRef,
        feature,
        getMeasurementLabel
      );
      sourceList.current = sourceIds;
      layerList.current = layerIds;
    }

    onChange(updatedPoints);
  };

  const handleDrawDelete = (_: DrawDeleteEvent) => {
    const featureCollection = drawRef.getAll();

    // deleted all features
    if (
      !(featureCollection?.features[0] as FeaturePolygon | FeatureLineString)
        ?.geometry?.coordinates?.length
    ) {
      removeSourcesAndLayers();
      setDrawModeFromProps();
      onChange([]);
      return;
    }

    const feature = featureCollection.features[0];
    const { geometry } = feature;
    const isPolygon = geometry.type === 'Polygon';
    const isLineString = geometry.type === 'LineString';
    let updatedPoints = [];

    if (isPolygon) {
      updatedPoints = polygonToPoints(geometry as Polygon);
    }

    if (isLineString) {
      updatedPoints = geometry.coordinates.map((coordinates) => {
        return {
          type: 'Point',
          coordinates,
        };
      });
    }

    onChange(updatedPoints);
  };

  // Mounted, set up event listeners and add initial points
  React.useEffect(() => {
    addEventListeners();

    if (points?.length) {
      const isPolygon = type === 'area';
      const isLineString = type === 'path';
      const isPoint = type === 'point';

      if (isPolygon || isLineString) {
        const feature = isPolygon
          ? pointsToFeaturePolygon(points)
          : pointsToFeatureLineString(points);
        if (feature) {
          const featureIds = drawRef.add(feature);

          if (editable) {
            drawRef.changeMode(drawRef.modes.DIRECT_SELECT, {
              featureId: featureIds[0],
            });
          }

          if (showMeasurements) {
            const { sourceIds, layerIds } = addLabelsToMap(
              mapRef,
              feature,
              getMeasurementLabel
            );
            sourceList.current = sourceIds;
            layerList.current = layerIds;
          }
        }
      } else if (isPoint) {
        const feature: FeaturePoint = {
          type: 'Feature',
          geometry: points[0],
          properties: {},
        };
        drawRef.add(feature);
      }
    }

    return () => {
      removeSourcesAndLayers();
      removeEventListeners();

      // causes a crash in some cases
      try {
        drawRef.deleteAll();
      } catch (err) {}
    };
  }, []);

  // Incoming points as props, update existing if changed
  React.useEffect(() => {
    const featureCollection = drawRef.getAll();
    const feature = featureCollection?.features[0];
    let drawPoints: Point[] = [];

    if (feature) {
      const { id, geometry } = feature;
      const isPolygon = typeRef.current === 'area';
      const isLineString = typeRef.current === 'path';
      const isPoint = typeRef.current === 'point';

      if (isPolygon || isLineString) {
        drawPoints = isPolygon
          ? polygonToPoints(geometry as Polygon)
          : (geometry as FeatureLineString['geometry']).coordinates.map(
              (coordinates) => {
                return {
                  type: 'Point',
                  coordinates,
                };
              }
            ) ?? [];

        // Check if rendered points differ to incoming points and update
        const hasChanged =
          drawPoints[0]?.coordinates?.length &&
          JSON.stringify(drawPoints) !== JSON.stringify(points);
        if (hasChanged) {
          const updatedFeature = isPolygon
            ? pointsToFeaturePolygon(points)
            : pointsToFeatureLineString(points);

          if (!updatedFeature) {
            console.log('no feature to update');
            return;
          }

          drawRef.set({
            type: 'FeatureCollection',
            features: [
              {
                ...updatedFeature,
                id,
              },
            ],
          });

          if (editable) {
            drawRef.changeMode(drawRef.modes.DIRECT_SELECT, {
              featureId: String(feature.id),
            });
          }

          if (showMeasurements) {
            removeSourcesAndLayers();
            const { sourceIds, layerIds } = addLabelsToMap(
              mapRef,
              drawRef.get(String(id)),
              getMeasurementLabel
            );
            sourceList.current = sourceIds;
            layerList.current = layerIds;
          }
        }
      }

      if (isPoint) {
        // @ts-ignore
        drawPoints = [geometry];
        const hasChanged =
          drawPoints[0]?.coordinates?.length &&
          JSON.stringify(drawPoints) !== JSON.stringify(points);
        if (hasChanged) {
          drawRef.set({
            type: 'FeatureCollection',
            features: [
              {
                id: id,
                type: 'Feature',
                geometry: {
                  type: 'Point',
                  coordinates: points[0]?.coordinates ?? [],
                },
                properties: {},
              },
            ],
          });

          if (editable) {
            drawRef.changeMode(drawRef.modes.DRAW_POINT);
          }
        }
      }
    }

    // All points have been removed
    if (!drawPoints.length) {
      drawRef.deleteAll();
      removeSourcesAndLayers();
      setDrawModeFromProps();
    }
  }, [JSON.stringify(points), type]);

  // Change drawing mode based on type and editable props
  React.useEffect(() => {
    setDrawModeFromProps();
  }, [type, editable]);

  // Update snap setting reference for callbacks
  React.useEffect(() => {
    snapRef.current = snap;
  }, [snap]);

  // Update type setting reference for callbacks and clear sources and layers if changing
  React.useEffect(() => {
    if (type !== typeRef.current) {
      drawRef.deleteAll();
      removeSourcesAndLayers();
      setDrawModeFromProps();
      onChange([]);
    }
    typeRef.current = type;
  }, [type]);

  return null;
};

export default MapDraw;
