import React from 'react';

import { i18nLanguages, I18nLocale } from '@mobble/i18n';
import { type User } from '@mobble/models';

import { FormBuilder, type FormBuilderProps } from '../Form/FormBuilder';

export interface SettingsUserFormProps {
  user: User;
  loading?: boolean;
  onSubmit: (formValues: SettingsUserFormValues) => void;
  onCancel: () => void;
}

export interface SettingsUserFormValues {
  name: string;
  locale: I18nLocale;
}

export const SettingsUserForm: React.FC<SettingsUserFormProps> = ({
  user,
  loading,
  onSubmit,
  onCancel,
}) => {
  const localeOptions = i18nLanguages.map((opt) => ({
    value: opt.code,
    label: opt.name,
  }));

  const form: FormBuilderProps<SettingsUserFormValues> = {
    flex: true,
    i18nRootKey: 'user.user_settings.profile.form',
    fields: [
      {
        name: 'name',
        type: 'text',
        required: true,
        initialValue: user.name,
      },
      {
        name: 'locale',
        type: 'select',
        options: localeOptions,
        initialValue: user.locale?.toLowerCase(),
      },
    ],
    loading,
    onSubmit,
    onCancel,
  };

  return <FormBuilder {...form} />;
};
