import React from 'react';
import { defineMessages } from 'react-intl';

import { useI18n, useMessages } from '@mobble/i18n';
import {
  mapAssetsTypes,
  MapAssetType,
  mapAssetTypeToGeometryFeatureType,
} from '@mobble/models/src/model/MapAsset';
import { type Point } from '@mobble/models/src/model/MapGeometry';

import { mapAssetTypeToMode } from '../../Map/Plugins/Creator';
import { FormBuilder, type FormBuilderProps } from '../Form/FormBuilder';

export interface MapAssetCreateFormProps {
  error?: string;
  loading?: boolean;
  initialValues?: Partial<MapAssetCreateFormValues>;

  onCancel: () => void;
  onTouched: (dirty: boolean) => void;
  onSubmit: (formValues: MapAssetCreateFormValues) => void;
}

export interface MapAssetCreateFormValues {
  name: string;
  description?: string;
  type?: MapAssetType;
  points?: Point[];
}

const messages = defineMessages({
  'map_assets.type.bore': {
    description: 'map_assets.type.bore',
    defaultMessage: 'Bore',
  },
  'map_assets.type.dam': {
    description: 'map_assets.type.dam',
    defaultMessage: 'Dam',
  },
  'map_assets.type.electric-fence': {
    description: 'map_assets.type.electric-fence',
    defaultMessage: 'Electric fence',
  },
  'map_assets.type.electric-fence-unit': {
    description: 'map_assets.type.electric-fence-unit',
    defaultMessage: 'Electric fence unit',
  },
  'map_assets.type.feeder': {
    description: 'map_assets.type.feeder',
    defaultMessage: 'Feeder',
  },
  'map_assets.type.gate': {
    description: 'map_assets.type.gate',
    defaultMessage: 'Gate',
  },
  'map_assets.type.hazard': {
    description: 'map_assets.type.hazard',
    defaultMessage: 'Hazard',
  },
  'map_assets.type.other-line': {
    description: 'map_assets.type.other-line',
    defaultMessage: 'Other line',
  },
  'map_assets.type.other-point': {
    description: 'map_assets.type.other-point',
    defaultMessage: 'Other point',
  },
  'map_assets.type.pipeline': {
    description: 'map_assets.type.pipeline',
    defaultMessage: 'Pipeline',
  },
  'map_assets.type.road': {
    description: 'map_assets.type.road',
    defaultMessage: 'Road',
  },
  'map_assets.type.shed': {
    description: 'map_assets.type.shed',
    defaultMessage: 'Shed',
  },
  'map_assets.type.silo': {
    description: 'map_assets.type.silo',
    defaultMessage: 'Silo',
  },
  'map_assets.type.tank': {
    description: 'map_assets.type.tank',
    defaultMessage: 'Tank',
  },
  'map_assets.type.water-trough': {
    description: 'map_assets.type.water-trough',
    defaultMessage: 'Water trough',
  },
});

export const MapAssetCreateForm: React.FC<MapAssetCreateFormProps> = ({
  error,
  loading,
  initialValues,
  onCancel,
  onTouched,
  onSubmit,
}) => {
  const strings = useMessages(messages);
  const { formatMessage } = useI18n();

  const typeToModeI18n = (type: MapAssetType) => {
    switch (mapAssetTypeToGeometryFeatureType(type)) {
      case 'Point':
        return formatMessage({
          defaultMessage: 'Point',
          description: 'map_asset.mode.point',
        });
      case 'LineString':
        return formatMessage({
          defaultMessage: 'Line',
          description: 'map_asset.mode.line_string',
        });
      default:
        return '';
    }
  };

  const [mapAssetType, setMapAssetType] = React.useState<MapAssetType>(
    (initialValues.type ?? MapAssetType.Bore) as MapAssetType
  );

  const mapAssetTypeOptions = mapAssetsTypes.map((value) => ({
    label: strings[`map_assets.type.${value}`],
    labelExtra: typeToModeI18n(value),
    value,
  }));

  const form: FormBuilderProps<MapAssetCreateFormValues> = {
    flex: true,
    i18nRootKey: 'map_assets.map_asset.create.form',
    fields: [
      { name: 'name', type: 'text', required: true },
      { name: 'description', type: 'textarea' },
      {
        name: 'type',
        type: 'select',
        required: true,
        options: mapAssetTypeOptions,
        initialValue: initialValues?.type,
      },
      {
        name: 'points',
        type: 'map-creator',
        required: true,
        mapCreator: {
          options: {
            single: mapAssetTypeToMode(mapAssetType) === 'point',
          },
        },
        initialValue: initialValues?.points,
      },
    ],
    error,
    loading,
    onChange: (val) => {
      setMapAssetType(val.type);
    },
    onSubmit,
    onCancel,
    onTouched,
  };

  return <FormBuilder {...form} />;
};
