import React from 'react';
import { defineMessages } from 'react-intl';
import * as Yup from 'yup';

import { Color } from '@mobble/colors';
import { useMessages } from '@mobble/i18n';
import {
  findMob,
  getLivestockAgeColor,
  type Mob,
} from '@mobble/models/src/model/Mob';
import { type Paddock } from '@mobble/models/src/model/Paddock';
import {
  type ConfiguredPropertyType,
  ConfiguredPropertyTypeGroup,
  type ConfiguredPropertyTypeGroupCustom,
  getLivestockAttributes,
  getLivestockParentIdForValue,
} from '@mobble/models/src/model/Property';

import { MobCard } from '@src/components';

import { Box } from '../../Components/Layout/Box';
import { Spinner } from '../../Components/UI/Spinner';
import { type InlineOptionProps } from '../../Components/UX/InlineOption';
import { FormBuilder, type FormBuilderProps } from '../Form/FormBuilder';

import styles from './paddockMergeMobForm.scss';

export interface PaddockmergeMobsForm {
  principalMob?: Mob | null;
  mobOptions: InlineOptionProps[];
  mobs: Mob[];
  initialValues: Partial<PaddockMergeMobsFormValues>;
  propertyTypes: ConfiguredPropertyType[];
  paddock: Paddock;
  error?: string;
  loading?: boolean;
  combinedSize: number;
  onCancel: () => void;
  onTouched: (dirty: boolean) => void;
  onSubmit: (formValues: PaddockMergeMobsFormValues) => void;
  onCreateCustomField: (
    group: ConfiguredPropertyTypeGroupCustom,
    livestockTypeId: string,
    label: string
  ) => Promise<void>;
  onSelectMobs: (mobIds: string[]) => void;
}

export interface PaddockMergeMobsFormValues {
  mobs: string[];
  classes: string[];
  ages: number[];
  dse: number;
  date: string;
}

const messages = defineMessages({
  addNewClassType: {
    defaultMessage: 'Add new class type',
    description:
      'mobs.mob.create.form.mob_form.classes.add_new.placeholder.label',
  },
  mobsValidationMessage: {
    defaultMessage: 'Must select at least two mobs to merge',
    description: 'Merge Mobs validation message',
  },
});

export const PaddockMergeMobsForm: React.FC<PaddockmergeMobsForm> = ({
  principalMob,
  mobOptions,
  mobs,
  initialValues,
  propertyTypes,
  paddock,
  combinedSize,
  error,
  loading,
  onCancel,
  onSubmit,
  onTouched,
  onCreateCustomField,
  onSelectMobs,
}) => {
  const strings = useMessages(messages);
  const getAgeColor = getLivestockAgeColor(propertyTypes, principalMob?.type);
  const handleCreateCustomField =
    (type: ConfiguredPropertyTypeGroupCustom) =>
    (value: string): Promise<void> => {
      if (!principalMob) {
        return Promise.reject();
      }
      const liveStockTypeId = getLivestockParentIdForValue(
        principalMob.type,
        propertyTypes
      );
      if (!liveStockTypeId) {
        return Promise.reject();
      }

      return onCreateCustomField(type, liveStockTypeId, value);
    };

  const form: FormBuilderProps<PaddockMergeMobsFormValues> = {
    flex: true,
    i18nRootKey: 'paddocks.paddock.merge_mobs.form',
    className: styles.paddockMergeMobsForm,
    fields: [
      {
        name: 'mobs',
        type: 'select-multiple',
        initialValue: initialValues.mobs,
        validation: Yup.array()
          .min(2, strings.mobsValidationMessage)
          .required(),
        options: mobOptions,
        onChange: (values) => {
          onSelectMobs(values.mobs as string[]);
          return values;
        },
      },
      {
        name: 'selected_mobs_preview',
        type: 'custom',
        component: ({ values }) => {
          return (
            <Box background={Color.LightGrey} spacing={2}>
              {values.mobs.map((mobId) => (
                <Box spacing={{ bottom: 1 }} key={mobId}>
                  {loading ? (
                    <Spinner />
                  ) : (
                    <MobCard
                      propertyTypes={propertyTypes}
                      paddockName={paddock?.name}
                      mob={findMob(mobs)(mobId) as Mob}
                    />
                  )}
                </Box>
              ))}
            </Box>
          );
        },
        containerComponent: ({ input }) => (
          <div className={styles.mobMergeWrapperContainer}>{input}</div>
        ),
      },
      {
        show: () => Boolean(principalMob),
        name: 'classes',
        type: 'select-multiple',
        initialValue: initialValues.classes,
        options: () => {
          if (!principalMob) {
            return [];
          }
          const parentId = propertyTypes.find(
            (a) => a.type === principalMob.type
          )?.id;
          return propertyTypes
            .filter(
              (a) =>
                a.group === ConfiguredPropertyTypeGroup.class &&
                a.parentId === parentId
            )
            .map((a) => ({
              label: a.label,
              value: a.type,
            }));
        },
        addNew: {
          onSubmit: handleCreateCustomField(ConfiguredPropertyTypeGroup.class),
          placeholder: strings.addNewClassType,
        },
      },
      {
        show: () => Boolean(principalMob),
        name: 'ages',
        type: 'select-multiple',
        initialValue: initialValues.ages,
        required: true,
        options: () =>
          principalMob
            ? getLivestockAttributes(propertyTypes)(
                ConfiguredPropertyTypeGroup.tag,
                getLivestockParentIdForValue(principalMob.type, propertyTypes)
              ).map((a) => ({
                ...a,
                value: Number(a.value),
                color: getAgeColor(a.label) || Color.White,
              }))
            : [],
        sortFunction: (options) =>
          options.sort((a: any, b: any) => b.label.localeCompare(a.label)),
      },
      {
        show: () => Boolean(principalMob),
        name: 'dse',
        type: 'float',
        initialValue: initialValues.dse,
        required: true,
      },
      {
        show: () => Boolean(principalMob),
        name: 'date',
        type: 'date',
        required: true,
        initialValue: new Date().toISOString(),
      },
      {
        show: () => Boolean(principalMob),
        name: 'new_mob_preview',
        type: 'custom',
        component: ({ values }) => {
          if (!principalMob) {
            return null;
          }
          return (
            <Box background={Color.Green} spacing={2}>
              <Box spacing={{ bottom: 1 }}>
                <MobCard
                  propertyTypes={propertyTypes}
                  paddockName={paddock?.name}
                  mob={{
                    ...principalMob,
                    size: combinedSize,
                    classes: values.classes,
                    ages: values.ages,
                    DSE: values.dse,
                  }}
                />
              </Box>
            </Box>
          );
        },
      },
    ],
    reinitialize: true,
    error,
    loading,
    onSubmit,
    onCancel,
    onTouched,
  };

  return <FormBuilder {...form} />;
};
