import React from 'react';

import { Color } from '@mobble/colors';
import { MapStyle, mapStyleToString } from '@mobble/models/src/model/MapStyle';

import { Clickable } from '@src/stories/Components/UX/Clickable';

import { Box } from '../../Components/Layout/Box';
import { Spacer } from '../../Components/Layout/Spacer';
import { Text } from '../../Components/UI/Text';

import styles from './settingsMapStyleButton.scss';

export interface SettingsMapStyleButtonProps {
  onClick: () => void;
  selected?: boolean;
  mapStyle: MapStyle;
}

export const SettingsMapStyleButton: React.FC<SettingsMapStyleButtonProps> = ({
  onClick,
  selected,
  mapStyle,
}) => {
  return (
    <Clickable className={styles.container} href={onClick}>
      <Box className={[styles.inner, selected ? styles.selected : null]}>
        <Box className={styles.imageContainer}>
          {(() => {
            switch (mapStyle) {
              case MapStyle.Satellite:
                return (
                  <img
                    src={require('../../../assets/images/mapbox-imagery.png')}
                  />
                );
              case MapStyle.SatelliteMapTiler:
                return (
                  <img
                    src={require('../../../assets/images/maptiler-imagery.png')}
                  />
                );
              case MapStyle.SatelliteHere:
                return (
                  <img
                    src={require('../../../assets/images/here-imagery.png')}
                  />
                );
              case MapStyle.Outdoor:
                return (
                  <img
                    src={require('../../../assets/images/mapbox-outdoor.png')}
                  />
                );
              case MapStyle.Street:
                return (
                  <img
                    src={require('../../../assets/images/mapbox-streets.png')}
                  />
                );
              case MapStyle.Light:
                return (
                  <img
                    src={require('../../../assets/images/mapbox-light.png')}
                  />
                );
            }
          })()}
        </Box>
      </Box>
      <Spacer y={1} />
      <Text
        color={selected ? Color.Green : Color.DarkGrey}
        variant="small"
        align="center"
        i18n={{ key: `map.map_style.${mapStyleToString(mapStyle)}.label` }}
      />
    </Clickable>
  );
};
