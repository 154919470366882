import React, {
  ComponentPropsWithoutRef,
  forwardRef,
  SyntheticEvent,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import classNames from 'classnames/bind';

import { useOnClickOutside } from '@src/hooks/useOnClickOutside';

import { ButtonProps } from '../Button';

import DialogFooter, { DialogFooterProps } from './Footer';
import DialogHeader, { DialogHeaderProps } from './Header';

import styles from './Dialog.scss';
const cx = classNames.bind(styles);

type PickedProps =
  | 'id'
  | 'children'
  | 'onBlur'
  | 'aria-label'
  | 'role'
  | 'className'
  | 'style';

type DialogSize = 'medium' | 'large';

type DialogDrawerPosition = 'top' | 'right' | 'bottom' | 'left';

export interface DialogProps
  extends Pick<ComponentPropsWithoutRef<'dialog'>, PickedProps> {
  /**
   * Controls visibility of modal
   */
  isOpen: boolean;

  /**
   * Dialog will behave like a modal window.
   * If set to `false` you must manually position the dialog
   */
  isModal?: boolean;

  /**
   * Boolean indicating if clicking outside the dialog
   * or the overlay (for modal dialogs), default is true
   */
  shouldCloseOnOutsideClick?: boolean;

  /**
   * Boolean indicating if pressing the esc key should close the modal
   * Only applies to modal dialogs, default is true
   * Note: By disabling the esc key from closing the modal
   * you may introduce an accessibility issue.
   */
  shouldCloseOnEsc?: boolean;

  /**
   * Sets the default width of the dialog, defaults to `medium`
   * Only applies to non-drawer dialogs
   */
  size?: DialogSize;

  /**
   * Displays the Dialog as a drawer and sets the position to open from
   */
  drawerPosition?: DialogDrawerPosition;

  /**
   * Pass props to render a Dialog Header
   */
  header?: DialogHeaderProps;

  /**
   * Custom elements to render in in the main section of the dialog
   */
  content?: ComponentPropsWithoutRef<'dialog'>['children'];

  /**
   * Pass props to render a Dialog Footer
   */
  footer?: DialogFooterProps;

  /**
   * Function that will be run when the modal is requested
   * to be closed (either by clicking on overlay, pressing ESC
   * or clicking the 'Close' button in footer (if present).
   * Note: It is not called if `isOpen` is changed by other means.
   */
  onRequestClose?: () => void;
}

/**
 * Dialog is a modern, accessible way to display dialogs or "modals".
 * See: https://developer.mozilla.org/en-US/docs/Web/HTML/Element/dialog
 */
const Dialog = forwardRef<HTMLDialogElement, DialogProps>(
  (
    {
      isOpen = false,
      isModal = true,
      shouldCloseOnEsc = true,
      shouldCloseOnOutsideClick = true,
      size = 'medium',
      header,
      content,
      footer,
      drawerPosition,
      children,
      onRequestClose,
      className,
      ...others
    },
    dialogRef
  ) => {
    const ref = useRef<HTMLDialogElement>(null);
    // Expose dialog ref to parent component
    useImperativeHandle(dialogRef, () => ref?.current as HTMLDialogElement);
    const contentRef = useRef<HTMLDivElement>(null);
    const [contentIsScrollable, setContentIsScrollable] = useState(false);
    const hasCloseProp = typeof onRequestClose === 'function';

    const rootClasses = cx(
      {
        Dialog: true,
        [size]: size !== 'medium',
        isOpen: isOpen,
        [drawerPosition]: !!drawerPosition,
      },
      className
    );

    // Close non-modal dialog when clicked outside
    useOnClickOutside(ref, () => {
      if (isOpen && !isModal && hasCloseProp && shouldCloseOnOutsideClick) {
        onRequestClose();
      }
    });

    // display a border on the footer if content is scrollable
    useEffect(() => {
      if (contentRef.current && isOpen) {
        window.requestAnimationFrame(() => {
          if (
            contentRef.current?.scrollHeight &&
            contentRef.current?.scrollHeight > contentRef.current?.offsetHeight
          ) {
            setContentIsScrollable(true);
          }
        });
      }
    }, [contentRef, isOpen]);


    useEffect(() => {
      const dialog = ref.current;

      if (!dialog) return;

      const handleOpen = () => {
        if (isModal) {
          dialog.showModal();
          document.body.classList.add('modal-open');
        } else {
          dialog.show();
        }
      };

      const handleClose = () => {
        dialog.close();
        if (isModal) {
          document.body.classList.remove('modal-open');
        }
      };

      if (isOpen) {
        if (!dialog.open) {
          handleOpen();
        }
      } else {
        if (dialog.open) {
          handleClose();
        }
      }

      return () => {
        if (isModal) {
          document.body.classList.remove('modal-open');
        }
      };
    }, [isOpen, isModal]);

    const handleClick = (e: React.MouseEvent<HTMLDialogElement>) => {
      const target = e.target as HTMLDialogElement;
      const rect = target.getBoundingClientRect();
      if (
        rect.left > e.clientX ||
        rect.right < e.clientX ||
        rect.top > e.clientY ||
        rect.bottom < e.clientY
      ) {
        // overlay mask clicked
        if (shouldCloseOnOutsideClick && hasCloseProp) {
          onRequestClose();
        }
      }
    };

    // esc key pressed
    const handleCancel = (e: SyntheticEvent) => {
      if (!shouldCloseOnEsc) {
        e.preventDefault();
      } else if (hasCloseProp) {
        onRequestClose();
      }
    };

    const handleRequestClose: ButtonProps['onClick'] = () => {
      if (hasCloseProp) {
        onRequestClose();
      }
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLDialogElement>) => {
      if (e.key === 'Escape' && !isModal && hasCloseProp) {
        handleCancel(e);
      }
    };

    return (
      <dialog
        ref={ref}
        className={rootClasses}
        onCancel={handleCancel}
        onClick={handleClick}
        onKeyDown={handleKeyDown}
        {...others}
      >
        {header && (
          <DialogHeader onCloseClick={handleRequestClose} {...header} />
        )}

        {content && <section ref={contentRef}>{content}</section>}
        {children}

        {footer && (
          <DialogFooter
            showBorder={contentIsScrollable}
            onCloseClick={handleRequestClose}
            {...footer}
          />
        )}
      </dialog>
    );
  }
);

Dialog.displayName = 'Dialog';

export default Dialog;
