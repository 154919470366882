import React from 'react';

import { Color, colorToHex } from '@mobble/colors';
import { useI18n } from '@mobble/i18n';
import {
  getRainGaugeTotalsPerMonthForYearInMm,
  getRainGaugeYearsAvailable,
  RainGauge,
} from '@mobble/models/src/model/RainGauge';
import { getMonths } from '@mobble/shared/src/core/Date';
import { makeQuantityOfLength } from '@mobble/shared/src/core/Quantity';

import { Bar, Datum } from '@src/stories/Components/Charts/Bar';
import { chartColors } from '@src/stories/Components/Charts/config';
import { quantityToLocaleI18nItem } from '@src/stories/Components/Locale/LocaleQuantity';

export interface ChartRainGaugeMonthlyRainfallProps {
  rainGauge: RainGauge;
  year?: number;
  height?: string;
}

const COLORS = [
  colorToHex(Color.Blue),
  colorToHex(Color.Blue, 0.8),
  colorToHex(Color.Blue, 0.4),
  colorToHex(Color.Blue, 0.2),
  ...chartColors,
];

export const ChartRainGaugeMonthlyRainfall: React.FC<
  ChartRainGaugeMonthlyRainfallProps
> = ({ rainGauge, year, height }) => {
  const { translate } = useI18n();
  const months = getMonths({ short: true });
  const years = year ? [year] : getRainGaugeYearsAvailable(rainGauge);
  const readingsPerYearPerMonth = years.reduce((acc, y) => {
    const { readingsPerMonth } =
      getRainGaugeTotalsPerMonthForYearInMm(rainGauge)(y);
    return { ...acc, [y]: readingsPerMonth };
  }, {});

  const [maxValue, barData]: [number, Datum[]] = new Array(12)
    .fill(null)
    .reduce(
      (acc, _, index) => {
        const [max, values] = years.reduce(
          (acc, y) => {
            const val = readingsPerYearPerMonth[y][index].total;
            return [
              Math.max(acc[0], val),
              [...acc[1], { label: y, value: val }],
            ];
          },
          [0, []]
        );

        return [
          Math.max(acc[0], max),
          [
            ...acc[1],
            {
              index: months[index],
              values,
            },
          ],
        ];
      },
      [0, []]
    );

  const color = (a) => {
    if (years.length === 1) {
      return colorToHex(Color.Blue, 0.65 + (0.35 / maxValue) * a.value);
    }
    const index = years.length - 1 - years.indexOf(a.id);
    return COLORS[index % COLORS.length];
  };

  return (
    <Bar
      showLegend={years.length > 1}
      grouped
      data={barData}
      barStyle="valueOnTop"
      color={color}
      height={height}
      formatValue={(mm) => {
        const q_mm = makeQuantityOfLength('mm', mm);
        const i8nItem = quantityToLocaleI18nItem(q_mm);
        return translate(i8nItem);
      }}
      formatLabelValue={String}
    />
  );
};
