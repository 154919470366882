import React from 'react';

import { useI18n } from '@mobble/i18n';
import {
  type MapAsset,
  MapAssetType,
  mapAssetTypeToGeometryFeatureType,
} from '@mobble/models/src/model/MapAsset';
import { pointsToLineString } from '@mobble/models/src/model/MapGeometry';
import { useEntitiesRefresher } from '@mobble/shared/src/hooks/useEntitiesRefresher';
import { useMapAsset } from '@mobble/store/src/hooks/mapAssets';
import { useProperties } from '@mobble/store/src/hooks/properties';
import { useExtStatus } from '@mobble/store/src/hooks/root';

import { useFormError } from '@src/hooks/useFormError';
import { useLinking } from '@src/hooks/useLinking';
import { useNavigateBack } from '@src/hooks/useNavigateBack';
import { toPath } from '@src/interfaces/Routing';
import * as ROUTE_NAME from '@src/screens/config/routeNames';
import { type ScreenRendererProps } from '@src/screens/config/types';
import { Box } from '@src/stories/Components/Layout/Box';
import { DeleteForm } from '@src/stories/Views/Form/DeleteForm/DeleteForm';
import {
  MapAssetEditForm,
  type MapAssetEditFormValues,
} from '@src/stories/Views/MapAsset/MapAssetEditForm';
import { EntitySliceFactoryPrelude } from '@src/stories/Views/Misc/EntitySliceFactoryPrelude';
import { ScreenHeader } from '@src/stories/Views/Misc/ScreenHeader';

export const MapAssetEditHeader: React.FC<ScreenRendererProps> = (props) => {
  const { formatMessage } = useI18n();
  const { mapAssetId = '' } = props.route.params;
  const mapAsset = useMapAsset(mapAssetId);
  const goBack = useNavigateBack();

  return (
    <ScreenHeader
      title={formatMessage(
        {
          id: 'screen.title.map_asset_edit',
          defaultMessage: 'Edit {MAP_ASSET_NAME}',
        },
        {
          MAP_ASSET_NAME: mapAsset.entity?.name,
        }
      )}
      breadcrumbs={[
        {
          title: formatMessage({
            description: 'screen.title.summaries',
            defaultMessage: 'Summaries',
          }),
          href: ROUTE_NAME.SUMMARIES_LIST,
        },
        {
          title: { key: 'screen.title.map_assets' },
          href: ROUTE_NAME.MAP_ASSETS_LIST,
        },
        {
          title: mapAsset?.entity?.name || '',
          href: toPath(ROUTE_NAME.MAP_ASSET_DETAIL, { mapAssetId }),
        },
      ]}
      onGoBack={goBack}
    />
  );
};

export const MapAssetEdit: React.FC<ScreenRendererProps> = (props) => {
  const { mapAssetId = '' } = props.route.params;
  const { formatMessage } = useI18n();
  const linkTo = useLinking();
  const goBack = useNavigateBack();
  const properties = useProperties();
  const propertyId = properties.selected?.id;
  const mapAsset = useMapAsset(mapAssetId);

  const extStatus = useExtStatus('mapAssets', 'update', mapAssetId);
  const formLoading = extStatus?.loading;
  const extStatusDelete = useExtStatus('mapAssets', 'delete', mapAssetId);
  const deleteLoading = extStatusDelete?.loading;
  const formError = useFormError({
    entityName: formatMessage({
      defaultMessage: 'Map Asset',
      description: 'screen.title.map_asset_detail',
    }),
    active: Boolean(extStatus?.error),
  });

  useEntitiesRefresher([mapAsset], propertyId);

  const Prelude = EntitySliceFactoryPrelude({
    preludes: [properties.prelude, mapAsset.prelude],
    required: [properties.selected],
    notFound: [mapAsset.entity],
  });

  if (Prelude) {
    return Prelude;
  }

  const handleSubmit = (formValues: MapAssetEditFormValues) => {
    if (!properties.selected || !mapAsset.entity) {
      return Promise.reject();
    }

    const featureType = mapAssetTypeToGeometryFeatureType(
      formValues.type as MapAssetType
    );

    const geometry =
      featureType === 'Point'
        ? formValues.points![0]
        : pointsToLineString(formValues.points ?? []);

    const updatedMapAsset = {
      ...mapAsset.entity,
      propertyId: properties.selected.id,
      name: formValues.name,
      description: formValues.description ?? '',
      map: {
        type: formValues.type as MapAssetType,
        geometry,
      },
    } as MapAsset;

    mapAsset
      .update(updatedMapAsset)
      .then(() => {
        goBack();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleDelete = () => {
    mapAsset
      .remove()
      .then(() => {
        linkTo(ROUTE_NAME.MAP_OVERVIEW);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Box>
      <MapAssetEditForm
        mapAsset={mapAsset.entity}
        error={formError}
        loading={formLoading}
        onCancel={goBack}
        onTouched={() => {}}
        onSubmit={handleSubmit}
      />

      <DeleteForm
        i18nRootKey="map_assets.map_asset.delete"
        loading={deleteLoading}
        onDelete={handleDelete}
      />
    </Box>
  );
};

export default {
  name: ROUTE_NAME.MAP_ASSET_EDIT,
  header: MapAssetEditHeader,
  component: MapAssetEdit,
};
