import React from 'react';

import { Box } from './Box';
import { Spacer } from './Spacer';
import { type Cardinal,type Spacing } from './Spacing';

import styles from './stack.scss';

export interface VStackProps {
  flex?: boolean;
  alignment?: 'start' | 'center' | 'end';
  children?: React.ReactNode;
  spacing?: Spacing | Cardinal<Spacing>;
  spacingAfter?: Spacing;
  fullHeight?: boolean;
  className?: string;
}

export const VStack: React.FC<VStackProps> = ({
  flex,
  alignment,
  children,
  spacing,
  spacingAfter,
  fullHeight,
  className,
}) => {
  const classNames = [
    styles.vStack,
    alignment ? styles[`alignment-${alignment}`] : null,
    fullHeight ? styles.fullHeight : null,
    className,
  ]
    .filter(Boolean)
    .join(' ');
  const x =
    typeof spacing === 'number'
      ? spacing
      : spacing?.left ?? spacing?.right ?? spacingAfter ?? null;
  return (
    <Box className={classNames} flex={flex} fill spacing={spacing}>
      {x
        ? React.Children.map(children, (child) => (
            <>
              <Spacer x={x} />
              <Box>{child}</Box>
              <Spacer x={x} />
            </>
          ))
        : children}
    </Box>
  );
};

export interface HStackProps {
  flex?: boolean;
  alignment?: 'top' | 'center' | 'bottom' | 'justify';
  children?: React.ReactNode;
  spacing?: Spacing | Cardinal<Spacing>;
  wrap?: boolean;
  className?: string;
}

export const HStack: React.FC<HStackProps> = ({
  flex = true,
  alignment,
  children,
  spacing,
  wrap,
  className,
}) => {
  const classNames = [
    styles.hStack,
    alignment ? styles[`alignment-${alignment}`] : null,
    wrap ? styles.wrap : null,
    className,
  ]
    .filter(Boolean)
    .join(' ');

  const y =
    typeof spacing === 'number'
      ? spacing
      : spacing?.top ?? spacing?.bottom ?? 0;

  return (
    <Box className={classNames} flex={flex}>
      {spacing
        ? React.Children.map(children, (child) => (
            <Box>
              <Spacer y={y} flex />
              {child}
              <Spacer y={y} flex />
            </Box>
          ))
        : children}
    </Box>
  );
};
