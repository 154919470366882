import React from 'react';

import { Color } from '@mobble/colors';
import { type I18nItem } from '@mobble/i18n';

import { Box, type BoxProps } from '../Layout/Box';
import { Spacer } from '../Layout/Spacer';
import { Icon, type IconName } from '../UI/Icon';
import { Spinner } from '../UI/Spinner';
import { Text } from '../UI/Text';

import styles from './button.scss';

export type ButtonProps = BoxProps & {
  intent?: 'primary' | 'secondary' | 'destructive';
  outline?: boolean;
  flex?: boolean;
  label: I18nItem | string;
  size?: 'small' | 'normal';
  icon?: IconName;
  iconAfter?: IconName;
  loading?: boolean;
  disabled?: boolean;
  type?: 'button' | 'submit' | 'reset';
  onClick: () => void;
};

const textColorScheme = {
  primary: Color.White,
  secondary: Color.White,
  destructive: Color.White,
  outline_primary: Color.Green,
  outline_secondary: Color.Purple,
  outline_destructive: Color.Red,
  outline_disabled_primary: Color.Green,
  outline_disabled_secondary: Color.Purple,
  outline_disabled_destructive: Color.Red,
  disabled: Color.White,
};

export const Button: React.FC<ButtonProps> = ({
  intent = 'primary',
  size = 'normal',
  type = 'button',
  flex,
  outline,
  icon,
  iconAfter,
  onClick,
  disabled,
  loading,
  label,
}) => {
  const textColor = (() => {
    if (outline) {
      if (disabled) {
        return textColorScheme[`outline_disabled_${intent}`];
      }
      return textColorScheme[`outline_${intent}`];
    }
    if (disabled) {
      return textColorScheme.disabled;
    }
    return textColorScheme[intent];
  })();

  const classNames = [
    styles.button,
    styles[`button-${size}`],
    styles[`button-${intent}`],
    outline ? styles.outline : null,
    disabled ? styles.disabled : null,
    flex ? styles.flex : null,
    loading ? styles.buttonLoading : null,
  ]
    .filter(Boolean)
    .join(' ');

  return (
    <button
      disabled={disabled || loading}
      className={classNames}
      onClick={() => !disabled && onClick()}
      type={type}
    >
      {loading && (
        <Box className={styles.spinnerContainer}>
          <Spinner color={textColor} />
        </Box>
      )}
      <Box className={styles.contentContainer}>
        {label ? <Spacer flex /> : null}

        {icon && (
          <>
            <Icon name={icon} color={textColor} size="small" />
            {label ? <Spacer x={1} /> : null}
          </>
        )}

        {label ? (
          <Text
            bold
            color={textColor}
            i18n={typeof label === 'object' ? label : undefined}
          >
            {typeof label === 'string' ? label : undefined}
          </Text>
        ) : null}

        {iconAfter && (
          <>
            <Spacer x={1} />
            <Icon name={iconAfter} color={textColor} size="small" />
          </>
        )}

        {label ? <Spacer flex /> : null}
      </Box>
    </button>
  );
};
