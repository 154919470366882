import React from 'react';

import { Color } from '@mobble/colors';
import { type MobAction } from '@mobble/models/src/model/MobAction';

import { Clickable } from '@src/stories/Components/UX/Clickable';

import { Spacer } from '../../Components/Layout/Spacer';
import { HStack } from '../../Components/Layout/Stack';
import { Avatar } from '../../Components/UI/Avatar';
import { Text } from '../../Components/UI/Text';
import { TimelineEvent } from '../../Components/UI/TimelineEvent';

interface MobActionCardProps {
  mobAction: MobAction;
  last?: boolean;
  onClick: (mobAction: MobAction) => void;
}

export const MobActionCard: React.FC<MobActionCardProps> = ({
  mobAction,
  onClick,
  last,
}) => {
  return (
    <Clickable fullWidth href={() => onClick(mobAction)}>
      <TimelineEvent
        title={mobAction.title}
        date={mobAction.date}
        last={last}
        color={Color.Purple}
      >
        <Text color={Color.DarkGrey}>{mobAction.description}</Text>
        <Spacer y={1} />
        <HStack>
          <Avatar size="tiny" name={mobAction.createdBy.name} />
          <Spacer x={0.5} />
          <Text color={Color.Grey} variant="tiny">
            {mobAction.createdBy.name}
          </Text>
        </HStack>
      </TimelineEvent>
    </Clickable>
  );
};
