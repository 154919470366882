import React from 'react';

import { useI18n } from '@mobble/i18n';
import { useEntitiesRefresher } from '@mobble/shared/src/hooks/useEntitiesRefresher';
import {
  useCustomMapLayer,
  useCustomMapLayers,
  useProperties,
} from '@mobble/store/src/hooks';

import { useNavigateBack } from '@src/hooks/useNavigateBack';
import { toPath } from '@src/interfaces/Routing';
import { Box } from '@src/stories/Components/Layout/Box';
import { CustomMapLayerInfoTable } from '@src/stories/Views/CustomMapLayer/CustomMapLayerInfoTable';
import { EntitySliceFactoryPrelude } from '@src/stories/Views/Misc/EntitySliceFactoryPrelude';
import { ScreenHeader } from '@src/stories/Views/Misc/ScreenHeader';

import * as ROUTE_NAME from '../config/routeNames';
import { type ScreenRendererProps } from '../config/types';

import styles from './customMapLayerDetail.scss';

export const CustomMapLayerDetailHeader: React.FC<ScreenRendererProps> = (
  props
) => {
  const { customMapLayerId = '' } = props.route.params;
  const { formatMessage } = useI18n();
  const goBack = useNavigateBack();

  const properties = useProperties();
  const propertyId = properties.selected?.id;
  const customMapLayers = useCustomMapLayers(propertyId);
  const customMapLayer = useCustomMapLayer(customMapLayerId, propertyId);
  const customMapLayerName = customMapLayer.entity?.name;

  const quickLinks = customMapLayers.entities
    .map((l) => ({
      title: l.name,
      href: toPath(ROUTE_NAME.CUSTOM_MAP_LAYER_DETAIL, {
        customMapLayerId: l.id,
      }),
    }))
    .sort((a, b) => a.title.localeCompare(b.title));

  return (
    <ScreenHeader
      title={
        customMapLayerName ||
        formatMessage({
          defaultMessage: 'Custom map layer',
          description: 'screen.title.custom_map_layer_detail',
        })
      }
      breadcrumbs={[
        {
          title: formatMessage({
            description: 'screen.title.settings',
            defaultMessage: 'Settings',
          }),
          href: ROUTE_NAME.SETTINGS_LIST,
        },
        {
          title: formatMessage({
            description: 'screen.title.settings_property_custom_map_layers',
            defaultMessage: 'Custom map layers',
          }),
          href: ROUTE_NAME.SETTINGS_PROPERTY_CUSTOM_MAP_LAYERS,
        },
      ]}
      quickLinks={quickLinks}
      actions={[
        {
          label: formatMessage(
            {
              defaultMessage: 'Edit "{TITLE}"',
              description: 'screen.title.edit_x',
            },
            {
              TITLE: customMapLayerName,
            }
          ),
          icon: 'edit',
          href: toPath(ROUTE_NAME.CUSTOM_MAP_LAYER_EDIT, { customMapLayerId }),
        },
      ]}
      onGoBack={goBack}
    />
  );
};

export const CustomMapLayerDetail: React.FC<ScreenRendererProps> = (props) => {
  const { customMapLayerId = '' } = props.route.params;
  const properties = useProperties();
  const propertyId = properties.selected?.id;
  const customMapLayer = useCustomMapLayer(customMapLayerId, propertyId);

  useEntitiesRefresher([customMapLayer], propertyId);

  const Prelude = EntitySliceFactoryPrelude({
    preludes: [properties.prelude, customMapLayer.prelude],
    required: [properties.selected?.types],
    notFound: [customMapLayer.entity],
  });

  if (Prelude) {
    return Prelude;
  }

  return (
    <Box className={styles.customMapLayerTabTable}>
      <CustomMapLayerInfoTable customMapLayer={customMapLayer.entity} />
    </Box>
  );
};

export default {
  name: ROUTE_NAME.CUSTOM_MAP_LAYER_DETAIL,
  header: CustomMapLayerDetailHeader,
  component: CustomMapLayerDetail,
};
