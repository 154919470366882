import { defineMessages } from 'react-intl';

import { useMessages } from '@mobble/i18n';
import { type IconName } from '@mobble/icon';
import { toMobDisplayName } from '@mobble/models/src/model/Mob';
import { useEntitiesRefresher } from '@mobble/shared/src/hooks/useEntitiesRefresher';
import {
  usePaddock,
  usePaddocks,
  useProperties,
  usePurchase,
  usePurchases,
} from '@mobble/store/src/hooks';

import { useNavigateBack } from '@src/hooks/useNavigateBack';
import { toPath } from '@src/interfaces/Routing';
import { EntitySliceFactoryPrelude } from '@src/stories/Views/Misc/EntitySliceFactoryPrelude';
import { ScreenHeaderProps } from '@src/stories/Views/Misc/ScreenHeader';

import * as ROUTE_NAME from '../config/routeNames';

const messages = defineMessages({
  purchaseDetail: {
    defaultMessage: 'Purchase',
    description: 'screen.title.purchase_detail',
  },
  purchaseEdit: {
    defaultMessage: 'Edit Purchase',
    description: 'screen.title.purchase_edit',
  },
  summaries: {
    defaultMessage: 'Summaries',
    description: 'screen.title.summaries',
  },
  purchases: {
    description: 'screen.title.summary_purchases',
    defaultMessage: 'Purchases',
  },
});

export const usePurchaseScreenHeader = (
  purchaseId: string,
  isEdit?: boolean
): Pick<
  ScreenHeaderProps,
  'title' | 'breadcrumbs' | 'quickLinks' | 'actions' | 'onGoBack'
> => {
  const strings = useMessages(messages);
  const onGoBack = useNavigateBack();
  const properties = useProperties();
  const propertyId = properties.selected?.id;
  const purchases = usePurchases(propertyId);
  const purchase = usePurchase(purchaseId, properties.selected?.id);

  const purchaseName = purchase.entity
    ? toMobDisplayName(purchase.entity.mob)
    : strings.purchaseDetail;

  const title = !isEdit ? purchaseName : strings.purchaseEdit;

  const breadcrumbs: ScreenHeaderProps['breadcrumbs'] = [
    {
      title: strings.summaries,
      href: ROUTE_NAME.SUMMARIES_LIST,
    },
    {
      title: strings.purchases,
      href: ROUTE_NAME.SUMMARY_PURCHASES,
    },
  ];

  if (isEdit) {
    breadcrumbs.push({
      title: purchaseName,
      href: toPath(ROUTE_NAME.PURCHASE_DETAIL, {
        purchaseId,
      }),
    });
  }

  const quickLinks = !isEdit
    ? purchases.entities
        .map((p) => ({
          title: toMobDisplayName(p.mob),
          href: toPath(ROUTE_NAME.PURCHASE_DETAIL, {
            purchaseId: p.id,
          }),
        }))
        .sort((a, b) => a.title.localeCompare(b.title))
    : undefined;

  const actions: ScreenHeaderProps['actions'] = !isEdit
    ? [
        {
          icon: 'edit' as IconName,
          label: strings.purchaseEdit,
          href: toPath(ROUTE_NAME.PURCHASE_EDIT, {
            purchaseId,
          }),
        },
      ]
    : undefined;

  return { title, breadcrumbs, quickLinks, actions, onGoBack };
};

export const usePurchaseScreen = (purchaseId: string) => {
  const properties = useProperties();
  const propertyId = properties.selected?.id;
  const purchase = usePurchase(purchaseId, properties.selected?.id);
  const paddocks = usePaddocks(propertyId);
  const paddock = usePaddock(purchase.entity?.paddock);

  useEntitiesRefresher([purchase, paddock], propertyId);

  const Prelude = EntitySliceFactoryPrelude({
    preludes: [properties.prelude, purchase.prelude, paddocks.prelude],
    required: [properties.selected],
    notFound: [purchase.entity],
  });

  return { Prelude, purchase, paddock, paddocks, properties };
};
