import React from 'react';

import { type Point } from '@mobble/models/src/model/MapGeometry';
import {
  type SortOption,
  type SortSetting,
} from '@mobble/models/src/model/Sort';
import { type Task } from '@mobble/models/src/model/Task';
import { type User } from '@mobble/models/src/model/User';
import { toISO8601 } from '@mobble/shared/src/core/Date';

import { type ListSelectOption } from '@src/components/ListSelect';

import { FormBuilder, type FormBuilderProps } from '../Form/FormBuilder';

export interface TaskEditFormProps {
  task: Task;
  paddockOptions: ListSelectOption[];
  paddockSortOptions: SortOption[];
  sortPaddockOptions: (
    options: ListSelectOption[],
    sortSettings: SortSetting[]
  ) => ListSelectOption[];
  users: User[];
  error?: string;
  loading?: boolean;
  findPaddockIdForPoint: (point: Point) => undefined | string;
  onCancel: () => void;
  onTouched: (dirty: boolean) => void;
  onSubmit: (formValues: TaskEditFormValues) => void;
}

export interface TaskEditFormValues {
  title: string;
  description?: string;
  due_date?: string;
  assigned_to?: string[];
  paddock_id?: string;
  location?: null | Point;
}

export const TaskEditForm: React.FC<TaskEditFormProps> = ({
  task,
  paddockOptions,
  paddockSortOptions,
  sortPaddockOptions,
  users,
  error,
  loading,
  findPaddockIdForPoint,
  onCancel,
  onTouched,
  onSubmit,
}) => {
  const handleChange = (
    values: TaskEditFormValues,
    _: any,
    setValues: (values: TaskEditFormValues) => void
  ) => {
    if (values.location) {
      setValues({
        ...values,
        paddock_id: findPaddockIdForPoint(values.location),
      });
    }
  };

  const form: FormBuilderProps<TaskEditFormValues> = {
    flex: true,
    i18nRootKey: 'tasks.task.edit.form',
    fields: [
      { name: 'title', type: 'text', required: true, initialValue: task.title },
      { name: 'description', type: 'textarea', initialValue: task.description },
      {
        name: 'due_date',
        type: 'date',
        initialValue: task.dueDate ? toISO8601(task.dueDate) : undefined,
      },
      {
        name: 'assigned_to',
        type: 'select-multiple',
        options: users
          .map((user) => ({
            label: user.name,
            value: user.id,
          }))
          .sort((a, b) => a.label.localeCompare(b.label)),
        initialValue: task.assignedToUsers?.map((a) => a.id),
      },
      {
        name: 'paddock_id',
        type: 'select',
        options: paddockOptions,
        sortOptions: paddockSortOptions,
        sortFunction: sortPaddockOptions,
        initialValue: task.paddockId,
      },
      {
        name: 'location',
        type: 'location',
        required: false,
        initialValue: task.location,
      },
    ],
    onChange: handleChange,
    error,
    loading,
    onSubmit,
    onCancel,
    onTouched,
  };

  return <FormBuilder {...form} />;
};
