import React from 'react';

import { useI18n } from '@mobble/i18n';
import { useAuthentication } from '@mobble/shared/src/hooks/useAuthentication';
import { useGetUser, useUpdateUser } from '@mobble/store/src/hooks/auth';

import { useLinking } from '@src/hooks/useLinking';
import * as ROUTE_NAME from '@src/screens/config/routeNames';
import { ScreenRendererProps } from '@src/screens/config/types';
import { Box } from '@src/stories/Components/Layout/Box';
import { Avatar } from '@src/stories/Components/UI/Avatar';
import { EntitySliceFactoryPrelude } from '@src/stories/Views/Misc/EntitySliceFactoryPrelude';
import { ScreenHeader } from '@src/stories/Views/Misc/ScreenHeader';
import { SettingsSignOutForm } from '@src/stories/Views/Settings/SettingsSignOutForm';
import {
  SettingsUserForm,
  type SettingsUserFormValues,
} from '@src/stories/Views/Settings/SettingsUserForm';

import styles from './settingsUser.scss';

export const SettingsUserHeader: React.FC<ScreenRendererProps> = () => {
  const { formatMessage } = useI18n();
  const linkTo = useLinking();

  return (
    <ScreenHeader
      title={formatMessage({
        description: 'screen.title.settings_user',
        defaultMessage: 'Profile',
      })}
      breadcrumbs={[
        {
          title: formatMessage({
            description: 'screen.title.settings',
            defaultMessage: 'Settings',
          }),
          href: ROUTE_NAME.SETTINGS_LIST,
        },
      ]}
      onGoBack={() => {
        linkTo(ROUTE_NAME.SETTINGS_LIST);
      }}
    />
  );
};

export const SettingsUser: React.FC<ScreenRendererProps> = () => {
  const user = useGetUser();
  const { signOut } = useAuthentication();
  const linkTo = useLinking();
  const { updateUser } = useUpdateUser();
  const [formLoading, setFormLoading] = React.useState(false);

  const Prelude = EntitySliceFactoryPrelude({
    preludes: [],
    required: [user],
  });

  if (Prelude) {
    return Prelude;
  }

  const handleCancel = () => {
    linkTo(ROUTE_NAME.SETTINGS_LIST);
  };

  const handleSubmitProfile = ({ name, locale }: SettingsUserFormValues) => {
    if (!user) {
      return;
    }

    setFormLoading(true);

    updateUser({
      ...user,
      name,
      locale,
    })
      .then(() => {
        setFormLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <Box className={styles.avatarContainer}>
        <Avatar name={user.name} />
      </Box>

      <SettingsUserForm
        loading={formLoading}
        user={user}
        onCancel={handleCancel}
        onSubmit={handleSubmitProfile}
      />

      <SettingsSignOutForm onSignOut={signOut} />
    </>
  );
};

export default {
  name: ROUTE_NAME.SETTINGS_USER,
  header: SettingsUserHeader,
  component: SettingsUser,
};
