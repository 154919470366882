import {
  I18nContextProps,
  makeQuantityFormatMessageItem,
} from '@mobble/i18n/src';
import { InventoryCategory } from '@mobble/models/src';
import { formatDate, fromRawDate,RawDate } from '@mobble/shared/src/core/Date';
import { formatMonetary } from '@mobble/shared/src/core/Monetary';

import {
  ElTableValue,
  TableColumn,
} from '@src/stories/Components/Layout/Table';

export const makeInventoryItemBatchesTableColumns = ({
  category,
  currencySymbol,
  formatMessage,
}: {
  category: InventoryCategory;
  currencySymbol: string;
  formatMessage: I18nContextProps['formatMessage'];
}): TableColumn[] => {
  return [
    ...(category === InventoryCategory.Chemicals
      ? [
          {
            key: 'name',
            label: formatMessage({
              description: 'inentory_item_batches.table.heading.column.name',
              defaultMessage: 'Batch ID',
            }),
            toValue: (entity) => entity.name,
          },
        ]
      : [
          {
            key: 'supplierName',
            label: formatMessage({
              description:
                'inentory_item_batches.table.heading.column.supplierName',
              defaultMessage: 'Supplier name',
            }),
            toValue: (entity) => entity.supplierName,
          },
        ]),
    {
      key: 'quantity',
      label: formatMessage({
        description: 'inentory_item_batches.table.heading.column.quantity',
        defaultMessage: 'Quantity',
      }),
      toValue: (entity) =>
        formatMessage(...makeQuantityFormatMessageItem(entity.quantity)),
    },
    {
      key: 'dateReceived',
      label: formatMessage({
        description: 'inentory_item_batches.table.heading.column.dateReceived',
        defaultMessage: 'Date received',
      }),
      toValue: (entity) =>
        entity.dateReceived
          ? fromRawDate(entity.dateReceived).toDate().getTime()
          : null,
      valueToString: (value) =>
        value ? formatDate(fromRawDate(value as RawDate)) : '',
    },
    ...(category === InventoryCategory.Feed
      ? [
          {
            key: 'pricePerUnitCents',
            label: formatMessage({
              description:
                'inentory_item_batches.table.heading.column.pricePerUnitCents',
              defaultMessage: 'Price per unit',
            }),
            toValue: (entity) => entity.pricePerUnitCents,
            render: (value) => {
              return (
                <ElTableValue value={formatMonetary(value, currencySymbol)} />
              );
            },
          },
        ]
      : [
          {
            key: 'dateOfManufacture',
            label: formatMessage({
              description:
                'inentory_item_batches.table.heading.column.dateOfManufacture',
              defaultMessage: 'Date of manufacture',
            }),
            toValue: (batch) =>
              batch.dateOfManufacture
                ? fromRawDate(batch.dateOfManufacture).toDate().getTime()
                : null,
            valueToString: (value) =>
              value ? formatDate(fromRawDate(value as RawDate)) : '',
          },
          {
            key: 'expiryDate',
            label: formatMessage({
              description:
                'inentory_item_batches.table.heading.column.expiryDate',
              defaultMessage: 'Expiry date',
            }),
            toValue: (batch) =>
              batch.dateExpiry
                ? fromRawDate(batch.dateExpiry).toDate().getTime()
                : null,
            valueToString: (value) =>
              value ? formatDate(fromRawDate(value as RawDate)) : '',
          },
        ]),
    {
      key: 'notes',
      label: formatMessage({
        description: 'inentory_item_batches.table.heading.column.notes',
        defaultMessage: 'Notes',
      }),
      toValue: (entity) => entity.notes || '',
    },
  ];
};
