import { I18nItem } from '@mobble/i18n';
import { type MapAsset } from '@mobble/models/src/model/MapAsset';

import {
  TableCellValue,
  TableColumn,
} from '@src/stories/Components/Layout/Table';

export const makeMapAssetsTableColumns = ({
  translate,
}: {
  translate: (i18n: I18nItem) => string;
}): TableColumn<TableCellValue, MapAsset>[] => {
  return [
    {
      key: 'name',
      label: { key: 'map_assets.table.heading.column.name' },
      toValue: (mapAsset) => mapAsset.name,
    },
    {
      key: 'description',
      label: { key: 'map_assets.table.heading.column.description' },
      toValue: (mapAsset) => mapAsset.description,
    },
    {
      key: 'type',
      label: { key: 'map_assets.table.heading.column.type' },
      toValue: (mapAsset) =>
        translate({ key: `map_detail.map_assets__${mapAsset.map.type}.label` }),
    },
  ];
};
