import React, { MouseEvent } from 'react';

import Checkbox from '@src/components/Checkbox';
import ColorSwatch from '@src/components/ColorSwatch/ColorSwatch';
import { type LabelLike } from '@src/components/ListSelect';

import { Box } from '../Layout/Box';
import { Spacer } from '../Layout/Spacer';
import { HStack } from '../Layout/Stack';
import { Text } from '../UI/Text';

import { Clickable } from './Clickable';

import styles from './inlineOption.scss';

export interface InlineOptionProps {
  label: LabelLike;
  value: number | string;
  labelExtra?: LabelLike;
  description?: LabelLike;
  component?: React.ReactNode | JSX.Element;
  type?: 'checkbox' | 'none';
  color?: string;
  selected?: boolean;
  condensed?: boolean;
  disabled?: boolean;
  onClick?: (event: MouseEvent<HTMLDivElement>) => void;
  className?: string;
}

export const InlineOption: React.FC<InlineOptionProps> = ({
  type,
  value,
  color,
  selected,
  label,
  description,
  component,
  labelExtra,
  condensed,
  onClick,
  disabled,
  className,
}) => {
  const renderLabelLike = (labelLike: LabelLike): React.ReactNode => {
    return <Text bold>{labelLike}</Text>;
  };

  const classNames = [
    styles.inlineOption,
    disabled ? styles.disabled : false,
    selected ? styles.selected : false,
  ]
    .filter(Boolean)
    .join(' ');

  return (
    <Clickable
      role="option"
      aria-selected={selected}
      data-value={value}
      fullWidth
      href={onClick}
      disabled={disabled}
      className={[styles.wrapper, className].filter(Boolean).join(' ')}
    >
      <Box flex className={classNames} spacing={condensed ? 1 : 2}>
        <HStack alignment="center">
          {type === 'checkbox' ? (
            <Box spacing={{ top: 0, right: 1, bottom: 0, left: 0 }}>
              <Checkbox
                id={label as string}
                aria-label={label as string}
                checked={selected}
                tabIndex={-1}
                onChange={() => {}}
              />
            </Box>
          ) : null}
          {component ? (
            <Box flex>{component}</Box>
          ) : (
            <Box flex>
              <HStack className={styles.labelWrapper}>
                <HStack wrap>{renderLabelLike(label)}</HStack>
                {color ? (
                  <ColorSwatch color={color} className={styles.colorSwatch} />
                ) : null}
                {labelExtra ? (
                  <>
                    <Spacer flex />
                    <Box>{renderLabelLike(labelExtra)}</Box>
                  </>
                ) : null}
              </HStack>
              {description ? renderLabelLike(description) : null}
            </Box>
          )}
        </HStack>
      </Box>
    </Clickable>
  );
};
