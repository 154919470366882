import React from 'react';

import { MediaQuerySize,useMediaQuery } from '@src/hooks/useMediaQuery';

import styles from './responsive.scss';

export interface ResponsiveProps {
  mobile?: boolean;
  desktop?: boolean;
  children: React.ReactElement | (() => React.ReactElement);
  className?: string;
  style?: React.CSSProperties;
}

export const Responsive: React.FC<ResponsiveProps> = (props) => {
  if (typeof props.children === 'function') {
    return <ResponsiveLazy {...(props as ResponsiveLazyProps)} />;
  }

  const classNames = [
    props.mobile ? styles.mobile : false,
    props.desktop ? styles.desktop : false,
    props.className,
  ]
    .filter(Boolean)
    .join(' ');

  return (
    <div style={props.style} className={classNames}>
      {props.children}
    </div>
  );
};

interface ResponsiveLazyProps {
  mobile?: boolean;
  desktop?: boolean;
  children: () => React.ReactElement;
}

export const ResponsiveLazy: React.FC<ResponsiveLazyProps> = (props) => {
  const size = useMediaQuery();

  let show = false;
  if (props.desktop && (size === MediaQuerySize.Large || size === MediaQuerySize.XLarge)) {
    show = true;
  } else if (props.mobile && (size === MediaQuerySize.Small || size === MediaQuerySize.Medium)) {
    show = true;
  }

  if (!show) {
    return null;
  }

  return props.children();
};

export interface ResponsiveMobileOrDesktopProps {
  mobile: React.ReactElement;
  desktop: React.ReactElement;
  style?: React.CSSProperties;
}

export const ResponsiveMobileOrDesktop: React.FC<
  ResponsiveMobileOrDesktopProps
> = (props) => {
  return (
    <>
      <Responsive style={props.style} mobile>
        {props.mobile}
      </Responsive>
      <Responsive style={props.style} desktop>
        {props.desktop}
      </Responsive>
    </>
  );
};
