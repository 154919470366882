import React from 'react';

import { Paddock } from '@mobble/models/src/model/Paddock';
import { usePaddocks } from '@mobble/store/src/hooks/paddocks';
import { useProperties } from '@mobble/store/src/hooks/properties';

export const useBulkImportPaddocks = () => {
  const properties = useProperties();
  const paddocks = usePaddocks(properties.selected.id);

  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);
  const [loadingProgress, setLoadingProgress] = React.useState<string | null>(
    null
  );
  const [paddockImportErrors, setPaddockImportErrors] = React.useState<
    string[] | null
  >(null);

  const addPaddock = async (
    paddock: Paddock,
    numberOfPaddocksToImport: number,
    currentPaddockIndex: number
  ) => {
    const newPaddock: Omit<Paddock, 'id'> = {
      propertyId: properties.selected.id,
      name: paddock.name,
      type: 'Pasture',
      properties: {
        size: paddock.properties.size,
      },
      mobs: [],

      geometry: {
        polygon: paddock.geometry.polygon,
      },
    };

    await paddocks.create(newPaddock).then((fulfilled) => {
      setLoadingProgress(
        ((currentPaddockIndex / (numberOfPaddocksToImport - 1)) * 100).toFixed(
          2
        )
      );
      if (!fulfilled) {
        setPaddockImportErrors((p) =>
          p ? [...p, paddock.name] : [paddock.name]
        );
      }
    });
  };

  const addPaddocks = async (paddocksToAdd: Paddock[]) => {
    if (!paddocksToAdd.length && isSubmitting) return;
    setIsSubmitting(true);

    return Promise.all(
      paddocksToAdd.map((paddock, index) =>
        addPaddock(paddock, paddocksToAdd.length, index)
      )
    ).then(() => {
      setIsSubmitting(false);
    });
  };

  return {
    isSubmitting,
    loadingProgress,
    addPaddocks,
    paddockImportErrors,
  };
};
