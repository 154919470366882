import React, { useState } from 'react';
import { useNavigate } from 'react-router';

import { toISO8601 } from '@mobble/shared/src/core/Date';

import { useLinking } from '@src/hooks/useLinking';
import { toPath } from '@src/interfaces/Routing';
import { DeleteForm } from '@src/stories/Views/Form/DeleteForm/DeleteForm';
import { ScreenHeader } from '@src/stories/Views/Misc/ScreenHeader';
import {
  PurchaseEditForm,
  PurchaseEditFormValues,
} from '@src/stories/Views/Purchases/PurchaseEditForm';

import * as ROUTE_NAME from '../config/routeNames';
import { ScreenRendererProps } from '../config/types';

import {
  usePurchaseScreen,
  usePurchaseScreenHeader,
} from './usePurchaseScreen';

export const PurchaseEditHeader: React.FC<ScreenRendererProps> = ({
  route,
}) => {
  const { purchaseId } = route.params;
  const props = usePurchaseScreenHeader(purchaseId, true);

  return <ScreenHeader {...props} />;
};

export const PurchaseEdit: React.FC<ScreenRendererProps> = ({ route }) => {
  const { purchaseId } = route.params;
  const { Prelude, purchase, paddocks, properties } =
    usePurchaseScreen(purchaseId);
  const navigate = useNavigate();
  const linkTo = useLinking();

  const [formLoading, setFormLoading] = useState(false);

  if (Prelude) {
    return Prelude;
  }

  const handleSubmit = (formValues: PurchaseEditFormValues) => {
    if (!purchase.entity || !properties.selected) {
      return;
    }

    const updatedPurchase = {
      ...purchase.entity,
      mob: {
        ...purchase.entity.mob,
        size: Number(formValues.number),
      },
      pricePerHeadCents: Number(formValues.price_per_head) * 100,
      date: toISO8601(formValues.date),
      sellerName: formValues.seller,
      notes: formValues.notes,
    };

    setFormLoading(true);

    purchase.update(updatedPurchase).then(() => {
      setFormLoading(false);
      navigate(toPath(ROUTE_NAME.PURCHASE_DETAIL, { purchaseId }));
    });
  };

  const handleCancel = () => {
    navigate(toPath(ROUTE_NAME.PURCHASE_DETAIL, { purchaseId }));
  };

  const handleDelete = () => {
    setFormLoading(true);
    purchase.remove().then(() => {
      setFormLoading(false);
      linkTo(ROUTE_NAME.SUMMARY_PURCHASES);
    });
  };

  return (
    <>
      <PurchaseEditForm
        propertyTypes={properties.selected?.types}
        paddocks={paddocks.entities}
        purchase={purchase.entity}
        loading={formLoading}
        onCancel={handleCancel}
        onSubmit={handleSubmit}
      />
      <DeleteForm
        i18nRootKey="purchases.purchase.delete"
        loading={formLoading}
        onDelete={handleDelete}
      />
    </>
  );
};

export default {
  name: ROUTE_NAME.PURCHASE_EDIT,
  header: PurchaseEditHeader,
  component: PurchaseEdit,
};
