import React from 'react';

import { Color } from '@mobble/colors';
import { type I18nItem, useI18n } from '@mobble/i18n';
import { SortDirection } from '@mobble/models/src/model/Sort';
import { formatNumber } from '@mobble/shared/src/core/Number';
import * as Quantity from '@mobble/shared/src/core/Quantity';

import { Button, Checkbox, Icon, Input } from '@src/components';
import { LocaleQuantity } from '@src/stories/Components/Locale/LocaleQuantity';
import { Reorderable } from '@src/stories/Components/UI/Reorderable';
import { Text, type TextProps } from '@src/stories/Components/UI/Text';
import { ClickableArea } from '@src/stories/Components/UX/ClickableArea';
import { ModalFlyUp } from '@src/stories/Components/UX/ModalFlyUp';
import { usePrinterContext } from '@src/stories/Views/Misc/Printer';

import { Box } from './Box';
import { Spacer } from './Spacer';
import { HStack, VStack } from './Stack';

import styles from './table.scss';

export interface TableProps<T = {}> {
  selected?: string[];
  onSelect?: (ids: string[]) => void;
  withSelection?: (ids: string[]) => React.ReactElement;
  columns: TableColumn<TableCellValue, AnyDataItem<T>>[];
  data?: AnyDataItem<T>[];
  loading?: boolean;
  header?: null | React.ReactNode;
  sticky?: boolean;
  onEmpty?: I18nItem | (() => React.ReactElement) | string;
  idExtractor?: (item: AnyDataItem<T>, index: number) => string;
  title: I18nItem | string;
  showTitle?: boolean;
  counts?: string;
  onSearch?: (query: string) => void;
  onShowFilter?: () => void;
  onShowSort?: () => void;
  onRefresh?: () => void;
  onClearFilter?: () => void;
  searchQuery?: string;
  activeFilterCount?: number;
  onClickRow?: (item: AnyDataItem<T>, ev: MouseEvent) => void;
  renderColSpan?: (
    item: DataItemExtended<T>,
    depth: number
  ) => React.ReactElement;
}

export type DataItem<T = {}> = T;

export type DataItemExtended<T = {}> = T & {
  _children?: AnyDataItem<T>[];
  _expanded?: boolean;
  _span?: number;
  _title?: string | number | number[] | string[];
};

export type AnyDataItem<T> = DataItem<T> | DataItemExtended<T>;

export enum TableScrollDirection {
  Up = 'up',
  Down = 'down',
}

export type TableCellValue =
  | Quantity.AnyQuantity
  | string
  | number
  | boolean
  | null
  | [number, number];

export interface TableColumn<
  Value extends TableCellValue = TableCellValue,
  ItemT = any
> {
  label: I18nItem | string;
  key: string;
  enabled?: boolean;
  sortable?: boolean;
  averages?:
    | boolean
    | ((values: TableCellValue[], average: TableCellValue) => TableCellValue);
  totals?:
    | boolean
    | ((
        values: TableCellValue[],
        total: TableCellValue,
        rows: any[]
      ) => TableCellValue);
  toValue: (item: ItemT) => Value;
  toCsvValue?: (item: ItemT) => string;
  valueToString?: (value: Value, item: ItemT) => string;
  render?: (
    value: Value,
    item: ItemT,
    column: TableColumn<Value, ItemT>
  ) => React.ReactElement;
}

interface ActiveSort {
  key: string;
  direction: SortDirection;
}

function sortData<T extends {}>(columns: TableColumn<TableCellValue>[]) {
  return (data: AnyDataItem<T>[], sort: ActiveSort): AnyDataItem<T>[] => {
    const column = columns.find((c) => c.key === sort.key);
    if (!column) {
      return data;
    }

    const sorter = (a: T, b: T) => {
      const valA = column.toValue(
        sort.direction === SortDirection.Descending ? b : a
      );
      const valB = column.toValue(
        sort.direction === SortDirection.Descending ? a : b
      );

      if (typeof valA === 'number' || typeof valB === 'number') {
        return (Number(valA) || 0) - (Number(valB) || 0);
      } else if (typeof valA === 'boolean' && typeof valB === 'boolean') {
        return valA === valB ? 0 : valA ? 1 : -1;
      } else if (Array.isArray(valA) && Array.isArray(valB)) {
        return valA[0] - valB[0] === 0 ? valA[1] - valB[1] : valA[0] - valB[0];
      } else if (typeof valA === 'object' && typeof valB === 'object') {
        try {
          return Quantity.subtract(valA as any, valB as any).value;
        } catch (_) {
          return 0;
        }
      } else {
        return String(valA).localeCompare(String(valB));
      }
    };

    return data.sort(sorter);
  };
}

function calculateTotal(
  acc: TableCellValue | Quantity.QuantityOfLength,
  value: TableCellValue | Quantity.QuantityOfLength
): TableCellValue | Quantity.QuantityOfLength {
  try {
    if (typeof acc === 'number') {
      return acc + ((value as number) ?? 0);
    } else if (typeof acc === 'object') {
      return Quantity.sum([acc, value] as any);
    } else {
      return null;
    }
  } catch (e) {
    return null;
  }
}

export const Table: React.FC<TableProps> = ({
  selected,
  onSelect,
  withSelection,
  data,
  columns,
  onEmpty,
  loading,
  sticky,
  header,
  idExtractor = (item, i) => `${i}${JSON.stringify(item)}`,
  onShowFilter,
  onClearFilter,
  title,
  showTitle,
  counts,
  onSearch,
  searchQuery,
  activeFilterCount,
  onClickRow,
  onRefresh,
  renderColSpan,
}) => {
  const { translate, formatMessage } = useI18n();
  const [activeSort, setActiveSort] = React.useState<ActiveSort | null>(null);
  const isEmpty = data.length === 0;

  const [reorderIsOpen, setReorderIsOpen] = React.useState(false);
  const [reorderableColumns, setReorderableColumns] = React.useState<
    TableColumn[]
  >(
    columns.map((column) => ({
      ...column,
      enabled: column.enabled ?? true,
      sortable: column.sortable ?? true,
    }))
  );

  const tableElWrapperRef = React.useRef<HTMLDivElement>(null);
  const { printFromRef } = usePrinterContext();

  const printTable = () => {
    printFromRef(tableElWrapperRef);
  };

  const downloadCSV = () => {
    const header = columns.map((column) =>
      typeof column.label !== 'string' ? translate(column.label) : column.label
    );

    const lines = sortedData.map((data) =>
      columns.map((column) => {
        const value = column.toCsvValue
          ? column.toCsvValue(data)
          : column.toValue(data);

        const toValueString = column.valueToString
          ? column.valueToString(value, data)
          : valueToString(value);

        return `"${toValueString}"`;
      })
    );

    const csv = [header, ...lines].map((line) => line.join(',')).join('\n');
    const tmpAnchor = document.createElement('a');

    tmpAnchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv);
    tmpAnchor.target = '_blank';

    tmpAnchor.download = `Mobble ${
      title ? (typeof title === 'string' ? title : translate(title)) : 'table'
    }.csv`;
    tmpAnchor.click();
  };

  const renderReorderableColumnItem = (item) => (
    <HStack alignment="center">
      <Text variant="body" i18n={item.label} />
      <Spacer flex />
      <Checkbox
        id={`sort-${item.key}`}
        aria-label={formatMessage({
          defaultMessage: 'Column visibility',
          description: 'entities.entities_list.column_visibility',
        })}
        checked={item.enabled}
        radio
        onChange={() => {
          setReorderableColumns([
            ...reorderableColumns.map((column) => ({
              ...column,
              enabled:
                column.key === item.key ? !column.enabled : column.enabled,
            })),
          ]);
        }}
      />

      <Spacer x={2} />
    </HStack>
  );

  const sortedData = activeSort?.key
    ? sortData(columns)(data, activeSort)
    : data;

  const searchOnChange = (val: string) => {
    setActiveSort(null);
    onSearch(val);
  };

  const tableOptions = (
    <div className={styles.buttonGroup}>
      {onRefresh && (
        <Button
          aria-label={formatMessage({
            defaultMessage: 'Refresh',
            description: 'entities.entities_list.refresh',
          })}
          icon="refresh"
          intent="neutral"
          outline
          loading={loading}
          onClick={onRefresh}
        />
      )}
      <Button
        aria-label={formatMessage({
          defaultMessage: 'Download',
          description: 'entities.entities_list.download',
        })}
        icon="download"
        intent="neutral"
        outline
        onClick={downloadCSV}
      />
      <Button
        aria-label={formatMessage({
          defaultMessage: 'Print',
          description: 'entities.entities_list.print',
        })}
        icon="print"
        intent="neutral"
        outline
        onClick={printTable}
      />
      <Button
        aria-label={formatMessage({
          defaultMessage: 'Reorder columns',
          description: 'entities.entities_list.reorder_columns',
        })}
        icon="settings"
        intent="neutral"
        outline
        onClick={() => setReorderIsOpen(true)}
      />
    </div>
  );

  const toolbarTitle = (
    <>
      {title && showTitle ? (
        <>
          <Spacer x={2} />
          <Text variant="card-title" i18n={title} />
          <Spacer x={2} />
        </>
      ) : null}
      {counts && (
        <>
          <Text i18n={counts} />
          <Spacer x={2} />
        </>
      )}
    </>
  );

  const search = () => (
    <div className={styles.searchInputWrapper}>
      <Input
        id="search"
        placeholder={formatMessage({
          defaultMessage: 'Search',
          description: 'entities.entities_list.filter.search.placeholder',
        })}
        type="text"
        icon="search"
        showClear
        onChange={searchOnChange}
        value={searchQuery}
      />
    </div>
  );

  const toolbar = (
    <div className={styles.tableHeaderToolbar}>
      {(selected?.length < 1 || !selected) && (
        <>
          <section>
            {onSearch && search()}

            {onShowFilter ? (
              <div className={styles.buttonGroup}>
                <Button
                  aria-label={formatMessage({
                    defaultMessage: 'Filter options',
                    description: 'entities.entities_list.filter_options',
                  })}
                  icon="filter"
                  intent="neutral"
                  outline
                  onClick={onShowFilter}
                >
                  {activeFilterCount ? <span>{activeFilterCount}</span> : null}
                </Button>
                {activeFilterCount && onClearFilter ? (
                  <Button
                    aria-label={formatMessage({
                      defaultMessage: 'Clear filter',
                      description: 'entities.entities_list.clear_filter',
                    })}
                    icon="close"
                    intent="neutral"
                    outline
                    onClick={onClearFilter}
                  />
                ) : null}
              </div>
            ) : null}

            {toolbarTitle}
          </section>
          {!isEmpty && <section>{tableOptions}</section>}
        </>
      )}

      {withSelection && selected.length > 0 && (
        <div className={styles.selectionToolbar}>
          {withSelection(selected)}

          <Button
            aria-label={formatMessage({
              defaultMessage: 'Clear selected',
              description: 'entities.entities_list.clear_selected',
            })}
            icon="close"
            intent="neutral"
            outline
            onClick={() => onSelect([])}
          />
        </div>
      )}
    </div>
  );

  const renderOnEmpty = () => {
    if (onEmpty) {
      if (typeof onEmpty === 'function') {
        return onEmpty();
      }
      return (
        <Box flex spacing={2}>
          <VStack alignment="center">
            <Text i18n={onEmpty} />
          </VStack>
        </Box>
      );
    }
    return null;
  };

  return (
    <Box className={[styles.tableWrapper, sticky ? styles.sticky : null]}>
      {header}

      {toolbar}

      <Spacer y={1} />

      <div ref={tableElWrapperRef}>
        {isEmpty && onEmpty ? (
          renderOnEmpty()
        ) : (
          <ElTable
            expandable={data.some((r) => (r as any)?._children?.length > 0)}
            selectable={Boolean(withSelection)}
            rows={sortedData}
            columns={reorderableColumns.filter((c) => c.enabled)}
            selected={selected}
            onSelect={onSelect}
            idExtractor={idExtractor}
            activeSort={activeSort}
            onSort={(key, direction) => setActiveSort({ key, direction })}
            onClickRow={onClickRow}
            renderColSpan={renderColSpan}
          />
        )}
      </div>

      <ModalFlyUp
        isOpen={reorderIsOpen}
        title={formatMessage({ defaultMessage: 'Columns' })}
        onClose={() => setReorderIsOpen(false)}
      >
        <Reorderable
          items={reorderableColumns}
          keyExtractor={(item) => item.key}
          onReorder={setReorderableColumns}
          renderItem={renderReorderableColumnItem}
        />
      </ModalFlyUp>
    </Box>
  );
};

interface ElTableProps<T = {}> {
  rows: any[];
  columns: TableColumn<TableCellValue, T>[];
  indentation?: number;
  idExtractor?: (item: any, index: number) => string;
  expandable: boolean;
  selectable: boolean;
  selected?: string[];
  onSelect?: (id: string[]) => void;
  renderTableHead?: (tableProps: ElTableProps) => React.ReactNode;
  renderTableRow?: (
    tableProps: ElTableProps,
    item: any,
    index: number
  ) => React.ReactNode;
  activeSort?: ActiveSort;
  onSort: (key: string, direction: SortDirection) => void;
  onClickRow?: (item: any, ev: any) => void;
  renderColSpan?: (
    item: DataItemExtended<T>,
    depth: number
  ) => React.ReactElement;
}

const ElTable: React.FC<ElTableProps> = (props) => {
  const {
    rows,
    // columns,
    idExtractor = (item, i) => `${i}${JSON.stringify(item)}`,
    // selectable,
    // indentation,
    renderTableHead = (tableProps) => <ElTableHead tableProps={tableProps} />,
    renderTableRow = (tableProps: ElTableProps, item: any, index: number) => (
      <ElTableRow
        key={idExtractor(item, index)}
        tableProps={tableProps}
        item={item}
        index={index}
      />
    ),
  } = props;

  return (
    <table cellPadding={0} cellSpacing={0} border={0} className={styles.table}>
      {renderTableHead(props)}
      {rows.length > 0 && (
        <tbody>
          {rows.map((row, index) => renderTableRow(props, row, index))}
        </tbody>
      )}
    </table>
  );
};

interface ELTableHeadProps {
  tableProps: ElTableProps;
}

const ElTableHead: React.FC<ELTableHeadProps> = ({ tableProps }) => {
  const {
    columns,
    expandable,
    selectable,
    selected,
    activeSort,
    onSort,
    rows,
  } = tableProps;

  return (
    <>
      <thead>
        <tr>
          {expandable && <th className={styles.cellSelect}></th>}

          {selectable && (
            <th className={styles.cellSelect}>
              {selected?.length > 0 && (
                <Text bold variant="small">
                  ({selected.length})
                </Text>
              )}
            </th>
          )}

          {columns.map((c) => (
            <th key={c.key}>
              <span className={styles.printOnly}>
                <Text bold variant="small" i18n={c.label} />
                <Box
                  className={[
                    styles.sortIcon,
                    activeSort?.key === c.key
                      ? activeSort?.direction === SortDirection.Ascending
                        ? styles.sortIconAscending
                        : styles.sortIconDescending
                      : '',
                  ].join(' ')}
                ></Box>
              </span>
              {c.sortable ? (
                <ClickableArea
                  href={() =>
                    onSort(
                      c.key,
                      activeSort?.key === c.key &&
                        activeSort?.direction === SortDirection.Ascending
                        ? SortDirection.Descending
                        : SortDirection.Ascending
                    )
                  }
                >
                  <HStack alignment="center">
                    <Text bold variant="small" i18n={c.label} />
                    <Spacer x={1} />
                    <Box
                      className={[
                        styles.sortIcon,
                        activeSort?.key === c.key
                          ? activeSort?.direction === SortDirection.Ascending
                            ? styles.sortIconAscending
                            : styles.sortIconDescending
                          : '',
                      ].join(' ')}
                    >
                      <Icon size="small" name="arrow-right" />
                    </Box>
                  </HStack>
                </ClickableArea>
              ) : (
                <Text bold variant="body" i18n={c.label} />
              )}
            </th>
          ))}
        </tr>
      </thead>
      {rows.length > 0 && (
        <tfoot>
          <tr>
            {expandable && <td className={styles.cellSelect}></td>}
            {selectable && <td className={styles.cellSelect}></td>}

            {columns.map((c) => (
              <ElTableFooterCell key={c.key} column={c} rows={rows} />
            ))}
          </tr>
        </tfoot>
      )}
    </>
  );
};

interface ELTableRowProps {
  index: number;
  tableProps: ElTableProps;
  item: any;
  depth?: number;
}

const ElTableRow: React.FC<ELTableRowProps> = ({
  tableProps,
  item,
  index,
  depth = 0,
}) => {
  const { columns, onClickRow, renderColSpan } = tableProps;

  const [expanded, setExpanded] = React.useState(item._expanded ?? false);

  return (
    <>
      <tr
        {...{ datadepth: depth }}
        data-entity-id={item.id}
        className={[
          onClickRow ? styles.tableRowClickable : '',
          expanded ? styles.expanded : '',
        ].join(' ')}
        onClick={onClickRow ? (ev) => onClickRow(item, ev) : undefined}
      >
        <ElTableRowSelect index={index} item={item} tableProps={tableProps} />
        <ElTableRowExpander
          item={item}
          expanded={expanded}
          onToggle={() => setExpanded((e) => !e)}
          tableProps={tableProps}
        />

        {item._span ? (
          <>
            <td colSpan={item._span}>
              {renderColSpan ? renderColSpan(item, depth) : item._title}
            </td>
            {[...columns].splice(item._span).map((c) => (
              <ElTableCell key={c.key} column={c} item={item} />
            ))}
          </>
        ) : (
          columns.map((c) => <ElTableCell key={c.key} column={c} item={item} />)
        )}
      </tr>
      {expanded && (
        <>
          {item._children?.map((child, index) => (
            <ElTableRow
              key={`${JSON.stringify(child)}`}
              index={index}
              item={child}
              tableProps={tableProps}
              depth={depth + 1}
            />
          ))}
        </>
      )}
    </>
  );
};

interface ElTableRowSelectProps {
  index: number;
  tableProps: ElTableProps;
  item: any;
}

const ElTableRowSelect: React.FC<ElTableRowSelectProps> = ({
  tableProps,
  item,
  index,
}) => {
  const { formatMessage } = useI18n();
  const { onSelect, selected, idExtractor, selectable } = tableProps;

  if (!selectable) {
    return null;
  }

  const id = idExtractor(item, index);
  const isSelected = selected.includes(id);

  const handleClick = (ev) => {
    // prevent click event from trigger on row
    ev.stopPropagation();

    if (isSelected) {
      onSelect(selected.filter((s) => s !== id));
    } else {
      onSelect([...selected, id]);
    }
  };

  return (
    <td className={styles.cellSelect} onClick={handleClick}>
      <Checkbox
        id={`row-${id}`}
        aria-label={formatMessage({
          defaultMessage: 'Select row',
          description: 'entities.entities_list.select_row',
        })}
        checked={isSelected}
        onChange={() => {}}
      />
    </td>
  );
};

interface ElTableRowExpanderProps<T = {}> {
  tableProps: ElTableProps;
  expanded: boolean;
  item: DataItemExtended<T>;
  onToggle: () => void;
}

const ElTableRowExpander: React.FC<ElTableRowExpanderProps> = ({
  tableProps,
  item,
  expanded,
  onToggle,
}) => {
  const { expandable } = tableProps;

  if (!expandable) {
    return null;
  }

  return (
    <td className={styles.cellExpand}>
      {item?._children?.length > 0 && (
        // TODO: add new button size
        <button
          onClick={onToggle}
          className={[
            styles.cellExpandButton,
            expanded ? styles.expanded : '',
          ].join(' ')}
        >
          <span className={styles.inner}>
            <Icon name="chevron-right" />
          </span>
        </button>
      )}
    </td>
  );
};

interface ELTableCellProps {
  column: TableColumn<TableCellValue>;
  item: any;
}

const ElTableCell: React.FC<ELTableCellProps> = ({ item, column }) => {
  const rawValue = column.toValue(item);
  const value = column.valueToString
    ? column.valueToString(rawValue, item)
    : rawValue;

  return (
    <td>
      {column.render ? (
        column.render(value, item, column)
      ) : (
        <ElTableValue value={value} />
      )}
    </td>
  );
};

interface ELTableFooterCellProps {
  column: TableColumn<TableCellValue>;
  rows: any[];
}

const ElTableFooterCell: React.FC<ELTableFooterCellProps> = ({
  rows,
  column,
}) => {
  const { translate, formatMessage } = useI18n();

  if (!column.totals && !column.averages) {
    return <td></td>;
  }

  const values = rows.map((row) => column.toValue(row));
  const value = (() => {
    const total = values.reduce(calculateTotal);

    if (column.averages) {
      const average = Quantity.isQuantity(total)
        ? total.value / values.length
        : (total as number) / values.length;

      if (typeof column.averages === 'function') {
        return column.averages(values, average);
      }

      return formatMessage(
        {
          defaultMessage: 'Avg: {value, number}{unit}',
          description: 'Table column average',
        },
        {
          value: average,
          unit: Quantity.isQuantity(total) ? ` ${total.unit}` : '',
        }
      );
    }

    if (typeof column.totals === 'function') {
      return column.totals(values, total, rows);
    }

    return total;
  })();

  const valueString = column.valueToString
    ? column.valueToString(value, {})
    : valueToString(value);

  return (
    <td
      title={
        typeof column.label !== 'string'
          ? translate(column.label)
          : column.label
      }
    >
      <ElTableValue bold value={valueString} variant="small" />
    </td>
  );
};

type ElTableValueProps = {
  value: TableCellValue;
} & TextProps;

export const ElTableValue: React.FC<ElTableValueProps> = ({
  value,
  ...delegated
}) => {
  switch (typeof value) {
    case 'string':
      return (
        <Text
          {...delegated}
          variant="body"
          color={value === '0' ? Color.Grey : undefined}
        >
          {value}
        </Text>
      );
    case 'number': {
      const str = formatNumber(value);
      return (
        <Text
          {...delegated}
          variant="body"
          color={value === 0 ? Color.Grey : undefined}
        >
          {str}
        </Text>
      );
    }
    case 'object':
      if (value === null) {
        return null;
      }
      return <LocaleQuantity {...delegated} quantity={value as any} />;
    case 'boolean':
      return (
        <Icon
          name={value ? 'check-circle' : 'close-circle'}
          className={styles.valueIcon}
        />
      );
    default:
      return null;
  }
};

const valueToString = (value: any): string => {
  switch (typeof value) {
    case 'string':
      return value;
    case 'number':
      return formatNumber(value);
    case 'boolean':
      return value ? 'true' : 'false';
    case 'object':
      if (value && value.unit) {
        return `${value?.value ?? 0} ${value.unit}`;
      }
      return JSON.stringify(value);
    default:
      return '';
  }
};
