import React from 'react';
import { FillPaint, LinePaint } from 'mapbox-gl';

import { colorToHex } from '@mobble/colors';
import {
  MapStyle,
  mapStyleIsSatellite,
} from '@mobble/models/src/model/MapStyle';
import { Color } from '@mobble/theme';

import { Polygon } from '@src/stories/Mapbox/Polygon';

import { type MapCustomFeature } from './MapItemType';

export interface CustomFeatureProps {
  mapStyle: MapStyle;
  item: MapCustomFeature | null;
  disabled?: boolean;
  fill?: boolean;
}

export const CustomFeature: React.FC<CustomFeatureProps> = ({
  mapStyle,
  item,
  disabled,
}) => {
  if (!item.visible) {
    return null;
  }

  const defaultColor = mapStyleIsSatellite(mapStyle)
    ? 'rgba(255,255,255,0.85)'
    : 'rgba(0,0,0,0.85)';

  const layerColor = (() => {
    if (item.color) {
      return colorToHex(item.color);
    }
    return colorToHex(Color.White);
  })();

  const fillPaint = (color: string): FillPaint => ({
    'fill-color': color,
    'fill-opacity': 0.15,
  });

  const linePaint = (color: string): LinePaint => ({
    'line-color': color,
    'line-width': disabled ? 2 : 4,
  });

  const paintColor = disabled ? defaultColor : layerColor;

  return (
    <Polygon
      id={`custom_feature-${item.id}`}
      data={item.polygon}
      linePaint={linePaint(paintColor)}
      lineLayout={{ 'line-cap': 'round', 'line-join': 'round' }}
      fillPaint={disabled ? undefined : fillPaint(paintColor)}
      onClick={disabled ? undefined : item.onClick}
    />
  );
};
