import React from 'react';

import { useI18n } from '@mobble/i18n/src';
import {
  Inventory,
  INVENTORY_TYPE,
  InventoryCategory,
} from '@mobble/models/src/model/Inventory';
import {
  InventoryItem,
  InventoryItemChemical,
  InventoryItemFeed,
  InventoryItemStatus,
} from '@mobble/models/src/model/InventoryItem';
import { type User } from '@mobble/models/src/model/User';
import { Quantity } from '@mobble/shared/src';
import { MassVolumeUnits , Type as QuantityType } from '@mobble/shared/src/core/Quantity';

import { FormBuilder, type FormBuilderProps } from '../Form/FormBuilder';

export interface InventoryItemCreateFormProps<T extends InventoryItem> {
  inventory: Inventory;
  user: User;
  error?: string;
  loading?: boolean;

  onCancel: () => void;
  onTouched: (dirty: boolean) => void;
  onSubmit: (inventoryItem: Omit<T, 'id'>) => void;
}

export const InventoryItemCreateForm: React.FC<
  InventoryItemCreateFormProps<InventoryItem>
> = (props) => {
  switch (props.inventory.category) {
    case InventoryCategory.Chemicals:
      return <InventoryItemChemicalsCreateForm {...props} />;
    case InventoryCategory.Feed:
      return <InventoryItemFeedCreateForm {...props} />;
  }
};

export interface InventoryItemChemicalsCreateFormValues {
  name: string;
  batchId: string;
  unit: MassVolumeUnits;
  whp: number;
  esi: number;
  woolWhp: number;
  notes: string;
}

const InventoryItemChemicalsCreateForm: React.FC<
  InventoryItemCreateFormProps<InventoryItemChemical>
> = ({ inventory, user, error, loading, onCancel, onTouched, onSubmit }) => {
  const handleSubmit = (formValues: InventoryItemChemicalsCreateFormValues) => {
    onSubmit({
      inventoryId: inventory.id,
      propertyId: inventory.propertyId,
      status: InventoryItemStatus.Active,
      createdBy: user,
      name: formValues.name,
      notes: formValues.notes,
      quantity: {
        value: 0,
        unit: formValues.unit,
        type: QuantityType.MassVolume,
      },
      //
      category: InventoryCategory.Chemicals,
      esi: formValues.esi ?? 0,
      woolWhp: formValues.woolWhp ?? 0,
      whp: formValues.whp,
    });
  };

  const { translate } = useI18n();
  const units = Quantity.unitsForType(QuantityType.MassVolume);

  const options = units.map((unit: string) => ({
    label: translate({ key: `generic.quantity.unit.${unit}.long` }),
    value: unit,
  }));

  const form: FormBuilderProps<InventoryItemChemicalsCreateFormValues> = {
    i18nRootKey: 'inventory_items.inventory_item.chemicals.create.form',
    flex: true,
    fields: [
      { name: 'name', type: 'text', required: true },
      {
        name: 'unit',
        type: 'select',
        options: (values) =>
          options.map((option) => ({
            ...option,
            selected: option.value === values.unit,
          })),
        required: true,
      },
      { name: 'whp', type: 'number', min: 0 },
      {
        name: 'esi',
        type: 'number',
        min: 0,
        show: () => inventory.type === INVENTORY_TYPE.ANIMAL_HEALTH,
      },
      {
        name: 'woolWhp',
        type: 'number',
        min: 0,
        show: () => inventory.type === INVENTORY_TYPE.ANIMAL_HEALTH,
      },
      { name: 'notes', type: 'textarea' },
    ],
    error,
    loading,
    onSubmit: handleSubmit,
    onCancel,
    onTouched,
  };

  return <FormBuilder {...form} />;
};

export interface InventoryItemFeedCreateFormValues {
  name: string;
  unit: MassVolumeUnits;
  notes: string;
}

const InventoryItemFeedCreateForm: React.FC<
  InventoryItemCreateFormProps<InventoryItemFeed>
> = ({ inventory, user, loading, onCancel, onTouched, onSubmit }) => {
  const handleSubmit = (formValues: InventoryItemFeedCreateFormValues) => {
    onSubmit({
      inventoryId: inventory.id,
      propertyId: inventory.propertyId,
      status: InventoryItemStatus.Active,
      category: InventoryCategory.Feed,
      createdBy: user,

      name: formValues.name,
      quantity: {
        value: 0,
        unit: formValues.unit,
        type: QuantityType.MassVolume,
      },
      notes: formValues.notes,
    });
  };

  const { translate } = useI18n();
  const units = Quantity.unitsForType(QuantityType.MassVolume);

  const options = units.map((unit: string) => ({
    label: translate({ key: `generic.quantity.unit.${unit}.long` }),
    value: unit,
  }));

  const form: FormBuilderProps<InventoryItemFeedCreateFormValues> = {
    i18nRootKey: 'inventory_items.inventory_item.feed.create.form',
    flex: true,
    fields: [
      { name: 'name', type: 'text', required: true },
      {
        name: 'unit',
        type: 'select',
        options: (values) =>
          options.map((option) => ({
            ...option,
            selected: option.value === values.unit,
          })),
        required: true,
      },
      { name: 'notes', type: 'textarea' },
    ],
    loading,
    onSubmit: handleSubmit,
    onCancel,
    onTouched,
  };

  return <FormBuilder {...form} />;
};
