import { Mob, Paddock, Task } from '@mobble/models';
import {
  convertTo,
  makeQuantity,
  Type,
} from '@mobble/shared/src/core/Quantity';
import { useSettings } from '@mobble/store/src/hooks';

import { unitFromSettingsForQuantityType } from '@src/stories/Components/Locale/LocaleQuantity';

interface UseDashboardTilesProps {
  mobs: Mob[];
  paddocks: Paddock[];
  tasks: Task[];
}

export const useDashboardTiles = (props: UseDashboardTilesProps) => {
  const { mobs, paddocks, tasks } = props;
  const { settings } = useSettings();
  const unit = unitFromSettingsForQuantityType(settings)(Type.Area);

  const totalDSE = mobs.reduce((a, b) => a + b.DSE * b.size, 0);
  const totalHaM2 = paddocks.reduce(
    (a, b) => a + (b.properties.size.value || 0),
    0
  );

  const totalArea = {
    value: convertTo(unit)(makeQuantity(Type.Area, 'ha', totalHaM2)).value,
    unit,
  };

  const totalDSEPerAreaUnit = {
    totalDSEPerArea: totalArea.value !== 0 ? totalDSE / totalArea.value : 0,
    unit: totalArea.unit,
  };

  const activeTasks = tasks.filter((task) => task.status === 'pending').length;

  return {
    totalDSE,
    totalArea,
    totalDSEPerAreaUnit,
    activeTasks,
  };
};
