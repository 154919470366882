import { useI18n } from '@mobble/i18n';
import { type Paddock } from '@mobble/models/src/model/Paddock';
import {
  ConfiguredPropertyTypeGroup,
  getPropertyPaddockTypeOptions,
  getUnselectedPaddockTypeColor,
} from '@mobble/models/src/model/Property';
import { usePaddock, usePaddocks } from '@mobble/store/src/hooks/paddocks';
import { getPaddocksInSamePaddockGroupAsPaddock } from '@mobble/models/src/model/PaddockGroup';
import { useProperties } from '@mobble/store/src/hooks/properties';
import { useExtStatus } from '@mobble/store/src/hooks/root';
import {
  Point,
  pointsToFeaturePolygon,
} from '@mobble/models/src/model/MapGeometry';
import { usePaddockGroups } from '@mobble/store/src/hooks/paddockGroups';
import { usePaddockGeometry } from '@mobble/store/src/hooks/paddockGeometries';
import { type QuantityOfArea } from '../core/Quantity';
import { useEntitiesRefresher } from '../hooks/useEntitiesRefresher';

export type PaddockEditView = {
  paddockId: string;
  EntitySliceFactoryPrelude: any;
  onDeleteSuccess?: () => void;
  onSubmitSuccess: () => void;
};

export interface PaddockEditFormValues {
  name: string;
  paddock_type: Paddock['type'];
  grazeable_area: QuantityOfArea;
  points?: Point[];
}

export const usePaddockEditView = (props: PaddockEditView) => {
  const {
    paddockId,
    EntitySliceFactoryPrelude,
    onDeleteSuccess,
    onSubmitSuccess,
  } = props;
  const { translate } = useI18n();
  const properties = useProperties();
  const paddock = usePaddock(paddockId);
  const paddocks = usePaddocks(properties.selected?.id);
  const propertyId = paddock.entity?.propertyId ?? properties.selected?.id;
  const paddockGeometry = usePaddockGeometry(paddockId);
  const paddockGroups = usePaddockGroups(properties.selected?.id);

  const extStatus = useExtStatus('paddocks', 'update', paddockId);
  const formLoading = extStatus?.loading;
  const extStatusDelete = useExtStatus('paddocks', 'delete', paddockId);
  const deleteLoading = extStatusDelete?.loading;
  const formError = extStatus?.error
    ? translate({
        key: 'generic.form.error.native',
        params: {
          '%ENTITY': translate({
            key: 'paddocks.paddock.create.form.title.label',
          }),
        },
      })
    : '';

  useEntitiesRefresher([paddocks, paddockGeometry, paddockGroups], propertyId);

  const Prelude = EntitySliceFactoryPrelude({
    preludes: [properties.prelude, paddocks.prelude],
    required: [properties.selected],
    notFound: [paddock.entity],
  });

  const paddocksInSamePaddockGroup = getPaddocksInSamePaddockGroupAsPaddock(
    paddockGroups.entities,
    paddocks.entities
  )(paddock.entity?.id).filter((p) => p.id !== paddock.entity.id);

  const paddockHasMobs = paddock.entity?.mobs.length > 0;
  const paddockHasOpenGates = paddocksInSamePaddockGroup.length > 0;
  const paddockTypes = getPropertyPaddockTypeOptions(properties.selected);
  const otherPaddockNames = paddocks.entities
    .filter((p) => p.id !== paddockId)
    .map((p) => p.name.toLowerCase());

  const handleCreateCustomField = (label: string) => {
    if (!properties.selected) {
      return Promise.reject();
    }
    const color = getUnselectedPaddockTypeColor(properties.selected);

    return properties.addPropertyType({
      group: ConfiguredPropertyTypeGroup.paddockType,
      parentId: propertyId,
      color,
      label,
    });
  };

  const handleDelete = () => {
    paddock
      .remove()
      .then(() => {
        typeof onDeleteSuccess === 'function' && onDeleteSuccess();
      })
      .catch((err) => {
        console.log(err);
        // TODO: handle all paddock errors approriately
        // input specific errors should focus on the first input
        // gloal errors can display in fixed location
      });
  };

  const handleSubmit = (formValues: PaddockEditFormValues) => {
    const polygon = formValues.points
      ? pointsToFeaturePolygon(formValues.points)?.geometry ?? null
      : null;

    paddock
      .update({
        ...paddock.entity,
        name: formValues.name,
        type: formValues.paddock_type,
        properties: { size: formValues.grazeable_area },
        geometry: {
          polygon,
        },
      })
      .then(async () => {
        if (paddockGeometry.entity?.polygon?.coordinates && !polygon) {
          await paddockGeometry.remove();
        }
        onSubmitSuccess();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return {
    Prelude,
    paddock,
    paddockGeometry,
    paddockTypes,
    formLoading,
    deleteLoading,
    formError,
    paddockHasMobs,
    paddockHasOpenGates,
    otherPaddockNames,
    handleCreateCustomField,
    handleDelete,
    handleSubmit,
  };
};
