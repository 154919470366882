import React from 'react';
import { defineMessages } from 'react-intl';
import * as Yup from 'yup';

import { Color } from '@mobble/colors';
import { useI18n, useMessages } from '@mobble/i18n';
import { getLivestockAgeColor, Mob } from '@mobble/models/src/model/Mob';
import {
  type ConfiguredPropertyType,
  ConfiguredPropertyTypeGroup,
  type ConfiguredPropertyTypeGroupCustom,
  getLivestockAttributes,
  getLivestockParentIdForValue,
} from '@mobble/models/src/model/Property';
import {
  formatStockingRate,
  StockingUnit,
} from '@mobble/models/src/model/Settings';
import { useSetting } from '@mobble/store/src/hooks';

import { getStockingUnitI18n } from '@src/stories/Components/Locale/LocaleStockingUnit';

import { FormBuilder, type FormBuilderProps } from '../Form/FormBuilder';

import styles from './mobEditForm.scss';

export interface MobEditFormProps {
  propertyTypes: ConfiguredPropertyType[];

  mob: Mob;
  error?: string;
  loading: boolean;

  onTouched: (dirty: boolean) => void;
  onCancel: () => void;
  onSubmit: (formValues: MobEditFormValues) => void;
  onCreateCustomField: (
    group: ConfiguredPropertyTypeGroupCustom,
    livestockTypeId: string,
    label: string
  ) => Promise<void>;
}

export type MobEditFormValues = Omit<Mob, 'propertyId' | 'id'>;

const messages = defineMessages({
  addNewBreedType: {
    defaultMessage: 'Add new breed type',
    description:
      'mobs.mob.create.form.mob_form.breed.add_new.placeholder.label',
  },
  addNewGenderType: {
    defaultMessage: 'Add new gender type',
    description:
      'mobs.mob.create.form.mob_form.gender.add_new.placeholder.label',
  },
  addNewClassType: {
    defaultMessage: 'Add new class type',
    description:
      'mobs.mob.create.form.mob_form.classes.add_new.placeholder.label',
  },
  sizeValidation: {
    defaultMessage: 'Head must be a whole number',
    description: 'Head count number validation',
  },
  stockingRateValidation: {
    defaultMessage: 'Stocking rate must be a number',
    description: 'Stocking rate number validation',
  },
});

export const MobEditForm: React.FC<MobEditFormProps> = ({
  propertyTypes,
  mob,
  error,
  loading,
  onCancel,
  onSubmit,
  onTouched,
  onCreateCustomField,
}) => {
  const { formatMessage } = useI18n();
  const strings = useMessages(messages);
  const stockingUnit = useSetting('stockingUnit') as StockingUnit;
  const stockingUnitLabel = formatMessage(getStockingUnitI18n(stockingUnit));
  const formattedStockingRate = formatStockingRate(mob.DSE, stockingUnit);

  const getAgeColor = getLivestockAgeColor(propertyTypes, mob?.type);
  const handleCreateCustomField =
    (type: ConfiguredPropertyTypeGroupCustom) =>
    (value: string): Promise<void> => {
      if (!mob?.type) {
        return Promise.reject();
      }
      const liveStockTypeId = getLivestockParentIdForValue(
        mob.type,
        propertyTypes
      );
      if (!liveStockTypeId) {
        return Promise.reject();
      }

      return onCreateCustomField(type, liveStockTypeId, value);
    };

  const form: FormBuilderProps<MobEditFormValues> = {
    flex: true,
    className: styles.mobEditForm,
    i18nRootKey: 'mobs.mob.edit.form.mob_form',
    fields: [
      {
        name: 'type',
        type: 'display',
        required: true,
        initialValue: mob.type,
      },
      {
        name: 'breed',
        type: 'select',
        options: (values) =>
          getLivestockAttributes(propertyTypes)(
            ConfiguredPropertyTypeGroup.breed,
            getLivestockParentIdForValue(values.type, propertyTypes)
          ),
        required: true,
        initialValue: mob.breed,
        addNew: {
          onSubmit: handleCreateCustomField(ConfiguredPropertyTypeGroup.breed),
          placeholder: strings.addNewBreedType,
        },
      },
      {
        name: 'gender',
        type: 'select',
        options: (values) =>
          getLivestockAttributes(propertyTypes)(
            ConfiguredPropertyTypeGroup.gender,
            getLivestockParentIdForValue(values.type, propertyTypes)
          ),
        required: true,
        initialValue: mob.gender,
        addNew: {
          onSubmit: handleCreateCustomField(ConfiguredPropertyTypeGroup.gender),
          placeholder: strings.addNewGenderType,
        },
      },
      {
        name: 'ages',
        type: 'select-multiple',
        options: (values) =>
          getLivestockAttributes(propertyTypes)(
            ConfiguredPropertyTypeGroup.tag,
            getLivestockParentIdForValue(values.type, propertyTypes)
          ).map((i) => ({
            ...i,
            color: getAgeColor(i.label) || Color.White,
          })),
        sortFunction: (options) =>
          options.sort((a: any, b: any) => b.label.localeCompare(a.label)),
        required: true,
        initialValue: mob.ages.map((num) => String(num)),
      },
      {
        name: 'classes',
        type: 'select-multiple',
        options: (values) => {
          const parentId = propertyTypes.find(
            (a) => a.type === values.type
          )?.id;

          const activeClasses = propertyTypes
            .filter(
              (a) =>
                a.group === ConfiguredPropertyTypeGroup.class &&
                a.parentId === parentId
            )
            .map((a) => ({
              label: a.label,
              value: a.type,
            }));

          return mob.classes.reduce((acc, a) => {
            const found = activeClasses.find((b) => b.value === a);
            if (found) {
              return acc;
            }
            return [...acc, { label: a, value: a }];
          }, activeClasses);
        },
        required: false,
        initialValue: mob.classes,
        addNew: {
          onSubmit: handleCreateCustomField(ConfiguredPropertyTypeGroup.class),
          placeholder: strings.addNewClassType,
        },
      },
      {
        name: 'size',
        type: 'number',
        min: 1,
        required: true,
        validation: Yup.number()
          .positive()
          .integer(strings.sizeValidation)
          .required(),
        initialValue: mob.size,
      },
      {
        name: 'DSE',
        type: 'float',
        label: formatMessage(
          {
            defaultMessage: '{STOCKING_UNIT}/head',
            description: 'Stocking rate per head',
          },
          {
            STOCKING_UNIT: stockingUnitLabel,
          }
        ),
        required: true,
        initialValue: formattedStockingRate,
        validation: Yup.number()
          .min(0, strings.stockingRateValidation)
          .required(),
      },
    ],
    error,
    loading,
    onSubmit,
    onCancel,
    onTouched,
  };

  return <FormBuilder {...form} />;
};
