import { defineMessages } from 'react-intl';

import { useI18n } from '@mobble/i18n/src';
import {
  formatStockingRate,
  StockingUnit,
} from '@mobble/models/src/model/Settings';
import { useSetting } from '@mobble/store/src/hooks';

import { Text, TextProps } from '../UI/Text';

const stockingUnitMessages = defineMessages({
  generic_quantity_unit_LSU_AUS: {
    defaultMessage: 'LSU',
    description: 'generic.quantity.unit.LSU_AUS',
  },
  generic_quantity_unit_AE: {
    defaultMessage: 'AE',
    description: 'generic.quantity.unit.AE',
  },
  generic_quantity_unit_SU: {
    defaultMessage: 'SU',
    description: 'generic.quantity.unit.SU',
  },
  generic_quantity_unit_AU: {
    defaultMessage: 'AU',
    description: 'generic.quantity.unit.AU',
  },
  generic_quantity_unit_DSE: {
    defaultMessage: 'DSE',
    description: 'generic.quantity.unit.DSE',
  },

  //

  generic_quantity_unit_LSU_AUS_description: {
    defaultMessage: 'Livestock Unit (Australia)',
    description: 'generic.quantity.unit.LSU_AUS.long',
  },
  generic_quantity_unit_AE_description: {
    defaultMessage: 'Adult Equivalent (Australia)',
    description: 'generic.quantity.unit.AE.long',
  },
  generic_quantity_unit_SU_description: {
    defaultMessage: 'Stocking Unit (New Zealand)',
    description: 'generic.quantity.unit.SU.long',
  },
  generic_quantity_unit_AU_description: {
    defaultMessage: 'Animal Unit (North America)',
    description: 'generic.quantity.unit.AU.long',
  },
  generic_quantity_unit_DSE_description: {
    defaultMessage: 'Dry Sheep Equivalent (Australia)',
    description: 'generic.quantity.unit.DSE.long',
  },
});

export const getStockingUnitI18n = (stockingUnit: StockingUnit) => {
  switch (stockingUnit) {
    case StockingUnit.LSU_AUS:
      return stockingUnitMessages.generic_quantity_unit_LSU_AUS;
    case StockingUnit.AE:
      return stockingUnitMessages.generic_quantity_unit_AE;
    case StockingUnit.SU:
      return stockingUnitMessages.generic_quantity_unit_SU;
    case StockingUnit.AU:
      return stockingUnitMessages.generic_quantity_unit_AU;
    case StockingUnit.DSE:
    default:
      return stockingUnitMessages.generic_quantity_unit_DSE;
  }
};

export const getStockingUnitI18nDescription = (stockingUnit: StockingUnit) => {
  switch (stockingUnit) {
    case StockingUnit.LSU_AUS:
      return stockingUnitMessages.generic_quantity_unit_LSU_AUS_description;
    case StockingUnit.AE:
      return stockingUnitMessages.generic_quantity_unit_AE_description;
    case StockingUnit.SU:
      return stockingUnitMessages.generic_quantity_unit_SU_description;
    case StockingUnit.AU:
      return stockingUnitMessages.generic_quantity_unit_AU_description;
    case StockingUnit.DSE:
    default:
      return stockingUnitMessages.generic_quantity_unit_DSE_description;
  }
};

export const useStockingRateOptions = () => {
  const { formatMessage } = useI18n();

  const stockingRateOptions = [
    {
      value: StockingUnit.DSE,
      label: formatMessage(getStockingUnitI18n(StockingUnit.DSE)),
      labelExtra: formatMessage(
        getStockingUnitI18nDescription(StockingUnit.DSE)
      ),
    },
    {
      value: StockingUnit.LSU_AUS,
      label: formatMessage(getStockingUnitI18n(StockingUnit.LSU_AUS)),
      labelExtra: formatMessage(
        getStockingUnitI18nDescription(StockingUnit.LSU_AUS)
      ),
    },
    {
      value: StockingUnit.AU,
      label: formatMessage(getStockingUnitI18n(StockingUnit.AU)),
      labelExtra: formatMessage(
        getStockingUnitI18nDescription(StockingUnit.AU)
      ),
    },
    {
      value: StockingUnit.AE,
      label: formatMessage(getStockingUnitI18n(StockingUnit.AE)),
      labelExtra: formatMessage(
        getStockingUnitI18nDescription(StockingUnit.AE)
      ),
    },
    {
      value: StockingUnit.SU,
      label: formatMessage(getStockingUnitI18n(StockingUnit.SU)),
      labelExtra: formatMessage(
        getStockingUnitI18nDescription(StockingUnit.SU)
      ),
    },
  ];

  return stockingRateOptions;
};

export type LocaleStockingRateProps = TextProps & {
  stockingRate: number;
};

export const LocaleStockingRate: React.FC<LocaleStockingRateProps> = ({
  stockingRate,
  ...rest
}) => {
  const stockingUnit = useSetting('stockingUnit') as StockingUnit;

  return (
    <Text {...rest}>{formatStockingRate(stockingRate, stockingUnit)}</Text>
  );
};
