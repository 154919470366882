import React from 'react';

import { Color } from '@mobble/colors';
import { useI18n } from '@mobble/i18n';

import { Box } from '@src/stories/Components/Layout/Box';
import { VStack } from '@src/stories/Components/Layout/Stack';
import {
  type InfoTileProps,
  InfoTiles,
} from '@src/stories/Components/UI/InfoTile';
import { Spinner } from '@src/stories/Components/UI/Spinner';

import { combineTotals, RootItem } from './usePropertyReportForRange';

export interface LivestockReconciliationTilesProps {
  reportData: RootItem[] | null;
}

export const LivestockReconciliationTiles: React.FC<
  LivestockReconciliationTilesProps
> = ({ reportData }) => {
  const { formatMessage } = useI18n();

  if (!reportData) {
    return (
      <VStack alignment="center">
        <Box spacing={4}>
          <Spinner color={Color.Black} />
        </Box>
      </VStack>
    );
  }

  const totals = reportData.reduce(
    (a, b) => {
      return {
        ...a,
        ...combineTotals(a, b.totals),

        startingHeadTypes: {
          ...a.startingHeadTypes,
          [b.type]: a.startingHeadTypes[b.type] + b.totals.startingHead,
        },
        closingHeadTypes: {
          ...a.closingHeadTypes,
          [b.type]: a.closingHeadTypes[b.type] + b.totals.closingHead,
        },
      };
    },
    {
      startingHead: 0,
      closingHead: 0,
      naturalIncrease: 0,
      purchased: 0,
      casualties: 0,
      sold: 0,

      startingHeadTypes: {
        Cattle: 0,
        Sheep: 0,
        Goats: 0,
        Other: 0,
      },
      closingHeadTypes: {
        Cattle: 0,
        Sheep: 0,
        Goats: 0,
        Other: 0,
      },
    }
  );

  const mobHeadExists =
    Object.values(totals.startingHeadTypes).some((value) => value > 0) ||
    Object.values(totals.closingHeadTypes).some((value) => value > 0);

  const tiles: InfoTileProps[] = [
    {
      id: 'totals',
      label: formatMessage({
        defaultMessage: 'Livestock Totals',
        description: 'dashboard.tile.livestock-reconciliation.totals.title',
      }),
      ...(mobHeadExists
        ? {
            values: [
              {
                label: formatMessage({
                  defaultMessage: 'Cattle',
                  description: 'dashboard.tile.total-livestock-by-type.cattle',
                }),
                value: totals.closingHeadTypes.Cattle || 0,
                previousValue: totals.startingHeadTypes.Cattle || 0,
              },
              {
                label: formatMessage({
                  defaultMessage: 'Sheep',
                  description: 'dashboard.tile.total-livestock-by-type.sheep',
                }),
                value: totals.closingHeadTypes.Sheep || 0,
                previousValue: totals.startingHeadTypes.Sheep || 0,
              },
              {
                label: formatMessage({
                  defaultMessage: 'Goats',
                  description: 'dashboard.tile.total-livestock-by-type.goats',
                }),
                value: totals.closingHeadTypes.Goats || 0,
                previousValue: totals.startingHeadTypes.Goats || 0,
              },
              {
                label: formatMessage({
                  defaultMessage: 'Other',
                  description: 'dashboard.tile.total-livestock-by-type.other',
                }),
                value: totals.closingHeadTypes.Other || 0,
                previousValue: totals.startingHeadTypes.Other || 0,
              },
            ].filter((item) => item.value > 0 || item.previousValue > 0),
          }
        : {
            value: formatMessage({
              defaultMessage: '-',
              description:
                'dashboard.tile.total-livestock-by-type.no-livestock',
            }),
          }),
    },

    {
      id: 'sold',
      label: formatMessage({
        defaultMessage: 'Livestock sold',
        description: 'reports.tile.livestock-reconciliation.totals.title',
      }),
      value: totals.sold,
    },
    {
      id: 'casualties',
      label: formatMessage({
        defaultMessage: 'Livestock casualties',
        description: 'reports.tile.livestock-reconciliation.casualties.title',
      }),
      value: totals.casualties,
    },
    {
      id: 'purchased',
      label: formatMessage({
        defaultMessage: 'Livestock purchased',
        description: 'reports.tile.livestock-reconciliation.purchased.title',
      }),
      value: totals.purchased,
    },
    {
      id: 'natural-increase',
      label: formatMessage({
        defaultMessage: 'Livestock natural increases',
        description:
          'reports.tile.livestock-reconciliation.natural-increase.title',
      }),
      value: totals.naturalIncrease,
    },
  ];

  return <InfoTiles items={tiles} />;
};
