import React from 'react';

import { ScreenHeader } from '@src/stories/Views/Misc/ScreenHeader';
import { NaturalIncreaseInfoTable } from '@src/stories/Views/NaturalIncrease/NaturalIncreaseInfoTable';

import * as ROUTE_NAME from '../config/routeNames';
import { ScreenRendererProps } from '../config/types';

import {
  useNaturalIncreaseScreen,
  useNaturalIncreasesScreenHeader,
} from './useNaturalIncreasesScreen';

export const NaturalIncreaseDetailHeader: React.FC<ScreenRendererProps> = ({
  route,
}) => {
  const { naturalIncreaseId } = route.params;
  const props = useNaturalIncreasesScreenHeader(naturalIncreaseId);

  return <ScreenHeader {...props} />;
};

export const NaturalIncreaseDetail: React.FC<ScreenRendererProps> = ({
  route,
}) => {
  const { naturalIncreaseId } = route.params;
  const { Prelude, paddock, properties, naturalIncrease } =
    useNaturalIncreaseScreen(naturalIncreaseId);

  if (Prelude) {
    return Prelude;
  }

  return (
    <NaturalIncreaseInfoTable
      naturalIncrease={naturalIncrease.entity}
      paddock={paddock.entity}
      propertyTypes={properties.selected?.types}
    />
  );
};

export default {
  name: ROUTE_NAME.NATURAL_INCREASE_DETAIL,
  header: NaturalIncreaseDetailHeader,
  component: NaturalIncreaseDetail,
};
