import {
  createSlice,
  createAsyncThunk,
  PayloadAction,
  current,
} from '@reduxjs/toolkit';
import { keyBy } from 'lodash';
import mobbleService from '@mobble/service';
import { User, UserRole } from '@mobble/models/src/model/User';
import {
  type ConfiguredPropertyType,
  type Property,
  type PropertyOrganisation,
} from '@mobble/models/src/model/Property';
import { type State as RootState } from '../';
import {
  ExtStatusPerAction,
  updateStatusForPayloadAction,
} from '../lib/ExtStatus';
import { builderAddExtStatusPerActionCasesForThunks } from '../lib/entitySliceFactory';
import { UserOfProperty } from '@mobble/service/src/ext/properties';
import { RequestErrors } from '@mobble/shared/src/core/Error';

export interface PropertiesState {
  extStatus: ExtStatusPerAction;
  selectedProperty?: string;
  entities: Property[];
}

export const defaultState: PropertiesState = {
  extStatus: {},
  entities: [],
};

const name = 'properties';

const slice = createSlice({
  name,
  initialState: defaultState,
  reducers: {
    flush: () => defaultState,
    select: (state, action: PayloadAction<string>) => {
      const exists = state.entities?.find(
        (property) => property.id === action.payload
      );
      state.selectedProperty = exists ? action.payload : state.selectedProperty;
    },
  },
  extraReducers(builder) {
    builder
      // properties/getAll
      .addCase(thunkGetAll.fulfilled, (state, action) => {
        state.extStatus = updateStatusForPayloadAction(state.extStatus)(
          action as any
        );

        state.entities = action.payload;

        const selectedPropertyExists =
          state.entities.findIndex((p) => p.id === state.selectedProperty) !==
          -1;

        const defaultProperty =
          state.entities.find((p) => p.currentUserRole === UserRole.Owner) ||
          state.entities[0];

        state.selectedProperty = selectedPropertyExists
          ? state.selectedProperty
          : defaultProperty?.id;
      })

      // properties/get
      .addCase(thunkGet.fulfilled, (state, action) => {
        return {
          ...state,
          properties: [
            ...(current(state.entities) || [])?.filter(
              (a) => a.id !== action.payload.id
            ),
            action.payload,
          ],
        };
      })
      // properties/create
      .addCase(thunkCreate.fulfilled, (state, action) => {
        state.entities.push(action.payload);
      })
      // properties/update
      .addCase(thunkUpdate.fulfilled, (state, action) => {
        state.entities = state.entities.map((p) => {
          if (p.id === action.payload.id) {
            return {
              ...p,
              name: action.payload.name ?? p.name,
              pic: action.payload.pic ?? p.pic,
            };
          }
          return p;
        });
      })
      // properties/delete
      .addCase(thunkDelete.fulfilled, (state, action) => {
        state.entities = state.entities.filter(
          (p) => p.id !== action.meta.arg.id
        );
      })
      // properties/getOrganisation
      .addCase(thunkGetOrganisation.fulfilled, (state, action) => {
        state.extStatus = updateStatusForPayloadAction(state.extStatus)(
          action as any
        );

        const organisationId = action.payload.id;
        state.entities = state.entities.map((property) => {
          if (property.organisationId === organisationId) {
            return {
              ...property,
              organisation: action.payload,
            };
          }
          return property;
        });
      })
      // properties/getUsers
      .addCase(thunkGetUsers.fulfilled, (state, action) => {
        state.extStatus = updateStatusForPayloadAction(state.extStatus)(
          action as any
        );

        const usersById = keyBy(action.payload, 'id');
        const organisationId = action.meta.arg;

        state.entities = state.entities.map((property) => {
          if (property.organisationId === organisationId) {
            return {
              ...property,
              users: property.users.map((user) => {
                const userFromAction = usersById[user.id];
                if (userFromAction) {
                  return {
                    ...user,
                    expires: userFromAction.expires,
                  };
                }
                return user;
              }),
            };
          }

          return property;
        });
      })
      // properties/addUser
      .addCase(thunkAddUser.fulfilled, (state, action) => {
        state.entities = state.entities.map((property) => {
          const users = (action.payload as any)
            .filter((u) => u.propertyId === property.id)
            .map((u) => ({
              id: u.id,
              name: u.name,
              email: u.email,
              role: u.role,
            }));

          return {
            ...property,
            users,
          };
        });
      })
      // properties/editUser
      .addCase(thunkEditUser.fulfilled, (state, action) => {
        state.entities = state.entities.map((property) => {
          const users = [...property.users, action.payload];
          return {
            ...property,
            users,
          };
        });
      })
      // properties/deleteUser
      .addCase(thunkDeleteUser.fulfilled, (state, action) => {
        state.entities = state.entities.map((property) => {
          const deletedUsersOfProperty = action.payload.filter(
            (p) => p.propertyId === property.id && !p.roles
          );

          if (deletedUsersOfProperty) {
            return {
              ...property,
              users: property.users.filter((user) =>
                deletedUsersOfProperty.find((u) => u.email === user.email)
              ),
            };
          }

          return property;
        });
      })
      // properties/addPropertyType
      .addCase(thunkAddPropertyType.fulfilled, (state, action) => {
        return {
          ...state,
          entities: current(state.entities).map((property) => {
            if (property.id === action.payload[0]) {
              return {
                ...property,
                types: [...property.types, action.payload[1]],
              };
            }
            return property;
          }),
        };
      })
      // properties/updatePropertyType
      .addCase(thunkUpdatePropertyType.fulfilled, (state, action) => {
        return {
          ...state,
          entities: current(state.entities).map((property) => {
            if (property.id === action.payload[0]) {
              return {
                ...property,
                types: property.types.map((type) => {
                  if (type.id === action.payload[1].id) {
                    return action.payload[1];
                  }
                  return type;
                }),
              };
            }
            return property;
          }),
        };
      })
      // properties/removePropertyType
      .addCase(thunkRemovePropertyType.fulfilled, (state, action) => {
        return {
          ...state,
          entities: current(state.entities).map((property) => {
            if (property.id === action.payload[0]) {
              return {
                ...property,
                types: property.types.filter(
                  (type) => type.id !== action.payload[1].id
                ),
              };
            }
            return property;
          }),
        };
      });

    builderAddExtStatusPerActionCasesForThunks(builder)([
      thunkGetAll,
      thunkGet,
      thunkAddPropertyType,
    ]);
  },
});

export const thunkGetAll = createAsyncThunk<Property[], void>(
  `${name}/getAll`,
  mobbleService.api.properties.get
);

export const thunkGet = createAsyncThunk<Property, string>(
  `${name}/get`,
  async (propertyId: string) => {
    const property = await mobbleService.api.properties.find(propertyId);
    if (!property) {
      throw new Error('Property not found');
    }
    return property;
  }
);

export const thunkCreate = createAsyncThunk<Property, any>(
  `${name}/create`,
  async (input, { dispatch }) => {
    const property = await mobbleService.api.properties.create(input);
    return property;
  }
);

export const thunkUpdate = createAsyncThunk<Partial<Property>, any>(
  `${name}/update`,
  async (input) => {
    const property = await mobbleService.api.properties.update(input);
    return property;
  }
);

export const thunkDelete = createAsyncThunk<void, Property>(
  `${name}/delete`,
  async (input) => {
    const property = await mobbleService.api.properties.delete(input);
    return property;
  }
);

export const thunkGetOrganisation = createAsyncThunk<
  PropertyOrganisation,
  string
>(`${name}/getOrganisation`, async (propertyId: string) => {
  const org = await mobbleService.api.properties.getOrganisation({
    id: propertyId,
  });
  return org;
});

export interface ThunkChangeUserInput {
  propertyIds: string[];
  organisationId: string;
  email: string;
  role: UserRole;
  expires?: string;
}

export const thunkGetUsers = createAsyncThunk<User[], string>(
  `${name}/getUsers`,
  async (propertyId: string) => {
    const users = await mobbleService.api.properties.getUsers(propertyId);
    return users;
  }
);

export const thunkAddUser = createAsyncThunk<User[], ThunkChangeUserInput>(
  `${name}/addUser`,
  async ({ propertyIds, organisationId, email, role, expires }) => {
    const user = await mobbleService.api.properties.addUser({
      organisationId,
      users: propertyIds.map((id) => ({
        email: email.toLowerCase(),
        propertyId: id,
        organisationId,
        roles: [role],
        // expires,
      })),
    });

    return user;
  }
);

export const thunkEditUser = createAsyncThunk<User, ThunkChangeUserInput>(
  `${name}/editUser`,
  async ({ propertyIds, organisationId, email, role }) => {
    const user = await mobbleService.api.properties.editUser({
      organisationId,
      users: propertyIds.map((id) => ({
        email: email.toLowerCase(),
        propertyId: id,
        organisationId,
        roles: [role],
      })),
    });

    return user;
  }
);

export interface ThunkDeleteUserInput {
  propertyIds: string[];
  organisationId: string;
  email: string;
}

export const thunkDeleteUser = createAsyncThunk<
  UserOfProperty[],
  ThunkDeleteUserInput
>(`${name}/deleteUser`, async ({ propertyIds, organisationId, email }) => {
  const user = await mobbleService.api.properties.deleteUser({
    organisationId,
    users: propertyIds.map((id) => ({
      email: email.toLowerCase(),
      propertyId: id,
      organisationId,
      roles: [],
    })),
  });
  return user;
});

export const thunkAddPropertyType = createAsyncThunk<
  [Property['id'], ConfiguredPropertyType],
  { propertyId: Property['id'] } & Partial<ConfiguredPropertyType>
>(`${name}/addPropertyType`, async (input, { getState }) => {
  const result = await mobbleService.api.propertyTypes.create(
    input.propertyId,
    input
  );
  return [input.propertyId, result];
});

export const thunkUpdatePropertyType = createAsyncThunk<
  [Property['id'], ConfiguredPropertyType],
  { propertyId: Property['id'] } & ConfiguredPropertyType
>(`${name}/updatePropertyType`, async (input, { getState }) => {
  const result = await mobbleService.api.propertyTypes.update(
    input.propertyId,
    input
  );
  return [input.propertyId, result];
});

export const thunkRemovePropertyType = createAsyncThunk<
  [Property['id'], ConfiguredPropertyType],
  { propertyId: Property['id'] } & ConfiguredPropertyType
>(`${name}/removePropertyType`, async (input, { getState }) => {
  await mobbleService.api.propertyTypes.remove(input.propertyId, input);
  return [input.propertyId, input];
});

export const { flush, select } = slice.actions;
export const { reducer } = slice;
