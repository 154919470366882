import { matchPath, useLocation, useParams } from 'react-router-dom';

import { toPath } from '@src/interfaces/Routing';
import * as ROUTE_NAMES from '@src/screens/config/routeNames';
import { useAppLayoutContext } from '@src/stories/Components/Layout/AppLayout';

import { type RouteName } from '../screens/config/types';

const ALL_ROUTE_NAMES =
  // : RouteName[]
  Object.values(ROUTE_NAMES);

export interface useActiveScreensResponse {
  activeScreen: null | RouteName;
  activeRouteParams: Record<string, any>;
  isActive: (...screens: string[]) => boolean;
}

export const useActiveScreens = (): useActiveScreensResponse => {
  const { params } = useAppLayoutContext();
  const routeParams = useParams();
  const location = useLocation();

  const [activeScreen, activeParams] = ALL_ROUTE_NAMES.reduce(
    (acc, routeName) => {
      if (!acc[0] || !acc[1]) {
        // @TODO handle this a bit better than matching twice
        let match = matchPath(routeName, location.pathname);
        if (!match) {
          match = matchPath(toPath(routeName), location.pathname);
        }

        if (match) {
          return [match.pattern.path, match.params];
        }
      }
      return acc;
    },
    [null, null]
  );

  const isActive = (...screens: string[]) =>
    screens.some((s) => activeScreen === s);

  return {
    isActive,
    activeScreen,
    activeRouteParams: { ...routeParams, ...params, ...activeParams },
  };
};
