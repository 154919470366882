import React from 'react';

import { useI18n } from '@mobble/i18n';
import { filterUsersOfUserRoles } from '@mobble/models/src/model/User';
import { useAuth } from '@mobble/store/src/hooks';
import { useProperties } from '@mobble/store/src/hooks/properties';

import { useLinking } from '@src/hooks/useLinking';
import * as ROUTE_NAME from '@src/screens/config/routeNames';
import { ScreenRendererProps } from '@src/screens/config/types';
import { LoadingScreen } from '@src/stories/Views/Misc/LoadingScreen';
import { ScreenHeader } from '@src/stories/Views/Misc/ScreenHeader';
import { SettingsBillingOverview } from '@src/stories/Views/Settings/SettingsBilling';

import {
  billingRegionToPhoneNumber,
  contactEmail,
  useBilling,
} from './hooks/useBilling';

export const SettingsBillingHeader: React.FC<ScreenRendererProps> = () => {
  const { formatMessage } = useI18n();
  const linkTo = useLinking();
  const goBack = () => {
    linkTo(ROUTE_NAME.SETTINGS_LIST);
  };

  return (
    <ScreenHeader
      title={formatMessage({
        description: 'screen.title.billing',
        defaultMessage: 'Billing',
      })}
      breadcrumbs={[
        {
          title: formatMessage({
            description: 'screen.title.settings',
            defaultMessage: 'Settings',
          }),
          href: ROUTE_NAME.SETTINGS_LIST,
        },
      ]}
      onGoBack={goBack}
    />
  );
};

export const SettingsBilling: React.FC<ScreenRendererProps> = () => {
  const properties = useProperties();
  const propertyUsers = filterUsersOfUserRoles(properties.selected.users);

  const me = useAuth();
  const currentUserCount = propertyUsers.length || 0;

  const {
    subscription,
    products,
    error: billingActionError,
    handleCheckout,
    handleManageSubscription,
  } = useBilling();
  const phoneNumber = billingRegionToPhoneNumber(me.user.billingRegion);

  if (subscription.loadingStatus === 'loading') {
    return <LoadingScreen />;
  }

  return (
    <SettingsBillingOverview
      currentUserCount={currentUserCount}
      subscription={subscription.currentSubscription}
      billingActionError={billingActionError}
      products={products}
      onClickManageSubscription={handleManageSubscription}
      getCheckoutLink={handleCheckout}
      trialEnded={subscription.trialEnded}
      daysUntilTrialEnd={subscription.daysUntilTrialEnd}
      displayOptions={subscription.displayOptions}
      contactEmail={contactEmail}
      contactPhone={phoneNumber}
    />
  );
};

export default {
  name: ROUTE_NAME.SETTINGS_BILLING,
  header: SettingsBillingHeader,
  component: SettingsBilling,
};
