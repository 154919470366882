import React from 'react';

import { reactIntersperse } from '../../../interfaces/React';
import { Box } from '../../Components/Layout/Box';
import { Spacer } from '../../Components/Layout/Spacer';
import { useMapPluginsContext } from '../Context';

import Bounds from './Bounds';
import Camera from './Camera';
import Creator from './Creator';
import Items from './Items';
import ItemsWhileDrawing from './ItemsWhileDrawing';
import Measure from './Measure';
import Pan from './Pan';
import StyleDetail from './StyleDetail';
import User from './User';

import styles from './index.scss';

const AllPlugins = {
  Bounds,
  Camera,
  Creator,
  Items,
  ItemsWhileDrawing,
  Measure,
  Pan,
  StyleDetail,
  User,
};

export type MapPluginType =
  | 'bounds'
  | 'camera'
  | 'creator'
  | 'items'
  | 'items-while-drawing'
  | 'measure'
  | 'pan'
  | 'style-detail'
  | 'user';

export type MapPluginTypes =
  | 'standard'
  | 'standard-restricted'
  | 'edit'
  | 'display'
  | 'display-points'
  | MapPluginType[];

export const mapPluginTypesToArray = (
  types: MapPluginTypes
): MapPluginType[] => {
  switch (types) {
    case 'standard':
      return [
        'camera',
        'style-detail',
        'pan',
        'bounds',
        'items',
        'measure',
        'user',
      ];
    case 'standard-restricted':
      return ['camera', 'bounds', 'items-while-drawing', 'user'];
    case 'edit':
      return ['camera', 'items-while-drawing', 'creator'];
    case 'display':
      return ['camera', 'items'];
    case 'display-points':
      return ['camera', 'items', 'creator'];
    default:
      return types;
  }
};

interface Plugin {
  Toolbar?: React.FC;
  Effect?: React.FC;
  Content?: React.FC;
}

const plugins: Record<MapPluginType, Plugin> = {
  'style-detail': AllPlugins.StyleDetail,
  bounds: AllPlugins.Bounds,
  camera: AllPlugins.Camera,
  creator: AllPlugins.Creator,
  items: AllPlugins.Items,
  'items-while-drawing': AllPlugins.ItemsWhileDrawing,
  measure: AllPlugins.Measure,
  pan: AllPlugins.Pan,
  user: AllPlugins.User,
};

export const Toolbar: React.FC = () => {
  const { types } = useMapPluginsContext();

  const buttons = mapPluginTypesToArray(types)
    .map((type) => {
      const C = (plugins as any)[type]?.Toolbar;
      return C ? <C key={type} /> : null;
    })
    .filter(Boolean) as React.ReactElement[];

  return (
    <Box className={styles.container}>
      {reactIntersperse(<Spacer y={1} />, buttons)}
    </Box>
  );
};

export const ToolEffects: React.FC = () => {
  const { types } = useMapPluginsContext();

  const effects = mapPluginTypesToArray(types).map((type) => {
    const C = (plugins as any)[type]?.Effect;
    return C ? <C key={type} /> : null;
  });

  return <>{effects}</>;
};

export const ToolContents: React.FC = () => {
  const { types } = useMapPluginsContext();

  const contents = mapPluginTypesToArray(types)
    .map((type) => {
      const C = (plugins as any)[type]?.Content;
      return C ? <C key={type} /> : null;
    })
    .filter(Boolean) as React.ReactElement[];

  return <Box className={styles.contentsContainer}>{contents}</Box>;
};
