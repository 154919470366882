import React from 'react';

import { useI18n } from '@mobble/i18n';
import { Logo as IconLogo, MobbleIcon } from '@mobble/icon';

import styles from './logo.scss';

export interface LogoProps {
  icon?: boolean;
  white?: boolean;
  className?: string;
}

export const Logo: React.FC<LogoProps> = ({ icon, white, className }) => {
  const { formatMessage } = useI18n();
  return icon ? (
    <MobbleIcon
      className={[styles.icon, className].filter(Boolean).join(' ')}
      role="graphics-symbol"
      alt={formatMessage({
        defaultMessage: 'Mobble icon',
        description: 'Mobble icon image alt text',
      })}
    />
  ) : (
    <IconLogo
      white={white}
      className={[styles.logo, className].filter(Boolean).join(' ')}
      role="img"
      alt={formatMessage({
        defaultMessage: 'Mobble logo',
        description: 'Mobble logo image alt text',
      })}
    />
  );
};
