import React from 'react';

import { useI18n } from '@mobble/i18n';
import { getTotalLivestockByTypes } from '@mobble/models/src/model/Mob';
import {
  formatStockingRate,
  StockingUnit,
} from '@mobble/models/src/model/Settings';
import { formatDate } from '@mobble/shared/src/core/Date';
import { useEntitiesRefresher } from '@mobble/shared/src/hooks/useEntitiesRefresher';
import {
  useMobs,
  usePaddocks,
  useProperties,
  useRainGauges,
  useSetting,
  useTasks,
} from '@mobble/store/src/hooks';

import { useDateRange } from '@src/hooks/useDateRange';
import * as ROUTE_NAME from '@src/screens/config/routeNames';
import { ScreenRendererProps } from '@src/screens/config/types';
import { getStockingUnitI18n } from '@src/stories/Components/Locale/LocaleStockingUnit';
import { InfoTileProps } from '@src/stories/Components/UI/InfoTile';
import { Dashboard as DashboardView } from '@src/stories/Views/Dashboard/Dashboard';
import { ScreenHeader } from '@src/stories/Views/Misc/ScreenHeader';
import { usePropertyStockingRatesForRange } from '@src/stories/Views/Reports/usePropertyStockingRatesForRange';

import { useSubscription } from '../Settings/hooks/useBilling';

import { useDashboardSuggestions } from './hooks/useDashboardSuggestions';
import { useDashboardTiles } from './hooks/useDashboardTiles';
import { toInteger } from '@mobble/shared/src/core/Number';

const DEFAULT_INITIAL_DATE = '2000-01-01';
const CURRENT_DATE = formatDate(new Date(), 'YYYY-MM-DD');
const DATE_RANGE = `${DEFAULT_INITIAL_DATE}--${CURRENT_DATE}`;

export const DashboardHeader: React.FC<ScreenRendererProps> = (props) => {
  const { formatMessage } = useI18n();

  return (
    <ScreenHeader
      title={formatMessage({
        defaultMessage: 'Dashboard',
        description: 'screen.title.dashboard',
      })}
      onToggleDrawer={props.drawer.toggle}
    />
  );
};

export const Dashboard: React.FC<ScreenRendererProps> = () => {
  const { formatMessage } = useI18n();
  const stockingUnit = useSetting('stockingUnit') as StockingUnit;
  const properties = useProperties();
  const propertyId = properties.selected?.id;
  const mobs = useMobs(propertyId);
  const paddocks = usePaddocks(propertyId);
  const tasks = useTasks(propertyId);
  const rainGauges = useRainGauges(propertyId);

  const isOwner = properties.selected.currentUserRole === 'owner';
  const subscriptionDetails = useSubscription();

  const { totalDSE, totalArea, totalDSEPerAreaUnit, activeTasks } =
    useDashboardTiles({
      mobs: mobs.entities,
      paddocks: paddocks.entities,
      tasks: tasks.entities,
    });
  const { range } = useDateRange({ rangeFromRoute: DATE_RANGE });
  const propertyStockingRatesData = usePropertyStockingRatesForRange({
    propertyId: properties.selected.id,
    range,
  });

  const suggestionDetails = useDashboardSuggestions();
  const mobTypesTotalValus = getTotalLivestockByTypes(mobs.entities);

  const convertStockingRate = (stockingRate: number) =>
    formatStockingRate(stockingRate, stockingUnit);
  const stockingUnitLabel = formatMessage(getStockingUnitI18n(stockingUnit));

  useEntitiesRefresher([mobs, paddocks, tasks, rainGauges], propertyId);

  const doMobTotalsByTypeExist = Object.values(mobTypesTotalValus).some(
    (value) => value > 0
  );

  const infoTiles: InfoTileProps[] = [
    {
      id: 'total-stocking_rate',
      label: formatMessage(
        {
          defaultMessage: 'Total {STOCKING_UNIT}',
          description: 'dashboard.tile.total-stocking_rate.title',
        },
        {
          STOCKING_UNIT: stockingUnitLabel,
        }
      ),
      value: toInteger(convertStockingRate(totalDSE)),
    },
    {
      id: 'total-livestock-by-type',
      label: formatMessage({
        defaultMessage: 'Livestock Totals',
        description: 'dashboard.tile.total-livestock-by-type.title',
      }),
      ...(doMobTotalsByTypeExist
        ? {
            values: [
              mobTypesTotalValus.Cattle && {
                label: formatMessage({
                  defaultMessage: 'Cattle',
                  description:
                    'dashboard.tile.total-livestock-by-type.cattle.label',
                }),
                value: mobTypesTotalValus.Cattle || 0,
              },
              mobTypesTotalValus.Sheep && {
                label: formatMessage({
                  defaultMessage: 'Sheep',
                  description:
                    'dashboard.tile.total-livestock-by-type.sheep.label',
                }),
                value: mobTypesTotalValus.Sheep || 0,
              },
              mobTypesTotalValus.Goats && {
                label: formatMessage({
                  defaultMessage: 'Goats',
                  description:
                    'dashboard.tile.total-livestock-by-type.goats.label',
                }),
                value: mobTypesTotalValus.Goats || 0,
              },
              mobTypesTotalValus.Other && {
                label: formatMessage({
                  defaultMessage: 'Other',
                  description:
                    'dashboard.tile.total-livestock-by-type.other.label',
                }),
                value: mobTypesTotalValus.Other || 0,
              },
            ],
          }
        : {
            value: formatMessage({
              defaultMessage: '-',
              description:
                'dashboard.tile.total-livestock-by-type.no-livestock',
            }),
          }),
    },

    {
      id: 'total-grazeable-area',
      label: formatMessage(
        {
          defaultMessage: 'Total Grazeable <b>{UNIT}</b>',
          description: 'dashboard.tile.total-grazeable-area.title',
        },
        { UNIT: totalArea.unit }
      ),
      value: toInteger(totalArea.value),
    },
    {
      id: 'total-stocking_rate-per-area',
      label: formatMessage(
        {
          defaultMessage: 'Total {STOCKING_UNIT}/<b>{UNIT}</b>',
          description: 'dashboard.tile.total-stocking_rate-area.title',
        },
        {
          STOCKING_UNIT: stockingUnitLabel,
          UNIT: totalArea.unit,
        }
      ),
      value: convertStockingRate(totalDSEPerAreaUnit.totalDSEPerArea),
    },
    {
      id: 'active-tasks',
      label: formatMessage({
        defaultMessage: 'Active Tasks',
        description: 'dashboard.tile.active-tasks.title',
      }),
      value: activeTasks,
    },
  ];

  const mobsData = mobs.entities.length ? mobs.entities : null;
  const rainGaugeData = rainGauges.entities.length ? rainGauges.entities : null;

  const displayTialStatus =
    subscriptionDetails.loadingStatus === 'ready' &&
    subscriptionDetails.isTrial &&
    isOwner;

  const trialCountDown = subscriptionDetails.daysUntilTrialEnd;

  return (
    <DashboardView
      infoTiles={infoTiles}
      mobs={mobsData}
      rainGauges={rainGaugeData}
      dashboardSuggestions={suggestionDetails}
      propertyStockingRatesData={propertyStockingRatesData}
      displayTialStatus={displayTialStatus}
      trialCountDown={trialCountDown}
    />
  );
};

export default {
  name: ROUTE_NAME.DASHBOARD,
  header: DashboardHeader,
  component: Dashboard,
};
