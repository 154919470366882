import { useState } from 'react';
import classNames from 'classnames/bind';

import { useI18n } from '@mobble/i18n';
import { FilterItem } from '@mobble/models';
import { filterMobs, Mob } from '@mobble/models/src/model/Mob';
import { Paddock } from '@mobble/models/src/model/Paddock';
import {
  getPaddocksInSamePaddockGroupAsPaddock,
  type PaddockGroup,
} from '@mobble/models/src/model/PaddockGroup';
import {
  useMobs,
  usePaddock,
  usePaddockGroups,
  usePaddocks,
  useProperties,
} from '@mobble/store/src/hooks';

import { MobCard } from '@src/components';
import Checkbox from '@src/components/Checkbox';
import { Spinner } from '@src/stories/Components/UI/Spinner';
import { Text } from '@src/stories/Components/UI/Text';
import { Toggle } from '@src/stories/Components/UI/Toggle';
import { ModalFlyUp } from '@src/stories/Components/UX/ModalFlyUp';

import { SelectedMobsOptions } from '../Mob/List/SelectedMobsOptions';

import styles from './mobsSelectListModal.scss';
const cx = classNames.bind(styles);

export interface MobsSelectProps {
  title?: string;
  paddockId: Paddock['id'];
  mobsFilter: FilterItem[];
  selectedMobs?: Mob['id'][];
  onSelectMob: (mobId: Mob['id'][]) => void;
  onClickMob?: (mob: Mob) => void;
  onClose?: () => void;
}

const getMobsOnPaddockGroup =
  (mobs: Mob[], paddocks: Paddock[], paddockGroups: PaddockGroup[]) =>
  (paddock: Paddock): [Mob[], Mob[]] => {
    const mobIdsOnPaddock = paddock.mobs;

    const mobIdsOnPaddockGroup = getPaddocksInSamePaddockGroupAsPaddock(
      paddockGroups,
      paddocks
    )(paddock.id)
      .flatMap((p) => p.mobs)
      .filter((mobId) => !mobIdsOnPaddock.includes(mobId));

    const mobIdToMob = (mobId: string): null | Mob =>
      mobs.find((m) => m.id === mobId);

    const mobIdsToMobs = (mobIds: string[]): Mob[] => {
      return mobIds.map(mobIdToMob).filter(Boolean);
    };

    return [mobIdsToMobs(mobIdsOnPaddock), mobIdsToMobs(mobIdsOnPaddockGroup)];
  };

export const MobsSelectListModal = ({
  title,
  paddockId,
  mobsFilter,
  selectedMobs,
  onSelectMob,
  onClickMob,
  onClose,
}: MobsSelectProps) => {
  const { formatMessage } = useI18n();
  const properties = useProperties();
  const paddock = usePaddock(paddockId);
  const propertyId = paddock.entity?.propertyId;
  const property = properties.entities.find((p) => p.id === propertyId);
  const paddocks = usePaddocks(propertyId);
  const paddockGroups = usePaddockGroups(propertyId);
  const mobs = useMobs(propertyId);
  const [localSelectedMobs, setLocalSelectedMobs] = useState<Mob['id'][]>(
    selectedMobs ?? []
  );
  const [filtersEnabled, setFiltersEnabled] = useState(mobsFilter.length > 0);

  if (!paddock.entity) {
    return <Spinner />;
  }

  const rootClasses = cx({
    MobsSelectListModal: true,
    hasSelectedMobs: localSelectedMobs.length > 0,
  });

  const [allMobsOnPaddock, allMobsOnPaddockGroup] = getMobsOnPaddockGroup(
    mobs.entities,
    paddocks.entities,
    paddockGroups.entities
  )(paddock.entity);
  const [filteredMobsOnPaddock, filteredMobsOnPaddockGroup] =
    getMobsOnPaddockGroup(
      filterMobs(paddocks.entities)(allMobsOnPaddock, mobsFilter),
      paddocks.entities,
      paddockGroups.entities
    )(paddock.entity);

  const mobsOnPaddock = filtersEnabled
    ? filteredMobsOnPaddock
    : allMobsOnPaddock;
  const mobsOnPaddockGroup = filtersEnabled
    ? filteredMobsOnPaddockGroup
    : allMobsOnPaddockGroup;

  const selectedMobsData = localSelectedMobs
    .map((mobId) => {
      return allMobsOnPaddock.find((m) => m.id === mobId);
    })
    .filter(Boolean);

  const handleToggleFilters = () => {
    setFiltersEnabled(!filtersEnabled);
  };

  const handleToggleSelection = (mobId: Mob['id']) => {
    const newSelectedMobs = localSelectedMobs.includes(mobId)
      ? localSelectedMobs.filter((id) => id !== mobId)
      : [...localSelectedMobs, mobId];
    setLocalSelectedMobs(newSelectedMobs);
    onSelectMob(newSelectedMobs);
  };

  const renderItem = (mob: Mob) => {
    return (
      <li key={mob.id} className={styles.itemContainer}>
        <Checkbox
          id={`mobs-select-list-checkbox-${mob.id}`}
          checked={localSelectedMobs.includes(mob.id)}
          onChange={() => handleToggleSelection(mob.id)}
        />
        <MobCard
          mob={mob}
          paddockName={paddock.entity?.name}
          propertyTypes={property.types ?? []}
          onClick={onClickMob}
          className={styles.mobCard}
        />
      </li>
    );
  };

  return (
    <ModalFlyUp
      isOpen={true}
      onClose={onClose}
      title={title}
      footer={
        selectedMobsData.length > 0 && (
          <SelectedMobsOptions mobs={selectedMobsData} />
        )
      }
      className={rootClasses}
    >
      <section>
        {allMobsOnPaddock.length > filteredMobsOnPaddock.length && (
          <div className={styles.filterContainer}>
            {filtersEnabled && (
              <Text tagName="p">
                {formatMessage(
                  {
                    description: 'Mobs Select List filtered mobs description',
                    defaultMessage:
                      '{count, plural, one {# mob is} other {# mobs are}} hidden by filters',
                  },
                  {
                    count:
                      allMobsOnPaddock.length - filteredMobsOnPaddock.length,
                  }
                )}
              </Text>
            )}
            {!filtersEnabled && (
              <Text tagName="p">
                {formatMessage(
                  {
                    description: 'Mobs Select List mobs in paddock description',
                    defaultMessage:
                      '{count, plural, one {# mob} other {# mobs}} in paddock',
                  },
                  {
                    count: allMobsOnPaddock.length,
                  }
                )}
              </Text>
            )}

            <Toggle
              id="mobs-select-list-filter-toggle"
              aria-label={formatMessage({
                description: 'Mobs select list Show All toggle label',
                defaultMessage: 'Mobs filter toggle',
              })}
              checked={filtersEnabled}
              onChange={handleToggleFilters}
            />
          </div>
        )}
        {mobsOnPaddock.length === 0 && (
          <div className={styles.mobsEmpty}>
            <Text variant="body" tagName="h2" bold>
              {formatMessage({
                defaultMessage:
                  'There are currently no mobs visible on this paddock',
                description: 'Mobs select list empty messag',
              })}
            </Text>
          </div>
        )}

        {mobsOnPaddock.length > 0 && <ul>{mobsOnPaddock.map(renderItem)}</ul>}

        {mobsOnPaddockGroup.length > 0 && (
          <div className={styles.mobsOnPaddockGroup}>
            <Text variant="body" tagName="h2" bold>
              {formatMessage({
                defaultMessage: 'Mobs from paddocks with open gate',
                description: 'mobs.list.result.paddock_group.header',
              })}
            </Text>

            <ul>{mobsOnPaddockGroup.map(renderItem)}</ul>
          </div>
        )}
      </section>
    </ModalFlyUp>
  );
};
