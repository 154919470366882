import React from 'react';
import { defineMessages } from 'react-intl';

import { useI18n,useMessages } from '@mobble/i18n';
import {
  type MapAsset,
  type MapAssetType,
  toLength,
} from '@mobble/models/src/model/MapAsset';

import { quantityToLocaleI18nItem } from '../../Components/Locale/LocaleQuantity';
import { InfoRow, type InfoRowProps } from '../../Components/UI/InfoRow';

export interface MapAssetInfoTableProps {
  mapAsset: MapAsset;
}

const messages = defineMessages({
  description: {
    description: 'map_asset.info.table.row.description.label',
    defaultMessage: 'Description',
  },
  length: {
    description: 'map_asset.info.table.row.length.label',
    defaultMessage: 'Length',
  },
  name: {
    description: 'map_asset.info.table.row.name.label',
    defaultMessage: 'Name',
  },
  type: {
    description: 'map_asset.info.table.row.type.label',
    defaultMessage: 'Type of asset',
  },
});

export const MapAssetInfoTable: React.FC<MapAssetInfoTableProps> = ({
  mapAsset,
}) => {
  const strings = useMessages(messages);
  const { formatMessage } = useI18n();

  const typeToI18n = (type: MapAssetType) => ({
    id: `map_assets.type.${type}`,
    defaultMessage: [],
  });

  const length = toLength(mapAsset);

  const rows: InfoRowProps[] = [
    {
      label: strings.name,
      value: mapAsset.name,
    },
    {
      label: strings.type,
      value: formatMessage(typeToI18n(mapAsset.map.type)),
    },
    {
      label: strings.description,
      value: mapAsset.description || '-',
    },
    length
      ? {
          label: strings.length,
          value: quantityToLocaleI18nItem(length),
        }
      : null,
  ].filter(Boolean) as InfoRowProps[];

  return (
    <>
      {rows.map((row) =>
        Array.isArray(row) ? (
          <>
            {row.map((r) => (
              <InfoRow {...r} key={r.label} />
            ))}
          </>
        ) : (
          <InfoRow key={row.label} {...row} />
        )
      )}
    </>
  );
};
