import { useState } from 'react';

import { useI18n } from '@mobble/i18n/src';

import { DashboardSuggestionDetails } from '@src/screens/Dashboard/hooks/useDashboardSuggestions';
import { Box } from '@src/stories/Components/Layout/Box';
import { Spacer } from '@src/stories/Components/Layout/Spacer';
import { HStack } from '@src/stories/Components/Layout/Stack';
import { Icon } from '@src/stories/Components/UI/Icon';
import { Text } from '@src/stories/Components/UI/Text';
import { Clickable } from '@src/stories/Components/UX/Clickable';
import { ModalFlyUp } from '@src/stories/Components/UX/ModalFlyUp';
import { ProgressBar } from '@src/stories/Components/UX/ProgressBar';

import { SuggestionCard } from './SuggestionCard';

import styles from './dashboardSetUpProperty.scss';

export const DashboardSetUpProperty: React.FC<{
  dashboardSuggestions: DashboardSuggestionDetails;
}> = ({ dashboardSuggestions }) => {
  const { formatMessage } = useI18n();
  const [isSuggestionModalOpen, setIsSuggestionModalOpen] = useState(false);

  const currentProgress = dashboardSuggestions.suggestionCount || 1;
  const totalProgress = dashboardSuggestions.totalSuggestionCount + 1;

  return (
    <>
      <Box className={styles.setUpTile}>
        <Text
          variant="larger"
          bold
          i18n={{ key: 'dashboard.tile.suggestions.title' }}
        />
      </Box>
      <Clickable onClick={() => setIsSuggestionModalOpen(true)} fullWidth>
        <Box className={styles.setUpPropertyContainer}>
          <HStack alignment="center">
            <Text
              className={styles.setUpPropertyText}
              i18n={formatMessage({
                defaultMessage: 'You have',
                description: 'dashboard.setupProperty.youHave',
              })}
            />
            <Spacer x={1} />
            <Text className={styles.setUpPropertyCount}>
              {dashboardSuggestions.suggestions.length}
            </Text>
            <Spacer x={1} />
            <Text
              className={styles.setUpPropertyText}
              i18n={formatMessage({
                defaultMessage: 'suggestions to set up your property',
                description: 'dashboard.setupProperty.suggestionsToSetUp',
              })}
            />
            <Spacer x={2} />
            <Icon name="chevron-right" />
          </HStack>
          <Box className={styles.progressBarContainer}>
            <Spacer y={4} />
            <ProgressBar value={currentProgress} max={totalProgress} />
          </Box>
        </Box>
      </Clickable>
      <ModalFlyUp
        isOpen={isSuggestionModalOpen}
        title="Settting Up Property"
        onClose={() => setIsSuggestionModalOpen(false)}
      >
        <Box spacing={2}>
          {dashboardSuggestions.suggestions.map((option) => (
            <SuggestionCard
              key={option.id}
              option={option}
              removeSuggestion={dashboardSuggestions.removeSuggestion}
            />
          ))}
        </Box>
      </ModalFlyUp>
    </>
  );
};
